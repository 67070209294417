import { createSlice, PayloadAction, CaseReducer } from '@reduxjs/toolkit';
import { GetGiftCardServiceQuery, GetGiftCardsServiceData } from '@root/modules/gift-cards/services/get-gift-cards.service';
import { IRemoteData } from '@root/shared/remote-data';


export enum GiftCardsStatusOptions {
  ALL = 'All',
  ACTIVE = 'Active',
  REDEEMED = 'Redeemed'
}

export type GiftCardsTempFilter = Partial<Pick<GetGiftCardServiceQuery, 'searchParam'>>;

export interface IGiftCardsState extends IRemoteData<GetGiftCardsServiceData> {
  query: GetGiftCardServiceQuery;
  tempFilter: GiftCardsStatusOptions;
}
export type GiftCardsStateFilters = Partial<GetGiftCardServiceQuery>;

export namespace GiftCardAction {
  export type FetchPending = PayloadAction;
  export type FetchFulfilled = PayloadAction<GetGiftCardsServiceData>;
  export type FetchRejected = PayloadAction<string>
  export type TempFiltersChanged = PayloadAction<GiftCardsStatusOptions>;
  export type FiltersChanged = PayloadAction<GiftCardsStateFilters>;
  export type PageChanged = PayloadAction<number>;
  // export type PayRequestedGiftCardsPending = PayloadAction<RequestedOrder>;
}

export type GiftCardReducer = {
  fetchPending: CaseReducer<IGiftCardsState, GiftCardAction.FetchPending>;
  fetchFulfilled: CaseReducer<IGiftCardsState, GiftCardAction.FetchFulfilled>;
  fetchRejected: CaseReducer<IGiftCardsState, GiftCardAction.FetchRejected>;
  tempFiltersChanged: CaseReducer<IGiftCardsState, GiftCardAction.TempFiltersChanged>;
  filtersChanged: CaseReducer<IGiftCardsState, GiftCardAction.FiltersChanged>;
  pageChanged: CaseReducer<IGiftCardsState, GiftCardAction.PageChanged>;
};

export const giftCardsSlice = createSlice<IGiftCardsState, GiftCardReducer, 'giftCards'>({
  name: 'giftCards',
  initialState: {
    query: {
      page: 1,
      limit: 10,
      sortDirection: 'DESC',
    },
    tempFilter: GiftCardsStatusOptions.ALL,
    data: { totalPages: 0, totalItems: 0, data: [] },
    isLoading: false,
    error: null,
  },
  reducers: {
    fetchPending: (state) => {
      state.isLoading = true;
    },
    fetchFulfilled: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    fetchRejected: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    tempFiltersChanged: (state, action) => {
      state.tempFilter = action.payload;
    },
    filtersChanged: (state, action) => {
      state.query = { ...state.query, ...action.payload, page: 1 };
    },
    pageChanged: (state, action) => {
      state.query.page = action.payload;
    },
  },
});

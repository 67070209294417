import { call, delay, fork, put } from '@redux-saga/core/effects';
import { BE_BACKOFFICE_TOKEN_PARAM, ENTRY_TOKEN, SHOP_TOKEN_PARAM } from '@root/modules/auth/constants';
import { authSlice } from '@root/modules/auth/redux';
import { decryptSSOService, signInFinalService } from '@root/modules/auth/services';
import { kycSlice } from '@root/modules/kyc/redux/kyc.slice';
import { externalRedirect, isDevelopmentMode, notify } from '@root/shared/utils';
import isEmpty from 'lodash/isEmpty';
import queryString from 'query-string';
import { SagaReturnType } from 'redux-saga/effects';

const BETWEEN_PORTALS_TOKEN = 'tempToken';

function* finalizeSSOWorker({ token }) {
  const finalResult = yield call(signInFinalService, { token });
  if (finalResult.status === 200) {
    // ! We skip redirection till we have only one instance

    const { wrongDomain, redirectionUrl, redirectionToken } = finalResult.payload.loginData;
    if (wrongDomain && !isDevelopmentMode) {
      return externalRedirect(`https://${redirectionUrl}?${BETWEEN_PORTALS_TOKEN}=${redirectionToken}`);
    }
    yield put(authSlice.actions.loggedIn(finalResult.payload));
  } else {
    notify({ type: 'danger', text: "Sorry we weren't able to authorize you with the link, please use you credential to login", title: 'Error' });
    yield put(authSlice.actions.ssoRejected(finalResult.payload));
    yield delay(3000);
    window.location.href = window.location.origin + '/login';
  }
}

function* beBackOfficeSSOWorker({ token }) {
  const result: SagaReturnType<typeof decryptSSOService> = yield call(decryptSSOService, encodeURIComponent(token));
  if (result.status === 200) {
    const { wrongDomain, redirectionUrl, redirectionToken } = result.payload;

    if (wrongDomain && !isDevelopmentMode) {
      return externalRedirect(`https://${redirectionUrl}?${BETWEEN_PORTALS_TOKEN}=${redirectionToken}`);
    }

    yield call(finalizeSSOWorker, { token: redirectionToken });
  } else {
    notify({ type: 'danger', text: result.payload, title: 'Error' });
    yield put(authSlice.actions.ssoRejected(result.payload));
  }
}

export function* checkSSOWorker() {
  const query = queryString.parse(window.location.search, { parseNumbers: true });

  const noSSOParams = isEmpty(query);

  if (noSSOParams) {
    return false;
  }

  if (query[BE_BACKOFFICE_TOKEN_PARAM] || !!query[SHOP_TOKEN_PARAM] || !!query[ENTRY_TOKEN] || !!query[BETWEEN_PORTALS_TOKEN]) {
    yield put(authSlice.actions.loggedOut());
  }

  switch (true) {
    case !!query[BE_BACKOFFICE_TOKEN_PARAM]: {
      yield fork(beBackOfficeSSOWorker, { token: query[BE_BACKOFFICE_TOKEN_PARAM] });
      return true;
    }

    case !!query[SHOP_TOKEN_PARAM]: {
      yield fork(finalizeSSOWorker, { token: query[SHOP_TOKEN_PARAM] });
      return true;
    }

    case !!query[BETWEEN_PORTALS_TOKEN]: {
      yield fork(finalizeSSOWorker, { token: query[BETWEEN_PORTALS_TOKEN] });
      return true;
    }

    case !!query[ENTRY_TOKEN]: {
      // @ts-ignore
      yield put(kycSlice.actions.setActivateOrderId(query['orderId']));
      yield fork(finalizeSSOWorker, { token: query[ENTRY_TOKEN] });
      return true;
    }

    default:
      return false;
  }
}

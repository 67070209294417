import { Input } from '@root/shared/ui';
import { ChangeEvent, useState } from 'react';
import { MenuList, MenuListContainer, MenuListItem } from './email-autocomplete-dropdown.ui';

const emailDomains = ['gmail.com', 'hotmail.com', 'yahoo.com', 'outlook.com', 'aol.com', 'live.com', 'msn.com', 'googlemail.com', 'icloud.com', 'me.com'];

export const EmailAutocompleteDropdown = (props) => {
  const { value, error, onBlur, onChange, name, setFieldValue } = props;
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [changeEvent, setChangeEvent] = useState<ChangeEvent<HTMLInputElement> | null>(null);

  const selectEmail = (item) => {
    const emailValue = `${value.split('@')[0]}@${item}`;

    setFieldValue(name, emailValue);
    return onChange({ ...changeEvent, target: { ...changeEvent?.target, value: emailValue } });
  };

  const touchEnd = (item) => {
    selectEmail(item);
    return false;
  };

  const mouseDown = (item) => {
    selectEmail(item);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const haveAt = event.target.value.split('@').length - 1 !== 0;
    const isEmptyAfterAt = event.target.value.split('@')?.[1]?.length < 1;
    onChange(event);

    if (haveAt && isEmptyAfterAt) {
      setDropdownVisible(true);
    } else {
      setDropdownVisible(false);
    }
    setChangeEvent(event);
  };
  const renderItems = () =>
    emailDomains?.map((item) => (
      <MenuListItem key={item} onTouchEnd={() => touchEnd(item)} onMouseDown={() => mouseDown(item)}>
        {`${value}${item}`}
      </MenuListItem>
    ));

  return (
    <div>
      <Input
        {...props}
        value={value}
        error={!!error}
        onChange={handleChange}
        onBlur={(e) => {
          setDropdownVisible(false);
          onBlur(e);
        }}
      />
      {dropdownVisible && (
        <MenuListContainer>
          <MenuList>{renderItems()}</MenuList>
        </MenuListContainer>
      )}
    </div>
  );
};

import { Theme } from '@root/infra/theme';
import clsx from 'clsx';
import { FC } from 'react';
import styled, { css, StyledComponentProps } from 'styled-components';
import { LoaderSpinner } from '@root/shared/ui/icons/loader-spinner';

export type LinkButtonSize = 1 | 2 | 3 | 4 | 5;

export type LinkButtonColor = 'primary' | 'danger';

export type LinkButtonProps = StyledComponentProps<'a', any, LinkButtonExtraProps, any>;

export interface LinkButtonExtraProps {
  size?: LinkButtonSize;
  color?: LinkButtonColor;
  loading?: boolean;
  disabled?: boolean;
}

const getTextColor = (theme: Theme, color?: LinkButtonColor) => {
  switch (color) {
    case 'primary':
      return theme.colors.primary[500];
    case 'danger':
      return theme.colors.danger[500];
    default:
      return theme.colors.text.default;
  }
};

const getHoverTextColor = (theme: Theme, color?: LinkButtonColor) => {
  switch (color) {
    case 'primary':
      return theme.colors.success[500];
    case 'danger':
      return theme.colors.danger[300];
    default:
      return theme.colors.text.default;
  }
};

const getFontSize = (theme: Theme, size?: LinkButtonSize): string => {
  switch (size) {
    case 5:
      return '16px';
    case 2:
    case 1:
      return '13px';
    default:
      return '14px';
  }
};

const StyledLinkButton = styled.a<LinkButtonExtraProps>`
  color: ${({ theme, color }) => getTextColor(theme, color)};
  font-size: ${({ theme, size }) => getFontSize(theme, size)};
  padding: 0;
  font-weight: bold;
  box-sizing: border-box;
  display: inline-flex;
  transition: background-color 0.2s, color 0.2s;
  align-items: center;
  cursor: pointer;
  position: relative;
  pointer-events: ${({ loading }) => (loading ? 'none' : 'auto')};

  &:disabled span {
    color: ${({ theme }) => theme.colors.gray['400']};
  }

  &:hover {
    color: ${({ theme, color }) => getHoverTextColor(theme, color)};
  }

  ${(props) =>
    !!props.disabled &&
    css`
      color: ${({ theme }) => theme.colors.gray['300']};
      pointer-events: none;
    `}
`;

StyledLinkButton.defaultProps = {
  size: 4,
  color: 'primary',
};

export const LinkButton: FC<LinkButtonProps> = (props) => {
  return (
    <StyledLinkButton {...props}>
      <span className={clsx('flex items-center justify-center', { 'opacity-10': props.loading, 'opacity-100': !props.loading, 'pointer-events-none': props.disabled })}>
        {props.children}
      </span>
      {props.loading && (
        <span className='absolute top-0 left-0 bottom-0 right-0 flex items-center justify-center text-2xl'>
          <LoaderSpinner />
        </span>
      )}
    </StyledLinkButton>
  );
};

import { fetcher } from '@root/infra/fetcher';
import { IHttpResponse } from '@root/shared/http-service';

export type GetCurrenciesResponses = IHttpResponse<200, null> | IHttpResponse<400, string>;

export const saveUserCurrency = async (currencyCode: string): Promise<GetCurrenciesResponses> => {
  try {
    const response = await fetcher.put('/v1/user/preferred-currency', { currencyCode });

    if (response.status === 200) {
      return {
        status: 200,
        payload: null,
      };
    }

    return {
      status: 400,
      payload: 'Bad request',
    };
  } catch (e) {
    return {
      status: 400,
      payload: 'Bad request',
    };
  }
};

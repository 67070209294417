import { fetcher } from '@root/infra/fetcher';
import { IHttpResponse } from '@root/shared/http-service';
import { MetaMapper } from '../mappers/meta.mapper';
import { ICompanyInfo, IGateway, IInstance, ILegalDocument, IRegion, ISetting } from '../types';


const mockInst = {
  "id": 80,
  "code": "asdasd",
  "name": "asd",
  "type": "customer_portal",
  "priority": 1,
  "oldShop": false,
  "defaultBaseUrl": "stg.start2.online-line.com",
  "companyName": "asd",
  "createdAt": "2022-11-21T19:16:28.564Z",
  "updatedAt": "2022-11-21T19:16:28.564Z",
  "websiteId": "stg.fastshoppingstore.com",
  "countries": [
    {
      "id": 35,
      "code": "CO",
      "name": "Republic of Colombia",
      "customCompanyName": null,
      "phoneVerificationEnabled": true,
      "isActive": true
    }
  ],
  "baseCountry": {
    "id": 35,
    "code": "CO",
    "name": "Republic of Colombia",
    "customCompanyName": null,
    "phoneVerificationEnabled": true,
    "isActive": true
  }
}

export type MetaResponseData = {
  gateways?: IGateway[];
  legalDocuments: ILegalDocument[];
  regions: IRegion[];
  settings: ISetting[];
  currentInstance: IInstance;
  companyInfo: ICompanyInfo;
  isTwilioEnabled: boolean;
};

type ServerData =
  | {
      origin: string;
    }
  | undefined;

export type GetMetaResponses = IHttpResponse<200, MetaResponseData> | IHttpResponse<400, string>;

export const getMeta = async (serverData: ServerData): Promise<GetMetaResponses> => {
  try {
    const response = await fetcher('/shop/metadata');

    if (response.status === 200) {
      return {
        status: 200,
        payload: MetaMapper.toDomain({...response.data.data, currentInstance: response.data.data.currentInstance || mockInst}),
      };
    }

    return {
      status: 400,
      payload: 'Bad request',
    };
  } catch (e) {
    return {
      status: 400,
      payload: 'Bad request',
    };
  }
};

import { AxiosError } from 'axios';
import { fetcher } from '@root/infra/fetcher';
import { IHttpResponse } from '@root/shared/http-service';
import { handleAxiosError } from '@root/shared/utils/handle-axios-error';
import { AddressMapper } from '../mappers/address.mapper';
import { IAddress } from '../types/address';

export type GetAddressesResponse = IHttpResponse<200, IAddress[]> | IHttpResponse<400, string>;

export const getAddresses = async (): Promise<GetAddressesResponse> => {
  try {
    const response = await fetcher('/v1/user/addresses');

    return {
      status: 200,
      payload: response.data.data.map((item) => AddressMapper.toDomain(item)).sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()),
    };
  } catch (e) {
    return handleAxiosError(e as AxiosError);
  }
};

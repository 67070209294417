import { Theme } from '@root/infra/theme';
import { rgba } from 'polished';
import styled, { css, StyledComponentProps } from 'styled-components';

export interface InputExtraProps {
  error?: boolean;
  transparent?: boolean;
  placeholder?: string;
}

export type InputProps = StyledComponentProps<'input', any, InputExtraProps, any>;

const getBorderColor = (theme: Theme, error?: boolean) => {
  return error ? `1px solid ${theme.colors.danger['500']}` : `1px solid ${theme.colors.gray['400']}`;
};

const getBackgroundColor = (theme: Theme, transparent?: boolean) => {
  return transparent ? 'transparent' : rgba(theme.colors.gray['100'], 0.1);
};

export const InputWrapper = styled.div<{ withPrefix: boolean }>`
  ${(props) =>
    props.withPrefix &&
    css`
      .prefix {
        position: absolute;
        left: 12px;
        top: calc(50% - 11px);
        padding: 0;
        border: none;
      }

      input {
        padding: 0 0 0 52px;
      }
    `}
`;

export const Input = styled.input<InputExtraProps>`
  background: ${({ theme, transparent }) => getBackgroundColor(theme, transparent)};
  color: ${({ theme }) => theme.colors.gray['900']};
  border-radius: ${({ theme }) => `${theme.spacing(3)}px`};
  padding: ${({ theme }) => `0 ${theme.spacing(5)}px`};
  border: ${({ error, theme }) => getBorderColor(theme, error)};
  font-size: 14px;
  width: 100%;
  height: 48px;
  cursor: pointer;
  outline: none;

  &::placeholder {
    color: ${({ theme }) => theme.colors.gray['600']};
  }

  &:focus {
    border-color: ${({ theme, transparent }) => !transparent && theme.colors.success[500]};
    caret-color: ${({ theme }) => theme.colors.success[500]};
  }

  &:disabled {
    opacity: 0.3;
    pointer-events: none;
    background-color: ${({ theme }) => theme.colors.gray['200']};
    border: 1px solid ${({ theme }) => theme.colors.gray['400']};
    color: ${({ theme }) => theme.colors.gray['600']};
  }
`;

Input.defaultProps = {
  error: false,
  transparent: false,
};

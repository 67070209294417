import { CaseReducer, createSlice, PayloadAction } from "@reduxjs/toolkit";


export interface IMaintenanceState {
	isMaintenance: boolean;
}

export namespace MaintenanceActions {
	export type SetMaintenance = PayloadAction<boolean>
	export type SyncMaintenance = PayloadAction
}


export type MaintenanceReducer = {
  syncMaintenance: CaseReducer<IMaintenanceState, MaintenanceActions.SyncMaintenance>;
  setMaintenance: CaseReducer<IMaintenanceState, MaintenanceActions.SetMaintenance>;
};

const initialState: IMaintenanceState = {
	isMaintenance: false
}

export const maintenanceSlice = createSlice<IMaintenanceState, MaintenanceReducer, 'maintenance'>({
  name: 'maintenance',
  initialState,
  reducers: {
		syncMaintenance: () => {},
		setMaintenance: (state, action) => {
			state.isMaintenance = action.payload;
		}
	},
});

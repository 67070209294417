import { UserDropdownButtonUI } from './user-dropdown-button.ui';
import { useUserDropdownKycStatus } from '@root/modules/kyc/hooks/use-userdropdown-kyc-status';
import { AccountIcon } from '@root/shared/ui/icons/account-icon';
import { CheckIcon } from '@root/shared/ui/icons/check-icon';
import { CloseIcon } from '@root/shared/ui/icons/close-icon';
import { PendingIcon } from '@root/shared/ui/icons/pending-icon';
import { FC, HTMLAttributes } from 'react';

const { Button, Icon } = UserDropdownButtonUI;

export const UserDropdownButton: FC<HTMLAttributes<HTMLButtonElement> & { hovered: boolean }> = (props) => {
  const [{ status }] = useUserDropdownKycStatus();

  if (!status) {
    return null;
  }

  return (
    <Button status={status} {...props} hovered={props.hovered}>
      <AccountIcon width={16} height={16} />
      <Icon status={status}>
        {status === 'pending' && <PendingIcon width={4} height={4} />}
        {status === 'success' && <CheckIcon width={4} height={4} />}
        {status === 'failed' && <CloseIcon width={4} height={4} />}
      </Icon>
    </Button>
  );
};

import * as yup from 'yup';

const maxOneSpaceNoLeadingNoTrailingSpaceMaxOneDash = /^(?=.{1,10}$)+[a-zA-Z0-9]+([\s-]?)?[a-zA-Z0-9]+$/gm;

// TODO: Remove the file later
export const paymentAddressValidation = yup.object().shape({
  firstname: yup.string().required(),
  lastname: yup.string().required(),
  country: yup.string().required(),
  city: yup.string().required(),
  postalCode: yup
    .string()
    .matches(maxOneSpaceNoLeadingNoTrailingSpaceMaxOneDash, 'Postal Code is not valid')
    .max(10, 'Postal Code must be 10 letters/numbers or shorter')
    .required('Postal Code is required'),
  firstAddressLine: yup.string().required(),
  phoneNumbers: yup.array().of(
    yup.object().shape({
      value: yup.string(),
      label: yup.string(),
    }),
  ),
});

import { AxiosError } from 'axios';
import { fetcher } from '@root/infra/fetcher';
import { IHttpResponse } from '@root/shared/http-service';
import { handleAxiosError } from '@root/shared/utils/handle-axios-error';
import { PaymentItemMapper } from '../mappers/payment-item.mapper';
import { IPaymentItem } from '../types/payment-item';

export type GetPaymentMethodsResponse = IHttpResponse<200, IPaymentItem[]> | IHttpResponse<400, string>;

export const getPaymentMethods = async (): Promise<GetPaymentMethodsResponse> => {
  try {
    const response = await fetcher('/v1/user/payment-instruments?withDefault=true&withLast=true');

    return {
      status: 200,
      payload: response.data.data.map((item) => PaymentItemMapper.toDomain(item)),
    };
  } catch (e) {
    return handleAxiosError(e as AxiosError);
  }
};

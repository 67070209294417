import { IncreaseEWalletBalanceModal } from '../containers/increase-e-wallet-balance-modal';
import { Button } from '@root/shared/ui';
import { AddIcon } from '@root/shared/ui/icons/add-icon';
import clsx from 'clsx';
import { Fragment, useState, useCallback, FC } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  sidebar?: boolean;
  textVisible?: boolean;
};

export const IncreaseEWalletBalance: FC<Props> = ({ sidebar, textVisible }) => {
  const { t } = useTranslation('payment-methods', { keyPrefix: 'paymentMethods.e-wallet.increase' });
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const open = useCallback(() => {
    setIsOpen(true);
  }, []);

  const close = useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <Fragment>
      {sidebar ? (
        <Button className={clsx('w-full flex items-center justify-center py-2 px-2', { 'px-4 py-2': textVisible })} onClick={open}>
          {textVisible ? <AddIcon height={26} width={26} className='mr-2 mt-0.5' /> : <AddIcon height={16} width={16} className='ml-px mt-px' />}
          <span className={clsx('mb-px transition opacity-100', { 'hidden opacity-0': !textVisible })}>{t('actions.addPoints')}</span>
        </Button>
      ) : (
        <div>
          <div className='flex justify-end -mt-2'>
            <button onClick={open} className='inline-flex items-center text-primary-500 font-bold text-sm'>
              <AddIcon className='mr-2' />
              <span>{t('actions.increase')}</span>
            </button>
          </div>
        </div>
      )}

      {isOpen && <IncreaseEWalletBalanceModal isOpen={true} close={close} />}
    </Fragment>
  );
};

import styled, { StyledComponentProps } from 'styled-components';

export type AvatarSize = 'smaller' | 'small' | 'normal' | 'large';
export type AvatarExtraProps = {
  size?: AvatarSize;
};
export type AvatarProps = StyledComponentProps<'img', any, AvatarExtraProps, any>;

const getSize = (size: AvatarSize = 'normal') => {
  switch (size) {
    case 'smaller':
      return 24;
    case 'small':
      return 48;
    case 'normal':
      return 56;
    case 'large':
      return 64;
  }
};

export const Avatar = styled.img<AvatarExtraProps>`
  height: ${({ size }) => `${getSize(size)}px`};
  width: ${({ size }) => `${getSize(size)}px`};
  object-fit: cover;
  border-radius: 50%;
`;

Avatar.defaultProps = {
  size: 'normal',
};

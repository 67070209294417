import { FC, HTMLAttributes, useCallback, useState, PropsWithChildren, ReactNode, useEffect } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { rgba } from 'polished';
import clsx from 'clsx';

import { ChevronIcon } from '@root/shared/ui/icons/chevron-icon';

import { HEADER_HEIGHT } from '../header';

export const SIDEBAR_CLOSED_WIDTH = 80;
export const SIDEBAR_OPENED_WIDTH = 250;

const Header: FC<HTMLAttributes<HTMLDivElement>> = ({ children, className }) => {
  return <div className={`shadow ${className}`}>{children}</div>;
};

const Toggle: FC<HTMLAttributes<HTMLButtonElement>> = ({ children, className, ...props }) => {
  return (
    <button className={`hover:bg-primary-400 hover:bg-opacity-25 w-full flex items-center ${className}`} {...props}>
      {children}
    </button>
  );
};

const ToggleIcon = styled.span`
  display: block;
  font-size: 10px;

  padding: ${({ theme }) => theme.spacing(1)}px;
  background: ${({ theme }) => theme.colors.primary[400]};
  border-radius: ${({ theme }) => theme.spacing(1)}px;

  margin-left: ${({ theme }) => theme.spacing(8)}px;
  margin-right: ${({ theme }) => theme.spacing(3)}px;
`;

const ToggleBrand = styled.span`
  display: flex;
  align-items: center;
  height: 60px;
  vertical-align: center;
`;

const Button: FC<HTMLAttributes<HTMLButtonElement> & { active?: boolean }> = ({ children, className, active, ...props }) => {
  return (
    <button
      className={`px-3 w-full text-left flex items-center text-lg transition cursor-pointer bg-transparent bg-opacity-25 hover:bg-gray-800 hover:bg-opacity-25 ${clsx({
        'bg-gray-800': active,
      })} ${className}`}
      {...props}
    >
      {children}
    </button>
  );
};

const Link = styled(NavLink)<{ disabled?: boolean }>`
  opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'unset')};
  padding: 20px 0;

  width: 100%;
  display: flex;
  align-items: center;
  font-size: 1.125rem;

  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;

  background: transparent;

  &.active {
    background-color: ${({ theme }) => rgba(theme.colors.primary['400'], 0.6)};
  }

  &:hover {
    background-color: ${({ theme, disabled }) => (disabled ? 'transparent' : rgba(theme.colors.primary['400'], 0.5))} !important;
  }
`;

const LinkIcon = styled.span`
  font-size: 13px;
  margin-left: ${({ theme }) => theme.spacing(8.5)}px;
  margin-right: ${({ theme }) => theme.spacing(3)}px;
`;

const LinkTitle = styled.span`
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.875rem;
`;

const Menu: FC<HTMLAttributes<HTMLDivElement> & {isDisabled: boolean}> = ({ children, className, isDisabled }) => {
  const disabledClassName = isDisabled ? 'pointer-events-none opacity-50' : ''
  return <div className={`${className} ${disabledClassName}`}>{children}</div>;
};

const WrapperStyled = styled.div<{ isOpen?: boolean }>`
  padding-top: ${() => HEADER_HEIGHT}px;
  color: ${({ theme }) => theme.colors.gray[100]};

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
  transition: width 0.2s;
  width: ${({ isOpen }) => (isOpen ? SIDEBAR_OPENED_WIDTH : SIDEBAR_CLOSED_WIDTH)}px;
  overflow: hidden;

  ${LinkTitle} {
    transition: opacity 0.2s;
    opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  }

  ${ToggleBrand} {
    transition: opacity 0.2s;
    opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  }
`;

const Wrapper: FC<HTMLAttributes<HTMLDivElement> & { isOpen?: boolean }> = ({ children, className, isOpen }) => {
  return (
    <WrapperStyled className={`top-0 left-0 bottom-0 min-h-screen flex flex-col bg-primary-700 fixed overflow-auto z-50 ${className}`} isOpen={isOpen}>
      {children}
    </WrapperStyled>
  );
};

const AccordionLink = styled(Link)`
  padding-left: ${({ theme }) => theme.spacing(14)}px;
`;

const AccordionButton = styled.button<{ disabled?: boolean; isOpen?: boolean }>`
  opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'unset')};
  padding: 20px 0;

  width: 100%;
  display: flex;
  align-items: center;

  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;

  background: ${({ theme, isOpen }) => (!isOpen ? 'transparent' : rgba(theme.colors.primary['400'], 1))};

  &.active {
    background-color: ${({ theme }) => rgba(theme.colors.primary['400'], 0.6)};
  }

  &:hover {
    background-color: ${({ theme, disabled }) => (disabled ? 'transparent' : rgba(theme.colors.primary['400'], 0.5))} !important;
  }
`;

export const AccordionItem: FC<PropsWithChildren<{ label: string | ReactNode; disabled?: boolean; isSidebarOpen?: boolean; toggleSidebar: () => void }>> = ({
  label,
  children,
  disabled,
  isSidebarOpen,
  toggleSidebar,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggle = useCallback(() => {
    if (!isSidebarOpen) {
      toggleSidebar();
    }
    setIsOpen((prevState) => !prevState);
  }, [isSidebarOpen, toggleSidebar]);

  useEffect(() => {
    if (!isSidebarOpen && isOpen) {
      setIsOpen(false);
    }
  }, [isSidebarOpen, isOpen]);

  return (
    <div className='outlined'>
      <AccordionButton onClick={toggle} disabled={disabled} isOpen={isOpen} className='flex w-full text-left items-center'>
        <span className='w-full'>{label}</span>
        <span className={clsx('transition duration-300 mr-6 text-sm', { 'rotate-180': isOpen })}>
          <ChevronIcon />
        </span>
      </AccordionButton>
      {isOpen && <div className='bg-primary-800'>{children}</div>}
    </div>
  );
};

export const SidebarUI = { Wrapper, Header, Button, Menu, Toggle, ToggleIcon, ToggleBrand, Link, LinkIcon, LinkTitle, AccordionItem, AccordionLink };

import { useSelector } from 'react-redux';
import { useCallback, useState, useMemo } from 'react';

import { authSelector } from '@root/modules/auth/redux';
import { currentMembershipSelectorByUserLevel, membershipSelector } from '@root/modules/membership/redux/membership.selector';

export const useMembershipInfo = () => {
  const [isOpen, setIsOpen] = useState(false);

  const currentMembership = useSelector(currentMembershipSelectorByUserLevel);
  const memberships = useSelector(membershipSelector);
  const authState = useSelector(authSelector);

  const nextLevel = useMemo(
    () => memberships && (currentMembership ? memberships.find((item) => item.priority === currentMembership?.priority + 1) : memberships[0]),
    [memberships, currentMembership],
  );

  const toggleDropdown = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  const handleOutsideClick = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const state = {
    isOpen,
    isLoading: authState.loading,
    currentLevel: currentMembership,
    nextLevel,
    user: authState.user,
    memberships,
  };
  const handlers = { toggleDropdown, handleOutsideClick };

  return [state, handlers] as [typeof state, typeof handlers];
};

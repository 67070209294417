import { createSelector, Dictionary, Selector } from '@reduxjs/toolkit';

import { AppState } from '@root/infra/redux';
import { IAuthState } from '@root/modules/auth/redux';
import { Id } from '@root/shared/constants';

import { membershipSelector } from '@root/modules/membership/redux/membership.selector';
import { IMembershipsState } from '@root/modules/membership/redux/membership.slice';
import { IMiniProduct } from '@root/modules/products/types/mini-product';
import { IPurchasedProduct } from '@root/modules/products/types/purchased-product';
import { RECURRING_TYPES } from '@root/infra/enums/reccuring-type';
import { selectRecurringType } from '@root/modules/meta/redux';

import { productCategoriesSelector } from '../product-categories/product-categories.selector';
import { purchasedProductsByIdSelector } from '../purchased-products/purchased-products.selector';
import { PurchasedProductsState } from '../purchased-products/purchased-products.slice';
import { IProductsState } from './products.slice';

const addonsWithDiscountSelector = createSelector<
  [Selector<AppState, IProductsState>, Selector<AppState, IMembershipsState['memberships']>, Selector<AppState, IAuthState>],
  IProductsState
>([(state) => state.products, membershipSelector, (state) => state.auth], (products, membership, auth) => {
  const userMembership = auth?.user?.membershipId;
  const membershipData = membership?.find((m) => m?.id === userMembership);

  const updatedProducts = products.data.map((p) => {
    if (p.isAddon || p.isPhysicalAddon) {
      const membershipDiscountPrice = Math.round((p?.pricing?.price?.amount || 0) * ((100 - (membershipData?.addonsDiscount || 0)) / 100) * 1000) / 1000 || null;
      return {
        ...p,
        pricing: {
          ...p.pricing,
          discountPrice:
            p.membershipDiscountAllowed && !!membershipDiscountPrice && membershipDiscountPrice !== p.pricing.price?.amount ? { amount: membershipDiscountPrice } : null,
        },
      };
    } else {
      return p;
    }
  });

  return { ...products, data: updatedProducts };
});

export const productsListSelector = createSelector<
  [Selector<AppState, IProductsState>, Selector<AppState, Dictionary<IPurchasedProduct> | null>, Selector<AppState, { [key: string]: string } | null>],
  IProductsState['data']
>([addonsWithDiscountSelector, purchasedProductsByIdSelector, productCategoriesSelector.plans], (products, purchased, categoryPlans) => {
  if (categoryPlans || purchased) {
    return products.data.map((product) => {
      const purchasedProduct = purchased && purchased[product.shopId];
      const urlParam = categoryPlans && (categoryPlans[product.shopId] as string);
      const expiredOn = purchasedProduct?.status === 'Expired' ? purchasedProduct?.expireAt : undefined;

      const pricing = purchasedProduct
        ? {
            ...product.pricing,
            recurringPrice: { amount: purchasedProduct.recurringPrice },
            price: { amount: purchasedProduct.setupPrice },
          }
        : product.pricing;

      return {
        ...product,
        pricing,
        urlParam: urlParam || product.urlParam,
        purchased: !!purchasedProduct || product.purchased,
        canUpgrade: !!purchasedProduct?.canBeSwitchedTo?.length,
        expiredOn,
        subtitle: (!!purchasedProduct && purchasedProduct?.subtitle) || product.subtitle,
      };
    });
  }
  return products.data;
});

export const productsForUpgradeSelector = createSelector<[Selector<AppState, IProductsState['data']>, Selector<AppState, PurchasedProductsState>], IMiniProduct[]>(
  [productsListSelector, (state) => state.purchasedProducts],
  (products, purchased) => {
    if (products && purchased.data) {
      return purchased.data
        .map((purchasedProduct) =>
          purchasedProduct?.canBeSwitchedTo?.map((upgradePlan) => {
            const product = products.find((item: IMiniProduct) => item.shopId === upgradePlan.planId);
            return {
              ...product,
              pricing: {
                ...product?.pricing,
                price: { amount: upgradePlan?.switchPrice || 0 },
                recurringPrice: { amount: upgradePlan.recurringPrice || null },
              },
              isUpgradePlan: true,
            };
          }),
        )
        .flat() as IMiniProduct[];
    }
    return [];
  },
);

export const productsStateSelector = {
  main: createSelector<[Selector<AppState, IProductsState>], IProductsState>(
    (state) => state.products,
    (products) => products,
  ),

  data: createSelector<[Selector<AppState, IProductsState>], IProductsState['data']>(
    (state) => state.products,
    (products) => products.data,
  ),

  isLoading: createSelector<[Selector<AppState, IProductsState>], IProductsState['isLoading']>(
    (state) => state.products,
    (products) => products.isLoading,
  ),

  digital: createSelector<[Selector<AppState, IProductsState['data']>, Selector<AppState, RECURRING_TYPES>], IProductsState['data']>(
    [productsListSelector, (state) => selectRecurringType(state)],
    (products, recurringType) => {
      return products.filter((item) => (item.isDigitalBundle && recurringType === RECURRING_TYPES.MONTHLY ? !item.isAnnual : item.isAnnual));
    },
  ),

  physical: createSelector<[Selector<AppState, IProductsState['data']>], IProductsState['data']>(productsListSelector, (products) =>
    products?.filter((item) => item.isPhysicalBundle),
  ),

  digitalAddons: createSelector<[Selector<AppState, IProductsState['data']>], IProductsState['data']>(productsListSelector, (products) =>
    products?.filter((item) => item.isAddon && !item.purchased),
  ),

  tickets: createSelector<[Selector<AppState, IProductsState['data']>], IProductsState['data']>(productsListSelector, (products) => products?.filter((item) => item.isEventTicket)),

  purchasedBundle: createSelector<[Selector<AppState, IProductsState['data']>], IMiniProduct | undefined>(productsListSelector, (products) =>
    products?.find((item) => item.isDigitalBundle && !!item.purchased),
  ),

  physicalAddons: createSelector<[Selector<AppState, IProductsState['data']>], IProductsState['data']>(productsListSelector, (products) =>
    products?.filter((item) => item.isPhysicalAddon),
  ),

  purchased: createSelector<[Selector<AppState, IProductsState['data']>], IProductsState['data']>(productsListSelector, (products) => products?.filter((item) => !!item.purchased)),

  productsByCategoryIds: (ids: Id[]) =>
    createSelector<[Selector<AppState, IProductsState['data']>], IProductsState['data']>(productsListSelector, (products) => {
      const list = products?.filter((item) => ids.includes(item?.shopId)) as IMiniProduct[];
      const item = list[0];

      if (item?.isAddon || item?.isPhysicalAddon || item?.isEventTicket) {
        return list;
      } else {
        const purchased = products.filter((item) => item?.purchased);
        return purchased.length ? purchased : list;
      }
    }),
};

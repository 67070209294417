import styled from 'styled-components';

export const MenuListContainer = styled.div`
  position: absolute;
  background-color: ${({ theme }) => theme.colors.gray['800']};
  width: 100%;
  z-index: 3;
  margin-top: 5px;
  margin-bottom: 5px;
`;

export const MenuList = styled.ul`
  position: absolute;
  border: 1px solid;
  border-radius: ${({ theme }) => `${theme.spacing(3)}px`};
  border-color: ${({ theme }) => theme.colors.gray['300']};
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
  margin-top: 5px;
  margin-bottom: 5px;
  background-color: white;

  scrollbar-color: ${({ theme }) => `${theme.colors.gray['800']} ${theme.colors.gray['1000']}`};
  box-shadow: 0 10px 24px 0 rgba(12, 12, 12, 0.25);

  &::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.colors.gray['800']};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.gray['800']};
  }
`;

export const MenuListItem = styled.li`
  padding: ${({ theme }) => `${theme.spacing(3)}px ${theme.spacing(6)}px`};
  cursor: pointer;
  z-index: 3;

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray['200']};
  }
`;

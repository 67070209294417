type ExternalRedirectOptions = {
  target?: '_blank' | '_self';
  rel?: string;
  skipBeforeUnload?: boolean;
};

export const handleRedirect = (url: string) => (window.location.href = url);

export const externalRedirect = (url: string, options: ExternalRedirectOptions = {}) => {
  if (options.skipBeforeUnload) {
    window.onbeforeunload = null;
  }
  const link = document.createElement('a');
  link.href = url;
  link.target = options.target || '_self';
  link.rel = options.rel || '';
  link.click();
};

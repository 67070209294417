import { fetcher } from '@root/infra/fetcher';
import type { IHttpResponse } from '@root/shared/http-service';
import { AxiosError } from 'axios';
import { INSTANCE_TYPES } from '../constants/instance-types';

export interface IRedTokenSuccessResponse {
  wrongDomain: boolean;
  redirectionUrl: string;
  redirectionToken: string;
}

export type RedirectionTokenResponseResponse = IHttpResponse<200, IRedTokenSuccessResponse> | IHttpResponse<400, string>;

export const getRedirectionTokenService = async (instanceType: INSTANCE_TYPES = INSTANCE_TYPES.CUSTOMER_PORTAL): Promise<RedirectionTokenResponseResponse> => {
  try {
    const response = await fetcher.get(`/auth/get-redirection-token?instanceType=${instanceType}`);

    return {
      status: 200,
      payload: response.data.data,
    };
  } catch (e) {
    const error = e as AxiosError;
    console.error(e);

    if (error.isAxiosError && error.response) {
      return {
        status: 400,
        payload: error.response.data.message,
      };
    }

    return {
      status: 400,
      payload: 'Bad request',
    };
  }
};

import { FC, useCallback, useMemo } from 'react';
import clsx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { AbsolutePortal } from '@root/infra/portals';
import { useIsDesktop } from '@root/infra/device-type';
import { ChevronIcon } from '@root/shared/ui/icons/chevron-icon';
import { CloseIcon } from '@root/shared/ui/icons/close-icon';

import { InternalizationSelect, languageOptions } from '../components';
import { layoutSelector, layoutSlice } from '../redux';
import { currenciesSelector } from '@root/modules/currencies/redux/currencies.selector';
import { CurrencySelect } from '@root/modules/currencies/components/currency-select';

const Overlay = styled.div`
  background-color: rgba(12, 12, 12, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1200;
`;

const Header = styled.div`
  background: ${({ theme }) => theme.colors.gradients['9']};
`;

const Content = styled.div`
  z-index: 1201;
`;

export const SettingsOverlay: FC<{ trigger?: boolean }> = ({ trigger }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const isDesktop = useIsDesktop();
  const userCurrency = useSelector(currenciesSelector.userCurrency);
  const { settingsOverlayIsOpen: isOpen } = useSelector(layoutSelector);

  const foundLanguage = useMemo(() => languageOptions.find((item) => item.value === i18n.language), [i18n.language]);

  const close = useCallback(() => {
    dispatch(layoutSlice.actions.settingsOverlayClosed());
  }, [dispatch]);

  const open = useCallback(() => {
    dispatch(layoutSlice.actions.settingsOverlayOpened());
  }, [dispatch]);

  if (trigger && !isOpen) {
    return (
      <button
        className={clsx('flex items-center bg-transparent p-2 transition', {
          'bg-gray-200': isOpen && isDesktop,
          'rounded-full hover:bg-gray-200': isDesktop,
          'border border-gray-500 rounded-md !px-3 !py-2.5 hover:bg-primary-400 hover:bg-opacity-50 hover:border-transparent': !isDesktop,
        })}
        onClick={open}
      >
        <span className='flex items-center'>
          <span>{foundLanguage?.prefix}</span>
          <span className='ml-2 text-sm'>{foundLanguage?.value.toLocaleUpperCase()}</span>
          <span className='block w-px h-2.5 bg-white mx-1' />
          <span className='text-sm'>{userCurrency?.code}</span>
        </span>

        <ChevronIcon className={clsx('text-xs ml-1.5 transition transform rotate-0', { 'rotate-180': isOpen })} />
      </button>
    );
  }

  if (!isOpen) {
    return null;
  }

  return (
    <AbsolutePortal>
      <Overlay onClick={close} />
      <Content className='bg-white fixed left-0 right-0 bottom-0 rounded-t-xl'>
        <Header className='text-center p-6 font-bold relative rounded-t-xl'>
          {t('Preferences')}
          <button onClick={close} className='absolute top-1/2 right-6 transform -translate-y-1/2'>
            <CloseIcon />
          </button>
        </Header>

        <div className='flex flex-col bg-white shadow-2xl p-7 rounded-xl gap-y-4'>
          <div>
            <div className='text-gray-500 mb-1'>{t('Language')}</div>
            <InternalizationSelect topMenu />
          </div>
          <div>
            <div className='text-gray-500 mb-1'>{t('Currency')}</div>
            <CurrencySelect topMenu />
          </div>
        </div>
      </Content>
    </AbsolutePortal>
  );
};

import styled from 'styled-components';

const MembershipLevelsInfoScroller = styled.div`
  overflow: hidden;
`;

const MembershipLevelsInfoWrapper = styled.div<{ count: number }>`
  width: 100%;
  min-width: 498px;
  display: grid;
  grid-template-columns: repeat(${({ count }) => count}, 1fr);
  grid-gap: 2px;
`;

const MembershipLevelsInfoItemContainer = styled.div`
  overflow: hidden;
  align-self: center;

  &:first-child {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
  }

  &:last-child {
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
  }
`;

const MembershipLevelsInfoItemWrapper = styled.div<{ progress: number; isScaled: boolean }>`
  width: 100%;
  height: ${({ isScaled }) => (isScaled ? '80px' : '66px')};
  background: ${({ theme }) => theme.colors.gray[300]};
  color: ${({ theme }) => theme.colors.gray[600]};
  position: relative;
  font-size: 11px;
  overflow: hidden;
`;

const MembershipInfoText = styled.div`
  text-align: center;
  background: transparent;
  position: absolute;
  height: 100%;

  .divider {
    width: 40px;
    height: 1px;
    opacity: 0.4;
    margin: 0 auto;
    background: ${({ theme }) => theme.colors.gray[800]};
  }

  .label {
    font-weight: 900;
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const MembershipInfoFrontText = styled(MembershipInfoText)<{ progress: number; background: string }>`
  width: ${({ progress }) => progress}%;
  color: ${({ theme }) => theme.colors.gray[100]};
  background: ${({ background }) => background};
  z-index: 10;
  overflow: hidden;

  .divider {
    background: ${({ theme }) => theme.colors.gray[100]};
  }
`;

const MembershipInfoBackText = styled(MembershipInfoText)`
  width: 100%;
  color: ${({ theme }) => theme.colors.gray[800]};
  z-index: 2;
`;

const MembershipInfoScrollButton = styled.button`
  color: white;
  padding: 0.5rem;
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 999;
`;

export const MembershipLevelsInfoUI = {
  MembershipLevelsInfoScroller,
  MembershipLevelsInfoWrapper,
  MembershipLevelsInfoItemContainer,
  MembershipLevelsInfoItemWrapper,
  MembershipInfoFrontText,
  MembershipInfoBackText,
  MembershipInfoScrollButton,
};

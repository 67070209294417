import { ProductCarouselCard } from '../components/product-carousel-card';
import { useIsDesktop } from '@root/infra/device-type';
import { LayoutContainer } from '@root/infra/layout';
import { availableProductsSelector } from '@root/modules/products/redux/purchased-products/purchased-products.selector';
import { H1, LoaderSection } from '@root/shared/ui';
import { Carousel } from '@root/shared/ui/components/carousel';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

export const AvailableProducts = () => {
  const { t } = useTranslation('dashboard', { keyPrefix: 'availableProducts' });
  const isDesktop = useIsDesktop();

  const { data: products, isLoading } = useSelector(availableProductsSelector);

  if (isLoading) {
    return <LoaderSection />;
  }

  if (!products.length) {
    return null;
  }

  return (
    <LayoutContainer>
      <H1 className='text-2xl font-bold mb-6 lg:mb-4'>{t('title')}</H1>

      <div className='grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-[30px]'>
        {isDesktop && products.map((item) => <ProductCarouselCard item={item} key={item.id} />)}
        {!isDesktop &&
          (products.length === 1 ? (
            <ProductCarouselCard item={products[0]} />
          ) : (
            <Carousel isDesktop partialVisible renderButtonGroupOutside>
              {products.map((item) => (
                <ProductCarouselCard item={item} key={item.id} />
              ))}
            </Carousel>
          ))}
      </div>
    </LayoutContainer>
  );
};

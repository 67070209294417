import { createSelector, Selector } from '@reduxjs/toolkit';
import { RECURRING_TYPES } from '@root/infra/enums/reccuring-type';
import { AppState } from '@root/infra/redux';
import { IAuthState } from './auth.slice';

export const authSelector = createSelector<[Selector<AppState, IAuthState>], IAuthState>(
  (state) => state.auth,
  (auth) => auth,
);

export const authIsLoadingSelector = createSelector<[Selector<AppState, IAuthState>], boolean>(
  (state) => state.auth,
  (auth) => auth.loading,
);

export const authIsInitializedSelector = createSelector<[Selector<AppState, IAuthState>], boolean>(
  (state) => state.auth,
  (auth) => auth.initialized,
);

export const authCountrySelector = createSelector<[Selector<AppState, IAuthState>], string | null>(
  (state) => state.auth,
  (auth) => auth.country,
);

export const authUserIdSelector = createSelector<[Selector<AppState, IAuthState>], number | undefined>(
  (state) => state.auth,
  (auth) => auth.user?.customerId,
);

export const authUserRecurringTypeSelector = createSelector<[Selector<AppState, IAuthState>], RECURRING_TYPES | undefined>(
  (state) => state.auth,
  (auth) => auth.user?.recurringType,
);

export const authUserSelector = createSelector<[Selector<AppState, IAuthState>], IAuthState['user']>(
  (state) => state.auth,
  (auth) => auth.user,
);

export const authEmailSelector = createSelector<[Selector<AppState, IAuthState>], string | undefined>(
  (state) => state.auth,
  (auth) => auth.user?.email,
);

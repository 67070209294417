import { createSelector, Selector } from '@reduxjs/toolkit';
import { AppState } from '@root/infra/redux';
import { MetaState } from '@root/modules/meta/redux';
import { CountriesState } from './countries.slice';

const getCountries = (state: AppState) => state.countries;
const getMeta = (state: AppState) => state.meta;
const getByInstance = (countries: CountriesState, meta: MetaState) => {
  return meta?.currentInstance?.countries.map((item) => ({ code: item.code, name: item.name, phoneVerificationEnabled: item.phoneVerificationEnabled })) || [];
};
const getData = (state: CountriesState) => state.items.filter((item) => item.code !== 'Other');

export const countriesSelector = {
  byInstance: createSelector<[Selector<AppState, CountriesState>, Selector<AppState, MetaState>], CountriesState['items']>([getCountries, getMeta], getByInstance),
  data: createSelector<[Selector<AppState, CountriesState>], CountriesState['items']>([getCountries], getData),
};

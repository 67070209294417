import styled from 'styled-components';

export const LinearSection = styled.div`
  @media (min-width: 994px) {
    padding-left: ${({ theme }) => `${theme.spacing(14)}px`};
    position: relative;

    &:before {
      display: block;
      position: absolute;
      content: '';
      width: 1px;
      height: calc(100% - 20px);
      top: 10px;
      left: 20px;
      background: ${({ theme }) => theme.colors.gray[1000]};
    }
  }
`;

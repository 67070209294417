import { useQuery } from 'react-query';

import { GET_ORDERS } from '@root/modules/orders/queries/get-orders';
import { OrderStatus } from '@root/modules/orders/constants/order-status';

export const useOrdersStatistics = () => {
  const { data: receivedOrders, isLoading: receivedOrdersIsLoading } = useQuery(['orders', { hasCoordinator: false, isRequested: true, limit: 1 }], GET_ORDERS);

  const { data: waitingOrders, isLoading: waitingOrdersIsLoading } = useQuery(
    ['orders', { hasCoordinator: false, isRequested: true, limit: 1, statusFilter: OrderStatus.WAITING_FOR_PAYMENT }],
    GET_ORDERS,
  );

  const isLoading = receivedOrdersIsLoading || waitingOrdersIsLoading;
  const totalOrders = receivedOrders?.totalItems || 0;
  const totalNewOrders = waitingOrders?.totalItems || 0;

  const state = { isLoading, totalOrders, totalNewOrders };
  const handlers = {};

  return [state, handlers] as [typeof state, typeof handlers];
};

import { AxiosError } from 'axios';
import { Id } from '@root/shared/constants';
import { fetcher } from '@root/infra/fetcher';
import { IHttpResponse } from '@root/shared/http-service';
import { handleAxiosError } from '@root/shared/utils/handle-axios-error';
import { UpdatePaymentCardDtoMapper } from '../mappers/update-payment-card-dto.mapper';
import { UpdatePaymentCardDto } from '../dtos/update-payment-card.dto';

export type UpdatePaymentCardResponse = IHttpResponse<200, Id> | IHttpResponse<400, string>;

export const updatePaymentCardService = async (values: UpdatePaymentCardDto): Promise<UpdatePaymentCardResponse> => {
  try {
    const response = await fetcher.put('/payments/card/update', UpdatePaymentCardDtoMapper.toPersistence(values));

    return {
      status: 200,
      payload: response.data.paymentInstrumentId,
    };
  } catch (e) {
    return handleAxiosError(e as AxiosError);
  }
};

import { fetcher } from '@root/infra/fetcher';
import type { IHttpResponse } from '@root/shared/http-service';
import { AxiosError } from 'axios';
import { SignInDto } from '../dtos';
import { PreAuthTokensMapper } from '../mappers';
import { IPreAuth } from './../types';

export type SignInServiceResponse = IHttpResponse<200, IPreAuth> | IHttpResponse<400, string>;

const checkErrorMessage = (message: string) => {
  if (message?.toLowerCase().includes('invalid loginname or password')) {
    return 'Invalid Email, Username or password';
  }

  return message;
};

export const signInService = async (payload: SignInDto): Promise<SignInServiceResponse> => {
  try {
    const response = await fetcher.post('/auth/signin', payload);

    return {
      status: 200,
      payload: PreAuthTokensMapper.toDomain(response.data.data),
    };
  } catch (e) {
    const error = e as AxiosError;
    console.error(e);
    console.error(error.response);

    if (error.isAxiosError && error.response) {
      const errorMessage = checkErrorMessage(error.response.data.message);

      return {
        status: 400,
        payload: errorMessage,
      };
    }

    return {
      status: 400,
      payload: 'Bad request',
    };
  }
};

import { layoutSelector, layoutSlice } from '../../redux';
// import cnFlag from '@public/flags/cn.svg';
import cnFlag from '@root/assets/flags/cn.svg';
import deFlag from '@root/assets/flags/de.svg';
import esFlag from '@root/assets/flags/es.svg';
import frFlag from '@root/assets/flags/fr.svg';
import itFlag from '@root/assets/flags/it.svg';
import jpFlag from '@root/assets/flags/jp.svg';
import ptFlag from '@root/assets/flags/pt.svg';
import ruFlag from '@root/assets/flags/ru.svg';
import saFlag from '@root/assets/flags/sa.svg';
import skFlag from '@root/assets/flags/sk.svg';
import usFlag from '@root/assets/flags/us.svg';
import { CardLogo } from '@root/modules/payment-methods/components/card-logo';
import { Select } from '@root/shared/ui';
import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

const optionImageContainerProps: object = {
  className: 'w-5 h-5',
};

const optionImageProps: object = {
  className: 'rounded-full overflow-hidden object-cover h-full w-full',
};

export const languageOptions = [
  {
    value: 'en',
    label: 'English',
    prefix: (
      <div {...optionImageContainerProps}>
        <img alt='US flag' src={usFlag} {...optionImageProps} />
      </div>
    ),
  },
  {
    value: 'fr',
    label: 'French',
    prefix: (
      <div {...optionImageContainerProps}>
        <img alt='FR flag' src={frFlag} {...optionImageProps} />
      </div>
    ),
  },
  {
    value: 'es',
    label: 'Spanish',
    prefix: (
      <div {...optionImageContainerProps}>
        <img alt='ES flag' src={esFlag} {...optionImageProps} />
      </div>
    ),
  },
  {
    value: 'pt',
    label: 'Portuguese',
    prefix: (
      <div {...optionImageContainerProps}>
        <img alt='PT flag' src={ptFlag} {...optionImageProps} />
      </div>
    ),
  },
  {
    value: 'sk',
    label: 'Slovak',
    prefix: (
      <div {...optionImageContainerProps}>
        <img alt='PT flag' src={skFlag} {...optionImageProps} />
      </div>
    ),
  },
  {
    value: 'ru',
    label: 'Russian',
    prefix: (
      <div {...optionImageContainerProps}>
        <img alt='RU flag' src={ruFlag} {...optionImageProps} />
      </div>
    ),
  },
  {
    value: 'ar',
    label: 'Arabic',
    prefix: (
      <div {...optionImageContainerProps}>
        <img alt='SA flag' src={saFlag} {...optionImageProps} />
      </div>
    ),
  },
  {
    value: 'it',
    label: 'Italian',
    prefix: (
      <div {...optionImageContainerProps}>
        <img alt='IT flag' src={itFlag} {...optionImageProps} />
      </div>
    ),
  },
  {
    value: 'de',
    label: 'German',
    prefix: (
      <div {...optionImageContainerProps}>
        <img alt='DE flag' src={deFlag} {...optionImageProps} />
      </div>
    ),
  },
  {
    value: 'zh',
    label: 'Chinese',
    prefix: (
      <div {...optionImageContainerProps}>
        <img alt='CN flag' src={cnFlag} {...optionImageProps} />
      </div>
    ),
  },
  {
    value: 'ja',
    label: 'Japanese',
    prefix: (
      <div {...optionImageContainerProps}>
        <img alt='JP flag' src={jpFlag} {...optionImageProps} />
      </div>
    ),
  },
];

export const InternalizationSelect: FC<{ topMenu?: boolean }> = ({ topMenu }) => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();

  const { settingsOverlayIsOpen } = useSelector(layoutSelector);

  const currentValue = useMemo(() => languageOptions.find((language) => language.value === i18n.language), [i18n.language]);

  const handleChange = useCallback(
    (value) => {
      i18n.changeLanguage(value.value).then(() => console.log('Language changed'));
      settingsOverlayIsOpen && dispatch(layoutSlice.actions.settingsOverlayClosed());
    },
    [i18n, dispatch, settingsOverlayIsOpen],
  );

  return <Select menuPlacement={topMenu ? 'top' : undefined} bordered onChange={handleChange} value={currentValue} options={languageOptions} isSearchable={false} />;
};

import { getGiftCardsService } from '@root/modules/gift-cards/services/get-gift-cards.service';
import { giftCardsSelector } from '@root/modules/gift-cards/store/gift-cards.selector';
import { giftCardsSlice, IGiftCardsState } from '@root/modules/gift-cards/store/gift-cards.slice';
import { call, put, SagaReturnType, select, takeLatest } from 'redux-saga/effects';

function* handleFetch() {
  const query: IGiftCardsState['query'] = yield select(giftCardsSelector.query);

  const result: SagaReturnType<typeof getGiftCardsService> = yield call(getGiftCardsService, query);
	if (result.status === 200) {
		yield put(giftCardsSlice.actions.fetchFulfilled(result.payload))
	} else {
		yield put(giftCardsSlice.actions.fetchRejected(result.payload))
	}
}

export function* giftCardsSaga() {
  yield takeLatest([giftCardsSlice.actions.fetchPending, giftCardsSlice.actions.filtersChanged, giftCardsSlice.actions.pageChanged], handleFetch);
}

import { useIsDesktop } from '@root/infra/device-type';
import { ChevronRightIcon } from '@root/shared/ui/icons/chevron-icon';
import { CurrentDotColorfulIcon } from '@root/shared/ui/icons/current-dot-colorful-icon';
import { SuccessColorfulIcon } from '@root/shared/ui/icons/success-colorful-icon';
import clsx from 'clsx';
import { FC, HTMLProps, ReactNode, useMemo, useState } from 'react';
import styled from 'styled-components';

type StepMenuButtonProps = { label: string; status: StepMenuButtonStatus } & HTMLProps<HTMLButtonElement>;
type StepMenuButtonStatus = 'current' | 'completed' | 'uncompleted';

const ItemButton = styled.button<{ active?: boolean }>`
  position: relative;

  &:after {
    content: '';
    display: block;
    position: absolute;
    opacity: ${({ active }) => (active ? 1 : 0)};
    top: 0;
    right: 0;
    bottom: 0;
    width: 4px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    background-color: ${({ theme }) => theme.colors.success['300']};
  }
`;

const Item: FC<StepMenuButtonProps> = ({ label, status, onClick }) => {
  return (
    <ItemButton
      onClick={onClick}
      active={status === 'current'}
      className={clsx('my-0.5 px-11 py-2 w-full transition flex items-center leading-none', { 'bg-gray-1000': status === 'current', 'hover:bg-gray-1000': !!onClick })}
    >
      <span className='mr-2'>
        {status === 'completed' && <SuccessColorfulIcon />}
        {status === 'current' && <CurrentDotColorfulIcon />}
        {status === 'uncompleted' && <CurrentDotColorfulIcon className='opacity-10' />}
      </span>
      <span>{label}</span>
    </ItemButton>
  );
};

export type StepMenuProps = {
  sections: StepMenuSection[];
  onChange?: (key: string) => void;
  footer?: ReactNode;
};
export type StepMenuSection = {
  key: string;
  icon: ReactNode;
  name: string;
  items: StepMenuItem[];
};
export type StepMenuItem = {
  key: string;
  name: string;
  status: StepMenuButtonStatus;
};

export const StepMenu: FC<StepMenuProps> = ({ sections, onChange, footer }) => {
  const isDesktop = useIsDesktop();
  const [isOpen, setIsOpen] = useState(false);
  const currentSection = useMemo(() => sections.find((section) => !!section.items.find((item) => item.status === 'current')), [sections]);

  if (!isDesktop && !isOpen) {
    if (!currentSection) {
      return null;
    }

    return (
      <button onClick={() => setIsOpen(true)} className='bg-gray-800 py-4 flex w-full items-center relative'>
        <span key='toggle-btn' onClick={() => setIsOpen(true)} className='absolute top-4 right-6 rounded-full border border-primary-400 p-3 text-xs text-primary-400'>
          <ChevronRightIcon className='transform rotate-90' />
        </span>
        <div className='flex px-4 py-2'>
          <span className='mr-3 text-lg'>{currentSection.icon}</span>
          <span className='font-bold'>{currentSection.name}</span>
        </div>
      </button>
    );
  }

  return (
    <div className='bg-gray-800 py-4 lg:rounded-lg lg:shadow-2xl relative'>
      <button key='toggle-btn' onClick={() => setIsOpen(false)} className='absolute top-4 right-6 rounded-full border border-primary-400 p-3 text-xs text-primary-400 lg:hidden'>
        <ChevronRightIcon className='transform -rotate-90' />
      </button>
      {sections.map((section) => (
        <div key={section.key} className='mb-1'>
          <div className='flex px-4 py-2'>
            <span className='mr-3 text-lg'>{section.icon}</span>
            <span className='font-bold'>{section.name}</span>
          </div>
          {section.items.map((item) => (
            <Item key={item.key} onClick={onChange ? () => onChange(item.key) : undefined} status={item.status} label={item.name} />
          ))}
        </div>
      ))}
      {footer && <div className='px-4 pt-2 flex gap-4'>{footer}</div>}
    </div>
  );
};

import { AxiosError } from 'axios';
import { fetcher } from '@root/infra/fetcher';
import { Id } from '@root/shared/constants';
import { IHttpResponse } from '@root/shared/http-service';
import { handleAxiosError } from '@root/shared/utils/handle-axios-error';

export type MakeDefaultPaymentMethodsResponse = IHttpResponse<200> | IHttpResponse<400, string>;

export const makeDefaultPaymentItemService = async (id: Id): Promise<MakeDefaultPaymentMethodsResponse> => {
  try {
    await fetcher.post('/payments/card/make-default', { id, method: 'payment-card' });

    return {
      status: 200,
      payload: null,
    };
  } catch (e) {
    return handleAxiosError(e as AxiosError);
  }
};

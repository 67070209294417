import { LoaderSpinner } from '@root/shared/ui/icons/loader-spinner';
import clsx from 'clsx';
import { FC, HTMLAttributes } from 'react';

export const LoaderFill: FC<HTMLAttributes<HTMLDivElement>> = ({ className }) => {
  return (
    <div className={clsx('absolute top-0 left-0 bottom-0 right-0 text-4xl bg-gray-100 bg-opacity-100 text-primary-400 flex items-center justify-center z-1', className)}>
      <LoaderSpinner />
    </div>
  );
};

import React, { FC, ReactNode } from 'react';
import InputMask, { Props as InputMaskProps } from 'react-input-mask';

import { Input, InputProps, InputWrapper } from './input';
import { InputError } from './input-error';
import { InputLabel } from './input-label';

export interface MaskFieldProps extends InputProps {
  label?: string;
  error?: string;
  mask: InputMaskProps['mask'];
  hideError?: boolean;
  extra?: ReactNode;
  containerClassName?: string;
  prefix?: ReactNode;
}
export const MaskField: FC<MaskFieldProps> = ({ prefix, label, mask, containerClassName, error, hideError, extra, ...inputProps }) => {
  return (
    <div className={containerClassName || ''}>
      {!!label && <InputLabel>{label}</InputLabel>}
      <div className='relative'>
        <InputMask mask={mask} maskChar={''} {...inputProps}>
          {/*// @ts-ignore*/}
          {(maskProps) => (
            <InputWrapper withPrefix={!!prefix}>
              <div className='prefix'>{prefix}</div>
              <Input className='input' {...maskProps} />
            </InputWrapper>
          )}
        </InputMask>
        <div className='absolute top-0 right-0 bottom-0'>{extra}</div>
      </div>
      {!hideError && <InputError>{error}</InputError>}
    </div>
  );
};

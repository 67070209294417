import { getMaintenance } from "@root/modules/maintenance/services/get-maintenance";
import { maintenanceSlice } from "@root/modules/maintenance/store";
import { MaintenanceResponse } from "@root/modules/maintenance/types";
import { all, call, put, takeEvery } from "redux-saga/effects";

function* getMaintenanceSettings() {
	const maintenance: MaintenanceResponse = yield call(getMaintenance);
	
	if (maintenance.status === 200 && maintenance.payload.includes('customer_portal')) {
    yield put(maintenanceSlice.actions.setMaintenance(true));
  } else {
    yield put(maintenanceSlice.actions.setMaintenance(false));
  }
}

export function* maintenanceFlowSaga() {
	yield takeEvery([maintenanceSlice.actions.syncMaintenance.type], getMaintenanceSettings);
	yield all([getMaintenanceSettings()]);
}

import type { AxiosError } from 'axios';
import { fetcher } from '@root/infra/fetcher';
import type { IHttpResponse } from '@root/shared/http-service';
import { handleAxiosError } from '@root/shared/utils/handle-axios-error';
import { IOrder } from '../types/order';

export type GetOrdersServiceResponse = IHttpResponse<200, IOrder> | IHttpResponse<400, string>;

export const getLastOrder = async (): Promise<GetOrdersServiceResponse> => {
  try {
    const response = await fetcher.get('/v1/order/last');

    return {
      status: 200,
      payload: response.data.data,
    };
  } catch (e) {
    return handleAxiosError(e as AxiosError);
  }
};

import { Theme } from '@root/infra/theme';
import styled from 'styled-components';

export type InfoBlockColor = 'default' | 'primary' | 'secondary' | 'info' | 'warning' | 'danger';

export interface InfoBlockProps {
  color?: InfoBlockColor;
}

const getBgColor = (theme: Theme, color?: InfoBlockColor) => {
  switch (color) {
    case 'primary':
      return `linear-gradient(90deg, ${theme.colors.primary[400]} 0%, #BA1C50 100%);`;
    case 'secondary':
      return `linear-gradient(270deg, ${theme.colors.success[400]}, ${theme.colors.info[300]} 100%)`;
    case 'info':
      return `linear-gradient(270deg, ${theme.colors.info[300]} 0%, #8C84FF 100%)`;
    case 'warning':
      return `linear-gradient(270deg, #FF6D41 0%, ${theme.colors.warning[500]} 100%)`;
    case 'danger':
      return `linear-gradient(270deg, #FF8C32 0%, #FF5466 100%)`;
    default:
      return theme.colors.gray[600];
  }
};

export const InfoBlock = styled.div<InfoBlockProps>`
  position: relative;
  padding: ${({ theme }) => `${theme.spacing(6)}px ${theme.spacing(5)}px`};
  background-color: ${({ theme }) => theme.colors.gray[1000]};
  border-radius: ${({ theme }) => `${theme.spacing(1)}px`};

  &:before {
    display: block;
    content: '';
    height: 100%;
    width: 6px;
    position: absolute;
    top: 0;
    left: 0;
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
    background: ${({ theme, color }) => getBgColor(theme, color)};
  }

  @media (min-width: 994px) {
    padding: ${({ theme }) => `${theme.spacing(9)}px ${theme.spacing(10)}px`};
  }
`;

InfoBlock.defaultProps = {
  color: 'default',
};

import { OptionType, Select } from '@root/shared/ui';
import { FC, useCallback, useMemo } from 'react';
import { currenciesSlice } from '@root/modules/currencies/redux/currencies.slice';
import { currenciesSelector } from '@root/modules/currencies/redux/currencies.selector';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { MultiValue, SingleValue } from 'react-select';

const CurrencyCode = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 21px;
  height: 21px;
  border: 1px solid #000;
  border-radius: 50%;
  font-size: 14px;
`;

export const CurrencySelect: FC<{ topMenu?: boolean }> = ({ topMenu }) => {
  const dispatch = useDispatch();
  const list = useSelector(currenciesSelector.list);
  const selected = useSelector(currenciesSelector.userCurrency);

  const options = useMemo(
    () =>
      list?.map((item) => ({
        value: item.code,
        label: `${item.name} (${item.code})`,
        prefix: <CurrencyCode>{item.symbol}</CurrencyCode>,
      })),
    [list],
  );

  const currentValue = useMemo(
    () =>
      selected?.code
        ? {
            value: selected.code,
            label: `${selected.name} (${selected.code})`,
            prefix: <CurrencyCode>{selected.symbol}</CurrencyCode>,
          }
        : null,
    [selected.code, selected.name, selected.symbol],
  );

  const onChange = useCallback(
    (option: SingleValue<OptionType> | MultiValue<OptionType>) => {
      dispatch(currenciesSlice.actions.setUserCurrency((option as SingleValue<OptionType>)?.value as string));
    },
    [dispatch],
  );

  return <Select menuPlacement={topMenu ? 'top' : undefined} bordered onChange={onChange} value={currentValue} options={options} isSearchable={false} />;
};

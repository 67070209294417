import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { selectKycLevel1Data, selectKycLevel2Data, selectKycMeta, selectKycSettings } from '../../redux/kyc.selector';
import { authSelector } from '@root/modules/auth/redux';
import { KYC_STATUSES } from '../../enums/kyc.statuses';

export const useKycInitiateChecker = () => {
  const location = useLocation();

  const { user } = useSelector(authSelector);
  const level1Data = useSelector(selectKycLevel1Data);
  const level2Data = useSelector(selectKycLevel2Data);
  const settings = useSelector(selectKycSettings);
  const meta = useSelector(selectKycMeta);

  const isKycPage = location.pathname.includes('/kyc');
  const isVerificationSkipped = meta.isVerificationSkipped;

  const userHasUnverifiedProduct = user?.unverifiedProducts.has;

  const level1Status = level1Data?.verificationStatus;
  const level1Enabled = settings?.level1.enabled || false;

  const level2Status = level2Data?.verificationStatus;
  const level2Enabled = settings?.level2.enabled || false;

  const shouldKycFlowRun = useMemo(() => {
    if (isVerificationSkipped && !isKycPage) {
      return false;
    }

    if (isKycPage) {
      return true;
    }

    if (userHasUnverifiedProduct) {
      return true;
    }

    if (level1Status && level1Enabled && ![KYC_STATUSES.SUCCESS, KYC_STATUSES.NOT_STARTED].includes(level1Status)) {
      return true;
    }

    if (level2Status && level2Enabled && ![KYC_STATUSES.SUCCESS, KYC_STATUSES.NOT_STARTED].includes(level2Status)) {
      return true;
    }

    return false;
  }, [isKycPage, isVerificationSkipped, userHasUnverifiedProduct, level1Status, level1Enabled, level2Status, level2Enabled]);

  return shouldKycFlowRun;
};

import jwtDecode from 'jwt-decode';
import { setCookie } from '@root/shared/cookies';
import { fetcher } from '@root/infra/fetcher';

export const updateTokens = (accessToken: string, refreshToken: string) => {
  const { exp: accessTokenExp } = jwtDecode<{ exp: number }>(accessToken);
  const { exp: refreshTokenExp } = jwtDecode<{ exp: number }>(refreshToken);

  setCookie('accessToken', accessToken, accessTokenExp);
  setCookie('refreshToken', refreshToken, refreshTokenExp);

  fetcher.defaults.headers['Authorization'] = `Bearer ${accessToken}`;
};

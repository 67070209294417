import { createSelector, Selector } from '@reduxjs/toolkit';
import { AppState } from '@root/infra/redux';
import { CurrenciesState } from './currencies.slice';
import { ICurrency } from '@root/modules/currencies/types/currency';

export const currenciesSelector = {
  list: createSelector<[Selector<AppState, CurrenciesState>], CurrenciesState['items']>(
    (state) => state.currencies,
    (currencyDataState) => currencyDataState.items,
  ),

  error: createSelector<[Selector<AppState, CurrenciesState>], CurrenciesState['error']>(
    (state) => state.currencies,
    (currencyDataState) => currencyDataState.error,
  ),

  userCurrency: createSelector<[Selector<AppState, CurrenciesState>], ICurrency>([(state) => state.currencies], (currencyDataState) => {
    return currencyDataState.items.find((item) => item.code === currencyDataState.userCurrencyCode) || currencyDataState.items[0];
  }),
};

import { QueryFunction } from 'react-query';
import { getLastOrder } from '@root/modules/orders/services/get-last-order.service';
import { IOrder } from '@root/modules/orders/types/order';

export type GetOrdersQueryKey = ['last-order'];
export const GET_LAST_ORDER: QueryFunction<IOrder, GetOrdersQueryKey> = async ({ queryKey }) => {
  const result = await getLastOrder();

  if (result.status === 200) {
    return result.payload;
  }

  throw new Error(result.payload);
};

import { BEFORE_EXPIRE_DAYS } from '@root/modules/products/constants/before-expires-days';
import { BodyText } from '@root/shared/ui';
import clsx from 'clsx';
import { differenceInDays } from 'date-fns';
import dayjs from 'dayjs';
import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

interface IProps {
  expireAt: Date;
}

export const ExpiredText: FC<IProps> = ({ expireAt }) => {
  const { t } = useTranslation('dashboard', { keyPrefix: 'bundleInfo' });

  const activeDaysLeft = dayjs(new Date(expireAt)).startOf('day').diff(dayjs().startOf('day'), 'day');

  const isExpired = activeDaysLeft <= 0;
  const isExpiresSoon = activeDaysLeft <= BEFORE_EXPIRE_DAYS;

  return (
    <BodyText className={clsx('font-bold  text-base lg:text-[13px] mb-2 sm:mb-0', isExpired || isExpiresSoon ? 'text-danger-500' : 'text-gray-600')}>
      {isExpired ? (
        t('expired')
      ) : (
        <Trans
          i18nKey='dashboard:bundleInfo.expires-in-days'
          components={{
            days: <span className={clsx(activeDaysLeft > 1 ? 'inline' : 'hidden')} />,
            day: <span className={clsx(activeDaysLeft === 1 ? 'inline' : 'hidden')} />,
          }}
          values={{ count: activeDaysLeft }}
        />
      )}
    </BodyText>
  );
};

import styled from 'styled-components';

export const HeadlineSuperLarge = styled.p<{ lineHeight?: number }>`
  font-size: 36px;
  line-height: ${({ lineHeight }) => (lineHeight ? lineHeight + 'px' : 'unset')};
`;

export const HeadlineLarge = styled.p<{ lineHeight?: number }>`
  font-size: 24px;
  line-height: ${({ lineHeight }) => (lineHeight ? lineHeight + 'px' : 'unset')};
`;

export const Headline = styled.p`
  font-size: 22px;
`;

export const HeadlineMedium = styled.p<{ lineHeight?: number }>`
  font-size: 20px;
  line-height: ${({ lineHeight }) => (lineHeight ? lineHeight + 'px' : 'unset')};
`;

export const HeadlineSmall = styled.p`
  font-size: 18px;
`;

export const BodyTextLarge = styled.p<{ lineHeight?: number }>`
  font-size: 16px;
  line-height: ${({ lineHeight }) => (lineHeight ? lineHeight + 'px' : 'unset')};
`;

export const BodyText = styled.p<{ lineHeight?: number }>`
  font-size: 14px;
`;

export const SubHeadline = styled.p<{ lineHeight?: number }>`
  font-size: 14px;
  line-height: ${({ lineHeight }) => (lineHeight ? lineHeight + 'px' : 'unset')};
`;

export const Footnote = styled.p<{ lineHeight?: number }>`
  font-size: 13px;
  line-height: ${({ lineHeight }) => (lineHeight ? lineHeight + 'px' : 'unset')};
`;

export const Caption = styled.p`
  font-size: 12px;
`;

export const CaptionSmall = styled.p`
  font-size: 11px;
`;

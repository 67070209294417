import { useLiveChatParams } from '../../hooks';
import { LiveChatWidget, WidgetState } from '@livechat/widget-react';
import { authSelector } from '@root/modules/auth/redux';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { createSearchParams, useSearchParams } from 'react-router-dom';

const LIVECHAT_LICENSE = import.meta.env.REACT_APP_LIVECHAT_LICENSE;

export const LiveChat = () => {
  const { authed, user } = useSelector(authSelector);
  const navigate = useNavigate();
  const location = useLocation();
  const liveChatParams = useLiveChatParams();
  const [searchParams] = useSearchParams();
  const widgetState = searchParams.get('widget_state');

  const onVisibilityChanged = ({ visibility }) => {
    navigate({
      pathname: location.pathname,
      search: createSearchParams({
        widget_state: visibility,
      }).toString(),
    });
  };

  if (authed && user) {
    return (
      <LiveChatWidget
        customerName={`${user.firstName} ${user.lastName}`}
        customerEmail={user.email}
        license={LIVECHAT_LICENSE}
        visibility={(widgetState || 'minimized') as WidgetState['visibility']}
        sessionVariables={liveChatParams}
        onVisibilityChanged={onVisibilityChanged}
      />
    );
  }

  return <LiveChatWidget license={LIVECHAT_LICENSE} visibility='minimized' />;
};

import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { layoutSelector, layoutSlice } from '../redux';

export const useSidebar = () => {
  const dispatch = useDispatch();
  const { sidebarIsOpen } = useSelector(layoutSelector);

  const open = useCallback(() => {
    dispatch(layoutSlice.actions.sidebarOpened());
  }, [dispatch]);

  const close = useCallback(() => {
    dispatch(layoutSlice.actions.sidebarClosed());
  }, [dispatch]);

  const toggle = useCallback(() => {
    dispatch(layoutSlice.actions.sidebarToggled());
  }, [dispatch]);

  const state = { isOpen: sidebarIsOpen };
  const handlers = { open, close, toggle };

  return [state, handlers] as [typeof state, typeof handlers];
};

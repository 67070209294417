import { maintenanceSlice } from '@root/modules/maintenance/store';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

export const useMaintenance = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const handleFocus = () => dispatch(maintenanceSlice.actions.syncMaintenance());
		
    window.addEventListener('focus', handleFocus);
    return () => {
      window.removeEventListener('focus', handleFocus);
    };
  }, [dispatch]);
};

import styled from 'styled-components';
import { MaskField } from './mask-field';
import { PasswordField } from './password-field';
import { TextField } from './text-field';

export const TransparentTextField = styled(TextField)`
  background: transparent;
  border: ${({ theme, error }) => (error ? `1px solid ${theme.colors.danger['500']}` : `1px solid ${theme.colors.gray[400]}`)};
`;

export const TransparentPasswordField = styled(PasswordField)`
  background: transparent;
  border: ${({ theme, error }) => (error ? `1px solid ${theme.colors.danger['500']}` : `1px solid ${theme.colors.gray[400]}`)};
`;

export const TransparentMaskField = styled(MaskField)`
  background: transparent;
  border: ${({ theme, error }) => (error ? `1px solid ${theme.colors.danger['500']}` : `1px solid ${theme.colors.gray[400]}`)};
`;

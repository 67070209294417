import styled, { StyledComponentProps } from 'styled-components';

export interface CheckboxExtraProps {
  error?: boolean;
  transparent?: boolean;
}

export type CheckboxProps = StyledComponentProps<'input', any, CheckboxExtraProps, any>;

export const CheckboxContainer = styled.div`
  position: relative;
  display: inline-flex;
`;

export const CheckboxLabel = styled.label<{ disabled: boolean }>`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.gray[900]};
  padding-left: ${({ theme }) => `${theme.spacing(8)}px`};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  z-index: 1;
`;

export const CheckboxIcon = styled.div<{ disabled: boolean }>`
  display: inline-flex;
  align-self: flex-start;
  margin-right: ${({ theme }) => `${theme.spacing(4)}px`};
  top: 2px;
  width: 17px;
  height: 15px;
  position: absolute;
  z-index: 0;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  filter: ${({ disabled }) => (disabled ? 'grayscale(1)' : 'none')};
`;

export const Checkbox = styled.input<CheckboxExtraProps>`
  position: absolute;
  visibility: hidden;
  width: 0;
  height: 0;
  top: 0;
  left: 0;
`;

Checkbox.defaultProps = {
  type: 'checkbox',
};

import { Theme } from '@root/infra/theme';
import { LoaderSpinner } from '@root/shared/ui/icons/loader-spinner';
import { forwardRef } from 'react';
import styled, { StyledComponentProps } from 'styled-components';

export type ButtonColor = 'primary' | 'secondary' | 'primary-alt' | 'secondary-alt' | 'disabled' | 'outlined' | 'outlined-alt' | 'danger';

export type ButtonSize = 1 | 2 | 3 | 4 | 5;

export interface ButtonExtraProps {
  color?: ButtonColor;
  size?: ButtonSize;
  loading?: boolean;
  withIcon?: boolean;
}

export type ButtonProps = StyledComponentProps<'button', any, ButtonExtraProps, any>;

const getBgColor = (theme: Theme, color?: ButtonColor) => {
  switch (color) {
    case 'primary':
      return theme.colors.gradients[1];
    case 'primary-alt':
      return theme.colors.info['400'];
    case 'outlined':
      return theme.colors.gray[100];
    case 'outlined-alt':
      return theme.colors.gray[100];
    case 'disabled':
      return theme.colors.gray['300'];
    case 'danger':
      return theme.colors.gradients[7];

    default:
      return 'transparent';
  }
};

const getHoverBgColor = (theme: Theme, color?: ButtonColor) => {
  switch (color) {
    case 'secondary':
    case 'primary':
      return theme.colors.primary['500'];
    case 'outlined':
      return theme.colors.gray['100'];
    case 'outlined-alt':
      return 'transparent';
    case 'secondary-alt':
      return theme.colors.gray['100'];
    case 'danger':
      return theme.colors.gray['100'];
    default:
      return 'transparent';
  }
};

const getBorderColor = (theme: Theme, color?: ButtonColor) => {
  switch (color) {
    case 'primary-alt':
      return theme.colors.gray['100'];
    case 'outlined':
      return theme.colors.primary['500'];
    case 'outlined-alt':
      return theme.colors.gray['100'];
    case 'secondary':
      return theme.colors.primary['500'];
    default:
      return 'transparent';
  }
};

const getHoverBorderColor = (theme: Theme, color?: ButtonColor) => {
  switch (color) {
    case 'primary-alt':
      return theme.colors.gray['100'];
    case 'outlined':
      return theme.colors.primary['500'];
    case 'outlined-alt':
      return theme.colors.gray['100'];
    default:
      return 'transparent';
  }
};

const getHoverTextColor = (theme: Theme, color?: ButtonColor) => {
  switch (color) {
    case 'outlined':
      return theme.colors.primary['500'];
    case 'outlined-alt':
      return theme.colors.gray['100'];
    case 'secondary-alt':
      return theme.colors.primary['500'];
    case 'danger':
      return theme.colors.danger['500'];
    default:
      return theme.colors.gray['100'];
  }
};

const getTextColor = (theme: Theme, color?: ButtonColor) => {
  switch (color) {
    case 'outlined':
      return theme.colors.primary['500'];
    case 'outlined-alt':
      return theme.colors.primary['500'];
    case 'secondary':
    case 'secondary-alt':
      return theme.colors.primary['500'];
    case 'disabled':
      return theme.colors.gray['500'];
    default:
      return theme.colors.gray['100'];
  }
};

const getBorderRadius = (theme: Theme, size?: ButtonSize): string => {
  switch (size) {
    case 1:
      return theme.spacing(3) + 'px';
    case 2:
      return theme.spacing(4) + 'px';
    case 3:
      return theme.spacing(5) + 'px';
    case 4:
      return theme.spacing(6) + 'px';
    case 5:
      return theme.spacing(7) + 'px';
    default:
      return theme.spacing(3) + 'px';
  }
};

const getPadding = (theme: Theme, size?: ButtonSize, withIcon?: boolean): string => {
  if (withIcon) {
    switch (size) {
      case 2:
        return `${theme.spacing(2)}px ${theme.spacing(4.25)}px`;
      case 3:
        return `${theme.spacing(1.75)}px ${theme.spacing(6)}px`;
      case 4:
        return `${theme.spacing(3.5)}px ${theme.spacing(8)}px`;
      case 5:
        return `${theme.spacing(4)}px ${theme.spacing(8.125)}px`;
      default:
        return `${theme.spacing(3.5)}px ${theme.spacing(8)}px`;
    }
  }

  switch (size) {
    case 1:
      return `${theme.spacing(1)}px ${theme.spacing(1.75)}px`;
    case 2:
      return `${theme.spacing(2)}px ${theme.spacing(4.25)}px`;
    case 3:
      return `${theme.spacing(1.75)}px ${theme.spacing(6)}px`;
    case 5:
      return `${theme.spacing(4.5)}px ${theme.spacing(8.125)}px`;
    default:
      return `${theme.spacing(3.75)}px ${theme.spacing(8)}px`;
  }
};

const getFontSize = (theme: Theme, size?: ButtonSize): string => {
  switch (size) {
    case 5:
      return '16px';
    case 2:
      return '13px';
    case 1:
      return '12px';
    default:
      return '14px';
  }
};

const getBorderWidth = (theme: Theme, color?: ButtonColor) => {
  switch (color) {
    case 'primary':
      return 0;
    case 'primary-alt':
    case 'outlined':
    case 'outlined-alt':
    case 'disabled':
    case 'danger':
    default:
      return 1;
  }
};

const StyledButton = styled.button<ButtonExtraProps>`
  position: relative;
  background: ${({ theme, color }) => getBgColor(theme, color)};
  color: ${({ theme, color }) => getTextColor(theme, color)};
  border-radius: ${({ theme, size }) => getBorderRadius(theme, size)};
  padding: ${({ theme, size, withIcon }) => getPadding(theme, size, !!withIcon)};
  font-size: ${({ theme, size }) => getFontSize(theme, size)};
  line-height: normal;
  border-style: solid;
  border-width: ${({ theme, color }) => getBorderWidth(theme, color)}px;
  border-color: ${({ theme, color }) => getBorderColor(theme, color)};
  font-weight: bold;
  box-sizing: border-box;
  align-items: center;
  display: inline-flex;
  transition: 0.2s, color 0.2s;
  justify-content: center;
  z-index: 0;
  overflow: hidden;
  pointer-events: ${({ loading }) => (loading ? 'none' : 'auto')};

  &:disabled {
    background: ${({ theme }) => theme.colors.gray['300']};
    color: ${({ theme }) => theme.colors.gray['500']};
    border-color: ${({ theme }) => theme.colors.gray['300']};
    pointer-events: none;
  }

  @media (hover: hover) {
    &:hover {
      border-color: ${({ theme, color }) => getHoverBorderColor(theme, color)};
      background-color: ${({ theme, color }) => getHoverBgColor(theme, color)};
      color: ${({ theme, color }) => getHoverTextColor(theme, color)};

      svg path {
        fill: ${({ theme, color }) => getHoverTextColor(theme, color)};
      }
    }
  }

  &::before {
    content: '';
    position: absolute;
    left: -1px;
    right: 0;
    top: -1px;
    bottom: 0;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    display: block;
    z-index: -1;
    background: ${({ theme, color }) => getHoverBgColor(theme, color)};
    transition: opacity 0.2s;
    opacity: 0;
    border-radius: ${({ theme, size }) => getBorderRadius(theme, size)};
  }

  @media (hover: hover) {
    &:hover::before {
      opacity: 1;
    }
  }
`;

StyledButton.defaultProps = {
  size: 4,
  color: 'primary',
};

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(function Button({ loading, ...props }, ref) {
  return (
    <StyledButton {...props} ref={ref}>
      {props.children}
      {loading && (
        <span className='absolute top-0 left-0 bottom-0 right-0 bg-black bg-opacity-75 flex items-center justify-center text-2xl'>
          <LoaderSpinner />
        </span>
      )}
    </StyledButton>
  );
});

import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ArrowContainer, PopoverPosition, Popover } from 'react-tiny-popover';

import { theme } from '@root/infra/theme';

import { Header, PasswordInfo, PasswordStrengthIndicator, Point, Title } from '../fields';

const PasswordStrengthContainer = styled.div`
  position: absolute;
  width: 8px;
  height: 40px;
  right: 20px;
  top: 28px;
  font-size: 0;
  box-sizing: content-box;
  cursor: pointer;
`;

export type PasswordStrengthProps = {
  level?: number;
  items: { text: string; success: boolean }[];
};

export const PasswordStrength: FC<PasswordStrengthProps> = ({ level, items }) => {
  const { t } = useTranslation();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const togglePopover = useCallback(() => {
    setIsPopoverOpen((prevState) => !prevState);
  }, []);

  const title = useMemo(() => {
    switch (level) {
      case 0:
        return t('auth:Weak');
      case 1:
        return t('auth:Normal');
      case 2:
        return t('auth:Strong');
      case 3:
        return t('auth:Very strong');
    }
  }, [level, t]);

  return (
    <PasswordStrengthContainer>
      <PasswordStrengthIndicator level={level || 0} onClick={togglePopover} />
      <Popover
        isOpen={isPopoverOpen}
        positions={['bottom', 'left']}
        align='center'
        padding={5}
        reposition={true}
        content={({ position, childRect, popoverRect }) => (
          <ArrowContainer
            position={position as PopoverPosition}
            childRect={childRect}
            popoverRect={popoverRect}
            arrowColor={theme.colors.gray['100']}
            arrowStyle={{ zIndex: 1, transform: 'translateX(4px)' }}
            arrowSize={10}
          >
            <PasswordInfo handleTogglePopover={togglePopover}>
              <Header>{title}</Header>
              <Title>{t('auth:Your password should include')}</Title>
              {items.map((item, index) => (
                <Point key={index} success={item.success}>
                  {item.text}
                </Point>
              ))}
            </PasswordInfo>
          </ArrowContainer>
        )}
      >
        <div onClick={() => setIsPopoverOpen(!isPopoverOpen)}>Click me!</div>
      </Popover>
    </PasswordStrengthContainer>
  );
};

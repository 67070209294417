import { AxiosError } from 'axios';
import { fetcher } from '@root//infra/fetcher';
import { IHttpResponse } from '@root/shared/http-service';
import { handleAxiosError } from '@root/shared/utils/handle-axios-error';
import { IEWallet } from '../types';

export type GetEWalletResponses = IHttpResponse<200, IEWallet> | IHttpResponse<400, string>;

export const getEWallet = async (): Promise<GetEWalletResponses> => {
  try {
    const response = await fetcher('/user/wallets');

    return {
      status: 200,
      payload: {
        commission: response.data.data.commissionWallet,
        gifted: response.data.data.giftedWallet,
        point: response.data.data.pointWallet,
      },
    };
  } catch (e) {
    return handleAxiosError(e as AxiosError);
  }
};

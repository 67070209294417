import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IRemoteData } from '@root/shared/remote-data';
import { authSlice } from '@root/modules/auth/redux';
import { IBillingDetails } from '../../types/billing-details';

export interface IBillingDetailsState extends IRemoteData<IBillingDetails | null> {}

export namespace BillingDetailsActions {
  export type FetchPending = PayloadAction;
  export type FetchFulfilled = PayloadAction<IBillingDetails>;
  export type FetchRejected = PayloadAction<string>;
}

export type BillingDetailsCaseReducer = {
  fetchPending: CaseReducer<IBillingDetailsState, BillingDetailsActions.FetchPending>;
  fetchFulfilled: CaseReducer<IBillingDetailsState, BillingDetailsActions.FetchFulfilled>;
  fetchRejected: CaseReducer<IBillingDetailsState, BillingDetailsActions.FetchRejected>;
};

export const billingDetailsSlice = createSlice<IBillingDetailsState, BillingDetailsCaseReducer, 'billingDetails'>({
  name: 'billingDetails',
  initialState: {
    isLoading: false,
    data: null,
    error: null,
    loadedOnce: false,
  },
  extraReducers: (builder) => {
    builder.addCase(authSlice.actions.fetchProfileFulfilled, (state) => {
      state.isLoading = true;
      state.error = null;
    });
  },
  reducers: {
    fetchPending: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    fetchFulfilled: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    fetchRejected: (state, action) => {
      state.isLoading = true;
      state.error = action.payload;
    },
  },
});

import { CaseReducer, PayloadAction, createSlice } from '@reduxjs/toolkit';

export type UserAgentState = {
  userAgent: string | null;
  deviceType: string | null;
};

export namespace UserAgentActions {
  export type Updated = PayloadAction<string>;
  export type DeviceTypeUpdated = PayloadAction<string>;
}

export type UserAgentSliceReducer = {
  updated: CaseReducer<UserAgentState, UserAgentActions.Updated>;
  deviceTypeUpdated: CaseReducer<UserAgentState, UserAgentActions.DeviceTypeUpdated>;
};

export const userAgentInitialState: UserAgentState = {
  userAgent: null,
  deviceType: null,
};

export const userAgentSlice = createSlice<UserAgentState, UserAgentSliceReducer, 'userAgent'>({
  name: 'userAgent',
  initialState: userAgentInitialState,
  reducers: {
    updated: (state, action) => {
      state.userAgent = action.payload;
    },
    deviceTypeUpdated: (state, action) => {
      state.deviceType = action.payload;
    },
  },
});

import React, { FC } from 'react';
import styled from 'styled-components';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  className?: string;
}

export const Container: FC<Props> = ({ children, ...props }) => {
  return (
    <div {...props} className={`container ${props.className}`}>
      {children}
    </div>
  );
};

export const Row: FC<Props> = ({ children, ...props }) => {
  return (
    <div {...props} className={`grid grid-cols-12 px-2 md:px-0 gap-1 sm:gap-2 md:gap-3 lg:gap-4 xl:gap-6 2xl:gap-8 ${props.className}`}>
      {children}
    </div>
  );
};

export const LayoutContainer: FC<Props> = ({ children, className = '', ...props }) => {
  return (
    <div className={`px-5 py-6 lg:px-12 lg:pt-9 ${className}`} {...props}>
      {children}
    </div>
  );
};

export const Grid = styled(Row)`
  min-height: calc(100vh - 86px);
`;

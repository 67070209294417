import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';

export type AppSettingsState = { supportUrl: string | null };

export namespace SettingsActions {
  export type SetSupportUrl = PayloadAction<string | null>;
}

export type AppSettingsCaseReducer = {
  setSupportUrl: CaseReducer<AppSettingsState, SettingsActions.SetSupportUrl>;
};

const initialState: AppSettingsState = {
  supportUrl: null,
};

export const appSettingsSlice = createSlice<AppSettingsState, AppSettingsCaseReducer, 'appSettings'>({
  name: 'appSettings',
  initialState,
  reducers: {
    setSupportUrl: (state, action) => {
      state.supportUrl = action.payload;
    },
  },
});

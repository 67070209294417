import { getPaymentMethodInfo } from '@root/modules/payment-methods/helpers/getPaymentMethodInfo';
import { IPaymentItem } from '@root/modules/payment-methods/types/payment-item';
import { BodyTextLarge, BodyText, LinkButton } from '@root/shared/ui';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface IProps {
  item: IPaymentItem;
}

export const EditDefaultPaymentMethodInfo: FC<IProps> = ({ item }) => {
  const { t } = useTranslation('dashboard', { keyPrefix: 'paymentMethodsInfo' });
  const navigate = useNavigate();

  const paymentMethodInfo = getPaymentMethodInfo(item);

  const handleRedirect = useCallback(() => {
    if (item.type === 'payment-card') {
      navigate(`/payment-methods/edit/card/${item.id}`);
    }

    if (item.type === 'paypal') {
      navigate('/payment-methods');
    }
  }, [item, navigate]);

  return (
    <div className='p-4 py-3 sm:py-4 flex flex-row items-center'>
      <div>
        <BodyTextLarge className='font-bold'>{t('default-payment-method')}</BodyTextLarge>
        {item.type === 'payment-card' && (
          <BodyText className='text-[12px] font-bold text-gray-600'>{t('method-type-card', { type: paymentMethodInfo?.title, last4Number: item.last4Number })}</BodyText>
        )}
        {item.type === 'paypal' && (
          <BodyText className='text-[12px] font-bold text-gray-600'>
            {t('method-type-paypal', { type: paymentMethodInfo?.title, owner: item.owner.firstname + ' ' + item.owner.lastname })}
          </BodyText>
        )}
      </div>

      <LinkButton as='button' className='ml-auto' onClick={handleRedirect}>
        {t('edit')}
      </LinkButton>
    </div>
  );
};

import { fetcher } from '@root/infra/fetcher';
import { IHttpResponse } from '@root/shared/http-service';
import { WithLocale } from '@root/shared/types';
import { sanitizeObject } from '@root/shared/utils';
import { ICountry } from '../types';

export type GetCountriesResponses = IHttpResponse<200, ICountry[]> | IHttpResponse<400, string>;

export const getCountries = async (query?: WithLocale<{}>): Promise<GetCountriesResponses> => {
  try {
    const response = await fetcher('/region/country/list', { params: sanitizeObject({ language: query?.locale }) });

    if (response.status === 200) {
      return {
        status: 200,
        payload: response.data.map((item) => ({ code: item.code, name: item.name, phoneVerificationEnabled: item.phoneVerificationEnabled })),
      };
    }

    return {
      status: 400,
      payload: 'Bad request',
    };
  } catch (e) {
    return {
      status: 400,
      payload: 'Bad request',
    };
  }
};

// import setLanguage from 'next-translate/setLanguage';
import { PORTAL_TOKEN_PARAM, SHOP_TOKEN_PARAM } from '../../constants/tokens';
import { getTokens, removeTokens, updateTokens } from '../../helpers';
import { AuthActions, authSlice } from './auth.slice';
import { call, delay, fork, put, SagaReturnType, takeLatest } from '@redux-saga/core/effects';
import { fetcher } from '@root/infra/fetcher';
import { authSelector } from '@root/modules/auth/redux';
import { checkSSOWorker } from '@root/modules/auth/redux/sso';
import { fetchProfileService } from '@root/modules/auth/services';
import { settingsSlice } from '@root/modules/settings/store/settings.slice';
import queryString from 'query-string';
import { select } from 'redux-saga/effects';

const { loggedIn, loggedOut, tokensRefreshed, fetchProfilePending, fetchProfileFulfilled, fetchProfileRejected, membershipUpdated, tokensMissed, setCaptchaPassed } =
  authSlice.actions;

// const reloadWithLanguage = (value?: string) => {
//   if (!!value) {
//     return setLanguage(value);
//   }
// };

function* handleTokensRefreshed(action: AuthActions.TokensRefreshed) {
  const accessToken = action.payload.accessToken;
  const refreshToken = action.payload.refreshToken;
  updateTokens(accessToken, refreshToken);
  yield;
}

function* handleAuthFulfilled(action: AuthActions.Registered | AuthActions.LoggedIn) {
  const accessToken = action.payload.tokens.accessToken;
  const refreshToken = action.payload.tokens.refreshToken;
  updateTokens(accessToken, refreshToken);
  removeTempToken();
  yield;
}

function* handleClearSearchParams() {
  // eslint-disable-next-line valid-typeof
  if (typeof window !== undefined) {
    window.history.replaceState({}, document.title, window.location.pathname);
  }
  yield;
}

function* handleHydrate(action?: AuthActions.SetCaptchaPassed) {
  const { captchaPassed } = yield select(authSelector);

  if (captchaPassed) {
    const withSSO = yield call(checkSSOWorker);
    if (!withSSO) {
      yield put(fetchProfilePending());
    }
  }
}

function* handleFetchProfile() {
  const { accessToken, refreshToken } = getTokens();
  yield delay(0);

  if (!!accessToken || !!refreshToken) {
    const result: SagaReturnType<typeof fetchProfileService> = yield call(fetchProfileService);

    if (result.status === 200) {
      yield put(fetchProfileFulfilled(result.payload));
      yield call(handleClearSearchParams);
      // yield reloadWithLanguage()
    } else {
      yield put(fetchProfileRejected(result.payload));
    }
  } else {
    yield put(tokensMissed());
  }
}

function* handleLogout() {
  removeTokens();
  fetcher.defaults.headers['Authorization'] = undefined;
  yield;
}

export function* removeTempToken() {
  const query = queryString.parse(window.location.search, { parseNumbers: true });
  if (query[PORTAL_TOKEN_PARAM]) {
    yield put(authSlice.actions.removeTempToken(PORTAL_TOKEN_PARAM));
  }
  if (query[SHOP_TOKEN_PARAM]) {
    yield put(authSlice.actions.removeTempToken(SHOP_TOKEN_PARAM));
  }
}

export function* authFlowSaga() {
  yield fork(handleHydrate);
  yield takeLatest([setCaptchaPassed.type], handleHydrate);
  yield takeLatest([loggedIn.type], handleAuthFulfilled);
  yield takeLatest([tokensRefreshed.type], handleTokensRefreshed);
  yield takeLatest([loggedOut.type], handleLogout);
  yield takeLatest([fetchProfilePending.type, loggedIn.type, settingsSlice.actions.updated, membershipUpdated], handleFetchProfile);
}

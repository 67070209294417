import { IProductCategoryWithSub } from '../types/product-category';
import { RECURRING_TYPES } from '@root/infra/enums/reccuring-type';
import { fetcher } from '@root/infra/fetcher';
import { ProductCategoryMapper } from '@root/modules/products/mappers/product-category.mapper';
import { IHttpResponse } from '@root/shared/http-service';
import queryString from 'query-string';

export type GetProductCategoriesResponses = IHttpResponse<200, IProductCategoryWithSub[]> | IHttpResponse<400, string>;

export type GetProductsQuery = {
  uiCountry?: string;
  userId?: string;
  language?: string;
  recurringType?: RECURRING_TYPES;
};

export const getProductCategories = async (query?: GetProductsQuery): Promise<GetProductCategoriesResponses> => {
  try {
    const params = query ? `?${queryString.stringify(query)}` : '';
    const response = await fetcher.get(`/shop/product/list${params}`);

    return {
      status: 200,
      payload: response.data.data.filter((item) => !!item.visible).map((item) => ProductCategoryMapper.toDomain(item)),
    };
  } catch (e) {
    console.error(e);

    return {
      status: 400,
      payload: 'Bad request',
    };
  }
};

import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IRemoteData } from '@root/shared/remote-data';
import { IService } from '../../types/product-service';

export type IServicesState = IRemoteData<IService[]>;

export namespace ServicesActions {
  export type Pending = PayloadAction;
  export type Fulfilled = PayloadAction<IService[]>;
  export type Rejected = PayloadAction<string>;
}

export type ServicesSliceReducer = {
  pending: CaseReducer<IServicesState, ServicesActions.Pending>;
  fulfilled: CaseReducer<IServicesState, ServicesActions.Fulfilled>;
  rejected: CaseReducer<IServicesState, ServicesActions.Rejected>;
};

export const initialState: IServicesState = {
  isLoading: false,
  data: [],
  error: null,
};

export const servicesSlice = createSlice<IServicesState, ServicesSliceReducer, 'services'>({
  name: 'services',
  initialState: initialState,
  reducers: {
    pending: (state) => {
      state.isLoading = true;
    },
    fulfilled: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    rejected: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

import { type CountryCode, getCountryCallingCode } from 'libphonenumber-js';
import type { IAddress } from '../types/address';

export class AddressMapper {
  public static toDomain(address): IAddress {
    const phoneNumberPrefix = getCountryCallingCode((address.country || address.countryCode) as CountryCode);
    const phoneNumber = address.phone.replace('+' + phoneNumberPrefix, '');

    return {
      id: address.id,
      firstname: address.firstName,
      lastname: address.lastName,
      country: address.country || address.countryCode,
      state: address.state,
      city: address.city,
      postalCode: address.zipCode,
      firstAddressLine: address.address,
      secondAddressLine: address.address2,
      phoneNumberPrefix: phoneNumberPrefix,
      phoneNumber: phoneNumber,
      createdAt: address.createdAt,
      isDefault: address.primary,
    };
  }
}

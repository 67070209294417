import { fetcher } from '@root/infra/fetcher';
import type { IHttpResponse } from '@root/shared/http-service';
import { AxiosError } from 'axios';
import { AuthTokensMapper } from '../mappers';
import { IAuthTokens } from './../types';

export type RefreshTokenServiceResponse = IHttpResponse<200, IAuthTokens> | IHttpResponse<401, string>;

export const refreshTokenService = async (refreshToken: string): Promise<RefreshTokenServiceResponse> => {
  try {
    const response = await fetcher.post('/auth/refresh-tokens', undefined, {
      headers: {
        Authorization: `Bearer ${refreshToken}`,
      },
    });

    return {
      status: 200,
      payload: AuthTokensMapper.toDomain(response.data.data),
    };
  } catch (e) {
    const error = e as AxiosError;
    console.error(e);

    if (error.isAxiosError && error.response) {
      return {
        status: 401,
        payload: error.response.data.message,
      };
    }

    return {
      status: 401,
      payload: 'Bad request',
    };
  }
};

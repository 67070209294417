import { UserDropdownButton } from '../components/user-dropdown/user-dropdown-button';
import { Card, CardFooter, CardHeader, MenuItem, Username } from '../components/user-dropdown/user-dropdown.ui';
import { useIsDesktop } from '@root/infra/device-type';
import { authSelector, authSlice } from '@root/modules/auth/redux';
import { UserDropdownKYCStatus } from '@root/modules/kyc/components/user-dropdown-kyc-status';
import { BodyText } from '@root/shared/ui';
import { ChevronRightIcon } from '@root/shared/ui/icons/chevron-icon';
import { GearIcon } from '@root/shared/ui/icons/gear-icon';
import { LogoutIcon } from '@root/shared/ui/icons/logout-icon';
import { UserIdIcon } from '@root/shared/ui/icons/user-id-icon';
import clsx from 'clsx';
import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Popover } from 'react-tiny-popover';

export const UserDropdown: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isDesktop = useIsDesktop();
  const [hovered, setHovered] = useState<boolean>(false);

  const authState = useSelector(authSelector);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const openDropdown = useCallback(() => {
    setIsOpen(true);
  }, []);

  const closeDropdown = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleUserLogout = useCallback(async () => {
    dispatch(authSlice.actions.loggedOut());
  }, [dispatch]);

  return (
    <Popover
      isOpen={isOpen}
      onClickOutside={closeDropdown}
      positions={['bottom', 'top', 'left']}
      align={isDesktop ? 'end' : 'center'}
      padding={8}
      content={
        <Card>
          <CardHeader>
            <UserDropdownKYCStatus />
          </CardHeader>
          <CardFooter>
            <MenuItem as='button' className='w-full cursor-default flex justify-start items-start'>
              <span className='pt-1'>
                <UserIdIcon />
              </span>

              <div className='flex flex-col items-start'>
                <span className='text-sm ml-2 '>
                  {t('common:Your ID')} #{authState.user?.customerId}
                </span>
                <span className='text-sm ml-2'>{t('account:profile_badge.Username', { value: authState.user?.username })}</span>
                <span className='text-sm ml-2'>{t('account:profile_badge.recommender-id', { value: authState.user?.enroller?.customerId })}</span>
              </div>
            </MenuItem>

            <Link to='/settings'>
              <MenuItem as='button' className='w-full'>
                <GearIcon />
                <span className='ml-2'>{t('common:AccountSettings')}</span>
              </MenuItem>
            </Link>

            <MenuItem onClick={handleUserLogout} className='w-full cursor-pointer'>
              <LogoutIcon />
              <span className='ml-2'>{t('common:Log_out')}</span>
            </MenuItem>
          </CardFooter>
        </Card>
      }
    >
      <button
        className={clsx('relative flex items-center bg-transparent p-2 transition gap-3', {
          'bg-gray-200': isOpen && isDesktop,
          'rounded-full hover:bg-gray-200': isDesktop,
          'rounded-full !bg-gray-200': isOpen,
          'rounded-md !px-3 !py-2.5': !isDesktop,
        })}
        onClick={openDropdown}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        {authState.user && (
          <Username className='hidden lg:block cursor-pointer' title={authState.user.fullName}>
            <BodyText className='text-right leading-tight'>{authState.user.fullName}</BodyText>
          </Username>
        )}
        <UserDropdownButton hovered={isOpen || hovered} />
        <ChevronRightIcon
          className={clsx('transition cursor-pointer hidden lg:block ml-1', {
            '-rotate-90': isOpen,
            'rotate-90': !isOpen,
          })}
        />
      </button>
    </Popover>
  );
};

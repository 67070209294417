import styled from 'styled-components';
import { H3 } from './headings';

const Wrapper = styled.div``;

const Header = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => `${theme.spacing(1)}px ${theme.spacing(0)}px`};
  margin-bottom: ${({ theme }) => theme.spacing(3)}px;
`;

const HeaderTitle = styled(H3)`
  font-weight: 700;
  flex: 1;
`;

const HeaderIcon = styled.span`
  font-size: 20px;
  margin-right: ${({ theme }) => theme.spacing(3)}px;
`;

const HeaderAction = styled.div``;

const Body = styled.div``;

export const Section = { Wrapper, Header, HeaderIcon, HeaderTitle, HeaderAction, Body };

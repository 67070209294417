import { FC } from 'react';

export const ArrowRightPurpleIcon: FC<any> = ({ onHover, disabled, ...props }) => {
  return (
    <svg width='18px' height='11px' viewBox='0 0 18 11' {...props} version='1.1' xmlns='http://www.w3.org/2000/svg'>
      <defs>
        <linearGradient x1='100%' y1='50%' x2='0%' y2='50%' id='linearGradient-133'>
          <stop stopColor='#6983CD' offset='0%' />
          <stop stopColor='#9C4EFE' offset='100%' />
        </linearGradient>
        <linearGradient x1='100%' y1='50%' x2='0%' y2='50%' id='linearGradient-233'>
          <stop stopColor='#00EDB8' offset='0%' />
          <stop stopColor='#41C7FF' offset='100%' />
        </linearGradient>
        <linearGradient x1='100%' y1='50%' x2='0%' y2='50%' id='linearGradient-333'>
          <stop stopColor='#AFAFB8' offset='0%' />
          <stop stopColor='#AFAFB8' offset='100%' />
        </linearGradient>
      </defs>
      <g id='15-11' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g
          id='1.1-Home-Page---Gradient'
          transform='translate(-804.000000, -3138.000000)'
          fill={disabled ? 'url(#linearGradient-333)' : onHover ? 'url(#linearGradient-233)' : 'url(#linearGradient-133)'}
        >
          <g id='blog' transform='translate(150.000000, 2923.000000)'>
            <g id='Group' transform='translate(602.000000, 160.500000)'>
              <g transform='translate(52.000000, 48.000000)' id='4.-Elements-/-Icons-/-Basic-/-Arrow-Right'>
                <g transform='translate(0.000000, 7.000000)'>
                  <path
                    d='M12.2929,0.292893075 C12.6834,-0.097631025 13.3166,-0.097631025 13.7071,0.292893075 L17.7065,4.29231007 L17.7071,4.29289007 C17.803,4.38877008 17.8753,4.49927008 17.9241,4.61722007 C17.973,4.73512008 18,4.86441008 18,5 C18,5.13505007 17.9732,5.26385007 17.9247,5.38138008 C17.9006,5.43977008 17.8708,5.49634007 17.8352,5.55023007 C17.7986,5.60568008 17.7559,5.65829008 17.7071,5.70711008 L17.7065,5.70769008 L13.7071,9.70711008 C13.3166,10.0976001 12.6834,10.0976001 12.2929,9.70711008 C11.9024,9.31658008 11.9024,8.68342008 12.2929,8.29289008 L14.5858,6 L1,6 C0.447715,6 0,5.55228007 0,5 C0,4.44772008 0.447715,4 1,4 L14.5858,4 L12.2929,1.70711007 C11.9024,1.31658008 11.9024,0.683417075 12.2929,0.292893075 Z'
                    id='Path'
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

import { createSelector, Selector } from '@reduxjs/toolkit';
import type { AppState } from '@root/infra/redux';
import type { OrdersState } from './orders.slice';
import { OrdersTabKey } from '../constants/orders-tab-key';

const getOrders = (state: AppState) => state.orders;
const getActiveTab = (orders: OrdersState) => {
  if (orders.query.isRequested) {
    return OrdersTabKey.RECEIVED;
  } else if (orders.query.hasCoordinator) {
    return OrdersTabKey.FORWARDED;
  }
  return OrdersTabKey.ORDERS;
};
const getTempFiltersCount = (orders: OrdersState) => {
  let count = 0;
  const { typeFilter, productFilter, statusFilter } = orders.tempFilters;

  if (!!typeFilter) count++;
  if (!!productFilter) count++;
  if (!!statusFilter) count++;

  return count;
};
const getFiltersCount = (orders: OrdersState) => {
  let count = 0;
  const { typeFilter, productFilter, statusFilter } = orders.query;

  if (!!typeFilter) count++;
  if (!!productFilter) count++;
  if (!!statusFilter) count++;

  return count;
};
const getQuery = (orders: OrdersState) => orders.query;
const getIsLading = (orders: OrdersState) => orders.isLoading;
const getTotalItems = (orders: OrdersState) => orders.data.totalItems;
const getTotalPages = (orders: OrdersState) => orders.data.totalPages;
const getTempFilters = (orders: OrdersState) => orders.tempFilters;
const getData = (orders: OrdersState) => orders.data.data;
const getCancellingRequestedOrderIds = (orders: OrdersState) => orders.cancelingRequestedOrderIds;
const getPayingRequestedOrderIds = (orders: OrdersState) => orders.payingRequestedOrderIds;

export const ordersSelector = {
  activeTab: createSelector<[Selector<AppState, OrdersState>], OrdersTabKey>([getOrders], getActiveTab),
  query: createSelector<[Selector<AppState, OrdersState>], OrdersState['query']>([getOrders], getQuery),
  isLoading: createSelector<[Selector<AppState, OrdersState>], OrdersState['isLoading']>([getOrders], getIsLading),
  totalItems: createSelector<[Selector<AppState, OrdersState>], number>([getOrders], getTotalItems),
  totalPages: createSelector<[Selector<AppState, OrdersState>], number>([getOrders], getTotalPages),
  tempFilters: createSelector<[Selector<AppState, OrdersState>], OrdersState['tempFilters']>([getOrders], getTempFilters),
  data: createSelector<[Selector<AppState, OrdersState>], OrdersState['data']['data']>([getOrders], getData),
  cancelingRequestedOrderIds: createSelector<[Selector<AppState, OrdersState>], OrdersState['cancelingRequestedOrderIds']>([getOrders], getCancellingRequestedOrderIds),
  payingRequestedOrderIds: createSelector<[Selector<AppState, OrdersState>], OrdersState['payingRequestedOrderIds']>([getOrders], getPayingRequestedOrderIds),
  filtersCount: createSelector<[Selector<AppState, OrdersState>], number>([getOrders], getFiltersCount),
  tempFiltersCount: createSelector<[Selector<AppState, OrdersState>], number>([getOrders], getTempFiltersCount),
};

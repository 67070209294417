import { ChevronIcon } from '@root/shared/ui/icons/chevron-icon';
import clsx from 'clsx';
import { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';

type HeaderProps = {
  isOpen: boolean;
  setIsOpen(isOpen: boolean): void;
};

const CardHeaderWrapper = styled.div<{ isOpen: boolean }>`
  padding: 1em 1.7em;
  position: relative;
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CardHeader: FC<PropsWithChildren<HeaderProps>> = ({ isOpen, setIsOpen, children }) => {
  return (
    <CardHeaderWrapper isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
      <div>{children}</div>
      <div className={clsx('transition transform duration-200', { 'rotate-180': isOpen })}>
        <ChevronIcon />
      </div>
    </CardHeaderWrapper>
  );
};

export const CardContent = styled.div<{ isOpen: boolean }>`
  width: 100%;
  transition: 200ms;
  height: 0;
  overflow: hidden;
  padding: 0 1.7em;
  opacity: 0;

  ${({ isOpen }) => {
    return isOpen
      ? `
         height: unset;
         padding: 1em 1.7em;
         opacity: 1;
        `
      : '';
  }}
`;

export const CardWrapper = styled.div`
  border-radius: 0.5em;
  background-color: ${({ theme }) => theme.colors.gray['100']};
  box-shadow: ${({ theme }) => theme.shadows[1]};
`;

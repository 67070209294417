import styled from 'styled-components';

import { Caption, H6 } from '@root/shared/ui';

const CardText = styled.div<{ color: string; large?: boolean }>`
  position: absolute;
  background: ${({ theme }) => theme.colors.gray[100]};
  border-radius: 0 100px 100px 0;
  color: ${({ color }) => color};
  padding: ${({ theme, large }) =>
    large
      ? `${theme.spacing(3)}px ${theme.spacing(12)}px ${theme.spacing(3)}px ${theme.spacing(6)}px`
      : `${theme.spacing(2.5)}px ${theme.spacing(10)}px ${theme.spacing(2.5)}px ${theme.spacing(5)}px`};
  font-weight: bold;
  font-size: ${({ large }) => (large ? '20px' : '16px')};
  top: ${({ large }) => (large ? '37px' : '32px')};
  left: 8px;

  @media screen and (min-width: 540px) {
    top: ${({ large }) => (large ? '50px' : '45px')};
  }
`;

const CardName = styled(H6)<{ large?: boolean }>`
  color: ${({ theme }) => theme.colors.gray[100]};
  font-weight: bold;
  font-size: ${({ large }) => (large ? '20px' : '16px')};
  position: absolute;
  top: ${({ large }) => (large ? '107px' : '89px')};
  left: ${({ large }) => (large ? '32px' : '28px')};

  @media screen and (min-width: 540px) {
    top: ${({ large }) => (large ? '120px' : '102px')};
  }
`;

const DiscountText = styled(Caption)<{ large?: boolean }>`
  color: ${({ theme }) => theme.colors.gray[100]};
  position: absolute;
  top: ${({ large }) => (large ? '142px' : '117px')};
  left: ${({ large }) => (large ? '32px' : '28px')};

  @media screen and (min-width: 540px) {
    top: ${({ large }) => (large ? '155px' : '130px')};
  }
`;

export const MembershipCardUI = { CardName, CardText, DiscountText };

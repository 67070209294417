import styled from 'styled-components';
import { IconButton } from '../icon-button';

export const DrawerWrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: ${({ theme }) => theme.colors.gray['800']};
  width: calc(100% - 64px);
  z-index: 1004;
  box-shadow: ${({ theme }) => theme.shadows[4]};

  @media screen and (min-width: 576px) {
    width: 413px;
  }
`;

export const DrawerHeader = styled.div`
  padding: ${({ theme }) => `${theme.spacing(6)}px`};
  display: flex;
  background-color: ${({ theme }) => theme.colors.gray['900']};
  align-items: center;
`;

export const DrawerTitle = styled.div`
  flex: 1;
  font-weight: bold;
  font-size: 16px;
`;

export const DrawerActions = styled.div`
  & > *:not(:last-child) {
    margin-right: ${({ theme }) => `${theme.spacing(3)}px`};
  }
`;

export const DrawerAction = styled(IconButton).attrs({ color: 'muted', size: 2 })`
  padding: 0 ${({ theme }) => `${theme.spacing(2)}px`};
  font-size: 14px;
`;

export const DrawerContent = styled.div``;

import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { createFetchingReducers } from '@root/shared/redux';
import { IRemoteData } from '@root/shared/remote-data';

import { IMiniProduct } from '../../types/mini-product';

export type IProductsState = IRemoteData<IMiniProduct[]>;

export namespace ProductsActions {
  export type FetchPending = PayloadAction;
  export type FetchFulfilled = PayloadAction<IMiniProduct[]>;
  export type FetchRejected = PayloadAction<string>;
}

export type ProductsSliceReducer = {
  fetchPending: CaseReducer<IProductsState, ProductsActions.FetchPending>;
  fetchFulfilled: CaseReducer<IProductsState, ProductsActions.FetchFulfilled>;
  fetchRejected: CaseReducer<IProductsState, ProductsActions.FetchRejected>;
};

export const productsState: IProductsState = {
  isLoading: false,
  data: [],
  error: null,
};

export const productsSlice = createSlice<IProductsState, ProductsSliceReducer, 'products'>({
  name: 'products',
  initialState: productsState,
  reducers: createFetchingReducers<IProductsState, ProductsActions.FetchPending, ProductsActions.FetchFulfilled, ProductsActions.FetchRejected>(),
});

import { FC, ReactNode } from 'react';

import { BodyText, H3 } from '@root/shared/ui';
import { DashboardUI } from './dashboard.ui';

const { Card } = DashboardUI;

export interface IQuickActionsCard {
  title: string;
  description: string;
  icon: ReactNode;
  cardClickAction: () => void;
}

export const QuickActionCard: FC<IQuickActionsCard> = ({ title, description, cardClickAction, icon }) => {
  return (
    <Card className='cursor-pointer hover:bg-gray-200 transition' onClick={cardClickAction}>
      <div className='min-h-[60px] h-full flex gap-4'>
        <div className='flex flex-col lg:flex-row lg:gap-6 h-full'>
          <div className='w-10 h-10 lg:w-12 lg:h-12 mb-2 lg:mb-0 flex-shrink-0'>{icon}</div>
          <div>
            <H3 className='font-bold mb-1 lg:text-base'>{title}</H3>
            <BodyText className='text-gray-600'>{description}</BodyText>
          </div>
        </div>
      </div>
    </Card>
  );
};

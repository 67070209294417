import { FC } from 'react';

export const PaymentMethodsIcon: FC = (props) => {
  return (
    <svg width='1em' height='1em' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.3079 6.82058C10.8119 6.82058 10.4104 7.22206 10.4104 7.71801C10.4104 8.21396 10.8119 8.61545 11.3079 8.61545H12.7438C12.8427 8.61545 12.9232 8.53488 12.9232 8.43596V7.00006C12.9232 6.90114 12.8427 6.82058 12.7438 6.82058H11.3079ZM9.3335 7.71801C9.3335 6.62729 10.2171 5.74365 11.3079 5.74365H12.7438C13.4374 5.74365 14.0002 6.30637 14.0002 7.00006V8.43596C14.0002 9.12965 13.4374 9.69237 12.7438 9.69237H11.3079C10.2171 9.69237 9.3335 8.80873 9.3335 7.71801Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.61538 1.07692C1.31831 1.07692 1.07692 1.31831 1.07692 1.61538C1.07692 1.91246 1.31831 2.15385 1.61538 2.15385H11.3077C12.3984 2.15385 13.2821 3.03749 13.2821 4.12821V6.28205C13.2821 6.57944 13.041 6.82051 12.7436 6.82051C12.4462 6.82051 12.2051 6.57944 12.2051 6.28205V4.12821C12.2051 3.63226 11.8036 3.23077 11.3077 3.23077H1.61538C0.723539 3.23077 0 2.50723 0 1.61538C0 0.723539 0.723539 0 1.61538 0H10.5897C10.8871 0 11.1282 0.241077 11.1282 0.538462C11.1282 0.835846 10.8871 1.07692 10.5897 1.07692H1.61538Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.538462 1.0769C0.835846 1.0769 1.07692 1.31798 1.07692 1.61537V11.3077C1.07692 11.8036 1.47841 12.2051 1.97436 12.2051H11.3077C11.8036 12.2051 12.2051 11.8036 12.2051 11.3077V9.15383C12.2051 8.85644 12.4462 8.61537 12.7436 8.61537C13.041 8.61537 13.2821 8.85644 13.2821 9.15383V11.3077C13.2821 12.3984 12.3984 13.282 11.3077 13.282H1.97436C0.883642 13.282 0 12.3984 0 11.3077V1.61537C0 1.31798 0.241077 1.0769 0.538462 1.0769Z'
        fill='currentColor'
      />
    </svg>
  );
};

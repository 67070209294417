import { forwardRef, PropsWithChildren } from 'react';
import styled from 'styled-components';

export type BadgeProps = {
  count?: number;
  limit?: number;
  prefixed?: boolean;
};

const BadgeWrapper = styled.span`
  position: relative;
  display: inline-flex;
`;

const BadgeCount = styled.span`
  position: absolute;
  top: 8px;
  right: 4px;
  background-color: ${({ theme }) => theme.colors.success['500']};
  color: ${({ theme }) => theme.colors.gray['100']};
  font-size: 10px;
  line-height: 10px;
  padding: 1px 4px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Badge = forwardRef<HTMLDivElement, PropsWithChildren<BadgeProps>>(function Test({ children, count, limit = 9, prefixed }, ref) {
  return (
    <BadgeWrapper ref={ref}>
      {!!count && (
        <BadgeCount>
          {prefixed && count > limit && '+'}
          {count}
          {!prefixed && count > limit && '+'}
        </BadgeCount>
      )}
      {children}
    </BadgeWrapper>
  );
});

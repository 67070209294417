import { Item, Overlay, Section, Wrapper, Link, ItemIcon } from '../components/menu/hamburger.ui';
import { UserDropdownButton } from '../components/user-dropdown/user-dropdown-button';
import { HamburgerSubMenu, IHamburgerSubMenu, SettingsOverlay } from '../containers';
import { layoutSelector, layoutSlice } from '../redux';
import { AbsolutePortal } from '@root/infra/portals';
import { HelpCenter } from '@root/modules/auth/components';
import { useRedirection } from '@root/modules/auth/hooks/use-redirection';
import { authSelector } from '@root/modules/auth/redux';
import { REDIRECTION_TYPES } from '@root/modules/auth/types';
import { useDiscordButton } from '@root/modules/discord/hooks/use-discord-button';
import { menuKYCWarningSelector, selectIsNavigationEnabled } from '@root/modules/kyc/redux/kyc.selector';
import { useLegalDocument } from '@root/modules/meta/hooks/use-legal-document';
import { OrdersTabKey } from '@root/modules/orders/constants/orders-tab-key';
import { IncreaseEWalletBalance } from '@root/modules/payment-methods/containers/increase-e-wallet-balance';
import { menuSubscriptionsWarningSelector } from '@root/modules/products/redux/purchased-products/purchased-products.selector';
import { BodyText } from '@root/shared/ui';
import { WarningBadge } from '@root/shared/ui/components/warning-badge';
import { ChevronRightIcon } from '@root/shared/ui/icons/chevron-icon';
import { CloseIcon } from '@root/shared/ui/icons/close-icon';
import { DashboardIcon } from '@root/shared/ui/icons/dashboard-icon';
import { DiscordIcon } from '@root/shared/ui/icons/discord-icon';
import { GearIcon } from '@root/shared/ui/icons/gear-icon';
import { GiftCardsIcon } from '@root/shared/ui/icons/gift-cards-icon';
import { KycIcon } from '@root/shared/ui/icons/kyc-icon';
import { OrdersIcon } from '@root/shared/ui/icons/orders-icon';
import { PaymentMethodsIcon } from '@root/shared/ui/icons/payment-methods-icon';
import { ProductsIcon } from '@root/shared/ui/icons/products-icon';
import { RedirectIcon } from '@root/shared/ui/icons/redirect-icon';
import { StackIcon } from '@root/shared/ui/icons/stack-icon';
import { SubmitTicketIcon } from '@root/shared/ui/icons/submit-ticket-icon';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';

export const HamburgerMenu = () => {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();

  const [activeSubMenu, setActiveSubMenu] = useState<IHamburgerSubMenu | null>(null);
  const layoutState = useSelector(layoutSelector);
  const authState = useSelector(authSelector);
  const showSubscriptionWarning = useSelector(menuSubscriptionsWarningSelector);
  const showKycWarning = useSelector(menuKYCWarningSelector);
  const isNavigationEnabled = useSelector(selectIsNavigationEnabled);

  const [, { open: openLegalDocument }] = useLegalDocument();
  const { redirect } = useRedirection();
  const { pathname } = useLocation();

  const { handleRedirect } = useDiscordButton();

  const closeMenu = useCallback(() => {
    dispatch(layoutSlice.actions.closed());
  }, [dispatch]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (layoutState.hamburgerMenuIsOpen) {
        window.document.body.style.overflow = 'hidden';
      } else {
        window.document.body.style.overflow = 'auto';
      }
    }
  }, [layoutState.hamburgerMenuIsOpen]);

  const redirectToShop = useCallback(() => {
    redirect(REDIRECTION_TYPES.SHOP);
  }, [redirect]);

  useEffect(() => {
    closeMenu();
  }, [pathname, closeMenu]);

  return (
    <AbsolutePortal>
      {layoutState.hamburgerMenuIsOpen && <Overlay onClick={closeMenu} />}

      <Wrapper isOpen={layoutState.hamburgerMenuIsOpen}>
        {activeSubMenu ? (
          <HamburgerSubMenu activeSubMenu={activeSubMenu} setActiveSubMenu={setActiveSubMenu} closeMenu={closeMenu} />
        ) : (
          <Fragment>
            <div>
              <div className=''>
                <Item as='span' className='pb-2.5' onClick={() => setActiveSubMenu('user')}>
                  <div className='flex gap-4 w-full'>
                    <div>
                      <UserDropdownButton hovered={false} />
                    </div>
                    <div>
                      <BodyText>{authState.user?.fullName}</BodyText>
                      <BodyText className='mt-2 font-bold'>
                        {t('common:Your ID')} #{authState.user?.customerId}
                      </BodyText>
                    </div>
                    <div className='ml-auto'>
                      <ChevronRightIcon />
                    </div>
                  </div>
                </Item>
              </div>

              <div className='border-b border-gray-600 sticky top-0 px-6 py-2.5 bg-primary-700 z-40'>
                <IncreaseEWalletBalance sidebar textVisible />
              </div>

              <Section noBorder isDisabled={!isNavigationEnabled}>
                <Link to='/' end>
                  <ItemIcon>
                    <DashboardIcon />
                  </ItemIcon>
                  <BodyText>{t('common:Dashboard')}</BodyText>
                </Link>
                <Link to='/products'>
                  <ItemIcon>
                    <StackIcon />
                  </ItemIcon>
                  <BodyText>{t('common:Products_and_Services')}</BodyText>
                </Link>
                <Link to='/orders' state={{ openedTab: OrdersTabKey.ORDERS }}>
                  <ItemIcon>
                    <OrdersIcon />
                  </ItemIcon>
                  <BodyText>{t('common:Account_orders')}</BodyText>
                </Link>
                <Link to='/payment-methods'>
                  <ItemIcon>
                    <PaymentMethodsIcon />
                  </ItemIcon>
                  <BodyText>{t('common:payment_methods')}</BodyText>
                </Link>
                <Item onClick={() => setActiveSubMenu('subscriptions')}>
                  <ItemIcon>
                    <ProductsIcon />
                  </ItemIcon>
                  <BodyText>
                    {t('common:Subscriptions')}
                    {showSubscriptionWarning && <WarningBadge content='!' className='ml-2' />}
                  </BodyText>
                  <ChevronRightIcon className='text-xs ml-auto' />
                </Item>
                <Link to='/kyc'>
                  <ItemIcon>
                    <KycIcon />
                  </ItemIcon>
                  <BodyText>
                    {t('common:KYC verification')}
                    {showKycWarning && <WarningBadge content='!' className='ml-2' />}
                  </BodyText>
                </Link>
                {/*<Link to='/gift-cards'>*/}
                {/*  <ItemIcon>*/}
                {/*    <GiftCardsIcon />*/}
                {/*  </ItemIcon>*/}
                {/*  <BodyText>{t('Gift_Cards', { ns: 'common' })}</BodyText>*/}
                {/*</Link>*/}
                <Link to='/settings'>
                  <ItemIcon>
                    <GearIcon />
                  </ItemIcon>
                  <BodyText>{t('common:AccountSettings')}</BodyText>
                </Link>
                <HelpCenter>
                  <Item>
                    <ItemIcon>
                      <SubmitTicketIcon />
                    </ItemIcon>
                    <BodyText>{t('common:Submit_a_ticket')}</BodyText>
                  </Item>
                </HelpCenter>

                <Item className='redirect_to_shop_button' onClick={redirectToShop}>
                  <ItemIcon>
                    <RedirectIcon width='13px' height='13px' />
                  </ItemIcon>
                  <BodyText>{t('common:Shop')}</BodyText>
                </Item>

                {/*<Item onClick={handleRedirect}>*/}
                {/*  <ItemIcon>*/}
                {/*    <DiscordIcon fill='#fff' />*/}
                {/*  </ItemIcon>*/}
                {/*  <BodyText>{t('common:go_to_discord')}</BodyText>*/}
                {/*</Item>*/}
              </Section>
            </div>

            <Section className='border-none flex-0' isDisabled={!isNavigationEnabled}>
              <div className='flex flex-col justify-end items-start px-6 pb-4'>
                <div className='mb-2'>
                  <SettingsOverlay trigger />
                </div>

                <div className='pt-4 pb-2 max-w-full'>
                  <div className='flex items-center justify-center gap-4 w-full text-xs'>
                    <div className='cursor-pointer max-w-1/2 truncate'>
                      <button onClick={() => openLegalDocument('privacy_policy')}>
                        <span className='hover:text-primary-300'>{t('privacy_policy')}</span>
                      </button>
                    </div>
                    <div className='cursor-pointer max-w-1/2 truncate'>
                      <button onClick={() => openLegalDocument('terms_of_use')}>
                        <span className='hover:text-primary-300'>{t('Terms of Use')}</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Section>
          </Fragment>
        )}
      </Wrapper>

      {layoutState.hamburgerMenuIsOpen && (
        <button className='fixed top-3 p-4 text-gray-100 bg-primary-700 shadow-2xl rounded-full' style={{ zIndex: 99999, left: 'calc(300px + 12px)' }} onClick={closeMenu}>
          <CloseIcon />
        </button>
      )}
    </AbsolutePortal>
  );
};

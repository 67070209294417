import { cancelOrderService } from '../services/cancel-order.service';
import { getOrdersService } from '../services/get-orders.service';
import { ordersSelector } from './orders.selector';
import { OrdersActions, ordersSlice, OrdersState } from './orders.slice';
import { INSTANCE_TYPES } from '@root/modules/auth/constants/instance-types';
import { getRedirectionTokenService } from '@root/modules/auth/services';
import { externalRedirect } from '@root/shared/utils';
import { SagaReturnType, takeLatest, call, put, select, takeEvery, delay } from 'redux-saga/effects';

function* handleFetch() {
  const query: OrdersState['query'] = yield select(ordersSelector.query);
  const result: SagaReturnType<typeof getOrdersService> = yield call(getOrdersService, query);

  if (result.status === 200) {
    yield put(ordersSlice.actions.fetchFulfilled(result.payload));
  } else {
    yield put(ordersSlice.actions.fetchRejected(result.payload));
  }
}

function* handleCancelRequestedOrder(action: OrdersActions.CancelRequestedOrderPending) {
  const result: SagaReturnType<typeof cancelOrderService> = yield call(cancelOrderService, { orderId: action.payload });

  if (result.status === 200) {
    yield put(ordersSlice.actions.cancelRequestedOrderFulfilled(action.payload));
  } else {
    yield put(ordersSlice.actions.cancelRequestedOrderRejected({ orderId: action.payload, message: result.payload }));
  }
}

function* handlePayRequestedOrder(action: OrdersActions.PayRequestedOrderPending) {
  const orders = yield select(ordersSelector.data);
  const order = orders.find((order) => order.id === action.payload.id);
  const quantity = order?.items.find((item) => item.action === 'RENEW')?.quantity || 1;
  const result: SagaReturnType<typeof getRedirectionTokenService> = yield call(getRedirectionTokenService, INSTANCE_TYPES.SHOP);

  if (result.status === 200) {
    const { redirectionUrl, redirectionToken } = result.payload;

    // for paying for friend mode
    const params = {
      requestToken: redirectionToken,
      requestedOrderId: action.payload.id.toString(),
      quantity: quantity.toString(),
    };

    if (order.status === 'Canceled') {
      params['failedTransactionId'] = order.transactions[0]?.id;
      params['status'] = order.status;
    }

    const query = new URLSearchParams(params).toString();

    let url = `https://${redirectionUrl}/checkout?${query}`;

    // For retry transaction
    if (action.payload.transactionId) {
      url = `https://${redirectionUrl}/checkout?tempTok=${redirectionToken}&orderId=${action.payload.id}&transactionId=${action.payload.transactionId}&quantity=${quantity}`;
    }

    externalRedirect(url);
    yield delay(5000);
    yield put(ordersSlice.actions.payRequestedOrderFulfilled(action.payload.id));
  } else {
    yield put(ordersSlice.actions.payRequestedOrderRejected({ orderId: action.payload.id, message: result.payload }));
  }
}

export function* ordersSaga() {
  yield takeLatest(
    [
      ordersSlice.actions.fetchPending,
      ordersSlice.actions.filtersChanged,
      ordersSlice.actions.tempFiltersApplied,
      ordersSlice.actions.pageChanged,
      ordersSlice.actions.sortChanged,
      ordersSlice.actions.cancelRequestedOrderFulfilled,
      ordersSlice.actions.tabChanged,
    ],
    handleFetch,
  );
  yield takeEvery([ordersSlice.actions.cancelRequestedOrderPending], handleCancelRequestedOrder);
  yield takeEvery([ordersSlice.actions.payRequestedOrderPending], handlePayRequestedOrder);
}

import { AxiosError } from 'axios';
import { RECURRING_TYPES } from '@root/infra/enums/reccuring-type';
import { fetcher } from '@root/infra/fetcher';
import type { IHttpResponse } from '@root/shared/http-service';

import { MembershipLevelsMapper } from '../mappers/membership.mapper';
import { IMembershipLevel } from '../types/membership-level';

export type FetchMembershipLevelsResponse = IHttpResponse<200, IMembershipLevel[]> | IHttpResponse<400, string>;
export type MembershipQueryProps = { uiCountry: string; recurringType: RECURRING_TYPES };

export const fetchMembershipLevelsService = async ({ uiCountry, recurringType }: MembershipQueryProps): Promise<FetchMembershipLevelsResponse> => {
  try {
    const response = await fetcher(
      uiCountry ? `/membership-level/list?countryCode=${uiCountry}&recurringType=${recurringType}` : `/membership-level/list?recurringType=${recurringType}`,
    );
    return {
      status: 200,
      payload: response.data.data.map((item) => MembershipLevelsMapper.toDomain(item)).sort((a, b) => a.priority - b.priority),
    };
  } catch (e) {
    const error = e as AxiosError;
    console.error(e);

    if (error.isAxiosError && error.response) {
      return {
        status: 400,
        payload: error.response.data.message,
      };
    }

    return {
      status: 400,
      payload: 'Bad request',
    };
  }
};

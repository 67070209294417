import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { pageLinks } from '@root/shared/routing/page-links';
import { authSelector } from '@root/modules/auth/redux';

import { useKycCommands } from './use-kyc-commands';
import { selectKycMeta, selectKycOrderForActivation, selectKycSettings } from '../../redux/kyc.selector';

export const useProductActivationStep = () => {
  const { user } = useSelector(authSelector);
  const orderForActivation = useSelector(selectKycOrderForActivation);
  const settings = useSelector(selectKycSettings);
  const { isProductActivated } = useSelector(selectKycMeta);

  const [, { showPage, enableNavigation, disableNavigation, resetSkippedVerification }] = useKycCommands();

  const hasUnverifiedProducts = user?.unverifiedProducts.has;
  const kycLevel1isEnabled = settings?.level1.enabled || false;

  const activationController = useCallback(
    (nextStepCb: any) => {
      console.log('-- 1.run product activation step');

      disableNavigation();
      resetSkippedVerification();

      if (hasUnverifiedProducts) {
        if (orderForActivation) {
          return showPage(pageLinks.kycActivation);
        } else if (isProductActivated) {
          return showPage(pageLinks.kycActivationStatus);
        } else {
          return showPage(pageLinks.kycActivationReminder);
        }
      }

      if (!hasUnverifiedProducts) {
        if (kycLevel1isEnabled) {
          // run kycLevel1 checker
          return nextStepCb();
        } else {
          enableNavigation();
          showPage(pageLinks.kycActivationStatus);
          return;
        }
      }

      console.log('--- 1. product activation step --- end');
      return;
    },
    [enableNavigation, disableNavigation, resetSkippedVerification, orderForActivation, hasUnverifiedProducts, kycLevel1isEnabled, showPage, isProductActivated],
  );

  return activationController;
};

import { Theme } from '@root/infra/theme';
import styled from 'styled-components';

const getKycMenuItemBg = (theme: Theme, status?: 'success' | 'failed' | 'pending') => {
  switch (status) {
    case 'success':
      return theme.colors.gradients[14];
    case 'pending':
      return theme.colors.gradients[10];
    case 'failed':
      return theme.colors.danger[500];
    default:
      return theme.colors.gray['100'];
  }
};

export const KYCMenuItem = styled.div<{ status?: 'success' | 'pending' | 'failed' }>`
  background: ${({ theme, status }) => getKycMenuItemBg(theme, status)};
`;

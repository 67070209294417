import { FC, PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;

  * {
    white-space: nowrap;
  }
`;

const SlideButton = styled.button<{ position?: 'left' | 'right' }>`
  position: absolute;
  transform: translate(0, -50%);
  border-radius: 50%;
  min-height: 48px;
  min-width: 48px;
  background-color: ${({ theme }) => theme.colors.gray['800']};
  box-shadow: ${({ theme }) => theme.shadows[5]};
  left: ${({ position }) => position === 'left' && '-24px'};
  right: ${({ position }) => position === 'right' && '-24px'};
  color: ${({ theme }) => theme.colors.primary['400']};
  top: 50%;
  font-size: 24px;
  display: none;

  @media screen and (min-width: 1140px) {
    display: unset;
  }
`;

const ItemsWrapper = styled.div`
  width: 100%;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;

  @media screen and (min-width: 1140px) {
    overflow: hidden;
  }
`;

const ItemsInner = styled.div`
  display: flex;
  gap: 1rem;
`;

export const Slide: FC<PropsWithChildren> = ({ children }) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const innerRef = useRef<HTMLDivElement>(null);
  const [canScrollLeft, setCanScrollLeft] = useState<boolean>(false);
  const [canScrollRight, setCanScrollRight] = useState<boolean>(false);

  const scroll = useCallback((direction: 'left' | 'right') => {
    const wrapper = wrapperRef.current;
    const inner = innerRef.current;

    if (wrapper && inner) {
      const innerWidth = inner.scrollWidth - inner.offsetWidth;

      let nextScroll = wrapper.scrollLeft;

      if (direction === 'left') {
        nextScroll -= window.innerWidth / 3;
      } else {
        nextScroll += window.innerWidth / 3;
      }

      if (innerWidth <= nextScroll) {
        setCanScrollRight(false);
      } else {
        setCanScrollRight(true);
      }

      if (nextScroll <= 0) {
        setCanScrollLeft(false);
      } else {
        setCanScrollLeft(true);
      }

      wrapper.scroll({
        left: nextScroll,
        behavior: 'smooth',
      });
    }
  }, []);

  useEffect(() => {
    const inner = innerRef.current;

    if (inner && inner.scrollWidth > inner.offsetWidth) {
      setCanScrollRight(true);
    }
  }, []);

  return (
    <Wrapper>
      <ItemsWrapper ref={wrapperRef}>
        <ItemsInner ref={innerRef}>{children}</ItemsInner>
      </ItemsWrapper>
      {canScrollLeft && (
        <SlideButton onClick={() => scroll('left')} position='left'>
          {'<'}
        </SlideButton>
      )}
      {canScrollRight && (
        <SlideButton onClick={() => scroll('right')} position='right'>
          {'>'}
        </SlideButton>
      )}
    </Wrapper>
  );
};

import { getBeBackOfficeToken } from '@root/modules/auth/services';
import { externalRedirect } from '@root/shared/utils';
import { useSelector } from 'react-redux';
import { DOMAINS } from '../constants/redirection-domains';
import { authSelector } from '../redux';
import { appSettingsSelector } from '@root/modules/app-settings/store/app-settings.selector';

export const useHelpCenter = (openNewTab: boolean = false) => {
  const { authed } = useSelector(authSelector);
  const supportLinkWithToken = useSelector(appSettingsSelector.supportUrl);

  const options = openNewTab ? { target: '_blank' as '_blank', rel: 'noopener noreferrer' } : {};

  const redirect = async () => {
    if (!authed) {
      return externalRedirect(DOMAINS.helpCenter, options);
    }

    const response = await getBeBackOfficeToken();

    if (response.status === 200) {
      externalRedirect(`${DOMAINS.helpCenter}?Token=${response.payload}`, options);
    } else {
      externalRedirect(DOMAINS.helpCenter, options);
    }
  };

  return { redirect, supportLinkWithToken: supportLinkWithToken || DOMAINS.helpCenter };
};

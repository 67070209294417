import { IProductStatistics } from '@root/modules/products/types/product-statistics';

export const createFakeBundleStatistics = (name: string): IProductStatistics => {
  function getTotalByName(name) {
    switch (name) {
      case 'Bundle 1':
        return 216;
      case 'Bundle 2':
        return 247;
      case 'Bundle 3':
        return 189;
      case 'Bundle 4':
        return 627;
      case 'Bundle 5':
        return 1189;
      default:
        return 0;
    }
  }

  return {
    rating: 5,
    reviews: {
      total: getTotalByName(name),
      percentages: {
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 100,
      },
    },
  };
};

import { IOrder } from '../types/order';
import { fetcher } from '@root/infra/fetcher';
import type { IHttpResponse } from '@root/shared/http-service';
import { IPaginatedData } from '@root/shared/paginated-data';
import { handleAxiosError } from '@root/shared/utils/handle-axios-error';
import { updateProductNameWithRecurringType } from '@root/shared/utils/recurring-type-hepler';
import type { AxiosError } from 'axios';

export type GetOrdersServiceQuery = Partial<{
  page: number;
  limit: number;
  sortKey: string;
  sortDirection: string;
  statusFilter: string;
  productFilter: string;
  typeFilter: string;
  hasCoordinator: boolean;
  searchByOrderId: string;
  isRequested: boolean;
}>;
export type GetOrdersServiceData = IPaginatedData<IOrder>;
export type GetOrdersServiceResponse = IHttpResponse<200, GetOrdersServiceData> | IHttpResponse<400, string>;

export const getOrdersService = async (rawQuery: GetOrdersServiceQuery = {}): Promise<GetOrdersServiceResponse> => {
  try {
    const { isRequested, ...query } = rawQuery;
    const response = await fetcher.get(isRequested ? '/v1/order/request/list' : '/v1/order/list', { params: query });

    return {
      status: 200,
      payload: {
        totalItems: response.data.data.meta.total,
        totalPages: response.data.data.meta.totalPages,
        data: response.data.data.items.map((item) => ({
          ...item,
          isRequested: !!isRequested,
          isForwarded: !!query.hasCoordinator,
          items: item.items.map((item) => ({
            ...item,
            level: updateProductNameWithRecurringType(item?.level, item.recurringType),
          })),
        })),
      },
    };
  } catch (e) {
    return handleAxiosError(e as AxiosError);
  }
};

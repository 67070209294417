import { CaseReducer, PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IRemoteData } from '@root/shared/remote-data';
import { IEWallet } from '../types';

export type IEWalletState = IRemoteData<IEWallet>;

export namespace LanguagesActions {
  export type FetchPending = PayloadAction;
  export type FetchFulfilled = PayloadAction<IEWallet>;
  export type FetchRejected = PayloadAction<string>;
  export type Reset = PayloadAction;
}

export type WalletsSliceReducer = {
  fetchPending: CaseReducer<IEWalletState, LanguagesActions.FetchPending>;
  fetchFulfilled: CaseReducer<IEWalletState, LanguagesActions.FetchFulfilled>;
  fetchRejected: CaseReducer<IEWalletState, LanguagesActions.FetchRejected>;
  reset: CaseReducer<IEWalletState, LanguagesActions.Reset>;
};

export const eWalletSlice = createSlice<IEWalletState, WalletsSliceReducer, 'eWallet'>({
  name: 'eWallet',
  initialState: {
    isLoading: false,
    data: { commission: 0, gifted: 0, point: 0 },
    error: null,
  },
  reducers: {
    fetchPending: (state) => {
      state.isLoading = true;
    },
    fetchFulfilled: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    fetchRejected: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    reset: (state) => {
      state.isLoading = false;
      state.error = null;
      state.data = {
        commission: 0,
        gifted: 0,
        point: 0,
      };
    },
  },
});

import maintenanceImg from '@root/assets/images/maintenance/maintenance.png';
import { LayoutContainer, Row } from '@root/infra/layout';
import { H1, BodyTextLarge, Image } from '@root/shared/ui';
import { Trans, useTranslation } from 'react-i18next';

const MaintenancePage = () => {
  const { t } = useTranslation('common');

  return (
    <div className='flex ml-20 mr-20'>
      <Row className='mt-14 mb-14 lg:mb-40 place-content-center'>
        <div className='col-span-10 col-start-2 md:col-span-6 md:col-start-2 lg:col-start-1 place-content-center'>
          <div className='flex justify-start items-end mt-8'>
            <H1 className='font-bold mr-2.5'>{t('maintenanceNoticeTitle')}</H1>
          </div>
          <BodyTextLarge className='mt-6 mb-8 leading-8'>{<Trans i18nKey='common:maintenanceNoticeMessage' components={{ br: <br /> }} />}</BodyTextLarge>
        </div>
        <div className='col-span-8 col-start-3 row-start-1 md:col-span-5 md:col-start-8'>
          <Image src={maintenanceImg} placeholder='blur' alt='Maintenance' />
        </div>
      </Row>
    </div>
  );
};

export default MaintenancePage;

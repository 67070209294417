import { useCallback, useEffect } from 'react';
import { callbackChain } from '@root/shared/utils/callback-chain';

import { useKycInitiateChecker } from './use-kyc-initiate-checker';
import { useProductActivationStep } from './use-product-activation-step';
import { useIdentityVerificationStep } from './use-identity-verification-step';
import { useAddressVerificationStep } from './use-address-verification-step';

export const useKycController = () => {
  const shouldKycFlowRun = useKycInitiateChecker();

  const activationController = useProductActivationStep();
  const identityController = useIdentityVerificationStep();
  const addressController = useAddressVerificationStep();

  const controllerFlow = useCallback(() => {
    console.group('___ flow is running');

    callbackChain(activationController, identityController, addressController);

    console.groupEnd();
  }, [activationController, identityController, addressController]);

  useEffect(() => {
    shouldKycFlowRun && controllerFlow();
  }, [shouldKycFlowRun, controllerFlow]);
};

import { FC } from 'react';
import styled from 'styled-components';
import { Rating } from './rating';
import { BodyText, Caption } from './text';

export type DetailedRatingProps = {
  total: number;
  rating: number;
  percents: number[];
};

const RatingProgressBar = styled.div`
  background: ${({ theme }) => theme.colors.gradients[10]};
  height: 100%;
  border-radius: 100px;
`;

const RatingProgressPoint = styled(BodyText)`
  min-width: 10px;
  text-align: center;
`;

const RatingProgressValue = styled(Caption)`
  min-width: 35px;
`;

export const DetailedRating: FC<DetailedRatingProps> = ({ total, rating, percents }) => {
  return (
    <div className='lg:flex gap-10'>
      <div className='flex gap-4 lg:flex-col lg:items-center mb-3 mt-3'>
        <div>
          <h1 className='font-bold text-4xl text-warning-500'>{rating.toFixed(1)}</h1>
        </div>
        <div className='text-center'>
          <Rating className='text-xl mb-1 gap-1' value={rating} />
          <BodyText className='text-gray-500'>{total} ratings</BodyText>
        </div>
      </div>

      <div className='border-l border-gray-300 my-6' />

      <div className='flex-1 flex flex-col gap-1.5'>
        {percents.reverse().map((percent, index) => (
          <div key={index} className='flex items-center gap-4'>
            <RatingProgressPoint className='font-bold'>{percents.length - index}</RatingProgressPoint>
            <div className='flex-1 h-2 rounded-full bg-gray-300 overflow-hidden'>
              <RatingProgressBar style={{ width: `${percent}%` }} />
            </div>
            <RatingProgressValue>{percent}%</RatingProgressValue>
          </div>
        ))}
      </div>
    </div>
  );
};

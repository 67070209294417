import { useMemo } from 'react';
import { darken } from 'polished';

import { generateGradient } from '@root/shared/utils';

import { calculateMembershipProgress } from '../helpers/calculate-membership-progress';
import { IMembershipLevel } from '../types/membership-level';

export const useCalculateProgress = (memberships: IMembershipLevel[], spentAmount: number, shouldGenerateGradient: boolean) => {
  return useMemo(() => {
    return memberships.map((m) => ({
      ...m,
      progress: calculateMembershipProgress(spentAmount, m.min, m.max),
      gradient: shouldGenerateGradient ? generateGradient(m.color, darken(0.2, m.color), '90deg') : m.color,
    }));
  }, [memberships, spentAmount, shouldGenerateGradient]);
};

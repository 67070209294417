import styled from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  width: 100%;
  background: rgba(202, 202, 202, 0.4);
  overflow: hidden;
  z-index: 1030;
  min-height: 100%;
`;

import styled from 'styled-components';
import { rgba } from 'polished';

import { Image } from '@root/shared/ui';

const Wrapper = styled.div<{ color?: string }>`
  width: 50px;
  height: 50px;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 200px;
  border: ${({ theme, color }) => (color ? `1px solid ${color}` : `1px solid ${theme.colors.gray[600]}`)};
  background: ${({ theme, color }) => (color ? rgba(color, 0.2) : rgba(theme.colors.gray[600], 0.1))};

  @media screen and (min-width: 1140px) {
    width: 40px;
    height: 40px;
  }
`;

const Icon = styled(Image)`
  width: 24px;
  height: 24px;

  @media screen and (min-width: 1140px) {
    width: 16px;
    height: 16px;
  }
`;

export const MembershipUserIconUI = { Wrapper, Icon };

import { FC, MouseEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { authSelector } from '@root/modules/auth/redux';
import { smoothScroll } from '@root/shared/utils/smooth-scroll';
import { ChevronRightIcon } from '@root/shared/ui/icons/chevron-icon';

import { useCalculateProgress } from '../hooks/use-calculate-progress';
import { membershipSelector } from '../redux/membership.selector';
import { MembershipLevelItem } from './membership-level-item';
import { MembershipLevelsInfoUI } from './membership-levels-info.ui';

const { MembershipInfoScrollButton, MembershipLevelsInfoScroller, MembershipLevelsInfoWrapper } = MembershipLevelsInfoUI;

type Props = {
  scaleCurrentLevel: boolean;
  shouldCalculateProgress: boolean;
  shouldGenerateGradient?: boolean;
  shouldShowYouthLabel?: boolean;
  moneySpentAmount?: number;
};

export const MembershipLevelsInfo: FC<Props> = ({
  scaleCurrentLevel,
  shouldCalculateProgress,
  shouldGenerateGradient = false,
  moneySpentAmount: moneySpentAmountProp,
  shouldShowYouthLabel = false,
}) => {
  const { t } = useTranslation('common');

  const { user } = useSelector(authSelector);
  const memberships = useSelector(membershipSelector);

  const scrollerRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const currentLevelRef = useRef<HTMLDivElement>(null);
  const [leftArrowIsVisible, setLeftArrowIsVisible] = useState<boolean>(false);
  const [rightArrowIsVisible, setRightArrowIsVisible] = useState<boolean>(false);
  const [currentLevelLabelIsVisible, setCurrentLevelLabelIsVisible] = useState<boolean>(false);
  const scrollerNextScrollLeftRef = useRef<number>(0);

  const moneySpentAmount = moneySpentAmountProp || user?.moneySpentAmount || 0;
  const membershipsWithProgress = useCalculateProgress(memberships || [], moneySpentAmount, shouldGenerateGradient);
  const membershipsMax = memberships?.map((item) => item.max) || [];
  const maxMembershipIndex = membershipsMax.findIndex((max) => Math.max(...membershipsMax) === max);

  const membershipWithYouthText = membershipsWithProgress.map((item) => ({
    ...item,
    alwaysShowLabel: shouldShowYouthLabel ? item.isCurrent : false,
    labelText: shouldShowYouthLabel && item.isCurrent ? t('Your Level') : '',
  }));

  const controlHandler = useCallback(() => {
    const scroller = scrollerRef.current;
    const wrapper = wrapperRef.current;
    const currentLevel = currentLevelRef.current;

    if (!!scroller && !!wrapper) {
      const scrollerWidth = Math.round(scroller.getBoundingClientRect().width);
      const wrapperWidth = Math.round(wrapper.getBoundingClientRect().width);
      const currentLevelWidth = Math.round((currentLevel && currentLevel.getBoundingClientRect().width) || 0);

      if (wrapperWidth > scrollerWidth) {
        if (scrollerNextScrollLeftRef.current === 0) {
          setRightArrowIsVisible(true);
          setLeftArrowIsVisible(false);
        }

        const scrollerScroll = Math.round(scrollerNextScrollLeftRef.current + scroller.clientWidth);

        if(!!currentLevel){
          const currentLevelPosition = currentLevel.offsetLeft + currentLevelWidth / 2 - scrollerNextScrollLeftRef.current;
      
          setCurrentLevelLabelIsVisible((currentLevelPosition > 0) && (currentLevelPosition < scrollerScroll))
        }

        setLeftArrowIsVisible(scrollerNextScrollLeftRef.current > 0);
        setRightArrowIsVisible(scrollerScroll < wrapperWidth);
      } else {
        setRightArrowIsVisible(false);
        setLeftArrowIsVisible(false);
      }
    }
  }, []);

  const scrollToLeft = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      const scroller = scrollerRef.current;

      if (!!scroller) {
        const visibleWidth = scroller.getBoundingClientRect().width;

        smoothScroll(scroller, 'left', visibleWidth);
        scrollerNextScrollLeftRef.current = Math.max(scrollerNextScrollLeftRef.current - visibleWidth, 0);
        controlHandler();
      }
    },
    [controlHandler],
  );

  const scrollToRight = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      const scroller = scrollerRef.current;
      const wrapper = wrapperRef.current;

      if (!!scroller && !!wrapper) {
        const visibleWidth = scroller.getBoundingClientRect().width;

        smoothScroll(scroller, 'right', visibleWidth);
        scrollerNextScrollLeftRef.current = Math.min(
          scrollerNextScrollLeftRef.current + visibleWidth, 
          wrapperRef.current.getBoundingClientRect().width - visibleWidth
        );
        controlHandler();
      }
    },
    [controlHandler],
  );

  useEffect(() => {
    controlHandler();

    window.addEventListener('resize', controlHandler);

    return () => {
      window.removeEventListener('resize', controlHandler);
    };
  }, [controlHandler]);

  if (!memberships) {
    return null;
  }

  return (
    <div className='relative rounded-xl overflow-hidden'>
      {leftArrowIsVisible && (
        <MembershipInfoScrollButton onClick={scrollToLeft} className='left-0 bg-gradient-to-l from-transparent to-gray-700'>
          <ChevronRightIcon className='transform rotate-180' />
        </MembershipInfoScrollButton>
      )}
      <MembershipLevelsInfoScroller ref={scrollerRef}>
        <MembershipLevelsInfoWrapper ref={wrapperRef} count={memberships.length}>
          {membershipWithYouthText.map((membership, index) => (
            <MembershipLevelItem
              scaleCurrentLevel={scaleCurrentLevel}
              membership={membership}
              shouldCalculateProgress={shouldCalculateProgress}
              key={membership.name}
              isHighestLevel={index === maxMembershipIndex}
              alwaysShowLabel={membership.alwaysShowLabel && currentLevelLabelIsVisible}
              labelText={membership.labelText}
              ref={membership.isCurrent ? currentLevelRef : null}
            />
          ))}
        </MembershipLevelsInfoWrapper>
      </MembershipLevelsInfoScroller>
      {rightArrowIsVisible && (
        <MembershipInfoScrollButton onClick={scrollToRight} className='right-0 bg-gradient-to-r from-transparent to-gray-700'>
          <ChevronRightIcon />
        </MembershipInfoScrollButton>
      )}
    </div>
  );
};

import { fetcher } from '@root/infra/fetcher';
import { PurchasedProductMapper } from '@root/modules/products/mappers/purchased-product.mapper';
import { IPurchasedProduct } from '@root/modules/products/types/purchased-product';
import { IHttpResponse } from '@root/shared/http-service';

export type GetPurchasedProductsResponse = IHttpResponse<200, IPurchasedProduct[]> | IHttpResponse<400, string>;

export const getPurchasedProducts = async (): Promise<GetPurchasedProductsResponse> => {
  try {
    const response = await fetcher.get('/payments/user/subscriptions');
    return {
      status: 200,
      payload: response.data.data.filter((item) => item.isDigitalBundle || item.isAddon).map((item) => PurchasedProductMapper.toDomain(item)),
    };
  } catch (e) {
    console.error(e);
    return {
      status: 400,
      payload: 'Bad request',
    };
  }
};

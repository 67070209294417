import { AxiosError } from 'axios';
import { fetcher } from '@root/infra/fetcher';
import { IHttpResponse } from '@root/shared/http-service';
import { handleAxiosError } from '@root/shared/utils/handle-axios-error';
import { BillingDetailsMapper } from '../mappers/billing-details.mapper';
import { IBillingDetails } from '../types/billing-details';

export type GetBillingDetailsResponse = IHttpResponse<200, IBillingDetails> | IHttpResponse<400, string>;

export const getBillingDetails = async (): Promise<GetBillingDetailsResponse> => {
  try {
    const response = await fetcher('/payments/user/billing-details');

    return {
      status: 200,
      payload: BillingDetailsMapper.toDomain(response.data),
    };
  } catch (e) {
    return handleAxiosError(e as AxiosError);
  }
};

import { IAddressVerificationData, IIdentityVerificationData } from '@root/modules/kyc/types/identity-verification';
import { KYC_STATUSES } from '@root/modules/kyc/enums/kyc.statuses';

export class KycIdentityVerificationMapper {
  public static toDomain(data: Omit<IIdentityVerificationData, 'verified'>): IIdentityVerificationData {
    const verified = [KYC_STATUSES.SUCCESS, KYC_STATUSES.ACCEPTABLE].includes(data.verificationStatus);
    return {
      ...data,
      verified,
    };
  }
}

export class KycAddressVerificationMapper {
  public static toDomain(data: Omit<IAddressVerificationData, 'verified'>): IAddressVerificationData {
    const verified = [KYC_STATUSES.SUCCESS, KYC_STATUSES.ACCEPTABLE].includes(data.verificationStatus);
    return {
      ...data,
      verified,
    };
  }
}

import { FC } from 'react';
import { rgba } from 'polished';
import styled from 'styled-components';

export interface PasswordIndicatorProps {
  level: 0 | 1 | 2 | 3 | number;
  onClick: () => void;
}

export interface IndicatorProps {
  active?: boolean;
}

const Container = styled.button`
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  height: 100%;
  flex-shrink: 0;
  padding: 1px 4px;
  box-sizing: border-box;
  border-radius: 1px;
  outline: none;

  &:focus {
    background-color: ${({ theme }) => `${rgba(theme.colors.gray['200'], 1)}`};
  }
`;

const Indicator = styled.div<IndicatorProps>`
  width: 8px;
  height: 8px;
  background-color: ${({ theme }) => theme.colors.success['500']};
  opacity: ${({ active }) => (active ? '1' : '0.2')};
  border-radius: 1px;
  flex-shrink: 0;
`;

export const PasswordStrengthIndicator: FC<PasswordIndicatorProps> = ({ level, onClick }) => {
  return (
    <Container onClick={onClick} type='button'>
      {new Array(4).fill(null).map((item: undefined, index: number) => (
        <Indicator key={index} active={index < level} />
      ))}
    </Container>
  );
};

PasswordStrengthIndicator.defaultProps = {
  level: 1,
};

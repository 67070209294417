import { AxiosError } from 'axios';
import { fetcher } from '@root//infra/fetcher';
import { IHttpResponse } from '@root/shared/http-service';
import { handleAxiosError } from '@root/shared/utils/handle-axios-error';
import { CreateAddressDto } from '../dtos/create-address.dto';
import { CreateAddressDtoMapper } from '../mappers/create-address-dto.mapper';

export type CreateAddressResponse = IHttpResponse<200> | IHttpResponse<400, string>;

export const createAddressService = async (values: CreateAddressDto): Promise<CreateAddressResponse> => {
  try {
    await fetcher.post(!!values.id ? '/user/address/update' : '/user/address/add', CreateAddressDtoMapper.toPersistence(values));

    return {
      status: 200,
      payload: null,
    };
  } catch (e) {
    return handleAxiosError(e as AxiosError);
  }
};

import { FC } from 'react';

export const RedirectIcon: FC<any> = (props) => {
  return (
    <svg width={props.width || '1em'} height={props.height || '1em'} viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.9167 0.923077C11.9167 0.551347 12.2181 0.25 12.5898 0.25H17.077C17.4487 0.25 17.7501 0.551347 17.7501 0.923077V5.41026C17.7501 5.78199 17.4487 6.08333 17.077 6.08333C16.7053 6.08333 16.4039 5.78199 16.4039 5.41026V1.59615H12.5898C12.2181 1.59615 11.9167 1.29481 11.9167 0.923077Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.5526 0.44714C17.8155 0.709993 17.8155 1.13616 17.5526 1.39901L11.2706 7.68107C11.0077 7.94392 10.5816 7.94392 10.3187 7.68107C10.0559 7.41821 10.0559 6.99204 10.3187 6.72919L16.6008 0.44714C16.8636 0.184287 17.2898 0.184287 17.5526 0.44714Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.71795 3.39108C2.09801 3.39108 1.59615 3.89293 1.59615 4.51287V15.2821C1.59615 15.902 2.09801 16.4039 2.71795 16.4039H13.4872C14.1071 16.4039 14.609 15.902 14.609 15.2821V10.7949C14.609 10.4232 14.9103 10.1218 15.2821 10.1218C15.6538 10.1218 15.9551 10.4232 15.9551 10.7949V15.2821C15.9551 16.6455 14.8506 17.7501 13.4872 17.7501H2.71795C1.35455 17.7501 0.25 16.6455 0.25 15.2821V4.51287C0.25 3.14947 1.35455 2.04492 2.71795 2.04492H7.20513C7.57686 2.04492 7.87821 2.34627 7.87821 2.718C7.87821 3.08973 7.57686 3.39108 7.20513 3.39108H2.71795Z'
        fill='currentColor'
      />
    </svg>
  );
};

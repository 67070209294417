const subtitlesPriority = [
    "FEM",
    "NTR",
    "DIGI",
    "WS",
    "PREMIER",
    "PREMIER PLUS",
    "BTR",
    "GDN",
    "PRIME",
    "ESSENTIAL",
];

export const replaceGrowthAcademy = (text) => {
  const array = text.split(' Growth Academy, ');
  if (array.length > 1) {
    const lastItem = `and ${array[array.length - 1]}`;
    array.pop();
    return `${array.join(', ')} ${lastItem}`.replace('Growth Academy', 'Growth Academies');
  }

  return text;
};

export const normalizePlanSubtitle = (subtitle?: string) => {
  if(!subtitle) return "";
  
  return replaceGrowthAcademy(
    subtitle
      .split(', ')
      .sort((aName, bName) => subtitlesPriority.findIndex((sp) => aName.includes(sp)) - subtitlesPriority.findIndex((sp) => bName.includes(sp)))
      .join(', '),
  );
};

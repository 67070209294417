import React from 'react';
import ReactDOM from 'react-dom/client';
import '@root/infra/theme/styles/index.css';
import App from '@root/App';
import reportWebVitals from '@root/reportWebVitals';

import './i18n';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { PasswordInputType } from '@root/shared/ui';
import React, { FC } from 'react';
import styled from 'styled-components';
import { HidePasswordIcon } from '@root/shared/ui/icons/hide-password-icon';
import { ShowPasswordIcon } from '@root/shared/ui/icons/show-password-icon';

export interface ShowPasswordButtonProps {
  handleClick: () => void;
  passwordInputType: PasswordInputType;
  withStrength: boolean;
}

const Button = styled.button<{ withStrength: boolean }>`
  position: absolute;
  top: 38px;
  font-size: 18px;
  right: ${({ withStrength }) => (withStrength ? '40px' : '20px')};
  box-sizing: border-box;

  svg {
    fill: ${({ theme }) => theme.colors.gray['900']};
  }
`;

export const ShowPasswordButton: FC<ShowPasswordButtonProps> = ({ handleClick, passwordInputType, withStrength }) => {
  return (
    <Button type='button' onClick={handleClick} withStrength={withStrength}>
      {passwordInputType === 'password' ? <ShowPasswordIcon /> : <HidePasswordIcon />}
    </Button>
  );
};

import { IAuthTokens } from '../types';

export class AuthTokensMapper {
  public static toDomain(item): IAuthTokens {
    return {
      accessToken: item.accessToken,
      refreshToken: item.refreshToken,
    };
  }
}

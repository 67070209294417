import styled from 'styled-components';

export const CountCard = styled.div`
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.gray['600']};
  color: ${({ theme }) => theme.colors.gray['400']};
  min-width: 30px;
  text-align: center;
  padding: 3px 4px;
`;

import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { metaSlice, visibleLegalDocumentSelector } from '../redux';

export const useLegalDocument = () => {
  const dispatch = useDispatch();
  const currentDocument = useSelector(visibleLegalDocumentSelector);

  const open = useCallback(
    (type: string) => {
      dispatch(metaSlice.actions.legalDocumentOpened(type));
    },
    [dispatch],
  );

  const close = useCallback(() => {
    dispatch(metaSlice.actions.legalDocumentClosed());
  }, [dispatch]);

  const state = currentDocument;
  const handlers = { open, close };

  return [state, handlers] as [typeof state, typeof handlers];
};

import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IMembershipLevel } from '../types/membership-level';

export type IMembershipsState = {
  loading: boolean;
  memberships: IMembershipLevel[];
  error: string | null;
};

export namespace MembershipActions {
  export type FetchMembershipsPending = PayloadAction;
  export type FetchMembershipsFulfilled = PayloadAction<IMembershipLevel[]>;
  export type FetchMembershipsRejected = PayloadAction<string>;
}

export type MembershipSliceReducer = {
  fetchMembershipPending: CaseReducer<IMembershipsState, MembershipActions.FetchMembershipsPending>;
  fetchMembershipFulfilled: CaseReducer<IMembershipsState, MembershipActions.FetchMembershipsFulfilled>;
  fetchMembershipRejected: CaseReducer<IMembershipsState, MembershipActions.FetchMembershipsRejected>;
};

export const membershipState: IMembershipsState = {
  loading: false,
  memberships: [],
  error: null,
};

export const membershipSlice = createSlice<IMembershipsState, MembershipSliceReducer, 'membership'>({
  name: 'membership',
  initialState: membershipState,
  reducers: {
    fetchMembershipPending: (state) => {
      state.loading = true;
    },
    fetchMembershipFulfilled: (state, action) => {
      state.loading = false;
      state.memberships = action.payload;
    },
    fetchMembershipRejected: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

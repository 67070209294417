import { call, fork, put, SagaReturnType, select, takeLatest } from 'redux-saga/effects';
import { authSlice } from '@root/modules/auth/redux';
import { selectKycSettings } from '@root/modules/kyc/redux/kyc.selector';
import { getVerificationStatusLevel1 } from '@root/modules/kyc/services/get-verification-status-level-1.service';
import { getVerificationStatusLevel2 } from '@root/modules/kyc/services/get-verification-status-level-2.service';
import { getKycSettings } from '@root/modules/kyc/services/get-kyc-settings.service';
import { kycSlice } from '@root/modules/kyc/redux/kyc.slice';

function* kycFlowWorker() {
  const settings = yield select(selectKycSettings);
  const level1Enabled = settings?.level1?.enabled;
  const level2Enabled = settings?.level2?.enabled;

  if (level1Enabled) {
    yield call(verifyIdentityWorker);
  }

  if (level2Enabled) {
    yield call(verifyAddressWorker);
  }
}

function* verifyIdentityWorker() {
  const response: SagaReturnType<typeof getVerificationStatusLevel1> = yield call(getVerificationStatusLevel1);
  if (response.status === 200) {
    yield put(kycSlice.actions.setKycLevel1Data(response.payload));
  }
}

function* verifyAddressWorker() {
  const response: SagaReturnType<typeof getVerificationStatusLevel2> = yield call(getVerificationStatusLevel2);
  if (response.status === 200) {
    yield put(kycSlice.actions.setKycLevel2Data(response.payload));
  }
}

function* kycSettingsWorker() {
  const response: SagaReturnType<typeof getKycSettings> = yield call(getKycSettings);
  if (response.status === 200) {
    yield put(kycSlice.actions.setKycSettings(response.payload));
  }
}

function* handleLogout() {
  yield put(kycSlice.actions.reset());
  localStorage.removeItem('level1');
  localStorage.removeItem('level2');
}

export function* kycFlowSaga() {
  yield fork(kycSettingsWorker);
  yield takeLatest([authSlice.actions.fetchProfileFulfilled], kycFlowWorker);
  yield takeLatest([authSlice.actions.loggedOut], handleLogout);
}

import styled from 'styled-components';

const Label = styled.div<{ recurringType: 'monthly' | 'annual' }>`
  color: ${({ theme }) => theme.colors.gray[100]};
  background: ${({ theme, recurringType }) => (recurringType === 'monthly' ? theme.colors.gradients[11] : theme.colors.gradients[1])};
`;

const ItemCheck = styled.div<{ checked?: boolean }>`
  width: ${({ theme }) => theme.spacing(5)}px;
  height: ${({ theme }) => theme.spacing(5)}px;
  border-width: 1px;
  border-color: ${({ theme }) => theme.colors.gray[400]};
  border-style: 'solid';
  border-radius: 100px;
  position: relative;
  cursor: pointer;

  &::before {
    display: ${({ checked }) => (checked ? 'block' : 'none')};
    content: '';
    width: ${({ theme }) => theme.spacing(2.5)}px;
    height: ${({ theme }) => theme.spacing(2.5)}px;
    background: ${({ theme }) => theme.colors.success[500]};
    border-radius: 100px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const UpgradeSubscriptionItemsUI = { Label, ItemCheck };

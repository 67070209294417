import { refreshTokenInterceptor } from '@root/modules/auth/interceptors';
import { getCookie } from '@root/shared/cookies';
import axios from 'axios';

export const fetcher = axios.create({
  baseURL: import.meta.env.REACT_APP_API_HOST,

  params: {},
});

if (typeof document !== 'undefined') {
  const accessToken = getCookie('accessToken');
  fetcher.defaults.params.language = getCookie('i18next') || 'en';
  fetcher.defaults.headers['Authorization'] = !!accessToken ? `Bearer ${accessToken}` : undefined;
  fetcher.interceptors.response.use(undefined, refreshTokenInterceptor);
}

import { IPaymentAddress, IPaymentItem } from '../types/payment-item';
import { paymentAddressValidation } from '../validations/payment-address.validation';

export class PaymentItemMapper {
  public static toDomain(data): IPaymentItem {
    const billingAddress = !!data.userAddress
      ? {
          firstname: data?.userAddress?.firstName,
          lastname: data?.userAddress?.lastName,
          firstAddressLine: data?.userAddress?.address,
          secondAddressLine: data.userAddress?.address2,
          phoneNumbers: [{ value: data.userAddress?.phone, label: data.userAddress?.phone }],
          city: data.userAddress?.city,
          country: data.userAddress?.country,
          postalCode: data.userAddress?.zipCode,
          isDefault: data.userAddress?.primary,
        }
      : {};

    const billingAddressIsValid = paymentAddressValidation.isValidSync(billingAddress);

    switch (data.method) {
      case 'payment-card':
        const expMonth = String(data.expMonth).length === 1 ? `0${data.expMonth}` : data.expMonth
        return {
          id: data.id,
          type: data.method,
          cardType: data.brand.toLowerCase(),
          expiration: expMonth + '/' + data.expYear,
          owner: { firstname: 'Not', lastname: 'Implemented' },
          billingAddress: billingAddress as IPaymentAddress,
          billingAddressIsValid: paymentAddressValidation.isValidSync(billingAddress),
          last4Number: data.last4,
          isLastUsed: data.isLast,
          isDefault: data.isDefault,
          nameOnCard: data.nameOnCard,
        };
      case 'paypal':
        return {
          id: data.id,
          type: 'paypal',
          billingAddress: billingAddress as IPaymentAddress,
          billingAddressIsValid,
          isLastUsed: data.isLast,
          isDefault: data.isDefault,
          owner: {
            firstname: data.userAddress?.firstName,
            lastname: data.userAddress?.lastName,
          },
        };
      default:
        return {
          id: data.id,
          type: data.method,
          billingAddress: billingAddress as IPaymentAddress,
          billingAddressIsValid: paymentAddressValidation.isValidSync(billingAddress),
          isLastUsed: data.isLast,
          isDefault: data.isDefault,
        };
    }
  }
}

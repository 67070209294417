export enum KYC_STATUSES {
  NOT_STARTED = 'NOT_STARTED',
  PROCESSING = 'PROCESSING',
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  ACCEPTABLE = 'ACCEPTABLE',
}

export enum KYC_ERROR_CODES {
  NAMES_NOT_MATCH = 'NAMES_NOT_MATCH',
  COUNTRIES_NOT_MATCH = 'COUNTRIES_NOT_MATCH',
  CITIES_NOT_MATCH = 'CITIES_NOT_MATCH',
  ADDRESS_NOT_MATCH = 'ADDRESS_NOT_MATCH',
  POSTAL_CODES_NOT_MATCH = 'POSTAL_CODES_NOT_MATCH',
  DOCUMENT_EXPIRED = 'DOCUMENT_EXPIRED',
  BLOCKED_BY_ADMIN = 'BLOCKED_BY_ADMIN',
  // !sumsub errors
  FORGERY = 'FORGERY',
  DOCUMENT_TEMPLATE = 'DOCUMENT_TEMPLATE',
  LOW_QUALITY = 'LOW_QUALITY',
  SPAM = 'SPAM',
  NOT_DOCUMENT = 'NOT_DOCUMENT',
  SELFIE_MISMATCH = 'SELFIE_MISMATCH',
  ID_INVALID = 'ID_INVALID',
  FOREIGNER = 'FOREIGNER',
  DUPLICATE = 'DUPLICATE',
  BAD_AVATAR = 'BAD_AVATAR',
  WRONG_USER_REGION = 'WRONG_USER_REGION',
  INCOMPLETE_DOCUMENT = 'INCOMPLETE_DOCUMENT',
  UNSATISFACTORY_PHOTOS = 'UNSATISFACTORY_PHOTOS',
  DOCUMENT_PAGE_MISSING = 'DOCUMENT_PAGE_MISSING',
  DOCUMENT_DAMAGED = 'DOCUMENT_DAMAGED',
  REGULATIONS_VIOLATIONS = 'REGULATIONS_VIOLATIONS',
  INCONSISTENT_PROFILE = 'INCONSISTENT_PROFILE',
  PROBLEMATIC_APPLICANT_DATA = 'PROBLEMATIC_APPLICANT_DATA',
  ADDITIONAL_DOCUMENT_REQUIRED = 'ADDITIONAL_DOCUMENT_REQUIRED',
  AGE_REQUIREMENT_MISMATCH = 'AGE_REQUIREMENT_MISMATCH',
  EXPERIENCE_REQUIREMENT_MISMATCH = 'EXPERIENCE_REQUIREMENT_MISMATCH',
  CRIMINAL = 'CRIMINAL',
  WRONG_ADDRESS = 'WRONG_ADDRESS',
  GRAPHIC_EDITOR = 'GRAPHIC_EDITOR',
  DOCUMENT_DEPRIVED = 'DOCUMENT_DEPRIVED',
  COMPROMISED_PERSONS = 'COMPROMISED_PERSONS',
  PEP = 'PEP',
  ADVERSE_MEDIA = 'ADVERSE_MEDIA',
  FRAUDULENT_PATTERNS = 'FRAUDULENT_PATTERNS',
  SANCTIONS = 'SANCTIONS',
  NOT_ALL_CHECKS_COMPLETED = 'NOT_ALL_CHECKS_COMPLETED',
  FRONT_SIDE_MISSING = 'FRONT_SIDE_MISSING',
  BACK_SIDE_MISSING = 'BACK_SIDE_MISSING',
  SCREENSHOTS = 'SCREENSHOTS',
  BLACK_AND_WHITE = 'BLACK_AND_WHITE',
  INCOMPATIBLE_LANGUAGE = 'INCOMPATIBLE_LANGUAGE',
  EXPIRATION_DATE = 'EXPIRATION_DATE',
  UNFILLED_ID = 'UNFILLED_ID',
  BAD_SELFIE = 'BAD_SELFIE',
  BAD_VIDEO_SELFIE = 'BAD_VIDEO_SELFIE',
  BAD_FACE_MATCHING = 'BAD_FACE_MATCHING',
  BAD_PROOF_OF_IDENTITY = 'BAD_PROOF_OF_IDENTITY',
  BAD_PROOF_OF_ADDRESS = 'BAD_PROOF_OF_ADDRESS',
  BAD_PROOF_OF_PAYMENT = 'BAD_PROOF_OF_PAYMENT',
  SELFIE_WITH_PAPER = 'SELFIE_WITH_PAPER',
  FRAUDULENT_LIVENESS = 'FRAUDULENT_LIVENESS',
  OTHER = 'OTHER',
  REQUESTED_DATA_MISMATCH = 'REQUESTED_DATA_MISMATCH',
  OK = 'OK',
  COMPANY_NOT_DEFINED_STRUCTURE = 'COMPANY_NOT_DEFINED_STRUCTURE',
  COMPANY_NOT_DEFINED_BENEFICIARIES = 'COMPANY_NOT_DEFINED_BENEFICIARIES',
  COMPANY_NOT_VALIDATED_BENEFICIARIES = 'COMPANY_NOT_VALIDATED_BENEFICIARIES',
  COMPANY_NOT_DEFINED_REPRESENTATIVES = 'COMPANY_NOT_DEFINED_REPRESENTATIVES',
  COMPANY_NOT_VALIDATED_REPRESENTATIVES = 'COMPANY_NOT_VALIDATED_REPRESENTATIVES',
}

export const KYC_ERRORS_WITH_MESSAGE = [
  KYC_ERROR_CODES.LOW_QUALITY,
  KYC_ERROR_CODES.SELFIE_MISMATCH,
  KYC_ERROR_CODES.ID_INVALID,
  KYC_ERROR_CODES.INCOMPLETE_DOCUMENT,
  KYC_ERROR_CODES.UNSATISFACTORY_PHOTOS,
  KYC_ERROR_CODES.DOCUMENT_DAMAGED,
  KYC_ERROR_CODES.WRONG_ADDRESS,
  KYC_ERROR_CODES.FRONT_SIDE_MISSING,
  KYC_ERROR_CODES.BACK_SIDE_MISSING,
  KYC_ERROR_CODES.BAD_SELFIE,
  KYC_ERROR_CODES.BAD_VIDEO_SELFIE,
  KYC_ERROR_CODES.BAD_PROOF_OF_IDENTITY,
  KYC_ERROR_CODES.BAD_PROOF_OF_ADDRESS,
  KYC_ERROR_CODES.SELFIE_WITH_PAPER,
  KYC_ERROR_CODES.FRAUDULENT_LIVENESS,
];

import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { pageLinks } from '@root/shared/routing';
import { kycSlice } from '../../redux/kyc.slice';

export const useKycCommands = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const showPage = useCallback(
    (url: string) => {
      navigate(url);
    },
    [navigate],
  );

  const disableNavigation = useCallback(() => {
    dispatch(kycSlice.actions.disabledNavigation());
  }, [dispatch]);

  const enableNavigation = useCallback(() => {
    dispatch(kycSlice.actions.enabledNavigation());
  }, [dispatch]);

  const startAnotherKycIdentityAttempt = useCallback(() => {
    localStorage.removeItem('level1');
    dispatch(kycSlice.actions.startedAnotherKyc1Attempt());
    showPage(pageLinks.kycIdentityVerificationProcessing);
  }, [dispatch, showPage]);

  const startAnotherKycAddressAttempt = useCallback(() => {
    localStorage.removeItem('level2');
    dispatch(kycSlice.actions.startedAnotherKyc2Attempt());
    showPage(pageLinks.kycAddressVerificationProcessing);
  }, [dispatch, showPage]);

  const startAddressVerificationFromIdentitySuccessPage = useCallback(() => {
    dispatch(kycSlice.actions.startedKyc2FromKyc1Success());
  }, [dispatch]);

  const skipVerification = useCallback(() => {
    dispatch(kycSlice.actions.skippedVerification(true));
    enableNavigation();
    showPage(pageLinks.home);
  }, [dispatch, showPage, enableNavigation]);

  const resetSkippedVerification = useCallback(() => {
    dispatch(kycSlice.actions.skippedVerification(false));
  }, [dispatch]);

  const state = {};
  const handlers = {
    showPage,
    enableNavigation,
    disableNavigation,
    skipVerification,
    resetSkippedVerification,
    startAnotherKycIdentityAttempt,
    startAnotherKycAddressAttempt,
    startAddressVerificationFromIdentitySuccessPage,
  };

  return [state, handlers] as [typeof state, typeof handlers];
};

import { ChevronRightIcon } from '@root/shared/ui/icons/chevron-icon';
import clsx from 'clsx';
import { FC, Fragment, ReactNode, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  align-items: center;
`;

const Item = styled.span`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

const ItemIcon = styled.span`
  font-size: 16px;
`;

const ItemText = styled.span`
  font-size: 13px;
  font-weight: bold;
`;

const Separator = styled.span`
  font-size: 12px;
`;

export type BreadcrumbItem = { icon?: ReactNode; url?: string; key: string; name: string };

export type BreadcrumbProps = {
  className?: string;
  items: BreadcrumbItem[];
};

export type BreadCrumbItemWithState = BreadcrumbItem & { isOnHover: boolean };

export const Breadcrumb: FC<BreadcrumbProps | any> = ({ items, className }) => {
  const [breadCrumbItems, setBreadCrumbItems] = useState<BreadCrumbItemWithState[]>([] as BreadCrumbItemWithState[]);

  useEffect(() => {
    setBreadCrumbItems(items.map((item) => ({ ...item, isOnHover: false })));
  }, [items]);

  const setOnHover = useCallback((index: Number, isOnHover: boolean) => {
    setBreadCrumbItems((prev) => prev.map((item, i) => (i === index ? { ...item, isOnHover } : item)));
  }, []);

  return (
    <Wrapper className={className}>
      {breadCrumbItems.map((item, index) => {
        const IconComponent: any = item.icon;
        return (
          <Fragment key={`fragment-${index}`}>
            {!!item.url ? (
              <Link to={item.url} key={index}>
                <Item onMouseLeave={() => setOnHover(index, false)} onMouseEnter={() => setOnHover(index, true)}>
                  {item.icon && (
                    <ItemIcon>
                      <IconComponent onHover={item.isOnHover} />
                    </ItemIcon>
                  )}
                  <ItemText className={clsx('underline hover:text-success-500', { 'text-success-500': item.isOnHover })}>{item.name}</ItemText>
                </Item>
              </Link>
            ) : (
              <Item key={index}>
                {item.icon && (
                  <ItemIcon>
                    <IconComponent onHover={item.isOnHover} />
                  </ItemIcon>
                )}
                <ItemText>{item.name}</ItemText>
              </Item>
            )}
            {index !== items.length - 1 && (
              <Separator key={`${item.url}-${index}`}>
                <ChevronRightIcon />
              </Separator>
            )}
          </Fragment>
        );
      })}
    </Wrapper>
  );
};

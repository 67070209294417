import { AxiosError } from 'axios';

import { fetcher } from '@root/infra/fetcher';
import type { IHttpResponse } from '@root/shared/http-service';

import { IKycSetting } from '@root/modules/kyc/types/settings';

export type Response = IHttpResponse<200, IKycSetting[]> | IHttpResponse<400, string>;

export const getKycSettings = async (): Promise<Response> => {
  try {
    const response = await fetcher.get(`/kyc/settings`);
    return {
      status: 200,
      payload: response.data.data,
    };
  } catch (e) {
    const error = e as AxiosError;

    if (error.isAxiosError && error.response) {
      return {
        status: 400,
        payload: error.response.data.message,
      };
    }

    return {
      status: 400,
      payload: 'Bad request',
    };
  }
};

import { AxiosError } from 'axios';

import { fetcher } from '@root/infra/fetcher';
import { IHttpResponse } from '@root/shared/http-service';

import { ToggleRenewalDto } from '../dtos/toggle-renewal.dto';

export type ToggleRenewalResponse = IHttpResponse<200, any> | IHttpResponse<400, string>;

export const toggleRenewalService = async (payload: ToggleRenewalDto): Promise<ToggleRenewalResponse> => {
  try {
    const response = await fetcher.post('/subscriptions/toggle/autopay', payload);

    return {
      status: 200,
      payload: response.data,
    };
  } catch (e) {
    const error = e as AxiosError;
    console.error(error);

    if (error.isAxiosError && error.response) {
      return {
        status: 400,
        payload: error.response.data.message,
      };
    }

    return {
      status: 400,
      payload: 'Bad Request',
    };
  }
};

import { useRedirection } from '@root/modules/auth/hooks/use-redirection';
import { REDIRECTION_TYPES } from '@root/modules/auth/types';
import { selectRedirectInstanceTypeByUserSubscription } from '@root/modules/products/redux/purchased-products/purchased-products.selector';
import { IPurchasedProduct } from '@root/modules/products/types/purchased-product';
import { BodyText, BodyTextLarge, LinkButton } from '@root/shared/ui';
import { ArrowLeftWithoutColorIcon as ArrowIcon } from '@root/shared/ui/icons/arrow-left';
import { format } from 'date-fns';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

export const RecentlyExpired: FC<{ item: IPurchasedProduct }> = ({ item }) => {
  const { t } = useTranslation('dashboard', { keyPrefix: 'bundleInfo' });
  const { redirect } = useRedirection();

  const { isAnnual } = useSelector(selectRedirectInstanceTypeByUserSubscription);

  const handleRenew = useCallback(() => {
    redirect(REDIRECTION_TYPES.RENEW, { planId: item.id, isAnnual });
  }, [redirect, item.id, isAnnual]);

  return (
    <div className='p-4'>
      <BodyTextLarge className='font-bold mb-1'>{t('recently-expired')}</BodyTextLarge>

      <div className='flex justify-between gap-4 items-center'>
        <BodyText className='flex gap-3 items-baseline'>
          <span className='text-[13px] font-bold'>{item.title.text}</span>
          <span className='divider w-0.5 h-0.5 rounded-full bg-gray-600 self-center' />
          <span className='text-[12px] text-gray-600'>{format(new Date(item.expireAt), 'MMM dd, yyyy')}</span>
        </BodyText>

        <LinkButton onClick={handleRenew}>
          {t('renew')} <ArrowIcon className='ml-2 rotate-180 p-0' color='secondary' />
        </LinkButton>
      </div>
    </div>
  );
};

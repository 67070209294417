import styled from 'styled-components';

export const RichEditorContent = styled.div`
  p {
    display: block;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;

    overflow-wrap: break-word;

    &:last-child{
      margin-bottom: 0;
    }
  }

  strong,
  b {
    font-weight: bold;
  }

  i {
    font-style: italic;
  }

  u {
    text-decoration: underline;
  }

  li {
    display: list-item;
  }

  ul,
  ol {
    display: block;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
    padding-left: 2em;
  }

  ol {
    list-style-type: decimal;
  }

  ul {
    list-style-type: disc;
  }

  a {
    display: inline-block;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.primary[500]};
    font-weight: bold;

    &:hover {
      color: ${({ theme }) => theme.colors.success[500]};
    }
  }
`;

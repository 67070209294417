import { getCookie } from '@root/shared/cookies';
import { io, Socket } from 'socket.io-client';

export const createSocket = (): Socket => {
  return io(import.meta.env.REACT_APP_API_HOST || '', {
    reconnection: true,
    reconnectionAttempts: 6,
    transports: ['websocket'],
    query: {
      authorization: getCookie('accessToken') || '',
    },
  });
};

import { FC, SVGProps } from 'react';

export const CheckIcon: FC<SVGProps<SVGSVGElement>> = ({ width, height, ...props }) => {
  return (
    <svg viewBox='0 0 8 8' fill='currentColor' height={height ? height : '1em'} width={width ? width : '1em'} version='1.1' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M1.91868603,6.53153721 L0.442659553,5.05551073 C0.193334308,4.80618549 0.17934878,4.40647694 0.410638193,4.14033532 C0.62490105,3.89378593 0.99846319,3.86761252 1.24501258,4.08187538 C1.25346337,4.0892195 1.26170391,4.0968021 1.26972424,4.10461402 L2.62579281,5.42544399 L2.62579281,5.42544399 L6.68520476,1.43159191 C6.92852593,1.19220041 7.31896188,1.1924794 7.56194067,1.4322184 C7.80225585,1.66932931 7.80485343,2.05635962 7.56774253,2.2966748 C7.56678206,2.29764826 7.56581832,2.29861848 7.56485134,2.29958547 L3.33289959,6.53153721 C2.9423753,6.9220615 2.30921032,6.9220615 1.91868603,6.53153721 Z' />
    </svg>
  );
};

export const CheckIconStepper: FC<any> = ({ width, height, ...props }) => {
  return (
    <svg viewBox='0 0 16 14' fill='currentColor' height={height ? height : '14'} width={width ? width : '16'} version='1.1' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M14.8514 0.562981C15.3271 0.932792 15.4129 1.61817 15.0431 2.09382L6.56131 13.0029C6.37241 13.2458 6.08971 13.3978 5.78284 13.4211C5.47599 13.4445 5.17355 13.3371 4.95008 13.1255L0.340911 8.76187C-0.0966089 8.34765 -0.115503 7.65719 0.298711 7.21967C0.712925 6.78215 1.40339 6.76326 1.84091 7.17746L5.5769 10.7144L13.3205 0.754611C13.6904 0.278966 14.3758 0.193171 14.8514 0.562981Z'
      />
    </svg>
  );
};

import styled from 'styled-components';

export const CloseBtn = styled.button`
  height: 16px;
  width: 16px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.gray['600']};
  color: ${({ theme }) => theme.colors.gray['300']};
  margin-left: ${({ theme }) => theme.spacing(4)}px;
  font-size: 6px;
`;

import styled from 'styled-components';

const Wrapper = styled.div``;
const Item = styled.button<{ active?: boolean }>`
  text-align: left;
  width: 100%;
  display: flex;
  padding: ${({ theme }) => `${theme.spacing(3.5)}px ${theme.spacing(4)}px`};
  background-color: ${({ active, theme }) => (active ? theme.colors.gray['1000'] : 'transparent')};
  color: ${({ active, theme }) => (active ? theme.colors.text.colorful : theme.colors.text.default)};
  border-radius: 4px;
  align-items: center;
`;
const ItemIcon = styled.span`
  margin-right: ${({ theme }) => theme.spacing(4)}px;
  font-size: 16px;
  color: white;
`;

export const SidebarMenu = { Wrapper, Item, ItemIcon };

import { FC } from 'react';

export const CheckSquareIcon: FC = () => {
  return (
    <svg viewBox='0 0 40 40' version='1.1' xmlns='http://www.w3.org/2000/svg'>
      <defs>
        <linearGradient x1='-0.229589451%' y1='49.9999853%' x2='100.229675%' y2='49.9999853%' id='linearGradient-14587284'>
          <stop stopColor='#00EDB8' offset='0%' />
          <stop stopColor='#41C7FF' offset='100%' />
        </linearGradient>
      </defs>
      <g id='15-11' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='GUI---5.-Cards_Logo_Icons' transform='translate(-960.000000, -835.000000)'>
          <g id='success' transform='translate(936.000000, 810.000000)'>
            <g id='check' transform='translate(24.000000, 25.000000)'>
              <path
                d='M28.8888889,40 L11.1111111,40 C4.97461389,40 0,35.0253861 0,28.8888889 L0,11.1111111 C0,4.97461389 4.97461389,0 11.1111111,0 L28.8888889,0 C35.0253861,0 40,4.97461389 40,11.1111111 L40,28.8888889 C40,35.0253861 35.0253861,40 28.8888889,40 Z'
                id='Path'
                fill='#FFFFFF'
              />
              <path
                d='M23.5203241,14.4360797 C24.8470622,13.108634 26.9987033,13.1080603 28.326149,14.4347984 C29.5514835,15.6594797 29.6462288,17.5870369 28.610039,18.9204096 L28.3274302,19.2406233 L21.1277332,26.4441603 C19.9032055,27.6693411 17.9759403,27.7642371 16.6425576,26.7283876 L16.3223348,26.4458678 L11.9963862,22.1237591 C10.6687051,20.7972566 10.6677497,18.6456157 11.9942522,17.3179346 C13.2187161,16.0923828 15.1462565,15.9972955 16.4798129,17.0332487 L16.8000768,17.3158006 L18.7207819,19.2341046 L23.5203241,14.4360797 Z'
                id='Path'
                fill='url(#linearGradient-14587284)'
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

import { createSelector, Selector } from '@reduxjs/toolkit';
import type { AppState } from '@root/infra/redux';
import type { IAddressesState } from './addresses.slice';
import type { IAddress } from '../../types/address';

const getAddresses = (state: AppState) => state.addresses;
const getSelf = (state: IAddressesState) => state;
const getData = (state: IAddressesState) => state.data;
const getIsLoading = (state: IAddressesState) => state.isLoading;
const getHasAddress = (state: IAddressesState) => !!state.data.length;
const getUpdateId = (state: IAddressesState) => state.meta.update.id;
const getRemoveIds = (state: IAddressesState) => state.meta.remove.ids;
const getHasUpdate = (state: IAddressesState) => !!state.meta.update.id;
const getCreateIsOpen = (state: IAddressesState) => state.meta.create.isOpen;
const getUpdateItem = (state: IAddressesState) => state.data.find((item) => item.id === state.meta.update.id);

export const addressesSelector = {
  isLoading: createSelector<[Selector<AppState, IAddressesState>], IAddressesState['isLoading']>([getAddresses], getIsLoading),
  data: createSelector<[Selector<AppState, IAddressesState>], IAddressesState['data']>([getAddresses], getData),
  self: createSelector<[Selector<AppState, IAddressesState>], IAddressesState>([getAddresses], getSelf),
  hasAddress: createSelector<[Selector<AppState, IAddressesState>], boolean>([getAddresses], getHasAddress),
  updateId: createSelector<[Selector<AppState, IAddressesState>], IAddressesState['meta']['update']['id']>([getAddresses], getUpdateId),
  hasUpdate: createSelector<[Selector<AppState, IAddressesState>], boolean>([getAddresses], getHasUpdate),
  updateItem: createSelector<[Selector<AppState, IAddressesState>], IAddress | undefined>([getAddresses], getUpdateItem),
  removeIds: createSelector<[Selector<AppState, IAddressesState>], IAddressesState['meta']['remove']['ids']>([getAddresses], getRemoveIds),
  isCreate: createSelector<[Selector<AppState, IAddressesState>], boolean>([getAddresses], getCreateIsOpen),
};

import { SagaReturnType, call, put, takeLatest } from '@redux-saga/core/effects';
import { authSlice } from '@root//modules/auth/redux';
import { getEWallet } from '../services/get-e-wallet';
import { eWalletSlice } from './e-wallet.slice';

function* handleFetch() {
  const result: SagaReturnType<typeof getEWallet> = yield call(getEWallet);

  if (result.status === 200) {
    yield put(eWalletSlice.actions.fetchFulfilled(result.payload));
  } else {
    yield put(eWalletSlice.actions.fetchRejected(result.payload));
  }
}

function* handleClear() {
  yield put(eWalletSlice.actions.reset());
}

export function* eWalletSaga() {
  yield takeLatest([eWalletSlice.actions.fetchPending, authSlice.actions.fetchProfileFulfilled], handleFetch);
  yield takeLatest([authSlice.actions.loggedOut], handleClear);
}

import { authSelector } from '@root/modules/auth/redux';
import { currentMembershipSelectorByUserLevel } from '@root/modules/membership/redux/membership.selector';
import { GET_LAST_ORDER } from '@root/modules/orders/queries/get-last-order';
import { purchasedProductsSelector } from '@root/modules/products/redux/purchased-products/purchased-products.selector';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

export const useLiveChatParams = () => {
  const authState = useSelector(authSelector);
  const user = authState.user as any;
  const purchasedProductsState = useSelector(purchasedProductsSelector);
  const purchased = purchasedProductsState.data;
  const membership = useSelector(currentMembershipSelectorByUserLevel);
  const { data: lastOrder } = useQuery(['last-order'], GET_LAST_ORDER, {
    enabled: !!user,
  });

  if (!user) {
    return undefined;
  }

  const roles =
    user?.roles
      ?.filter(Boolean)
      .map((role) => role.name)
      ?.join(', ') || '-';

  const subs = purchased.map((product) => product?.title.text).join(', ') || '-';

  return {
    ['Exigo ID']: user.customerId || '-',
    ['Legacy Rank']: roles || '-',
    ['Enroller ID']: user?.enroller?.customerId || '-',
    ['Country Code']: user.country || '-',
    ['Email Address']: user.email || '-',
    ['Language']: user.languageId || '-',
    ['Existing Rebilly bundle subscription']: subs,
    ['Membership level']: membership?.name || '-',
    ['Last Rebilly Transaction ID']: lastOrder?.rebillyTransactionID || '-',
    ['Last Rebilly Transaction Status']: lastOrder?.status || '-',
    ['Last Order invoiceUrl']: lastOrder?.invoiceUrl || '-',
  };
};

import { CaseReducer, PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ICountry } from '../types';

export type CountriesState = {
  isLoading: boolean;
  items: ICountry[];
  error: string | null;
};

export namespace CountriesActions {
  export type FetchPending = PayloadAction;
  export type FetchFulfilled = PayloadAction<ICountry[]>;
  export type FetchRejected = PayloadAction<string>;
}

export type CountriesSliceReducer = {
  fetchPending: CaseReducer<CountriesState, CountriesActions.FetchPending>;
  fetchFulfilled: CaseReducer<CountriesState, CountriesActions.FetchFulfilled>;
  fetchRejected: CaseReducer<CountriesState, CountriesActions.FetchRejected>;
};

export const countriesInitialState: CountriesState = {
  isLoading: false,
  items: [],
  error: null,
};

export const countriesSlice = createSlice<CountriesState, CountriesSliceReducer, 'countries'>({
  name: 'countries',
  initialState: countriesInitialState,
  reducers: {
    fetchPending: (state) => {
      state.isLoading = true;
    },
    fetchFulfilled: (state, action) => {
      state.isLoading = false;
      state.items = action.payload;
    },
    fetchRejected: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

import { checkInvalidCharacter } from "@root/modules/auth/helpers/get-invalid-character";
import { TFunction } from 'react-i18next';
import * as yup from 'yup';


export const signinValidation = (t: TFunction) =>
  yup.object().shape({
    email: yup.string(),
    username: yup.string().when('email', (email) => {
      if (email?.includes('@')) {
        return yup
          .string()
          .test('valid-email', '', function (value) {
            return checkInvalidCharacter.call(this, value, t);
          })
          .email(t('validation:matches.commonMessage', { title: t('validation:labels.email') }))
          .required(t('validation:required.default', { label: t('validation:labels.email') }))
          .max(50, (value) => t('validation:size.maxLength', { title: t('validation:labels.email'), num: `${value.max}` }))
          .label(t('validation:labels.email'));
      }

      return yup
        .string()
        .required(t('validation:required.default', { label: t('validation:labels.loginName') }))
        .max(50, (value) => t('validation:size.maxLength', { title: t('validation:labels.loginName'), num: `${value.max}` }))
        .label(t('validation:labels.loginName'));
    }),
    password: yup
      .string()
      .required(t('validation:required.default', { label: t('validation:labels.loginPassword') }))
      // .min(8)
      // .max(20)
      // .matches(/[@#$%^&+=.\-_*!]+/g, 'Password should contain at least one special character')
      // .matches(/[A-Z]+/g, 'Password should contain at least one uppercase character')
      .label(t('validation:labels.password')),
  });

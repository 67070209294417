import { FC } from 'react';

export const QuestionIcon: FC = (props) => {
  return (
    <svg width='1em' height='1em' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M8 1.5C4.13438 1.5 1 4.63438 1 8.5C1 12.3656 4.13438 15.5 8 15.5C11.8656 15.5 15 12.3656 15 8.5C15 4.63438 11.8656 1.5 8 1.5ZM8 14.3125C4.79063 14.3125 2.1875 11.7094 2.1875 8.5C2.1875 5.29063 4.79063 2.6875 8 2.6875C11.2094 2.6875 13.8125 5.29063 13.8125 8.5C13.8125 11.7094 11.2094 14.3125 8 14.3125Z'
        fill='currentColor'
      />
      <path
        d='M9.74375 5.44844C9.275 5.0375 8.65625 4.8125 8 4.8125C7.34375 4.8125 6.725 5.03906 6.25625 5.44844C5.76875 5.875 5.5 6.44844 5.5 7.0625V7.18125C5.5 7.25 5.55625 7.30625 5.625 7.30625H6.375C6.44375 7.30625 6.5 7.25 6.5 7.18125V7.0625C6.5 6.37344 7.17344 5.8125 8 5.8125C8.82656 5.8125 9.5 6.37344 9.5 7.0625C9.5 7.54844 9.15625 7.99375 8.62344 8.19844C8.29219 8.325 8.01094 8.54687 7.80937 8.8375C7.60469 9.13437 7.49844 9.49062 7.49844 9.85156V10.1875C7.49844 10.2562 7.55469 10.3125 7.62344 10.3125H8.37344C8.44219 10.3125 8.49844 10.2562 8.49844 10.1875V9.83281C8.49925 9.68113 8.54574 9.53321 8.63187 9.40834C8.71799 9.28347 8.83975 9.18746 8.98125 9.13281C9.90312 8.77813 10.4984 7.96563 10.4984 7.0625C10.5 6.44844 10.2312 5.875 9.74375 5.44844ZM7.375 11.9375C7.375 12.1033 7.44085 12.2622 7.55806 12.3794C7.67527 12.4967 7.83424 12.5625 8 12.5625C8.16576 12.5625 8.32473 12.4967 8.44194 12.3794C8.55915 12.2622 8.625 12.1033 8.625 11.9375C8.625 11.7717 8.55915 11.6128 8.44194 11.4956C8.32473 11.3783 8.16576 11.3125 8 11.3125C7.83424 11.3125 7.67527 11.3783 7.55806 11.4956C7.44085 11.6128 7.375 11.7717 7.375 11.9375Z'
        fill='currentColor'
      />
    </svg>
  );
};

import { InternalizationSelect, languageOptions } from '../components';
import { useIsDesktop } from '@root/infra/device-type';
import { CurrencySelect } from '@root/modules/currencies/components/currency-select';
import { currenciesSelector } from '@root/modules/currencies/redux/currencies.selector';
import { ChevronIcon } from '@root/shared/ui/icons/chevron-icon';
import clsx from 'classnames';
import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Popover as Dropdown, PopoverProps } from 'react-tiny-popover';
import styled from 'styled-components';

const DropdownWrapper = styled.div`
  width: calc(100vw - 48px);
  max-width: 368px;

  @media screen and (min-width: 576px) {
    width: 368px;
  }
`;

export const SettingsDropdown: FC<Pick<PopoverProps, 'positions' | 'align'>> = ({ positions, align }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const isDesktop = useIsDesktop();
  const { t, i18n } = useTranslation();
  const userCurrency = useSelector(currenciesSelector.userCurrency);

  const content = (
    <DropdownWrapper className='flex flex-col bg-white shadow-2xl p-7 rounded-xl gap-y-4'>
      <div>
        <div className='text-gray-500 mb-1'>{t('Language')}</div>
        <InternalizationSelect />
      </div>
      <div>
        <div className='text-gray-500 mb-1'>{t('Currency')}</div>
        <CurrencySelect />
      </div>
    </DropdownWrapper>
  );

  const foundLanguage = useMemo(() => languageOptions.find((item) => item.value === i18n.language), [i18n.language]);

  return (
    <Dropdown
      isOpen={isOpen}
      onClickOutside={() => setIsOpen(false)}
      positions={positions || ['bottom', 'top', 'left']}
      align={align || isDesktop ? 'end' : 'center'}
      padding={8}
      content={content}
    >
      <button
        className={clsx('flex items-center bg-transparent p-2 transition', {
          'bg-gray-200': isOpen && isDesktop,
          'rounded-full hover:bg-gray-200': isDesktop,
          'rounded-full !bg-gray-200': isOpen,
          'border border-gray-400 rounded-md !px-3 !py-2.5': !isDesktop,
        })}
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <span className='flex items-center'>
          <span>{foundLanguage?.prefix}</span>
          <span className='ml-2 text-sm'>{foundLanguage?.value.toLocaleUpperCase()}</span>
          <span className='block w-px h-2.5 bg-black mx-1' />
          <span className='text-sm'>{userCurrency?.code}</span>
        </span>
        <ChevronIcon className={clsx('text-xs ml-1.5 transition transform rotate-0', { 'rotate-180': isOpen })} />
      </button>
    </Dropdown>
  );
};

import { CLOSED_NOTIFICATIONS_KEY } from '../constants/closed-notification-key';
import { addItemToSessionStorage } from '../helpers/storage-utils';
import { INotification, NotificationColor } from '../types/notification';
import { Theme } from '@root/infra/theme';
import { Id } from '@root/shared/constants';
import { BodyText } from '@root/shared/ui';
import { CloseIcon } from '@root/shared/ui/icons/close-icon';
import { FC, useCallback, MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const getBgColor = (theme: Theme, color?: NotificationColor) => {
  switch (color) {
    case 'success':
      return theme.colors.success[500];
    case 'warning':
      return theme.colors.warning[600];
    case 'danger':
      return theme.colors.danger[400];
    case 'info':
      return theme.colors.info[500];
    default:
      return 'transparent';
  }
};

const Container = styled.div<{ color?: NotificationColor }>`
  background: ${({ theme, color }) => getBgColor(theme, color)};
  color: ${({ theme }) => theme.colors.gray[100]};
  border-radius: ${({ theme }) => theme.spacing(1.5)}px;
  padding: ${({ theme }) => theme.spacing(4)}px;

  @media (min-width: ${({ theme }) => theme.screens.sm}) {
    padding: ${({ theme }) => theme.spacing(5)}px;
  }
`;

interface IProps {
  item: INotification;
  closeNotification: (id: Id) => void;
}

export const NotificationItem: FC<IProps> = ({ item, closeNotification }) => {
  const { t, i18n } = useTranslation('notifications');

  const buttonClickAction = item.buttonAction as MouseEventHandler<HTMLButtonElement>;

  const handleClose = useCallback(() => {
    addItemToSessionStorage(CLOSED_NOTIFICATIONS_KEY, item.id);

    closeNotification(item.id);
  }, [closeNotification, item.id]);

  return (
    <Container className='flex items-center gap-2' color={item.color}>
      <BodyText className='font-bold flex flex-col gap-1 lg:gap-2 lg:flex-row items-start'>
        <span>{t(item.textData.key, item.textData.options)}</span>
        {!!item.buttonText && !!item.buttonAction && (
          <button className='underline hover:opacity-75 transition' onClick={buttonClickAction}>
            {t(item.buttonText)}
          </button>
        )}
      </BodyText>
      <button className='self-start mt-1 lg:self-center lg:mt-0 text-[12px] hover:opacity-75 transition ml-auto' onClick={handleClose}>
        <CloseIcon />
      </button>
    </Container>
  );
};

import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import resourcesToBackend from 'i18next-resources-to-backend';
import { initReactI18next } from 'react-i18next';

export const supportedLanguages = ['en', 'zh', 'de', 'es', 'fr', 'it', 'pt', 'ru', 'ar', 'sk', 'ja'];

i18n
  .use(
    resourcesToBackend((language, namespace, callback) => {
      import(`../src/assets/locales/${language}/${namespace}.json`)
        .then((resources) => {
          callback(null, resources);
        })
        .catch((error) => {
          callback(error, null);
        });
    }),
  )
  .use(LanguageDetector)
  .use(initReactI18next)

  .init({
    fallbackLng: 'en',
    supportedLngs: supportedLanguages,
    debug: import.meta.env.DEV,
    ns: ['common', 'auth', 'account', 'subscriptions', 'dashboard', 'orders', 'notifications', 'products', 'payment-methods', 'kyc', 'kyc-errors', 'validation', 'gift-cards'],
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    detection: {
      order: ['cookie', 'navigator', 'path', 'subdomain'],
      caches: ['cookie'],
      lookupCookie: 'siteLang',
      cookieMinutes: 60 * 24 * 100, // 100 days
    },
    react: {
      useSuspense: true,
    },
    nsSeparator: ':',
  });

export default i18n;

import { SocketEventPayloads } from './event-payloads';
import { SocketEventTypes } from './event-types';
import { SocketEvents } from './events';
import { createSocket } from './socket';
import { call, fork, put, race, SagaReturnType, take } from '@redux-saga/core/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { authSlice } from '@root/modules/auth/redux';
import { CurrencyMapper } from '@root/modules/currencies/mappers/currency.mapper';
import { currenciesSlice } from '@root/modules/currencies/redux/currencies.slice';
import { KycIdentityVerificationMapper, KycAddressVerificationMapper } from '@root/modules/kyc/mappers/kyc-verification.mapper';
import { kycSlice } from '@root/modules/kyc/redux/kyc.slice';
import { maintenanceSlice } from "@root/modules/maintenance/store";
import { END, eventChannel } from 'redux-saga';
import { Socket } from 'socket.io-client';


type SocketChannel = SagaReturnType<typeof socketChannel>;

type SocketAction = 
| SocketEvents.MembershipUpdated 
| SocketEvents.KYCLevel1Updated 
| SocketEvents.KYCLevel2Updated 
| SocketEvents.CurrencyUpdated 
| SocketEvents.MaintenanceUpdate;

// eslint-disable-next-line
function* socketChannel(socket: Socket) {
  return eventChannel<SocketAction>((emit) => {
    const membershipUpdatedHandler = (payload: SocketEventPayloads.MembershipUpdated) => {
      emit({ type: SocketEventTypes.MembershipUpdated, payload });
    };
    const kycLevel1UpdatedHandler = (payload: SocketEventPayloads.KYCLevel1Updated) => {
      emit({ type: SocketEventTypes.KYC_VERIFICATION_LEVEL1, payload });
    };
    const kycLevel2UpdatedHandler = (payload: SocketEventPayloads.KYCLevel2Updated) => {
      emit({ type: SocketEventTypes.KYC_VERIFICATION_LEVEL2, payload });
    };
    const errorHandler = () => emit(END);

    const currencyUpdatedHandler = (payload: SocketEventPayloads.CurrencyUpdated) => {
      emit({ type: SocketEventTypes.CurrencyRatesUpdated, payload });
    };

    const maintenanceUpdateHandler = (payload: SocketEventPayloads.MaintenanceUpdate) => {
      emit({ type: SocketEventTypes.MaintenanceUpdate, payload });
    };

    socket.on(SocketEventTypes.MembershipUpdated, membershipUpdatedHandler);
    socket.on(SocketEventTypes.KYC_VERIFICATION_LEVEL1, kycLevel1UpdatedHandler);
    socket.on(SocketEventTypes.KYC_VERIFICATION_LEVEL2, kycLevel2UpdatedHandler);
    socket.on(SocketEventTypes.CurrencyRatesUpdated, currencyUpdatedHandler);
    socket.on(SocketEventTypes.MaintenanceUpdate, maintenanceUpdateHandler);
    socket.on('connect_error', errorHandler);
    socket.on('disconnect', errorHandler);

    return () => {
      socket.off(SocketEventTypes.MembershipUpdated, membershipUpdatedHandler);
      socket.off(SocketEventTypes.KYC_VERIFICATION_LEVEL1, kycLevel1UpdatedHandler);
      socket.off(SocketEventTypes.KYC_VERIFICATION_LEVEL2, kycLevel2UpdatedHandler);
      socket.off(SocketEventTypes.CurrencyRatesUpdated, currencyUpdatedHandler);
      socket.off(SocketEventTypes.MaintenanceUpdate, maintenanceUpdateHandler);
      socket.off('connect_error', errorHandler);
      socket.off('disconnect', errorHandler);
    };
  });
}

function* socketChannelHandler(socket: Socket, channel: SocketChannel) {
  while (true) {
    const { socketAction, shouldCancel }: { socketAction?: SocketAction; shouldCancel?: PayloadAction } = yield race({
      socketAction: take(channel),
      shouldCancel: take([authSlice.actions.loggedOut, authSlice.actions.loggedOutPrevented]),
    });

    if (socketAction) {
      yield socketActionHandler(socketAction);
    }

    if (shouldCancel) {
      channel.close();
      socket.disconnect();
    }
  }
}

function* socketActionHandler(action: SocketAction) {
  switch (action.type) {
    case SocketEventTypes.MembershipUpdated:
      yield put(authSlice.actions.membershipUpdated());
      break;
    case SocketEventTypes.KYC_VERIFICATION_LEVEL1:
      yield console.log('-- saga-action', action.payload);
      yield put(kycSlice.actions.setKycLevel1Data(KycIdentityVerificationMapper.toDomain(action.payload)));
      break;
    case SocketEventTypes.KYC_VERIFICATION_LEVEL2:
      yield console.log('-- saga-action', action.payload);
      yield put(kycSlice.actions.setKycLevel2Data(KycAddressVerificationMapper.toDomain(action.payload)));
      break;
    case SocketEventTypes.CurrencyRatesUpdated: {
      try {
        yield put(currenciesSlice.actions.fetchFulfilled(action.payload.map((item) => CurrencyMapper.toDomain(item))));
        break;
      } catch (e) {
        console.log('SOKET CURRENCY ERROR', action);
        break;
      }
    }
    case SocketEventTypes.MaintenanceUpdate: {
      yield put(maintenanceSlice.actions.setMaintenance(!!action.payload.find(item => item === 'customer-portal')));
      break;
    }
  }
}

export function* socketSaga() {
  while (true) {
    yield take(authSlice.actions.fetchProfileFulfilled);

    const socket = createSocket();
    const channel: SocketChannel = yield call(socketChannel, socket);

    yield fork(socketChannelHandler, socket, channel);
  }
}

import { fetcher } from '@root/infra/fetcher';
import type { IHttpResponse } from '@root/shared/http-service';
import { AxiosError } from 'axios';

export type VerifyCaptchaResponse = IHttpResponse<200, { success: boolean }> | IHttpResponse<400, string>;

export const verifyCaptchaService = async (token: string): Promise<VerifyCaptchaResponse> => {
  try {
    const response = await fetcher.post(`/siteverify`, { token });

    return {
      status: 200,
      payload: { success: response.data.data.success },
    };
  } catch (e) {
    const error = e as AxiosError;

    if (error.isAxiosError && error.response) {
      return {
        status: 400,
        payload: error.response.data.message,
      };
    }

    return {
      status: 400,
      payload: 'Bad request',
    };
  }
};

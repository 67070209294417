export enum PAYMENT_METHODS {
  PAYPAL = 'paypal',
  E_WALLET = 'e-wallet',
  CARD = 'payment-card',
  ASTRO_PAY = 'AstroPay Card',
  BITCOIN = 'cryptocurrency',
  AMAZON_PAY = 'AmazonPay',
  BANK_TRANSFER = 'bank-transfer',
  CASH_DEPOSIT = 'cash-deposit',
  USA_ALTERNATIVE = 'Paynote',
  ASK_TO_PAY = 'ask-to-pay',
  MAXICASH = 'MaxiCash',
}

import { SignInDto } from '../dtos';
import { signInFinalService, signInService } from '../services';
import { signinValidation } from '../validation';
import { SHOP_TOKEN_PARAM } from '@root/modules/auth/constants';
import { INSTANCE_TYPES } from '@root/modules/auth/constants/instance-types';
import { authSlice } from '@root/modules/auth/redux';
import { externalRedirect, isDevelopmentMode, notify } from '@root/shared/utils';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

const initialValues: SignInDto = {
  email: '',
  username: '',
  password: '',
  initiator: import.meta.env.REACT_APP_SHOP_INITIATOR,
  instanceType: INSTANCE_TYPES.CUSTOMER_PORTAL,
};

export const useSigninForm = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('validation');
  const schema = useMemo(() => signinValidation(t), [t]);

  const onSubmit = useCallback(
    async (values: SignInDto) => {
      const { username, password, initiator } = values;

      const result = await signInService({ username, password, initiator, instanceType: INSTANCE_TYPES.CUSTOMER_PORTAL });

      if (result.status === 200) {
        const { wrongDomain, redirectionToken, redirectionUrl } = result.payload;

        // !START REDIRECTION
        if (wrongDomain && !isDevelopmentMode) {
          return externalRedirect(`https://${redirectionUrl}?${SHOP_TOKEN_PARAM}=${redirectionToken}`);
        }

        const finalResult = await signInFinalService({ token: redirectionToken });

        if (finalResult.status === 200) {
          dispatch(authSlice.actions.loggedIn(finalResult.payload));

          // const next = router.query.next as string | undefined;
          // removeEnrollerData();
          // await router.push(next || (cartItemsLoaded && cartItemsCount !== 0 && pageLinks.checkout) || '/');
        }
      } else {
        notify({
          type: 'danger',
          title: 'Error',
          text: result.payload,
        });
      }
    },
    [dispatch],
  );

  return { initialValues, onSubmit, validationSchema: schema };
};

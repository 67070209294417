import { createSelector, Selector } from '@reduxjs/toolkit';
import type { AppState } from '@root/infra/redux';
import { Id } from '@root/shared/constants';
import { PAYMENT_METHODS } from '../constants/payment-methods';
import type { IPaymentMethodsState } from './payment-methods.slice';
import type { IPaymentCard, IPaymentItem, IPaypal } from '../types/payment-item';
import { IPaymentMethodsStateMeta } from './payment-methods.slice';

const getPaymentMethods = (state: AppState) => state.paymentMethods;
const getSelf = (state: IPaymentMethodsState) => state;
const getIsLoading = (state: IPaymentMethodsState) => state.isLoading;
const getDefaultIsLoading = (state: IPaymentMethodsState) => state.meta.makeDefaultIsLoading;
const getUpdateAddressIsLoading = (state: IPaymentMethodsState) => state.meta.update.addressIsLoading;
const getData = (state: IPaymentMethodsState) => state.data;
const getPaypalItem = (state: IPaymentMethodsState) => state.data.find((item) => item.type === PAYMENT_METHODS.PAYPAL) as IPaypal | undefined;
const getCards = (state: IPaymentMethodsState) => state.data.filter((item) => item.type === PAYMENT_METHODS.CARD) as IPaymentCard[];
const getHasCard = (state: IPaymentMethodsState) => state.data.findIndex((item) => item.type === PAYMENT_METHODS.CARD) !== -1;
const getCreatedPaymentCardId = (state: IPaymentMethodsState) => state.meta.createdPaymentCardId;
const getHasCreatedPaymentCard = (state: IPaymentMethodsState) => !!state.meta.createdPaymentCardId;
const getCandidateToRemove = (state: IPaymentMethodsState) => state.meta.candidateToRemove;
const getCreatedPaymentCard = (state: IPaymentMethodsState) => state.data.find((item) => item.id === state.meta.createdPaymentCardId) as IPaymentCard | undefined;
const getRemoveIds = (state: IPaymentMethodsState) => state.meta.remove.ids;
const getChooseAddressIsOpen = (state: IPaymentMethodsState) => state.meta.update.chooseAddress;
const getDefaultPaymentMethod = (state: IPaymentMethodsState) => state.data.find((item) => item.isDefault) as IPaymentItem | undefined;

export const paymentMethodsSelector = {
  isLoading: createSelector<[Selector<AppState, IPaymentMethodsState>], boolean>([getPaymentMethods], getIsLoading),
  isMakeDefaultLoading: createSelector<[Selector<AppState, IPaymentMethodsState>], boolean>([getPaymentMethods], getDefaultIsLoading),
  updateAddressIsLoading: createSelector<[Selector<AppState, IPaymentMethodsState>], boolean>([getPaymentMethods], getUpdateAddressIsLoading),
  data: createSelector<[Selector<AppState, IPaymentMethodsState>], IPaymentMethodsState['data']>([getPaymentMethods], getData),
  cards: createSelector<[Selector<AppState, IPaymentMethodsState>], IPaymentCard[]>([getPaymentMethods], getCards),
  self: createSelector<[Selector<AppState, IPaymentMethodsState>], IPaymentMethodsState>([getPaymentMethods], getSelf),
  paypal: createSelector<[Selector<AppState, IPaymentMethodsState>], IPaypal | undefined>([getPaymentMethods], getPaypalItem),
  hasCard: createSelector<[Selector<AppState, IPaymentMethodsState>], boolean>([getPaymentMethods], getHasCard),
  candidateToRemove: createSelector<[Selector<AppState, IPaymentMethodsState>], IPaymentMethodsStateMeta['candidateToRemove']>([getPaymentMethods], getCandidateToRemove),
  createdPaymentCardId: createSelector<[Selector<AppState, IPaymentMethodsState>], IPaymentMethodsState['meta']['createdPaymentCardId']>(
    [getPaymentMethods],
    getCreatedPaymentCardId,
  ),
  hasCreatedPaymentCard: createSelector<[Selector<AppState, IPaymentMethodsState>], boolean>([getPaymentMethods], getHasCreatedPaymentCard),
  createdPaymentCard: createSelector<[Selector<AppState, IPaymentMethodsState>], IPaymentCard | undefined>([getPaymentMethods], getCreatedPaymentCard),
  removeIds: createSelector<[Selector<AppState, IPaymentMethodsState>], Id[]>([getPaymentMethods], getRemoveIds),
  chooseAddressIsOpen: createSelector<[Selector<AppState, IPaymentMethodsState>], boolean>([getPaymentMethods], getChooseAddressIsOpen),
  defaultPaymentMethod: createSelector<[Selector<AppState, IPaymentMethodsState>], IPaymentItem | undefined>([getPaymentMethods], getDefaultPaymentMethod),
};

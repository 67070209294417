import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';

export type SettingsState = {};

export namespace SettingsActions {
  export type Updated = PayloadAction;
}

export type SettingsSliceReducer = {
  updated: CaseReducer<SettingsState, SettingsActions.Updated>;
};

export const settingsSlice = createSlice<SettingsState, SettingsSliceReducer, 'settings'>({
  name: 'settings',
  initialState: {},
  reducers: {
    updated: () => {},
  },
});

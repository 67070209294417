import { fetcher } from '@root/infra/fetcher';
import { IHttpResponse } from '@root/shared/http-service';
import { WithLocale } from '@root/shared/types';
import { ICurrency } from '../types/currency';
import { CurrencyMapper } from '@root/modules/currencies/mappers/currency.mapper';

export type GetCurrenciesResponses = IHttpResponse<200, ICurrency[]> | IHttpResponse<400, string>;

export const getCurrencies = async (query?: WithLocale<{}>): Promise<GetCurrenciesResponses> => {
  try {
    const response = await fetcher('/currency-converter-rates');

    if (response.status === 200) {
      return {
        status: 200,
        payload: response.data.data.map((item) => CurrencyMapper.toDomain(item)),
      };
    }

    return {
      status: 400,
      payload: 'Bad request',
    };
  } catch (e) {
    return {
      status: 400,
      payload: 'Bad request',
    };
  }
};

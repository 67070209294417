import { RECURRING_TYPES } from '@root/infra/enums/reccuring-type';
import { fetcher } from '@root/infra/fetcher';
import { MiniProductMapper } from '@root/modules/products/mappers/mini-product.mapper';
import { IMiniProduct } from '@root/modules/products/types/mini-product';
import { IHttpResponse } from '@root/shared/http-service';
import { handleAxiosError } from '@root/shared/utils/handle-axios-error';
import type { AxiosError } from 'axios';
import queryString from 'query-string';

export type GetProductsQuery = {
  uiCountry?: string;
  userId?: string;
  language?: string;
  recurringType?: RECURRING_TYPES;
};

export type GetProductsResponse = IHttpResponse<200, IMiniProduct[]> | IHttpResponse<400, string>;

export const getProducts = async (query?: GetProductsQuery): Promise<GetProductsResponse> => {
  try {
    const params = query ? `?${queryString.stringify(query)}` : '';
    const response = await fetcher.get(`plan/list${params}`);

    return {
      status: 200,
      payload: response.data.data.map((item) => MiniProductMapper.toDomain(item)).sort((a, b) => a.priority - b.priority),
    };
  } catch (e) {
    return handleAxiosError(e as AxiosError);
  }
};

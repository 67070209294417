import { FC } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;

  & > div {
    height: 100%;
    width: 100%;
  }
`;

export const Image: FC<any> = ({ wrapperClassname, alt, ...props }) => {
  return (
    <Wrapper className={wrapperClassname}>
      <img {...props} alt={alt} />
    </Wrapper>
  );
};

import { SagaReturnType, takeEvery, call, put, select } from 'redux-saga/effects';
import { authSlice } from '@root/modules/auth/redux';
import { getPaymentMethods } from '../services/get-payment-methods.service';
import { updatePaymentCardService } from '../services/update-payment-card.service';
import { removePaymentItemService } from '../services/remove-payment-item.service';
import { CreateAddressDtoMapper } from '../mappers/create-address-dto.mapper';
import { paymentMethodsSelector } from './payment-methods.selector';
import { IPaymentCard } from '../types/payment-item';
import { IAddress } from '../types/address';
import { PaymentMethodsActions, paymentMethodsSlice } from './payment-methods.slice';
import { AddressesActions, addressesSlice } from './addresses/addresses.slice';
import { addressesSelector } from './addresses/addresses.selector';
import { makeDefaultPaymentItemService } from '@root/modules/payment-methods/services/make-default-payment-item.service';
import { createAddressService } from '@root/modules/payment-methods/services/create-address.service';
import { notify } from '@root/shared/utils';

function* handleFetch() {
  const result: SagaReturnType<typeof getPaymentMethods> = yield call(getPaymentMethods);

  if (result.status === 200) {
    yield put(paymentMethodsSlice.actions.fetchFulfilled(result.payload));
  } else {
    yield put(paymentMethodsSlice.actions.fetchRejected(result.payload));
  }
}

function* handleRemove(action: PaymentMethodsActions.RemovePending) {
  const result: SagaReturnType<typeof removePaymentItemService> = yield call(removePaymentItemService, action.payload);

  if (result.status === 200) {
    yield put(paymentMethodsSlice.actions.removeFulfilled(action.payload));
    notify({ type: 'success', title: 'Payment Method Deleted' });
  } else {
    yield put(paymentMethodsSlice.actions.removeRejected({ id: action.payload, message: result.payload }));
  }
}

function* handleAddressCreated(action: AddressesActions.CreateFulfilled) {
  const card: IPaymentCard | undefined = yield select(paymentMethodsSelector.createdPaymentCard);
  const createAddressModalIsOpen: boolean = yield select(addressesSelector.isCreate);

  if (card && !createAddressModalIsOpen) {
    yield put(
      paymentMethodsSlice.actions.updateCardAddressPending({
        id: card.id,
        address: CreateAddressDtoMapper.toPaymentAddress(action.payload),
      }),
    );
  }

  if (createAddressModalIsOpen) {
    yield put(addressesSlice.actions.createClosed());
  }
}

function* handleAssignAddress(action: PaymentMethodsActions.AddressAssigned) {
  const createdCard: IPaymentCard | undefined = yield select(paymentMethodsSelector.createdPaymentCard);
  const cards: IPaymentCard[] = yield select(paymentMethodsSelector.cards);
  const addresses: IAddress[] = yield select(addressesSelector.data);
  const address: IAddress | undefined = addresses.find((item) => item.id.toString() === action.payload.addressId.toString());
  const card: IPaymentCard | undefined = !!action.payload.cardId ? cards.find((item) => item.id === action.payload.cardId) : createdCard;

  if (!!card && !!address) {
    if (action.payload.updateUserAddress) {
      const createAddressValues = CreateAddressDtoMapper.toDomain(address);
      const updateAddressResult: SagaReturnType<typeof createAddressService> = yield call(createAddressService, { ...createAddressValues, isDefault: true });

      if (updateAddressResult.status === 200) {
        yield put(addressesSlice.actions.createFulfilled(createAddressValues));
      } else {
        yield put(addressesSlice.actions.createRejected(updateAddressResult.payload));
      }
    }

    yield put(
      paymentMethodsSlice.actions.updateCardAddressPending({
        id: card.id,
        address: CreateAddressDtoMapper.toPaymentAddress(CreateAddressDtoMapper.toDomain(address)),
      }),
    );
  }
}

function* handleUpdateCardAddress(action: PaymentMethodsActions.UpdateCardAddressPending) {
  const cards: IPaymentCard[] = yield select(paymentMethodsSelector.cards);
  const card: IPaymentCard | undefined = cards.find((item) => item.id.toString() === action.payload.id.toString());

  if (card) {
    const result: SagaReturnType<typeof updatePaymentCardService> = yield call(updatePaymentCardService, {
      id: card.id,
      expirationDate: card.expiration,
      billingAddress: action.payload.address,
    });

    if (result.status === 200) {
      yield put(paymentMethodsSlice.actions.updateCardAddressFulfilled({ id: card.id }));
    } else {
      yield put(paymentMethodsSlice.actions.updateCardAddressRejected({ id: card.id, message: result.payload }));
    }
  }
}

function* handleMakeDefault(action: PaymentMethodsActions.MakeDefaultPending) {
  const result: SagaReturnType<typeof makeDefaultPaymentItemService> = yield call(makeDefaultPaymentItemService, action.payload);

  if (result.status === 200) {
    yield put(paymentMethodsSlice.actions.makeDefaultFulfilled(action.payload));
  } else {
    yield put(paymentMethodsSlice.actions.makeDefaultRejected({ id: action.payload, message: result.payload }));
  }
}

function* handleFetchWithLoading() {
  yield put(paymentMethodsSlice.actions.fetchPending());
}

export function* paymentMethodsSaga() {
  yield takeEvery(
    [
      paymentMethodsSlice.actions.fetchPending,
      paymentMethodsSlice.actions.removeFulfilled,
      paymentMethodsSlice.actions.createCardFulfilled,
      paymentMethodsSlice.actions.updateCardAddressFulfilled,
      authSlice.actions.fetchProfileFulfilled,
      addressesSlice.actions.createFulfilled,
    ],
    handleFetch,
  );
  yield takeEvery([paymentMethodsSlice.actions.makeDefaultFulfilled], handleFetchWithLoading);
  yield takeEvery([paymentMethodsSlice.actions.removePending], handleRemove);
  yield takeEvery([paymentMethodsSlice.actions.updateCardAddressPending], handleUpdateCardAddress);
  yield takeEvery([paymentMethodsSlice.actions.makeDefaultPending], handleMakeDefault);
  yield takeEvery([paymentMethodsSlice.actions.addressAssigned], handleAssignAddress);
  yield takeEvery([addressesSlice.actions.createFulfilled], handleAddressCreated);
}

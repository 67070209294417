import { ChangeEventHandler, FormEventHandler, useCallback, useMemo, useState } from 'react';
import { getRedirectionTokenService } from '@root/modules/auth/services';
import { INSTANCE_TYPES } from '@root/modules/auth/constants/instance-types';
import { externalRedirect, notify } from '@root/shared/utils';

export const useIncreaseEWalletBalance = () => {
  const [amount, setAmount] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const isValid = useMemo(() => !isNaN(parseFloat(amount)), [amount]);

  const onChange = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
    setAmount(event.target.value);
  }, []);

  const onSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    async (event) => {
      event.preventDefault();
      setIsSubmitting(true);
      const result = await getRedirectionTokenService(INSTANCE_TYPES.SHOP);

      if (result.status === 200) {
        const { redirectionUrl, redirectionToken } = result.payload;
        externalRedirect(`https://${redirectionUrl}/checkout?tempTok=${redirectionToken}&action=initial&topUpAmount=${amount}`);
      } else {
        setIsSubmitting(false);
        notify({ type: 'danger', title: result.payload });
      }
    },
    [amount],
  );

  const state = { isValid, isSubmitting, amount };
  const handlers = { onChange, onSubmit };

  return [state, handlers] as [typeof state, typeof handlers];
};

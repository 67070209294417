import styled, { StyledComponentProps } from 'styled-components';

export interface RadioExtraProps {
  error?: boolean;
  transparent?: boolean;
}

export type RadioProps = StyledComponentProps<'input', any, RadioExtraProps, any>;

export const RadioContainer = styled.div`
  position: relative;
  display: inline-flex;
`;

export const RadioLabel = styled.label`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.gray['1000']};
`;

export const RadioIcon = styled.div<{ checkedIcon: string; unCheckedIcon: string }>`
  display: inline-flex;
  align-self: flex-start;
  margin-right: ${({ theme }) => `${theme.spacing(2)}px`};
  position: relative;
  top: 2px;
  width: 17px;
  height: 15px;

  &::before,
  &::after {
    display: none;
    content: '';
    width: 15px;
    height: 15px;
    background: url('${({ checkedIcon }) => checkedIcon}') center no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 1px;
  }

  &::after {
    background: url('${({ unCheckedIcon }) => unCheckedIcon}') no-repeat;
    width: 19px;
    height: 15px;
    left: -3px;
    top: 0;
  }
`;

export const Radio = styled.input<RadioExtraProps>`
  position: absolute;
  visibility: hidden;
  width: 0;
  height: 0;
  top: 0;
  left: 0;

  &:checked ~ ${RadioIcon}:after {
    display: block;
  }

  &:not(:checked) ~ ${RadioIcon}:before {
    display: block;
  }
`;

Radio.defaultProps = {
  type: 'radio',
};

import { FC } from 'react';

export const SubmitTicketIcon: FC = (props) => {
  return (
    <svg width='1em' height='1em' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.69217 1.07651C1.79976 1.07651 1.07651 1.79976 1.07651 2.69217C1.07651 2.98944 0.835524 3.23042 0.538254 3.23042C0.240985 3.23042 0 2.98944 0 2.69217C0 1.20522 1.20522 0 2.69217 0H11.3078C12.7948 0 14 1.20522 14 2.69217V8.43594C14 9.92289 12.7948 11.1281 11.3078 11.1281H7.90678L4.46436 13.882C4.30279 14.0113 4.08143 14.0365 3.89495 13.9469C3.70846 13.8572 3.58986 13.6686 3.58986 13.4617V11.1281H2.69217C1.20522 11.1281 0 9.92289 0 8.43594C0 8.13867 0.240985 7.89769 0.538254 7.89769C0.835524 7.89769 1.07651 8.13867 1.07651 8.43594C1.07651 9.32835 1.79976 10.0516 2.69217 10.0516H4.12811C4.42538 10.0516 4.66637 10.2926 4.66637 10.5899V12.3418L7.38172 10.1695C7.47716 10.0932 7.59575 10.0516 7.71797 10.0516H11.3078C12.2002 10.0516 12.9235 9.32835 12.9235 8.43594V2.69217C12.9235 1.79976 12.2002 1.07651 11.3078 1.07651H2.69217Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0 5.56401C0 5.26674 0.240985 5.02576 0.538254 5.02576H5.56405C5.86132 5.02576 6.10231 5.26674 6.10231 5.56401C6.10231 5.86128 5.86132 6.10227 5.56405 6.10227H0.538254C0.240985 6.10227 0 5.86128 0 5.56401Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.74749 3.74749C3.9577 3.53729 4.2985 3.53729 4.5087 3.74749L5.94464 5.18344C6.15485 5.39364 6.15485 5.73444 5.94464 5.94464L4.5087 7.38059C4.2985 7.59079 3.9577 7.59079 3.74749 7.38059C3.53729 7.17039 3.53729 6.82958 3.74749 6.61938L4.80283 5.56404L3.74749 4.5087C3.53729 4.2985 3.53729 3.9577 3.74749 3.74749Z'
        fill='currentColor'
      />
    </svg>
  );
};

import { PurchasedProductsState } from '@root/modules/products/redux/purchased-products/purchased-products.slice';
import { createSelector, Selector } from '@reduxjs/toolkit';
import { AppState } from '@root/infra/redux';

import { IServicesState } from './services.slice';
import { productsListSelector } from '../products/products.selector';
import { IProductsState } from '../products/products.slice';

export const servicesSelector = {
  main: createSelector<[Selector<AppState, IServicesState>], IServicesState>(
    (state) => state.services,
    (services) => services,
  ),
};

export const purchasedServicesSelector = createSelector<
  [Selector<AppState, IProductsState['data']>, Selector<AppState, IServicesState>, Selector<AppState, PurchasedProductsState>],
  Omit<IServicesState, 'error'>
>(
  productsListSelector,
  (state) => state.services,
  (state) => state.purchasedProducts,
  (products, services, purchasedProducts) => {
    const addons = products.filter((item) => item.isAddon);
    const purchasedServices = purchasedProducts.data.map((item) => item.services).flat();

    const servicesData = services.data
      .filter((service) => purchasedServices.find((purchased) => purchased.id === service.id))
      .filter((service) => addons.find((addon) => addon.primaryServiceId === service.id));

    return {
      isLoading: services.isLoading || purchasedProducts.isLoading,
      data: servicesData,
    };
  },
);

import { ChevronLeftIcon, ChevronRightIcon } from '@root/shared/ui/icons/chevron-icon';
import { FC } from 'react';
import ReactPaginate, { ReactPaginateProps } from 'react-paginate';
import styled from 'styled-components';

const PaginateWrapper = styled.div`
  & > ul {
    display: flex;

    & > li.previous,
    & > li.next {
      & > a {
        display: block;
        padding: calc(0.625rem + 0.5px) 0.625rem;
        border-radius: 0.25rem;
        font-size: 12px;
        color: ${({ theme }) => theme.colors.gray['300']};
        background-color: ${({ theme }) => theme.colors.gray['100']};
        border: 1px solid ${({ theme }) => theme.colors.gray['300']};
      }
    }

    & > li.previous.disabled,
    & > li.next.disabled {
      & > a {
        background-color: ${({ theme }) => theme.colors.gray['500']};
        border: 1px solid ${({ theme }) => theme.colors.gray['500']};
        pointer-events: none;
      }
    }

    & > li:not(:last-child) {
      margin-right: 0.5rem;
    }

    & > li:not(.previous):not(.next) {
      & > a {
        position: relative;
        overflow: hidden;
        font-size: 14px;
        display: block;
        padding: 0.375rem 0.9rem;
        border-radius: 0.25rem;
        color: ${({ theme }) => theme.colors.gray['900']};
        background-color: ${({ theme }) => theme.colors.gray['100']};
        border: 1px solid ${({ theme }) => theme.colors.gray['300']};
      }

      &.selected > a {
        color: ${({ theme }) => theme.colors.primary['500']};
        border: 1px solid ${({ theme }) => theme.colors.primary['500']};
        font-weight: 700;
      }
    }
  }
`;

export const Paginate: FC<ReactPaginateProps> = (props) => {
  return (
    <PaginateWrapper>
      <ReactPaginate previousLabel={<ChevronLeftIcon />} nextLabel={<ChevronRightIcon />} {...props} />
    </PaginateWrapper>
  );
};

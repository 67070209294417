import { ICurrency } from '@root/modules/currencies/types/currency';

export class CurrencyMapper {
  public static toDomain(item): ICurrency {
    return {
      id: item.to.id,
      code: item.to.code,
      name: item.to.name,
      symbol: item.to.symbol,
      rate: item.rateWithFee,
      rateWithFee: item.rateWithFee,
      round: item.round,
    };
  }
}

import { SagaReturnType, takeEvery, call, put } from 'redux-saga/effects';
import { authSlice } from '@root/modules/auth/redux';
import { getAddresses } from '../../services/get-addresses.service';
import { removeAddressService } from '../../services/remove-address.service';
import { AddressesActions, addressesSlice } from './addresses.slice';

function* handleFetch() {
  const result: SagaReturnType<typeof getAddresses> = yield call(getAddresses);

  if (result.status === 200) {
    yield put(addressesSlice.actions.fetchFulfilled(result.payload));
  } else {
    yield put(addressesSlice.actions.fetchRejected(result.payload));
  }
}

function* handleRemove(action: AddressesActions.RemovePending) {
  const result: SagaReturnType<typeof removeAddressService> = yield call(removeAddressService, action.payload);

  if (result.status === 200) {
    yield put(addressesSlice.actions.removeFulfilled(action.payload));
  } else {
    yield put(addressesSlice.actions.fetchRejected(result.payload));
  }
}

export function* addressesSaga() {
  yield takeEvery(
    [addressesSlice.actions.fetchPending, addressesSlice.actions.createFulfilled, addressesSlice.actions.removeFulfilled, authSlice.actions.fetchProfileFulfilled],
    handleFetch,
  );
  yield takeEvery([addressesSlice.actions.removePending], handleRemove);
}

import { FC, useRef, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useIsDesktop } from '@root/infra/device-type';
import { Image } from '@root/shared/ui';

import { IMembershipLevel } from '../types/membership-level';
import { MembershipCardUI } from './membership-card.ui';

const { CardName, CardText, DiscountText } = MembershipCardUI;

type Props = {
  firstName: string;
  lastName: string;
  membership: IMembershipLevel;
};

const MIN_LARGE_WIDTH_PX = 380;

export const MembershipCard: FC<Props> = ({ firstName, lastName, membership }) => {
  const { t } = useTranslation();
  const isDesktop = useIsDesktop();
  const containerRef = useRef<HTMLDivElement>(null);

  const [isLarge, setIsLarge] = useState(isDesktop);

  const controlHandler = useCallback(() => {
    const container = containerRef.current;

    if (!!container) {
      const containerWidth = Math.round(container.getBoundingClientRect().width);

      if (containerWidth < MIN_LARGE_WIDTH_PX) {
        setIsLarge(false);
      } else {
        setIsLarge(true);
      }
    }
  }, []);

  useEffect(() => {
    controlHandler();

    window.addEventListener('resize', controlHandler);

    return () => {
      window.removeEventListener('resize', controlHandler);
    };
  }, [controlHandler]);

  return (
    <div className='relative' ref={containerRef}>
      <div className='w-full'>
        <Image src={membership.cardImage} width={870} height={530} alt='card' />
      </div>
      <CardText color={membership.color} large={isLarge}>
        {membership.label} {t('common:Member')}
      </CardText>
      <CardName large={isLarge}>
        {firstName} {lastName}
      </CardName>
      <DiscountText large={isLarge}>
        {t('Add-ons Discount')}: {membership.addonsDiscount}%
      </DiscountText>
    </div>
  );
};

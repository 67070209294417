import { fetcher } from "@root/infra/fetcher";
import { MaintenanceResponse } from "@root/modules/maintenance/types";
import { AxiosError } from "axios";

export const getMaintenance = async (): Promise<MaintenanceResponse> => {
	try {
    const response = await fetcher.get('/shop/instance-types/in-maintenance');
    return { status: 200, payload: response.data.data };
  } catch (err) {
    const error = err as AxiosError;
		
    return {
      status: 400,
      payload: {
        message: error.response?.data?.message,
        type: error.response?.data?.type,
      },
    };
  }
}

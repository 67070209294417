import { FC } from 'react';

export const ProductsIcon: FC = (props) => {
  return (
    <svg width='1em' height='1em' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.297654 3.28762L6.75919 0.0568469C6.91078 -0.018949 7.08922 -0.018949 7.24081 0.0568469L13.7023 3.28762C14.0992 3.48605 14.0992 4.05241 13.7023 4.25085L7.24081 7.48162C7.08922 7.55741 6.91078 7.55741 6.75919 7.48162L0.297654 4.25085C-0.0992181 4.05241 -0.0992181 3.48605 0.297654 3.28762ZM12.2565 3.76919L7.00007 1.14042L1.74253 3.76919L7.00007 6.39688L12.2565 3.76919ZM13.2208 9.74914C13.4868 9.61615 13.8103 9.72396 13.9433 9.98995C14.0615 10.2264 13.9894 10.5082 13.7852 10.6613L13.7025 10.7124L7.24093 13.9431C7.11965 14.0038 6.9812 14.0159 6.85306 13.9795L6.75931 13.9431L0.297772 10.7124C0.0317838 10.5794 -0.0760294 10.2559 0.0569649 9.98995C0.175182 9.75351 0.443874 9.64206 0.688936 9.71361L0.779387 9.74914L7.00012 12.8584L13.2208 9.74914ZM13.9433 6.75918C13.8103 6.49319 13.4868 6.38538 13.2208 6.51837L7.00012 9.62768L0.779387 6.51837L0.688936 6.48285C0.443874 6.41129 0.175182 6.52275 0.0569649 6.75918C-0.0760294 7.02517 0.0317838 7.34861 0.297772 7.4816L6.75931 10.7124L6.85306 10.7488C6.9812 10.7851 7.11965 10.773 7.24093 10.7124L13.7025 7.4816L13.7852 7.43056C13.9894 7.27744 14.0615 6.99561 13.9433 6.75918Z'
        fill='currentColor'
      />
    </svg>
  );
};

import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Footnote } from './text';
import { Button } from './button';
import { ProgressBar } from '@root/shared/ui';

export type FooterStepProps = {
  title: string;
  hideGoPrev?: boolean;
  last?: boolean;
  canFinish?: boolean;
  canGoNext?: boolean;
  onGoNext?: () => void;
  onGoPrev?: () => void;
  progress?: number;
};

const StepText = styled(Footnote)`
  @media (min-width: 994px) {
    font-size: 16px;
  }
`;

export const FooterStep: FC<FooterStepProps> = ({ title, hideGoPrev, last, canFinish, canGoNext, progress = 0, onGoPrev, onGoNext }) => {
  const { t } = useTranslation('common');

  return (
    <div className='bg-gray-800 lg:bg-gray-1000 w-full fixed bottom-0'>
      <div className='bg-gray-700'>
        <ProgressBar style={{ width: `${progress}%` }} />
      </div>

      <div className='container mx-auto'>
        <div className='flex flex-col lg:flex-row lg:justify-between px-5 pb-4 lg:pt-4 items-center'>
          <StepText className='mb-3 mt-2 text-center lg:text-left lg:flex-shrink-0 lg:my-0 text-gray-400 lg:text-white'>{title}</StepText>
          <div className='flex w-full lg:w-auto justify-center'>
            {!hideGoPrev && (
              <Button color='secondary' className='mr-2 sm:mr-4 sm:px-9 flex-shrink-0' type='button' onClick={onGoPrev}>
                {t('Prev')}
              </Button>
            )}
            {!last && (
              <Button color='primary' className='max-w-xs w-full sm:w-auto sm:px-16' onClick={onGoNext} type='button' disabled={!canGoNext}>
                {t('Next')}
              </Button>
            )}
            {last && (
              <Button color='primary' className='max-w-xs w-full sm:w-auto sm:px-16' disabled={!canFinish} type='submit'>
                {t('Finish')}
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

import { useEffect, useRef, useState, forwardRef } from 'react';
import clsx from 'classnames';
import { useTranslation } from 'react-i18next';
import { ArrowContainer, PopoverPosition } from 'react-tiny-popover';

import { theme } from '@root/infra/theme';
import { Dropdown, Footnote, Image } from '@root/shared/ui';

import { IMembershipLevel } from '../types/membership-level';

import { MembershipLevelsInfoUI } from './membership-levels-info.ui';
import { PriceItem } from '@root/modules/currencies/components/price-item';

const { MembershipInfoBackText, MembershipInfoFrontText, MembershipLevelsInfoItemContainer, MembershipLevelsInfoItemWrapper } = MembershipLevelsInfoUI;

type Props = {
  scaleCurrentLevel: boolean;
  shouldCalculateProgress: boolean;
  membership: IMembershipLevel & { progress: number; gradient: string };
  alwaysShowLabel?: boolean;
  labelText?: string;
  isHighestLevel?: boolean;
};
export const MembershipLevelItem = forwardRef<HTMLDivElement, Props>(function MembershipLevelItem(
  { membership, shouldCalculateProgress, scaleCurrentLevel, alwaysShowLabel = false, isHighestLevel, labelText = '' },
  ref,
) {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [width, setWidth] = useState<number>(0);
  const { t } = useTranslation();

  const setWrapperWidth = () => {
    if (wrapperRef.current?.clientWidth) {
      setWidth(wrapperRef.current.clientWidth);
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setWrapperWidth();
      clearTimeout(timeout);
    }, 0);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', setWrapperWidth);
    return () => window.removeEventListener('resize', setWrapperWidth);
  }, []);

  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  if (!membership.min && !membership.max) {
    return null;
  }

  return (
    <MembershipLevelsInfoItemContainer ref={ref}>
      <Dropdown
        positions={['bottom', 'top']}
        align='center'
        padding={0}
        onClickOutside={toggleDropdown}
        isOpen={isDropdownOpen || alwaysShowLabel}
        containerClassName='bg-gray-100 shadow-md rounded-lg'
        containerStyle={{ zIndex: '1003' }}
        content={({ position, childRect, popoverRect }) => (
          <ArrowContainer
            position={position as PopoverPosition}
            childRect={childRect}
            popoverRect={popoverRect}
            arrowClassName={clsx('border-0 w-4 h-4 z-40 transform border-gray-800 bg-gray-100 rotate-45', {
              'rotate-45 translate-x-1': position === 'right',
              'rotate-225 -translate-x-1': position === 'left',
              'rotate-135': position === 'bottom',
              '-rotate-45': position === 'top',
            })}
            arrowColor={theme.colors.gray['100']}
            arrowSize={10}
          >
            <div className='relative flex items-center justify-between z-50 gap-1 bg-gray-100 -my-1 -mb-1.5'>
              <Image src={membership.iconImage} width={14} height={14} alt='' />
              <div style={{ color: membership.color }}>
                <Footnote className='font-bold'>{labelText || membership.label}</Footnote>
              </div>
            </div>
          </ArrowContainer>
        )}
      >
        <MembershipLevelsInfoItemWrapper
          className='membership-level-item-dropdown'
          isScaled={membership.isCurrent && scaleCurrentLevel}
          progress={shouldCalculateProgress ? membership.progress : 100}
          onMouseOver={() => setIsDropdownOpen(true)}
          onMouseLeave={() => setIsDropdownOpen(false)}
          ref={wrapperRef}
        >
          <MembershipInfoFrontText progress={shouldCalculateProgress ? membership.progress : 100} background={membership.gradient}>
            <div style={{ width, overflow: 'hidden' }}>
              <Footnote className='font-bold'>{membership.addonsDiscount}%</Footnote>
              <div className='divider' />
              <div className='label'>{membership.label}</div>
              {!isHighestLevel && (
                <div>
                  <PriceItem value={membership.min} rounding={0} /> {' - '}
                  <PriceItem value={membership.max} rounding={0} />
                </div>
              )}
              {isHighestLevel && <div>{'$' + membership.min.toFixed() + ' ' + t('common:and above')}</div>}
            </div>
          </MembershipInfoFrontText>
          <MembershipInfoBackText>
            <div>
              <Footnote className='font-bold'>{membership.addonsDiscount}%</Footnote>
              <div className='divider' />
              <div className='label'>{membership.label}</div>
              <div>
                {!isHighestLevel && (
                  <div>
                    <div>
                      <PriceItem value={membership.min} rounding={0} /> {' - '}
                      <PriceItem value={membership.max} rounding={0} />
                    </div>
                  </div>
                )}
                {isHighestLevel && (
                  <div>
                    <PriceItem value={membership.min} rounding={0} /> {' - '}
                    {t('common:and above')}
                  </div>
                )}
              </div>
            </div>
          </MembershipInfoBackText>
        </MembershipLevelsInfoItemWrapper>
      </Dropdown>
    </MembershipLevelsInfoItemContainer>
  );
});

export enum OrderStatus {
  PENDING = 'Pending',
  PAID = 'Paid',
  ON_HOLD = 'On hold',
  PROCESSING = 'Processing',
  DELIVERED = 'Delivered',
  EXPIRED = 'Expired',
  CANCELED = 'Canceled',
  REFUNDED = 'Refunded',
  BLOCKCHAIN_PENDING = 'Blockchain Pending',
  ERROR = 'Error',
  WAITING_FOR_PAYMENT = 'Waiting for payment',
  PARTIALLY_PAID = 'Partially paid',
}

export const orderStatusOptions = Object.values(OrderStatus).map((value) => ({ value, label: value }));

import { countries } from 'countries-list';
import { createFilter } from 'react-select';

export type CountryOptions = {
  value: string;
  label: JSX.Element;
  phones: string[];
  toString(): string;
};

export type PhonePrefixOptions = {
  value: string;
  label: JSX.Element;
  countryCode: string;
};

export type SplittedPhone = {
  phoneNumber: string;
  phoneNumberPrefix: string;
};

// TODO: we need tt filter these options depending on the region
export const countryOptions: Array<CountryOptions> = Object.keys(countries).map((countryCode) => {
  const { emoji, name, phone } = countries[countryCode];

  return {
    label: (
      <div className='flex items-center gap-2'>
        <div>{emoji}</div>
        <div>{name}</div>
      </div>
    ),
    countryName: name,
    toString: () => `${name} ${countryCode}`,
    value: countryCode,
    phones: phone.split(',').map((p) => `+${p}`),
  };
});

export const countrySelectFilterConfigs = createFilter({
  stringify: (option) => option?.data?.toString() || `${option.label} ${option.value}`,
});

export const splitPhone: (phone: string, country: string) => SplittedPhone = (phone, country) => {
  try {
    const countryData = countries[country];
    const codes = countryData?.phone.split(',');
    const codeInNumber = codes.find((code) => phone.replaceAll('+', '').replace(/\D/g, '').startsWith(code));

    let phoneNumberPrefix = codeInNumber ? `+${codeInNumber}` : '';
    let phoneNumber = phone.replace(codeInNumber, '').replace(/\D/g, '');

    if (!codeInNumber) {
      const parsedCountryData = Object.values(countries).find((country) => country?.phone.split(',').some((item) => phone.includes(`+${item}`)));
      const parsedCodes = parsedCountryData?.phone.split(',') as string[];
      const parsedCodeInNumber = parsedCodes.find((code) => phone.replaceAll('+', '').replace(/\D/g, '').startsWith(code));

      phoneNumberPrefix = parsedCodeInNumber ? `+${parsedCodeInNumber}` : '';
      phoneNumber = phone.replace(parsedCodeInNumber as string, '').replace(/\D/g, '');
    }

    return { phoneNumberPrefix, phoneNumber };
  } catch (e) {
    return { phoneNumberPrefix: '', phoneNumber: '' };
  }
};

export const phonePrefixOptions: Array<PhonePrefixOptions> = Object.keys(countries).reduce((options: Array<PhonePrefixOptions>, countryCode) => {
  const { phone, emoji } = countries[countryCode];

  return [
    ...options,
    ...phone.split(',').map((prefix) => ({
      label: (
        <div className='flex items-center gap-2'>
          <div>{emoji}</div>
          <div>+{prefix}</div>
        </div>
      ),
      countryCode,
      value: `${countryCode} +${prefix}`,
    })),
  ];
}, []);

import { FC } from 'react';

export const LogoutIcon: FC = (props) => {
  return (
    <svg width='1em' height='1em' viewBox='0 0 13 12' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.0278 2.57564C8.79349 2.34132 8.41359 2.34132 8.17928 2.57564C7.94496 2.80995 7.94496 3.18985 8.17928 3.42417L10.155 5.3999H3.99844C3.66707 5.3999 3.39844 5.66853 3.39844 5.9999C3.39844 6.33127 3.66707 6.5999 3.99844 6.5999H10.155L8.17928 8.57563C7.94496 8.80994 7.94496 9.18984 8.17928 9.42416C8.41359 9.65847 8.79349 9.65847 9.0278 9.42416L12.0278 6.42416C12.2621 6.18985 12.2621 5.80995 12.0278 5.57563L9.0278 2.57564Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.10466 1.33333C1.6719 1.33333 1.33333 1.67845 1.33333 2.09V9.90998C1.33333 10.323 1.67466 10.6688 2.10798 10.6667L4.88695 10.654C5.25514 10.6523 5.55497 10.9494 5.55665 11.3176C5.55833 11.6858 5.26122 11.9856 4.89303 11.9873L2.11468 12C2.11458 12 2.11478 12 2.11468 12C0.954812 12.0056 0 11.0742 0 9.90998V2.09C0 0.929553 0.948097 0 2.10466 0H4.98066C5.34885 0 5.64733 0.298477 5.64733 0.666667C5.64733 1.03486 5.34885 1.33333 4.98066 1.33333H2.10466Z'
        fill='currentColor'
      />
    </svg>
  );
};

import { FC } from 'react';

export const KycIcon: FC = (props) => {
  return (
    <svg width='1em' height='1em' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0 7C0 3.13369 3.13369 0 7 0C9.53429 0 11.7461 1.35118 12.9735 3.36317C13.103 3.57536 13.0704 3.84858 12.8946 4.02434L7.38434 9.5346C7.28335 9.63559 7.14638 9.69232 7.00355 9.69231C6.86073 9.6923 6.72377 9.63555 6.62279 9.53455L3.9111 6.82214C3.70084 6.61183 3.70089 6.27089 3.9112 6.06064C4.12151 5.85038 4.46244 5.85043 4.6727 6.06074L7.00364 8.3923L11.8228 3.57313C10.7487 2.06259 8.99071 1.07692 7 1.07692C3.72846 1.07692 1.07692 3.72846 1.07692 7C1.07692 10.2715 3.72846 12.9231 7 12.9231C10.0477 12.9231 12.5563 10.6201 12.884 7.65872C12.9167 7.36314 13.1828 7.15004 13.4784 7.18275C13.774 7.21547 13.9871 7.4816 13.9544 7.77718C13.567 11.2774 10.6034 14 7 14C3.13369 14 0 10.8663 0 7Z'
        fill='currentColor'
      />
    </svg>
  );
};

import { KYC_STATUSES } from './../enums/kyc.statuses';
import { IKycState } from './kyc.slice';
import { createSelector, Selector } from '@reduxjs/toolkit';
import { AppState } from '@root/infra/redux';
import { authUserSelector, IAuthState } from '@root/modules/auth/redux';
import { IKycSetting } from '@root/modules/kyc/types/settings';

export const kycSelector = createSelector<[Selector<AppState, IKycState>], IKycState>(
  (state) => state.kyc,
  (kyc) => kyc,
);

export const selectKycOrderForActivation = createSelector<[Selector<AppState, IKycState>], IKycState['activateOrderId']>(
  (state) => state.kyc,
  (kyc) => kyc.activateOrderId,
);

export const selectKycMeta = createSelector<[Selector<AppState, IKycState>], IKycState['meta']>(
  (state) => state.kyc,
  (kyc) => kyc.meta,
);

export const selectIsNavigationEnabled = createSelector<[Selector<AppState, IKycState>], IKycState['meta']['isNavigationEnabled']>(
  (state) => state.kyc,
  (kyc) => kyc.meta.isNavigationEnabled,
);

export const selectKycSettings = createSelector<[Selector<AppState, IKycState>], Record<string, IKycSetting> | null>(
  (state) => state.kyc,
  (kyc) => {
    if (!kyc.settings) {
      return null;
    }

    return kyc.settings?.reduce((acc, val) => {
      acc[val.level] = val;
      return acc;
    }, {});
  },
);

export const selectKycLevel1Data = createSelector<[Selector<AppState, IKycState>], IKycState['level1']>(
  (state) => state.kyc,
  (kyc) => kyc.level1,
);

export const selectKycLevel2Data = createSelector<[Selector<AppState, IKycState>], IKycState['level2']>(
  (state) => state.kyc,
  (kyc) => kyc.level2,
);

export const selectKycLevel1Required = createSelector<[Selector<AppState, IKycState['level1']>, Selector<AppState, Record<string, IKycSetting> | null>], boolean>(
  selectKycLevel1Data,
  selectKycSettings,
  (level1, settings) => {
    const level1Status = level1?.verificationStatus;
    const level1Enabled = settings?.level1.enabled || false;

    if (level1Status && level1Enabled && ![KYC_STATUSES.SUCCESS, KYC_STATUSES.NOT_STARTED].includes(level1Status)) {
      return true;
    }

    return false;
  },
);

export const selectKycLevel2Required = createSelector<
  [
    Selector<AppState, IKycState['level1']>,
    Selector<AppState, IKycState['level2']>,
    Selector<AppState, Record<string, IKycSetting> | null>,
    Selector<AppState, IAuthState['user']>,
  ],
  boolean
>(selectKycLevel1Data, selectKycLevel2Data, selectKycSettings, authUserSelector, (level1, level2, settings, user) => {
  const level1Status = level1?.verificationStatus;
  const level2Status = level2?.verificationStatus;
  const level2Enabled = settings?.level2.enabled && user?.isKycLevel2user && level1Status === KYC_STATUSES.SUCCESS;

  if (level2Status && level2Enabled && ![KYC_STATUSES.SUCCESS, KYC_STATUSES.NOT_STARTED].includes(level2Status)) {
    return true;
  }

  return false;
});

export const menuKYCWarningSelector = createSelector<[typeof selectKycLevel1Required, typeof selectKycLevel2Required], boolean>(
  selectKycLevel1Required,
  selectKycLevel2Required,
  (isLevel1Required, isLevel2Required) => {
    return isLevel1Required || isLevel2Required;
  },
);

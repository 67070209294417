import { ICurrency } from '@root/modules/currencies/types/currency';

export const defaultCurrency: ICurrency = {
  code: 'USD',
  id: 126,
  name: 'United States dollar',
  symbol: '$',
  rateWithFee: 1,
  rate: 1,
  round: 1,
};

export const CheckedIcon = () => {
  return (
    <svg width='1em' height='1em' viewBox='0 0 19 19' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'>
      <title>B1603BD7-BAC3-4899-91AE-4D6BCEF4789C</title>
      <defs>
        <filter x='-114.3%' y='-146.9%' width='328.6%' height='393.9%' filterUnits='objectBoundingBox' id='filter-1'>
          <feOffset dx='-2' dy='2' in='SourceAlpha' result='shadowOffsetOuter1' />
          <feGaussianBlur stdDeviation='3' in='shadowOffsetOuter1' result='shadowBlurOuter1' />
          <feColorMatrix values='0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.128566576 0' type='matrix' in='shadowBlurOuter1' result='shadowMatrixOuter1' />
          <feMerge>
            <feMergeNode in='shadowMatrixOuter1' />
            <feMergeNode in='SourceGraphic' />
          </feMerge>
        </filter>
        <polygon id='path-2' points='4.44911882 8.59093545 1.13018761 5.20490513 -3.55271368e-15 6.34982186 4.44911882 10.8888889 14 1.14491673 12.8777715 1.45661261e-13' />
      </defs>
      <g id='GUI' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='GUI---10.-Student-&amp;-Public-Pages---Elements' transform='translate(-248.000000, -1850.000000)'>
          <g id='Toggle' transform='translate(249.611111, 1853.000000)'>
            <g id='Border' transform='translate(0.000000, 1.000000)' stroke='#373D47'>
              <rect id='Rectangle-3' x='0.5' y='0.5' width='13' height='13' rx='4' />
            </g>
            <g id='Group' filter='url(#filter-1)' transform='translate(3.000000, 0.000000)'>
              <mask id='mask-3' fill='white'>
                <use xlinkHref='#path-2' />
              </mask>
              <use id='Shape' fill='#00EDB8' xlinkHref='#path-2' />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

import { FC } from 'react';

export const CalendarIcon: FC = () => {
  return (
    <svg width='100%' height='100%' viewBox='0 0 20 20' version='1.1'>
      <g id='15-11' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='0.1.3-Create-Account' transform='translate(-756.000000, -413.000000)'>
          <g id='user-info' transform='translate(158.000000, 267.000000)'>
            <g id='date-birth' transform='translate(343.000000, 113.000000)'>
              <g id='calendar-chedule-line' transform='translate(255.000000, 33.000000)'>
                <rect id='Rectangle' x='0' y='0' width='20' height='20' />
                <path
                  d='M13.75,1.66666667 C14.1773632,1.66666667 14.5295893,1.98836683 14.5777269,2.40281573 L14.5833333,2.5 L14.5826667,2.91666667 L15,2.91666667 C16.7795842,2.91666667 18.2334538,4.31121676 18.3284011,6.06710933 L18.3333333,6.25 L18.3333333,15 C18.3333333,16.8409492 16.8409492,18.3333333 15,18.3333333 L15,18.3333333 L5,18.3333333 C3.15905083,18.3333333 1.66666667,16.8409492 1.66666667,15 L1.66666667,15 L1.66666667,6.25 C1.66666667,4.40905083 3.15905083,2.91666667 5,2.91666667 L5,2.91666667 L5.41666667,2.91666667 L5.41666667,2.5 C5.41666667,2.03976271 5.78976271,1.66666667 6.25,1.66666667 C6.6773632,1.66666667 7.0295893,1.98836683 7.07772689,2.40281573 L7.08333333,2.5 L7.08266667,2.91666667 L12.9166667,2.91666667 L12.9166667,2.5 C12.9166667,2.03976271 13.2897627,1.66666667 13.75,1.66666667 Z M5.41666667,4.58266667 L5,4.58333333 C4.07952542,4.58333333 3.33333333,5.32952542 3.33333333,6.25 L3.33333333,6.25 L3.33333333,15 C3.33333333,15.9204746 4.07952542,16.6666667 5,16.6666667 L5,16.6666667 L15,16.6666667 C15.9204746,16.6666667 16.6666667,15.9204746 16.6666667,15 L16.6666667,15 L16.6666667,6.25 C16.6666667,5.32952542 15.9204746,4.58333333 15,4.58333333 L15,4.58333333 L14.5826667,4.58266667 L14.5833333,5 C14.5833333,5.46023729 14.2102373,5.83333333 13.75,5.83333333 C13.3226368,5.83333333 12.9704107,5.51163317 12.9222731,5.09718427 L12.9166667,5 L12.9166667,4.58266667 L7.08266667,4.58266667 L7.08333333,5 C7.08333333,5.46023729 6.71023729,5.83333333 6.25,5.83333333 C5.8226368,5.83333333 5.4704107,5.51163317 5.42227311,5.09718427 L5.41666667,5 L5.41666667,4.58266667 Z M14.1666667,13.3218667 C14.626904,13.3218667 15,13.6949627 15,14.1552 C15,14.5825632 14.6782998,14.9347893 14.2638509,14.9829269 L14.1666667,14.9885333 L5.83333333,14.9885333 C5.37309604,14.9885333 5,14.6154373 5,14.1552 C5,13.7278368 5.32170016,13.3756107 5.73614906,13.3274731 L5.83333333,13.3218667 L14.1666667,13.3218667 Z'
                  id='Combined-Shape'
                  fill='#00C2AE'
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

import { useSelector } from 'react-redux';
import { currenciesSelector } from '@root/modules/currencies/redux/currencies.selector';
import { useCallback, useMemo } from 'react';
import { customRound } from '@root/modules/currencies/helpers/round';

const wellKnownCurrencies = ['USD', 'EUR'];
const currenciesWithoutRounding = ['USD'];

export const useCurrencyConverter = (currencyCode?: string) => {
  const userDefaultCurrency = useSelector(currenciesSelector.userCurrency);
  const allCurrencies = useSelector(currenciesSelector.list);

  const userCurrency = useMemo(
    () => (currencyCode ? allCurrencies.find((item) => item.code === currencyCode) : userDefaultCurrency),
    [userDefaultCurrency, currencyCode, allCurrencies],
  );

  const convert = useCallback(
    (valueNumber, type: 'short' | 'long' = 'short', rounding?: number): any => {
      let value = Number(valueNumber);

      if (typeof value !== 'number' || isNaN(value)) {
        return 0;
      }

      value = value < 0 ? 0 : value;

      if (!userCurrency?.code) {
        return value;
      }

      let roundedValue = currenciesWithoutRounding.includes(userCurrency.code) ? value : customRound(value * userCurrency.rate, userCurrency.round);

      if (Math.floor(roundedValue) !== roundedValue) {
        const [integer, decimal] = `${roundedValue}`.split('.');

        if (decimal) {
          roundedValue = Number(`${integer}.${decimal.substring(0, 2)}`);
        }
      }

      if (type === 'long' && !wellKnownCurrencies.includes(userCurrency?.code)) {
        return `${userCurrency.code}${userCurrency.symbol}${roundedValue}`;
      }

      return `${userCurrency.symbol}${rounding === 0 ? roundedValue.toFixed(0) : roundedValue}`;
    },
    [userCurrency?.code, userCurrency?.rate, userCurrency?.round, userCurrency?.symbol],
  );

  return { convert };
};

import { FC } from 'react';

export const DashboardIcon: FC = (props) => {
  return (
    <svg width='1em' height='1em' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.64187 0.111519L0.225201 4.64093C0.0831091 4.74123 0 4.89545 0 5.05882V12.1765C0 13.1836 0.899576 14 2.00926 14H11.9907C13.1004 14 14 13.1836 14 12.1765V5.05882C14 4.89545 13.9169 4.74123 13.7748 4.64093L7.35813 0.111519C7.14748 -0.0371731 6.85252 -0.0371731 6.64187 0.111519ZM7 1.19857L12.8333 5.31739V12.1764C12.8333 12.5988 12.4561 12.9412 11.9907 12.9412H9.72179V6.9999L9.71239 6.90474C9.663 6.65777 9.42482 6.47049 9.13845 6.47049H4.86068L4.75582 6.47902C4.4837 6.52385 4.27734 6.74 4.27734 6.9999V12.9412H2.00926L1.89492 12.9342C1.48365 12.8835 1.16667 12.5636 1.16667 12.1764V5.31633L7 1.19857ZM5.44394 12.9412H8.55509L8.55473 7.5292H5.44323L5.44394 12.9412Z'
        fill='currentColor'
      />
    </svg>
  );
};

export const RadioOffIcon = () => {
  return (
    <svg width='1em' height='1em' viewBox='0 0 15 15' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'>
      <g id='Student+Educator' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='GUI---10.-Student-&amp;-Public-Pages---Elements' transform='translate(-191.000000, -1898.000000)' stroke='#373D47'>
          <g id='Border' transform='translate(191.000000, 1898.000000)'>
            <rect id='Rectangle-3' x='0.5' y='0.5' width='14' height='14' rx='7' />
          </g>
        </g>
      </g>
    </svg>
  );
};

import { FC } from 'react';
import { baseIconProps } from './base-icon-props';

export const StatisticsOrdersIcon: FC<any> = (props) => {
  return (
    <svg {...baseIconProps} viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.66667 0C1.64105 0 0 1.64105 0 3.66667V10.3333C0 12.359 1.64105 14 3.66667 14H5.33333V22.9253C5.33333 24.6243 6.70905 26 8.408 26H22.9253C24.6239 26 26 24.6247 26 22.9267V8.408C26 6.70947 24.6247 5.33333 22.9267 5.33333H22V3.66667C22 1.64105 20.359 0 18.3333 0H3.66667ZM20 5.33333V3.66667C20 2.74562 19.2544 2 18.3333 2H3.66667C2.74562 2 2 2.74562 2 3.66667V10.3333C2 11.2544 2.74562 12 3.66667 12H5.33333V8.408C5.33333 6.70905 6.70905 5.33333 8.408 5.33333H20ZM7.33333 13V22.9253C7.33333 23.5197 7.81362 24 8.408 24H22.9253C23.5201 24 24 23.5193 24 22.9267V8.408C24 7.8132 23.5193 7.33333 22.9267 7.33333H21H8.408C7.81362 7.33333 7.33333 7.81362 7.33333 8.408V13ZM13.6267 11.5202C13.6267 10.9679 14.0744 10.5202 14.6267 10.5202H20.8533C21.4056 10.5202 21.8533 10.9679 21.8533 11.5202C21.8533 12.0725 21.4056 12.5202 20.8533 12.5202H14.6267C14.0744 12.5202 13.6267 12.0725 13.6267 11.5202ZM14.6267 14.6667C14.0744 14.6667 13.6267 15.1144 13.6267 15.6667C13.6267 16.219 14.0744 16.6667 14.6267 16.6667H20.8533C21.4056 16.6667 21.8533 16.219 21.8533 15.6667C21.8533 15.1144 21.4056 14.6667 20.8533 14.6667H14.6267ZM13.6267 19.8132C13.6267 19.2609 14.0744 18.8132 14.6267 18.8132H20.8533C21.4056 18.8132 21.8533 19.2609 21.8533 19.8132C21.8533 20.3654 21.4056 20.8132 20.8533 20.8132H14.6267C14.0744 20.8132 13.6267 20.3654 13.6267 19.8132ZM10.6667 12.4668C11.219 12.4668 11.6667 12.0191 11.6667 11.4668C11.6667 10.9145 11.219 10.4668 10.6667 10.4668C10.1144 10.4668 9.66667 10.9145 9.66667 11.4668C9.66667 12.0191 10.1144 12.4668 10.6667 12.4668ZM11.6667 15.6003C11.6667 16.1525 11.219 16.6003 10.6667 16.6003C10.1144 16.6003 9.66667 16.1525 9.66667 15.6003C9.66667 15.048 10.1144 14.6003 10.6667 14.6003C11.219 14.6003 11.6667 15.048 11.6667 15.6003ZM10.6667 20.7331C11.219 20.7331 11.6667 20.2854 11.6667 19.7331C11.6667 19.1808 11.219 18.7331 10.6667 18.7331C10.1144 18.7331 9.66667 19.1808 9.66667 19.7331C9.66667 20.2854 10.1144 20.7331 10.6667 20.7331Z'
        fill='url(#paint0_linear_1004_5848)'
      />
      <defs>
        <linearGradient id='paint0_linear_1004_5848' x1='26.0597' y1='-0.0597079' x2='-0.0596938' y2='-0.0597079' gradientUnits='userSpaceOnUse'>
          <stop stopColor='#41C7FF' />
          <stop offset='1' stopColor='#8C84FF' />
        </linearGradient>
      </defs>
    </svg>
  );
};

import { DashboardUI } from '../components/dashboard.ui';
import { EditDefaultPaymentMethodInfo } from '../components/payment-methods-info/edit-default-payment-method-info';
import { LastPaymentInfo } from '../components/payment-methods-info/last-payment-info';
import { usePaymentMethodsInfo } from '../hooks/usePaymentMethodsInfo';
import { getPaymentMethodInfo } from '@root/modules/payment-methods/helpers/getPaymentMethodInfo';
import { useOutsideClick } from '@root/shared/hooks';
import { BodyTextLarge, Image, Button, LoaderFill, BodyText } from '@root/shared/ui';
import clsx from 'clsx';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const { InfoCard, InfoCardDropdown, InfoCardDropdownButton } = DashboardUI;

export const PaymentMethodsInfo = () => {
  const { t } = useTranslation('dashboard', { keyPrefix: 'paymentMethodsInfo' });
  const dropdownWrapperRef = useRef<HTMLDivElement>(null);

  const [{ isLoading, isOpen, defaultPaymentMethod }, { toggleDropdown, handleOutsideClick }] = usePaymentMethodsInfo();
  const paymentMethodInfo = defaultPaymentMethod && getPaymentMethodInfo(defaultPaymentMethod);

  useOutsideClick(dropdownWrapperRef, handleOutsideClick, true);

  if (isLoading) {
    return (
      <InfoCard className='lg:border-r'>
        <LoaderFill />
      </InfoCard>
    );
  }

  if (!defaultPaymentMethod) {
    return (
      <InfoCard className='lg:border-r'>
        <div className='p-4'>
          <BodyTextLarge className='font-bold mb-4 text-xl lg:text-base'>{t('no-payment-methods.title')}</BodyTextLarge>
          <Link to='/payment-methods'>
            <Button className='py-2.5 self-start px-6'>{t('no-payment-methods.button')}</Button>
          </Link>
        </div>
      </InfoCard>
    );
  }

  return (
    <InfoCard className={clsx({ 'lg:border-r': !isOpen })} isDropdownOpen={isOpen} ref={dropdownWrapperRef}>
      <div className='p-4 bg-gray-100'>
        <div className='flex flex-col sm:flex-row items-start sm:items-center'>
          <div className='flex flex-shrink-0 mb-2 sm:mb-0'>
            <div className='mr-4  h-auto max-h-12 w-12 lg:w-14 lg:h-auto lg:max-h-10 flex items-center'>
              <Image src={paymentMethodInfo?.imageUrl} alt='payment method' className='object-cover' />
            </div>
          </div>
          <div>
            <BodyTextLarge className='text-xl lg:text-base font-bold mb-1 sm:mb-0'>{t('default-payment-method')}</BodyTextLarge>
            {defaultPaymentMethod.type === 'payment-card' && (
              <BodyText className='font-bold text-gray-600 text-base lg:text-[13px] mb-2 sm:mb-0'>
                {t('method-type-card', { type: paymentMethodInfo?.title, last4Number: defaultPaymentMethod.last4Number })}
              </BodyText>
            )}
            {defaultPaymentMethod.type === 'paypal' && (
              <BodyText className='font-bold text-gray-600 text-base lg:text-[13px] mb-2 sm:mb-0'>
                {t('method-type-paypal', { type: paymentMethodInfo?.title, owner: defaultPaymentMethod.owner.firstname + ' ' + defaultPaymentMethod.owner.lastname })}
              </BodyText>
            )}
          </div>
        </div>
      </div>

      <div className='p-4 pt-1 lg:pt-0 bg-gray-100'>
        <InfoCardDropdownButton className='edit_pm_dashboard' isOpen={isOpen} toggle={toggleDropdown}>
          {t('edit-payment-methods')}
        </InfoCardDropdownButton>
      </div>

      {isOpen && (
        <InfoCardDropdown>
          {<LastPaymentInfo />}
          {defaultPaymentMethod && <EditDefaultPaymentMethodInfo item={defaultPaymentMethod} />}
        </InfoCardDropdown>
      )}
    </InfoCard>
  );
};

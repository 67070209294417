import { IPreAuth } from '../types';

export class PreAuthTokensMapper {
  public static toDomain(item): IPreAuth {
    return {
      country: item.country,
      redirectionToken: item.redirectionToken,
      redirectionUrl: item.redirectionUrl,
      wrongDomain: item.wrongDomain,
    };
  }
}

import { createSelector, Selector } from '@reduxjs/toolkit';
import { AppState } from '@root/infra/redux';
import { IEWalletState } from './e-wallet.slice';

const getEWallet = (state: AppState) => state.eWallet;
const getSelf = (state: IEWalletState) => state;
const getData = (state: IEWalletState) => state.data;

export const eWalletSelector = {
  self: createSelector<[Selector<AppState, IEWalletState>], IEWalletState>([getEWallet], getSelf),
  data: createSelector<[Selector<AppState, IEWalletState>], IEWalletState['data']>([getEWallet], getData),
};

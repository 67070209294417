import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';

export type LayoutState = {
  hamburgerMenuIsOpen: boolean;
  settingsOverlayIsOpen: boolean;
  globalLoaderIsOpen: boolean;
  sidebarIsOpen: boolean;
};

export namespace LayoutActions {
  export type Opened = PayloadAction;
  export type Closed = PayloadAction;

  export type SettingsOverlayOpened = PayloadAction;
  export type SettingsOverlayClosed = PayloadAction;

  export type GlobalLoaderOpened = PayloadAction;
  export type GlobalLoaderClosed = PayloadAction;

  export type SidebarOpened = PayloadAction;
  export type SidebarClosed = PayloadAction;
  export type SidebarToggled = PayloadAction;
}

export type LayoutReducer = {
  opened: CaseReducer<LayoutState, LayoutActions.Opened>;
  closed: CaseReducer<LayoutState, LayoutActions.Closed>;

  settingsOverlayOpened: CaseReducer<LayoutState, LayoutActions.SettingsOverlayOpened>;
  settingsOverlayClosed: CaseReducer<LayoutState, LayoutActions.SettingsOverlayClosed>;

  globalLoaderOpened: CaseReducer<LayoutState, LayoutActions.GlobalLoaderOpened>;
  globalLoaderClosed: CaseReducer<LayoutState, LayoutActions.GlobalLoaderClosed>;

  sidebarOpened: CaseReducer<LayoutState, LayoutActions.SidebarOpened>;
  sidebarClosed: CaseReducer<LayoutState, LayoutActions.SidebarClosed>;
  sidebarToggled: CaseReducer<LayoutState, LayoutActions.SidebarToggled>;
};

export const layoutInitialState: LayoutState = {
  hamburgerMenuIsOpen: false,
  settingsOverlayIsOpen: false,
  globalLoaderIsOpen: false,
  sidebarIsOpen: true,
};

export const layoutSlice = createSlice<LayoutState, LayoutReducer, 'layout'>({
  name: 'layout',
  initialState: layoutInitialState,
  reducers: {
    opened: (state) => {
      state.hamburgerMenuIsOpen = true;
    },
    closed: (state) => {
      state.hamburgerMenuIsOpen = false;
    },
    settingsOverlayOpened: (state) => {
      state.settingsOverlayIsOpen = true;
      state.hamburgerMenuIsOpen = false;
    },
    settingsOverlayClosed: (state) => {
      state.settingsOverlayIsOpen = false;
    },
    globalLoaderOpened: (state) => {
      state.globalLoaderIsOpen = true;
    },
    globalLoaderClosed: (state) => {
      state.globalLoaderIsOpen = false;
    },
    sidebarOpened: (state) => {
      state.sidebarIsOpen = true;
    },
    sidebarClosed: (state) => {
      state.sidebarIsOpen = false;
    },
    sidebarToggled: (state) => {
      state.sidebarIsOpen = !state.sidebarIsOpen;
    },
  },
});

import { Theme } from '@root/infra/theme';
import { generateGradient } from '@root/shared/utils';
import { lighten } from 'polished';
import styled, { css, StyledComponentProps } from 'styled-components';
import { Button } from './button';
import { H2, H4 } from './headings';
import { RichEditorContent } from './rich-editor-content';

export type CardColor = 'light' | 'dark';
export type CardElevation = 0 | 1 | 2 | 3 | 4 | 5;
export type CardDirection = 'horizontal' | 'vertical';
export type CardSize = 'small' | 'normal';

export type CardExtraProps = {
  color?: CardColor;
  elevation?: CardElevation;
  direction?: CardDirection;
  size?: CardSize;
  visible?: boolean;
  disableHover?: boolean;
  height?: string;
};

enum Levels {
  BRONZE = 1,
  SILVER = 2,
  GOLD = 3,
  PRIME = 4,
}

export type CardHeaderProps = {
  color: string;
};

export const getGradientByPriority = (theme: Theme, priority: number) => {
  switch (priority) {
    case Levels.BRONZE: {
      return theme.colors.gradients['1'];
    }
    case Levels.GOLD: {
      return theme.colors.gradients['2'];
    }
    case Levels.PRIME: {
      return theme.colors.gradients['4'];
    }
    default: {
      return theme.colors.gradients['1'];
    }
  }
};

export type CardProps = StyledComponentProps<'div', Theme, CardExtraProps, any>;

export const Card = styled.div<CardExtraProps>`
  background-color: ${({ theme, color }) => theme.colors.card[color || 'light']};
  box-shadow: ${({ theme, elevation }) => theme.shadows[elevation || 3]};
  border-radius: ${({ theme }) => `${theme.spacing(1.5)}px`};
  overflow: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  transition: box-shadow 0.2s;
  position: relative;
  display: flex;
  flex-direction: column;
  height: ${({ height }) => (height ? height : '100%')};
  max-width: 970px;
  margin: 0 auto;
  width: 100%;

  ${({ direction }) =>
    direction === 'horizontal' &&
    css`
      @media screen and (min-width: 768px) {
        flex-direction: row;
        ${CardCover} {
          width: 262px;
        }
      }
    `}

  ${({ disableHover }) =>
    !disableHover &&
    css`
      &:hover {
        box-shadow: ${({ theme }) => theme.shadows[5]};
      }
    `}
`;

export const CardLink = styled.a`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
`;

export const CardStars = styled.div`
  display: flex;
  font-size: 15px;
  color: ${({ theme }) => theme.colors.warning['500']};
  gap: ${({ theme }) => `${theme.spacing(1)}px`};
  align-items: center;
`;

export const CardRating = styled.p`
  display: inline-block;
  font-weight: bold;
  margin-top: 4px;
  color: ${({ theme }) => theme.colors.warning['500']};
`;

export const CardStatistics = styled.div`
  display: flex;
  gap: ${({ theme }) => `${theme.spacing(1)}px`};
  margin-bottom: ${({ theme }) => `${theme.spacing(1)}px`};
`;

export const CardHeader = styled.div<CardHeaderProps>`
  position: relative;
  padding: ${({ theme }) => `${theme.spacing(6.5)}px ${theme.spacing(10)}px ${theme.spacing(8.5)}px`};
  background: ${({ color, theme }) => (color === '#000' ? theme.colors.gradients[17] : generateGradient(lighten(0.6, color), lighten(0.2, color), '135deg'))};
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const CardCover = styled.img`
  width: 100%;
  object-fit: cover;
  height: 164px;
`;

export const CardContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const CardDescription = styled(RichEditorContent)`
  font-size: 15px;

  p {
    margin-bottom: 0.5em;

    &:last-child{
      margin-bottom: 0;
    }
  }
`;

export const CardTitle = styled(H2)<{ color?: string }>`
  font-weight: bold;
  line-height: 34px;
  overflow: hidden;
  color: ${({ theme, color }) => color || theme.colors.gray[900]};
`;

export const CardSubtitle = styled(H4)`
  color: ${({ theme }) => theme.colors.gray['900']};
  font-weight: bold;
  line-height: 24px;
  //max-width: ${({ theme }) => `${theme.spacing(50)}px`};
  margin: ${({ theme }) => `${theme.spacing(4)}px`} 0;
  word-break: break-word;
  @media (max-width: 1200px) {
    font-size: 16px;
  }
`;

export const CardLabels = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: ${({ theme }) => `${theme.spacing(2)}px`};
`;

export const CardLabel = styled(Button).attrs({ color: 'primary', size: 3 })`
  margin-bottom: ${({ theme }) => `${theme.spacing(2)}px`};
  margin-left: ${({ theme }) => `${theme.spacing(2)}px`};
`;

export const CardBestsellerLabel = styled(Button).attrs({ color: 'secondary-alt', size: 2 })`
  top: ${({ theme }) => `${theme.spacing(6)}px`};
  left: ${({ theme }) => `${theme.spacing(6)}px`};
  position: absolute;
  box-shadow: ${({ theme }) => theme.shadows['3']};
  background: ${({ theme }) => theme.colors.gray[100]};
  line-height: 16px;
  padding: ${({ theme }) => `${theme.spacing(2)}px ${theme.spacing(2)}px`};
  font-weight: 500;
  color: ${({ theme }) => theme.colors.gray[900]};
`;

export const CardSpecialOfferLabel = styled(Button).attrs({ color: 'secondary-alt', size: 1 })`
  box-shadow: ${({ theme }) => theme.shadows['0']};
  background: ${({ theme }) => theme.colors.gradients[6]};
  color: ${({ theme }) => theme.colors.gray[100]};
  line-height: 15px;
  padding: ${({ theme }) => `${theme.spacing(0.5)}px ${theme.spacing(1.6)}px`};
  font-weight: bold;
  font-size: 12px;
  pointer-events: none;
`;

Card.defaultProps = {
  color: 'light',
  elevation: 3,
};

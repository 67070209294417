import { PriceItem } from '@root/modules/currencies/components/price-item';
import { GET_LAST_ORDER } from '@root/modules/orders/queries/get-last-order';
import { BodyTextLarge, BodyText, LinkButton } from '@root/shared/ui';
import { ArrowLeftWithoutColorIcon as ArrowIcon } from '@root/shared/ui/icons/arrow-left';
import { externalRedirect } from '@root/shared/utils';
import { format } from 'date-fns';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

export const LastPaymentInfo: FC = () => {
  const { t } = useTranslation('dashboard', { keyPrefix: 'paymentMethodsInfo' });

  const { data: lastItem } = useQuery(['last-order'], GET_LAST_ORDER);

  if (!lastItem) {
    return null;
  }

    const orderPrice = lastItem.items.reduce((acc, product) => acc + product.amount + (product.processingFee || 0), 0);

  const orderTitle = lastItem.items.map((item) => item.level).join(', ');

  const handleViewReceipt = () => {
    return externalRedirect(lastItem.invoiceUrl, { target: '_blank', rel: 'noopener noreferrer' });
  };

  return (
    <div className='p-4 py-3 sm:py-4 flex flex-row flex-wrap gap-x-4 gap-y-2 items-center border-b border-gray-300'>
      <div>
        <BodyTextLarge className='font-bold'>{t('last-payment', { date: format(new Date(lastItem.createdAt), 'MMM dd, yyyy') })}</BodyTextLarge>
        <BodyText className='text-[13px] font-bold text-gray-600'>
          <span>
            <PriceItem value={orderPrice} />
          </span>
          <span className='px-1'>/</span>
          <span>{orderTitle}</span>
        </BodyText>
      </div>
      <LinkButton onClick={handleViewReceipt} as='button' className='ml-auto'>
        {t('view-receipt')}
        <ArrowIcon className='ml-2 rotate-180 p-0' color='secondary' />
      </LinkButton>
    </div>
  );
};

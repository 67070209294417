import { FC } from 'react';

export const UserIdIcon: FC = (props) => {
  return (
    <svg width='1em' height='1em' viewBox='0 0 15 14' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.0002 8.16663C9.80355 8.16663 8.8335 9.13668 8.8335 10.3333C8.8335 11.5299 9.80355 12.5 11.0002 12.5C12.1968 12.5 13.1668 11.5299 13.1668 10.3333C13.1668 9.13668 12.1968 8.16663 11.0002 8.16663ZM7.8335 10.3333C7.8335 8.58439 9.25126 7.16663 11.0002 7.16663C12.7491 7.16663 14.1668 8.58439 14.1668 10.3333C14.1668 12.0822 12.7491 13.5 11.0002 13.5C9.25126 13.5 7.8335 12.0822 7.8335 10.3333Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.1868 9.4914C12.382 9.68672 12.3819 10.0033 12.1866 10.1985L11.1457 11.2388C10.9507 11.4337 10.6346 11.4339 10.4392 11.2392L9.81348 10.6153C9.61792 10.4203 9.61745 10.1037 9.81243 9.90818C10.0074 9.71263 10.324 9.71216 10.5195 9.90713L10.7919 10.1786L11.4797 9.49119C11.675 9.29599 11.9916 9.29608 12.1868 9.4914Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.33316 9.16075C4.68559 9.16075 4.16063 9.68571 4.16063 10.3333C4.16063 10.6062 3.9394 10.8274 3.6665 10.8274C3.39359 10.8274 3.17236 10.6062 3.17236 10.3333C3.17236 9.13991 4.13979 8.17249 5.33316 8.17249H6.33316C6.60607 8.17249 6.8273 8.39372 6.8273 8.66662C6.8273 8.93952 6.60607 9.16075 6.33316 9.16075H5.33316Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.00016 4.16663C6.35583 4.16663 5.8335 4.68896 5.8335 5.33329C5.8335 5.97762 6.35583 6.49996 7.00016 6.49996C7.6445 6.49996 8.16683 5.97762 8.16683 5.33329C8.16683 4.68896 7.6445 4.16663 7.00016 4.16663ZM4.8335 5.33329C4.8335 4.13668 5.80355 3.16663 7.00016 3.16663C8.19678 3.16663 9.16683 4.13668 9.16683 5.33329C9.16683 6.52991 8.19678 7.49996 7.00016 7.49996C5.80355 7.49996 4.8335 6.52991 4.8335 5.33329Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.33333 1.5C2.76853 1.5 1.5 2.76853 1.5 4.33333V9.66667C1.5 11.2315 2.76853 12.5 4.33333 12.5H7C7.27614 12.5 7.5 12.7239 7.5 13C7.5 13.2761 7.27614 13.5 7 13.5H4.33333C2.21624 13.5 0.5 11.7838 0.5 9.66667V4.33333C0.5 2.21624 2.21624 0.5 4.33333 0.5H9.66667C11.7838 0.5 13.5 2.21624 13.5 4.33333V6.33333C13.5 6.60948 13.2761 6.83333 13 6.83333C12.7239 6.83333 12.5 6.60948 12.5 6.33333V4.33333C12.5 2.76853 11.2315 1.5 9.66667 1.5H4.33333Z'
        fill='currentColor'
      />
    </svg>
  );
};

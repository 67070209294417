export const HARDCODE_BUNDLE_DATA = {
  //! rookie
  ROOKIEENTRY: {
    imageUrl: '/images/products/Bronze_2x.webp',
    subtitle: 'FEM Growth Academy, FEM App , Community Social App, Instructor Led Training',
    title: { text: 'Bundle 1', color: "#805AD6"},
    fakeDiscountPercentage: 40,
    isBestSeller: false,
    description:
      '' +
      '          rookie ipsum dolor sit amet consectetur adipisicing elit. Eos deserunt maxime totam ratione omnis, optio excepturi odit blanditiis dicta accusantium alias inventore\n' +
      '          aperiam possimus amet, suscipit id ipsa aut voluptate. Expedita ipsam explicabo iusto nobis atque et fugit earum, aperiam perspiciatis quisquam eveniet, officia adipisci\n' +
      '          eaque recusandae soluta asperiores repellendus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos deserunt maxime totam ratione omnis, optio excepturi odit\n' +
      '          blanditiis dicta accusantium alias inventore aperiam possimus amet, suscipit id ipsa aut voluptate. Expedita ipsam explicabo iusto nobis atque et fugit earum, aperiam\n' +
      '          perspiciatis quisquam eveniet, officia adipisci eaque recusandae soluta asperiores repellendus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos deserunt\n' +
      '          maxime totam ratione omnis, optio excepturi odit blanditiis dicta accusantium alias inventore aperiam possimus amet, suscipit id ipsa aut voluptate. Expedita ipsam\n' +
      '          explicabo iusto nobis atque et fugit earum, aperiam perspiciatis quisquam eveniet, officia adipisci eaque recusandae soluta asperiores repellendus.\n' +
      '        ',
    bullets: [
      { text: 'Get 10+ classes across 15 categories (Language: English)' },
      { text: 'Get 1+ premium classes (Language: Multilingual)' },
      { text: 'New classes added every month' },
      { text: 'FEM App' },
      { text: 'Ready-to-go questionnaires' },
      { text: 'PDF workbooks for every class' },
      { text: 'Assignments & assessments' },
      { text: 'Free certificates' },
      { text: 'Gamification', points: ['Collect points and badges', 'Move-up levels', 'Be featured high on the leader board'] },
      { text: 'Watch on desktop, phone, or TV' },
      { text: 'Learning paths' },
      { text: 'Community Social App' },
      { text: 'Instructor-led training', points: ['Live Calls with authors and instructors', 'Monday – Friday via video conference'] },
    ],
  },

  //! bronze
  BRENTRY01: {
    imageUrl: '/images/products/Bronze_2x.webp',
    subtitle: 'FEM Growth Academy, FEM App , Community Social App, Instructor Led Training',
    title: { text: 'Bundle 2', color: '#B57133'},
    fakeDiscountPercentage: 40,
    isBestSeller: false,
    description:
      '' +
      '          bronze ipsum dolor sit amet consectetur adipisicing elit. Eos deserunt maxime totam ratione omnis, optio excepturi odit blanditiis dicta accusantium alias inventore\n' +
      '          aperiam possimus amet, suscipit id ipsa aut voluptate. Expedita ipsam explicabo iusto nobis atque et fugit earum, aperiam perspiciatis quisquam eveniet, officia adipisci\n' +
      '          eaque recusandae soluta asperiores repellendus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos deserunt maxime totam ratione omnis, optio excepturi odit\n' +
      '          blanditiis dicta accusantium alias inventore aperiam possimus amet, suscipit id ipsa aut voluptate. Expedita ipsam explicabo iusto nobis atque et fugit earum, aperiam\n' +
      '          perspiciatis quisquam eveniet, officia adipisci eaque recusandae soluta asperiores repellendus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos deserunt\n' +
      '          maxime totam ratione omnis, optio excepturi odit blanditiis dicta accusantium alias inventore aperiam possimus amet, suscipit id ipsa aut voluptate. Expedita ipsam\n' +
      '          explicabo iusto nobis atque et fugit earum, aperiam perspiciatis quisquam eveniet, officia adipisci eaque recusandae soluta asperiores repellendus.\n' +
      '        ',
    bullets: [
      { text: 'Get 10+ classes across 15 categories (Language: English)' },
      { text: 'Get 1+ premium classes (Language: Multilingual)' },
      { text: 'New classes added every month' },
      { text: 'FEM App' },
      { text: 'Ready-to-go questionnaires' },
      { text: 'PDF workbooks for every class' },
      { text: 'Assignments & assessments' },
      { text: 'Free certificates' },
      { text: 'Gamification', points: ['Collect points and badges', 'Move-up levels', 'Be featured high on the leader board'] },
      { text: 'Watch on desktop, phone, or TV' },
      { text: 'Learning paths' },
      { text: 'Community Social App' },
      { text: 'Instructor-led training', points: ['Live Calls with authors and instructors', 'Monday – Friday via video conference'] },
    ],
  },

  //! silver
  SLENTRY01: {
    imageUrl: '/images/products/Bronze_2x.webp',
    subtitle: 'FEM Growth Academy, FEM App , Community Social App, Instructor Led Training, and OK Academy',
    title: { text: 'Bundle 3', color: '#909090'},
    fakeDiscountPercentage: 52,
    isBestSeller: false,
    description:
      '' +
      '          silver ipsum dolor sit amet consectetur adipisicing elit. Eos deserunt maxime totam ratione omnis, optio excepturi odit blanditiis dicta accusantium alias inventore\n' +
      '          aperiam possimus amet, suscipit id ipsa aut voluptate. Expedita ipsam explicabo iusto nobis atque et fugit earum, aperiam perspiciatis quisquam eveniet, officia adipisci\n' +
      '          eaque recusandae soluta asperiores repellendus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos deserunt maxime totam ratione omnis, optio excepturi odit\n' +
      '          blanditiis dicta accusantium alias inventore aperiam possimus amet, suscipit id ipsa aut voluptate. Expedita ipsam explicabo iusto nobis atque et fugit earum, aperiam\n' +
      '          perspiciatis quisquam eveniet, officia adipisci eaque recusandae soluta asperiores repellendus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos deserunt\n' +
      '          maxime totam ratione omnis, optio excepturi odit blanditiis dicta accusantium alias inventore aperiam possimus amet, suscipit id ipsa aut voluptate. Expedita ipsam\n' +
      '          explicabo iusto nobis atque et fugit earum, aperiam perspiciatis quisquam eveniet, officia adipisci eaque recusandae soluta asperiores repellendus.\n' +
      '        ',
    bullets: [
      { text: 'Get 20+ classes across 15 categories (Language: English)' },
      { text: 'Get 2+ premium classes (Language: Multilingual)' },
      { text: 'New classes added every month' },
      { text: 'Community Social  and FEM Apps' },
      { text: 'Ready-to-go questionnaires' },
      { text: 'PDF workbooks for every class' },
      { text: 'Assignments & assessments' },
      { text: 'Free certificates' },
      { text: 'Gamification', points: ['Collect points and badges', 'Move-up levels', 'Be featured high on the leader board'] },
      { text: 'Watch on desktop, phone, or TV' },
      { text: 'Learning paths' },
      { text: 'Community Social App' },
      { text: 'Instructor-led training', points: ['Live Calls with authors and instructors', 'Monday – Friday via video conference'] },
    ],
  },

  //! silver-premier

  TLSLVPREM: {
    imageUrl: '/images/products/Bronze_2x.webp',
    subtitle: 'MH Academy, Live Gold',
    title: { text: 'Silver Premiere Bundle' },
    fakeDiscountPercentage: 54,
    isBestSeller: false,
    description:
      '' +
      '          silver Premiere ipsum dolor sit amet consectetur adipisicing elit. Eos deserunt maxime totam ratione omnis, optio excepturi odit blanditiis dicta accusantium alias inventore\n' +
      '          aperiam possimus amet, suscipit id ipsa aut voluptate. Expedita ipsam explicabo iusto nobis atque et fugit earum, aperiam perspiciatis quisquam eveniet, officia adipisci\n' +
      '          eaque recusandae soluta asperiores repellendus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos deserunt maxime totam ratione omnis, optio excepturi odit\n' +
      '          blanditiis dicta accusantium alias inventore aperiam possimus amet, suscipit id ipsa aut voluptate. Expedita ipsam explicabo iusto nobis atque et fugit earum, aperiam\n' +
      '          perspiciatis quisquam eveniet, officia adipisci eaque recusandae soluta asperiores repellendus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos deserunt\n' +
      '          maxime totam ratione omnis, optio excepturi odit blanditiis dicta accusantium alias inventore aperiam possimus amet, suscipit id ipsa aut voluptate. Expedita ipsam\n' +
      '          explicabo iusto nobis atque et fugit earum, aperiam perspiciatis quisquam eveniet, officia adipisci eaque recusandae soluta asperiores repellendus.\n' +
      '        ',
    bullets: [
      { text: 'Get 20+ classes across 15 categories (Language: English)' },
      { text: 'Get 3+ premium classes (Language: Multilingual)' },
      { text: 'New classes added every month' },
      { text: 'Community Social  and FEM Apps' },
      { text: 'Ready-to-go questionnaires' },
      { text: 'PDF workbooks for every class' },
      { text: 'Assignments & assessments' },
      { text: 'Free certificates' },
      { text: 'Gamification', points: ['Collect points and badges', 'Move-up levels', 'Be featured high on the leader board'] },
      { text: 'Watch on desktop, phone, or TV' },
      { text: 'Learning paths' },
      { text: 'Community Social App' },
      { text: 'Instructor-led training', points: ['Live Calls with authors and instructors', 'Monday – Friday via video conference'] },
    ],
  },

  //! gold
  GDENTRY01: {
    imageUrl: '/images/products/Bronze_2x.webp',
    subtitle: 'FEM Growth Academy, FEM App, NTR App, DIGI App, WS App Community Social App, Instructor Led Training, OK Academy and Full Travel Access',
    title: { text: 'Bundle 4', color: '#BAAC90' },
    fakeDiscountPercentage: 61,
    isBestSeller: true,
    description:
      '' +
      '          Elite ipsum dolor sit amet consectetur adipisicing elit. Eos deserunt maxime totam ratione omnis, optio excepturi odit blanditiis dicta accusantium alias inventore\n' +
      '          aperiam possimus amet, suscipit id ipsa aut voluptate. Expedita ipsam explicabo iusto nobis atque et fugit earum, aperiam perspiciatis quisquam eveniet, officia adipisci\n' +
      '          eaque recusandae soluta asperiores repellendus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos deserunt maxime totam ratione omnis, optio excepturi odit\n' +
      '          blanditiis dicta accusantium alias inventore aperiam possimus amet, suscipit id ipsa aut voluptate. Expedita ipsam explicabo iusto nobis atque et fugit earum, aperiam\n' +
      '          perspiciatis quisquam eveniet, officia adipisci eaque recusandae soluta asperiores repellendus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos deserunt\n' +
      '          maxime totam ratione omnis, optio excepturi odit blanditiis dicta accusantium alias inventore aperiam possimus amet, suscipit id ipsa aut voluptate. Expedita ipsam\n' +
      '          explicabo iusto nobis atque et fugit earum, aperiam perspiciatis quisquam eveniet, officia adipisci eaque recusandae soluta asperiores repellendus.\n' +
      '        ',

    bullets: [
      { text: 'Get 150+ classes across 9 categories (Language: English)' },
      { text: 'Get 4+ premium classes (Language: Multilingual)' },
      { text: 'New classes added every month' },
      { text: 'FEM App' },
      { text: 'Ready-to-go questionnaires' },
      { text: 'PDF workbooks for every class' },
      { text: 'Assignments & assessments' },
      { text: 'Free certificates' },
      { text: 'Gamification', points: ['Collect points and badges', 'Move-up levels', 'Be featured high on the leader board'] },
      { text: 'Watch on desktop, phone, or TV' },
      { text: 'Learning paths' },
      { text: 'Community Social App' },
      { text: 'Instructor-led training', points: ['Live Calls with authors and instructors', 'Monday – Friday via video conference'] },
    ],
  },
  PRMLRNENTRY: {
    imageUrl: '/images/products/Bronze_2x.webp',
    subtitle: 'FEM Growth Academy, FEM App, NTR App, DIGI App, WS App Community Social App, Instructor Led Training, OK Academy and Full Travel Access',
    title: { text: 'Bundle 5',color: '#DBBE5C' },
    fakeDiscountPercentage: 61,
    isBestSeller: true,
    description:
      '' +
      '          Elite ipsum dolor sit amet consectetur adipisicing elit. Eos deserunt maxime totam ratione omnis, optio excepturi odit blanditiis dicta accusantium alias inventore\n' +
      '          aperiam possimus amet, suscipit id ipsa aut voluptate. Expedita ipsam explicabo iusto nobis atque et fugit earum, aperiam perspiciatis quisquam eveniet, officia adipisci\n' +
      '          eaque recusandae soluta asperiores repellendus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos deserunt maxime totam ratione omnis, optio excepturi odit\n' +
      '          blanditiis dicta accusantium alias inventore aperiam possimus amet, suscipit id ipsa aut voluptate. Expedita ipsam explicabo iusto nobis atque et fugit earum, aperiam\n' +
      '          perspiciatis quisquam eveniet, officia adipisci eaque recusandae soluta asperiores repellendus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos deserunt\n' +
      '          maxime totam ratione omnis, optio excepturi odit blanditiis dicta accusantium alias inventore aperiam possimus amet, suscipit id ipsa aut voluptate. Expedita ipsam\n' +
      '          explicabo iusto nobis atque et fugit earum, aperiam perspiciatis quisquam eveniet, officia adipisci eaque recusandae soluta asperiores repellendus.\n' +
      '        ',

    bullets: [
      { text: 'Get 200+ classes across 15 categories (Language: English)' },
      { text: 'Get 4+ premium classes (Language: Multilingual)' },
      { text: 'New classes added every month' },
      { text: 'NTR, FEM, WS, and DIGI Apps' },
      { text: 'Ready-to-go questionnaires' },
      { text: 'PDF workbooks for every class' },
      { text: 'Assignments & assessments' },
      { text: 'Free certificates' },
      { text: 'Gamification', points: ['Collect points and badges', 'Move-up levels', 'Be featured high on the leader board'] },
      { text: 'Watch on desktop, phone, or TV' },
      { text: 'Learning paths' },
      { text: 'Community Social App' },
      { text: 'Instructor-led training', points: ['Live Calls with authors and instructors', 'Monday – Friday via video conference'] },
    ],
  },
};

import { CaseReducer, PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ICurrency } from '../types/currency';
import { defaultCurrency } from '@root/modules/currencies/constants/usd-currency';

export type CurrenciesState = {
  isLoading: boolean;
  items: ICurrency[];
  error: string | null;
  userCurrencyCode: string | null;
};

export namespace CurrenciesActions {
  export type FetchPending = PayloadAction;
  export type FetchFulfilled = PayloadAction<ICurrency[]>;
  export type FetchRejected = PayloadAction<string>;
  export type SetUserCurrency = PayloadAction<string>;
}

export type CurrenciesSliceReducer = {
  fetchPending: CaseReducer<CurrenciesState, CurrenciesActions.FetchPending>;
  fetchFulfilled: CaseReducer<CurrenciesState, CurrenciesActions.FetchFulfilled>;
  fetchRejected: CaseReducer<CurrenciesState, CurrenciesActions.FetchRejected>;
  setUserCurrency: CaseReducer<CurrenciesState, CurrenciesActions.SetUserCurrency>;
};

export const currenciesInitialState: CurrenciesState = {
  isLoading: false,
  items: [defaultCurrency],
  error: null,
  userCurrencyCode: 'USD',
};

export const currenciesSlice = createSlice<CurrenciesState, CurrenciesSliceReducer, 'currencies'>({
  name: 'currencies',
  initialState: currenciesInitialState,
  reducers: {
    fetchPending: (state) => {
      state.isLoading = true;
    },
    fetchFulfilled: (state, action) => {
      state.isLoading = false;
      state.items = [defaultCurrency, ...action.payload];
      state.error = null;
    },
    fetchRejected: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    setUserCurrency: (state, action) => {
      state.userCurrencyCode = action.payload;
    },
  },
});

import { authSelector, authSlice } from '@root/modules/auth/redux';
import { verifyCaptchaService } from '@root/modules/auth/services/captcha-verify.service';
import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

const Overlay = styled.div<{ passed: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(4px) brightness(25%);
  z-index: 99;
  visibility: ${({ passed }) => (passed ? 'hidden' : 'visible')};
`;

export const Captcha: FC = () => {
  const dispatch = useDispatch();
  const captchaPassed = useSelector(authSelector).captchaPassed;

  useEffect(() => {
    window.turnstile?.render('#turnstile-container', {
      sitekey: import.meta.env.REACT_APP_TURNSTILE_SITE_KEY,
      size: 'normal',
      callback: async function (token) {
        const response = await verifyCaptchaService(token);
        if (response.status === 200 && response.payload?.success) {
          sessionStorage.setItem('captchaPassed', 'true');
          dispatch(authSlice.actions.setCaptchaPassed(true));
        }
      },
    });
  }, [dispatch]);

  return (
    <Overlay passed={captchaPassed}>
      <div id='turnstile-container' />
    </Overlay>
  );
};

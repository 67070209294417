import { QueryFunction } from 'react-query';
import { getOrdersService, GetOrdersServiceData, GetOrdersServiceQuery } from '../services/get-orders.service';

export type GetOrdersQueryKey = ['orders', GetOrdersServiceQuery];

export const GET_ORDERS: QueryFunction<GetOrdersServiceData, GetOrdersQueryKey> = async ({ queryKey }) => {
  const [, query] = queryKey;

  const result = await getOrdersService(query);

  if (result.status === 200) {
    return result.payload;
  }

  throw new Error(result.payload);
};

import { FC } from 'react';

export const QuestionSquareInfo: FC = () => {
  return (
    <svg viewBox='0 0 40 40' version='1.1' xmlns='http://www.w3.org/2000/svg'>
      <defs>
        <linearGradient x1='100.229675%' y1='49.9999853%' x2='-0.229589451%' y2='49.9999853%' id='linearGradient-16565656'>
          <stop stopColor='#41C7FF' offset='0%' />
          <stop stopColor='#8C84FF' offset='100%' />
        </linearGradient>
      </defs>
      <g id='15-11' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='GUI---5.-Cards_Logo_Icons' transform='translate(-960.000000, -729.000000)'>
          <g id='Info' transform='translate(936.000000, 704.000000)'>
            <g id='question-3-square' transform='translate(24.000000, 25.000000)'>
              <path
                d='M28.8888889,40 L11.1111111,40 C4.97461389,40 0,35.0253861 0,28.8888889 L0,11.1111111 C0,4.97461389 4.97461389,0 11.1111111,0 L28.8888889,0 C35.0253861,0 40,4.97461389 40,11.1111111 L40,28.8888889 C40,35.0253861 35.0253861,40 28.8888889,40 Z'
                id='Path'
                fill='#FFFFFF'
              />
              <path
                d='M19.9965311,25.7777776 C20.7640137,25.7767817 21.5003494,26.0812209 22.043042,26.6239136 C22.5082058,27.0890773 22.7983269,27.6965082 22.8710983,28.3438771 L22.888,28.6613333 C22.8872375,30.1586354 21.7602802,31.382724 20.3136318,31.5387596 L19.999036,31.5555551 C18.4045254,31.5548634 17.1120919,30.2624298 17.1114002,28.6679191 C17.1107091,27.0734083 18.4020215,25.7798542 19.9965311,25.7777776 Z M28,15.8897333 C28,18.375976 26.7362474,20.6789801 24.6681763,22.0181568 L24.243974,22.2729098 L21.2950851,23.9112431 C20.0076733,24.6264989 18.3841904,24.1626747 17.6689346,22.8752629 C17.0047686,21.679809 17.3572582,20.1945797 18.4417764,19.4158295 L18.7049149,19.2491124 L21.6539854,17.6106782 C22.279014,17.2634907 22.6666667,16.604716 22.671822,16.0554696 C22.5945259,14.8141932 21.6005106,13.8468592 20.1815992,13.7757102 L19.8468395,13.775483 C18.7271322,13.7290011 17.7223799,14.4189592 17.34986,15.4515826 L17.2643275,15.7414072 C16.9314554,17.1760556 15.4985968,18.0692218 14.0639484,17.7363497 C12.6292999,17.4034776 11.7361337,15.9706191 12.0690058,14.5359706 C12.9248769,10.8472446 16.2845925,8.28967813 19.8100285,8.44852221 C24.0740417,8.21244238 27.7294257,11.4617095 28,15.8897333 Z'
                id='Combined-Shape'
                fill='url(#linearGradient-16565656)'
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IRemoteData } from '@root/shared/remote-data';
import { Id } from '@root/shared/constants';
import { authSlice } from '@root/modules/auth/redux';
import { IAddress } from '../../types/address';
import { CreateAddressDto } from '../../dtos/create-address.dto';

export interface IAddressesState extends IRemoteData<IAddress[]> {
  meta: {
    update: {
      id: Id | null;
    };
    create: {
      isOpen: boolean;
    };
    remove: {
      ids: Id[];
    };
  };
}

export namespace AddressesActions {
  export type FetchPending = PayloadAction;
  export type FetchFulfilled = PayloadAction<IAddress[]>;
  export type FetchRejected = PayloadAction<string>;

  export type CreatePending = PayloadAction<CreateAddressDto>;
  export type CreateFulfilled = PayloadAction<CreateAddressDto>;
  export type CreateRejected = PayloadAction<string>;

  export type RemovePending = PayloadAction<Id>;
  export type RemoveFulfilled = PayloadAction<Id>;
  export type RemoveRejected = PayloadAction<string>;

  export type UpdateOpened = PayloadAction<Id>;
  export type UpdateClosed = PayloadAction;

  export type CreateOpened = PayloadAction;
  export type CreateClosed = PayloadAction;
}

export type AddressesCaseReducer = {
  fetchPending: CaseReducer<IAddressesState, AddressesActions.FetchPending>;
  fetchFulfilled: CaseReducer<IAddressesState, AddressesActions.FetchFulfilled>;
  fetchRejected: CaseReducer<IAddressesState, AddressesActions.FetchRejected>;

  createPending: CaseReducer<IAddressesState, AddressesActions.CreatePending>;
  createFulfilled: CaseReducer<IAddressesState, AddressesActions.CreateFulfilled>;
  createRejected: CaseReducer<IAddressesState, AddressesActions.CreateRejected>;

  removePending: CaseReducer<IAddressesState, AddressesActions.RemovePending>;
  removeFulfilled: CaseReducer<IAddressesState, AddressesActions.RemoveFulfilled>;
  removeRejected: CaseReducer<IAddressesState, AddressesActions.RemoveRejected>;

  updateOpened: CaseReducer<IAddressesState, AddressesActions.UpdateOpened>;
  updateClosed: CaseReducer<IAddressesState, AddressesActions.UpdateClosed>;

  createOpened: CaseReducer<IAddressesState, AddressesActions.CreateOpened>;
  createClosed: CaseReducer<IAddressesState, AddressesActions.CreateClosed>;
};

export const addressesSlice = createSlice<IAddressesState, AddressesCaseReducer, 'addresses'>({
  name: 'addresses',
  initialState: {
    isLoading: false,
    data: [],
    error: null,
    meta: {
      update: {
        id: null,
      },
      create: {
        isOpen: false,
      },
      remove: {
        ids: [],
      },
    },
  },
  extraReducers: (builder) => {
    builder.addCase(authSlice.actions.fetchProfileFulfilled, (state) => {
      state.isLoading = true;
      state.error = null;
    });
  },
  reducers: {
    fetchPending: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    fetchFulfilled: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    fetchRejected: (state, action) => {
      state.isLoading = true;
      state.error = action.payload;
    },

    createPending: () => {},
    createFulfilled: (state) => {
      state.meta.update.id = null;
    },
    createRejected: () => {},

    removePending: (state, action) => {
      state.meta.remove.ids.push(action.payload);
    },
    removeFulfilled: (state, action) => {
      state.meta.remove.ids = state.meta.remove.ids.filter((id) => id !== action.payload);
      state.data = state.data.filter((item) => item.id !== action.payload);
    },
    removeRejected: (state, action) => {
      state.meta.remove.ids = state.meta.remove.ids.filter((id) => id !== action.payload);
    },

    updateOpened: (state, action) => {
      state.meta.update.id = action.payload;
    },
    updateClosed: (state) => {
      state.meta.update.id = null;
    },

    createOpened: (state) => {
      state.meta.create.isOpen = true;
    },
    createClosed: (state) => {
      state.meta.create.isOpen = false;
    },
  },
});

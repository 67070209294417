import { FC, useCallback, useState } from 'react';
import { Popover as BasePopover, PopoverProps } from 'react-tiny-popover';

import { theme } from '@root/infra/theme';

export type DropdownProps = Omit<PopoverProps, 'isOpen'> & { isOpen?: boolean; onVisibilityChange?: (visible: boolean) => void; disabled?: boolean; wrapperClassName?: string };

export const Dropdown: FC<DropdownProps> = ({ children, isOpen: baseIsOpen, onVisibilityChange, disabled, wrapperClassName, ...props }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const openDropdown = useCallback(() => {
    setIsOpen(true);
    onVisibilityChange?.(true);
  }, [onVisibilityChange]);

  const closeDropdown = useCallback(() => {
    setIsOpen(false);
    onVisibilityChange?.(false);
  }, [onVisibilityChange]);

  return (
    <BasePopover
      isOpen={typeof baseIsOpen === 'boolean' ? baseIsOpen : isOpen}
      onClickOutside={!disabled ? closeDropdown : undefined}
      containerStyle={{ boxShadow: theme.shadows[1] }}
      {...props}
    >
      <span className={wrapperClassName} onClick={!disabled ? openDropdown : undefined}>
        {children}
      </span>
    </BasePopover>
  );
};

import React, { lazy } from 'react';
import { ThemeProvider } from 'styled-components';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-multi-carousel/lib/styles.css';

import { StoreProvider } from '@root/infra/redux';
import { GlobalStyles, theme } from '@root/infra/theme';
import { Router } from '@root/infra/router';
import { ToastContainer } from '@root/shared/ui/components/toast.ui';
import { QueryProvider } from '@root/infra/query';
import GAScripts from './infra/analytics/containers/ga-scripts';

const LegalDocumentModal = lazy(() => import('@root/modules/meta/container/legal-document-modal').then(({ LegalDocumentModal }) => ({ default: LegalDocumentModal })));
const GlobalLoader = lazy(() => import('@root/infra/layout/components/global-loader').then(({ GlobalLoader }) => ({ default: GlobalLoader })));

function App() {
  return (
    <QueryProvider>
      <StoreProvider>
        <ThemeProvider theme={theme}>
          <GlobalStyles />
          <ToastContainer draggable={false} autoClose={5000} />
          <Router />
          <LegalDocumentModal />
          <GlobalLoader />
          <GAScripts />
        </ThemeProvider>
      </StoreProvider>
    </QueryProvider>
  );
}

export default App;

import { FC } from 'react';
import { baseIconProps } from './base-icon-props';

export const StatisticsProductsIcon: FC<any> = (props) => {
  return (
    <svg {...baseIconProps} viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2 3.66667C2 2.74562 2.74562 2 3.66667 2H22.3333C23.2544 2 24 2.74562 24 3.66667V22.3333C24 23.2544 23.2544 24 22.3333 24H3.66667C2.74562 24 2 23.2544 2 22.3333V3.66667ZM3.66667 0C1.64105 0 0 1.64105 0 3.66667V22.3333C0 24.359 1.64105 26 3.66667 26H22.3333C24.359 26 26 24.359 26 22.3333V3.66667C26 1.64105 24.359 0 22.3333 0H3.66667ZM6.70473 16.0718C6.19194 15.8667 5.60997 16.1161 5.40486 16.6289C5.19974 17.1417 5.44916 17.7236 5.96194 17.9287L12.6286 20.5954C12.867 20.6908 13.133 20.6908 13.3714 20.5954L20.0381 17.9287C20.5508 17.7236 20.8003 17.1417 20.5951 16.6289C20.39 16.1161 19.8081 15.8667 19.2953 16.0718L13 18.5899L6.70473 16.0718ZM12.6286 5.40486C12.867 5.30949 13.133 5.30949 13.3714 5.40486L20.0381 8.07152C20.4177 8.22339 20.6667 8.5911 20.6667 9C20.6667 9.40891 20.4177 9.77661 20.0381 9.92848L13.3714 12.5951C13.133 12.6905 12.867 12.6905 12.6286 12.5951L5.96194 9.92848C5.58228 9.77661 5.33333 9.40891 5.33333 9C5.33333 8.5911 5.58228 8.22339 5.96194 8.07152L12.6286 5.40486ZM9.02592 9L13 10.5896L16.9741 9L13 7.41037L9.02592 9ZM6.70473 12.0718C6.19194 11.8667 5.60997 12.1161 5.40486 12.6289C5.19974 13.1417 5.44916 13.7236 5.96194 13.9287L12.6286 16.5954C12.867 16.6908 13.133 16.6908 13.3714 16.5954L20.0381 13.9287C20.5508 13.7236 20.8003 13.1417 20.5951 12.6289C20.39 12.1161 19.8081 11.8667 19.2953 12.0718L13 14.5899L6.70473 12.0718Z'
        fill='url(#paint0_linear_1004_41584)'
      />
      <defs>
        <linearGradient id='paint0_linear_1004_41584' x1='26.0597' y1='-0.0597079' x2='-0.0596938' y2='-0.0597079' gradientUnits='userSpaceOnUse'>
          <stop stopColor='#41C7FF' />
          <stop offset='1' stopColor='#8C84FF' />
        </linearGradient>
      </defs>
    </svg>
  );
};

import { FC } from 'react';

import defaultIcon from '@root/assets/membership/no-level.svg';

import { IMembershipLevel } from '../types/membership-level';
import { MembershipUserIconUI } from './membership-user-icon.ui';

const { Wrapper, Icon } = MembershipUserIconUI;

export const MembershipUserIcon: FC<{ membership: IMembershipLevel }> = ({ membership }) => {
  return (
    <Wrapper color={membership.color}>
      <Icon src={membership?.iconImage || defaultIcon} alt='membership icon' />
    </Wrapper>
  );
};

import { fetcher } from '@root/infra/fetcher';
import type { IHttpResponse } from '@root/shared/http-service';
import { AxiosError } from 'axios';

import { IIdentityVerificationData } from '@root/modules/kyc/types/identity-verification';
import { KycIdentityVerificationMapper } from '@root/modules/kyc/mappers/kyc-verification.mapper';

export type Response = IHttpResponse<200, IIdentityVerificationData> | IHttpResponse<400, string>;

export const getVerificationStatusLevel1 = async (): Promise<Response> => {
  try {
    const response = await fetcher.get(`/kyc/level1/verification-status`);

    return {
      status: 200,
      payload: KycIdentityVerificationMapper.toDomain(response.data.data),
    };
  } catch (e) {
    const error = e as AxiosError;

    if (error.isAxiosError && error.response) {
      return {
        status: 400,
        payload: error.response.data.message,
      };
    }

    return {
      status: 400,
      payload: 'Bad request',
    };
  }
};

import { DashboardUI } from '../components/dashboard.ui';
import { useMembershipInfo } from '../hooks/useMembershipInfo';
import { useCurrencyConverter } from '@root/modules/currencies/hooks/use-currency-converter';
import { MembershipCard } from '@root/modules/membership/components/membership-card';
import { MembershipLevelsInfo } from '@root/modules/membership/components/membership-levels-info';
import { MembershipProgress } from '@root/modules/membership/components/membership-progress';
import { MembershipUserIcon } from '@root/modules/membership/components/membership-user-icon';
import { useOutsideClick } from '@root/shared/hooks';
import { BodyTextLarge, LoaderFill, BodyText, LinkButton } from '@root/shared/ui';
import clsx from 'clsx';
import { useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const { InfoCard, InfoCardDropdown, InfoCardDropdownButton } = DashboardUI;

export const MembershipInfo = () => {
  const { t } = useTranslation('dashboard', { keyPrefix: 'membershipInfo' });
  const dropdownWrapperRef = useRef<HTMLDivElement>(null);
  const { convert } = useCurrencyConverter();

  const [{ isLoading, isOpen, currentLevel, nextLevel, memberships, user }, { toggleDropdown, handleOutsideClick }] = useMembershipInfo();

  useOutsideClick(dropdownWrapperRef, handleOutsideClick);

  if (isLoading) {
    return (
      <InfoCard className='lg:border-r'>
        <LoaderFill />
      </InfoCard>
    );
  }

  if (!currentLevel || !memberships.length || !user) {
    return (
      <InfoCard className='lg:border-r'>
        <div className='p-4'>
          <BodyTextLarge className='font-bold mb-3 text-xl lg:text-base'>{t('no-membership.title')}</BodyTextLarge>
          <BodyText className='text-gray-600'>{t('no-membership.text')}</BodyText>
        </div>
      </InfoCard>
    );
  }

  return (
    <InfoCard isDropdownOpen={isOpen} ref={dropdownWrapperRef}>
      <div className='p-4 bg-gray-100'>
        <div className='flex flex-col sm:flex-row gap-2 sm:gap-4'>
          <div className='sm:mt-0.5'>
            <MembershipUserIcon membership={currentLevel} />
          </div>
          <div>
            <BodyTextLarge className='text-xl lg:text-base font-bold mb-2 lg:mb-1'>{currentLevel.label}</BodyTextLarge>
            <MembershipProgress spentAmount={user?.moneySpentAmount || 0} currentMembership={currentLevel} memberships={memberships} />
          </div>
        </div>
      </div>

      <div className='p-4 pt-0 lg:pt-0 lg:-mt-3 bg-gray-100'>
        <InfoCardDropdownButton isOpen={isOpen} toggle={toggleDropdown}>
          {t('about-your-membership')}
        </InfoCardDropdownButton>
      </div>

      {isOpen && (
        <InfoCardDropdown>
          <div className='max-w-[420px] mx-auto pt-1'>
            <MembershipCard firstName={user?.firstName} lastName={user?.lastName} membership={currentLevel} />
          </div>
          <div className='mx-4 mt-2 sm:max-w-[420px] sm:mx-auto lg:px-4 lg:max-w-none'>
            <MembershipLevelsInfo scaleCurrentLevel={true} shouldCalculateProgress={true} shouldGenerateGradient={true} shouldShowYouthLabel={true} />
          </div>

          <div className='p-4 pt-0 mt-5'>
            {nextLevel && (
              <BodyText className='mt-10'>
                <Trans
                  i18nKey='dashboard:membershipInfo.progress.reach-next-level'
                  components={{ bold: <b />, discount: <span className={clsx({ hidden: !nextLevel.addonsDiscount })} /> }}
                  values={{ nextLevel: nextLevel.name, money: convert(nextLevel.min - user?.moneySpentAmount, 'short', 0), discount: nextLevel.addonsDiscount }}
                />
              </BodyText>
            )}
          </div>

          <div className='p-4 py-3 border-t border-gray-300'>
            <Link to='/membership'>
              <LinkButton>{t('link-to-membership-page')}</LinkButton>
            </Link>
          </div>
        </InfoCardDropdown>
      )}
    </InfoCard>
  );
};

import { FC, useCallback, useState } from 'react';
import styled from 'styled-components';

import { PasswordStrength, PasswordStrengthProps, ShowPasswordButton, InputError, InputLabel, Input, InputProps } from '../fields';

export interface PasswordFieldProps extends InputProps {
  label: string;
  error?: string;
  passwordStrengthItems?: PasswordStrengthProps['items'];
  passwordStrengthLevel?: number;
  placeholder?: string;
}

export type PasswordInputType = 'password' | 'text';

const PasswordInputContainer = styled.div`
  position: relative;
`;

export const PasswordField: FC<PasswordFieldProps> = ({ label, error, passwordStrengthItems, passwordStrengthLevel, topLinkElement = null, ...inputProps }) => {
  const [inputType, setInputType] = useState<PasswordInputType>('password');

  const handleShowPassword = useCallback(() => {
    setInputType((prev) => (prev === 'text' ? 'password' : 'text'));
  }, []);

  return (
    <div>
      <PasswordInputContainer>
        <div className='w-full flex justify-between'>
          <div>{!!label && <InputLabel>{label}</InputLabel>}</div>
          <div>{topLinkElement}</div>
        </div>
        <Input {...inputProps} error={!!error} type={inputType} />
        <ShowPasswordButton withStrength={!!passwordStrengthItems?.length} handleClick={handleShowPassword} passwordInputType={inputType} />
        {!!passwordStrengthItems?.length && <PasswordStrength items={passwordStrengthItems} level={passwordStrengthLevel} />}
      </PasswordInputContainer>
      <InputError>{error}</InputError>
    </div>
  );
};

import * as uuid from 'uuid';

import defaultImage from '@root/assets/auth/background-login.webp';

import { IProductCategoryWithSub } from '../types/product-category';
import { normalizePlanSubtitle } from '../helpers/product-subtitle-normalizer';

export class ProductCategoryMapper {
  public static toDomain(obj): IProductCategoryWithSub {
    return {
      id: uuid.v1(),
      iconUrl: obj.iconUrl || '/images/membership/rookie.svg',
      imageUrl: '/images/membership/rookie.svg',
      imageUrlHeader: obj.imageUrl,
      name: obj.category,
      description: obj.description,
      shopUrl: obj.externalLink?.length ? obj.externalLink : `/products/${obj.urlParam}`,
      urlParam: obj.urlParam,
      subCategories: obj.subcategories?.map((category) => ({
        id: category.id,
        iconUrl: category.imageUrl || defaultImage,
        imageUrl: category.imageUrl || defaultImage,
        name: category.name,
        shopUrl: `/products/${obj.urlParam}/${category.urlParam}`,
        urlParam: category.urlParam,
        products: category?.plans
          ?.sort((a, b) => a.priority - b.priority)
          .map((plan) => ({
            id: plan.id,
            iconUrl: plan.imageUrl || defaultImage,
            imageUrl: plan.imageUrl || defaultImage,
            name: plan.name,
            titleColor: plan.color.toLowerCase().includes('fff') ? '#000' : plan.color,
            description: plan.isDigitalBundle ? normalizePlanSubtitle(plan.servicesInfo) : plan.serviceInfo,
            shopUrl: `/products/${obj.urlParam}/${category.urlParam}/${plan.id}`,
            shopId: plan.id,
            isDigitalBundle: plan.isDigitalBundle,
            isPhysicalBundle: plan.isPhysicalBundle,
            isAddon: plan.isAddon,
            isPhysicalAddon: plan.isPhysicalAddon,
            isAnnual: plan.isAnnual,
          })),
      })),
    };
  }
}

export const getNumberOfMonth = (recurringType: string): number => {
  try {
    if (recurringType === 'Monthly') {
      return 1;
    }

    if (recurringType === 'Annual') {
      return 12;
    }

    return Number(recurringType.split('x')[0]);
  } catch {
    return 0;
  }
};

export const updateProductNameWithRecurringType = (name: string, recurringType: string): string => {
  try {
    let productName = name;

    if (recurringType && !['Monthly', 'Annual'].includes(recurringType)) {
      const recurringTypeTitle = recurringType?.split('x');
      productName = `${productName} (${recurringTypeTitle[0]} Months)`;
    } else if (recurringType === 'Annual') {
      productName = `Annual ${productName.replace(recurringType, '').trim()}`;
    }

    return productName;
  } catch (e) {
    console.log(e);
    return name;
  }
};

import styled from 'styled-components';

export const H1 = styled.h1`
  font-size: 30px;
  line-height: 36px;

  @media screen and (min-width: 1140px) {
    font-size: 48px;
    line-height: 56px;
  }
`;

export const H2 = styled.h2`
  font-size: 28px;

  @media screen and (min-width: 1140px) {
    font-size: 36px;
  }
`;

export const H3 = styled.h3`
  font-size: 20px;

  @media screen and (min-width: 1140px) {
    font-size: 28px;
  }
`;

export const H4 = styled.h4`
  font-size: 20px;
  @media screen and (min-width: 1140px) {
    font-size: 24px;
  }
`;

export const H5 = styled.h5`
  font-size: 16px;

  @media screen and (min-width: 1140px) {
    font-size: 20px;
  }
`;

export const H6 = styled.h6`
  font-size: 16px;
`;

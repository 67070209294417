import { FC } from 'react';
import styled from 'styled-components';
import { Theme } from '@root/infra/theme';
import { CheckIcon } from '@root/shared/ui/icons/check-icon';
import { CloseIcon } from '@root/shared/ui/icons/close-icon';

export interface PointProps {
  children: any;
  success: boolean;
}

interface PointContainer {
  success: boolean;
}

const getColor = (success: boolean, theme: Theme) => {
  return success ? theme.colors.success[500] : theme.colors.danger[500];
};

const Container = styled.div<PointContainer>`
  display: flex;
  align-items: center;
  font-size: 13px;
  line-height: 18px;
  margin-bottom: 5px;
  color: ${({ theme, success }) => getColor(success, theme)};

  svg {
    width: 8px;
    height: auto;
    flex-shrink: 0;
    margin-right: 10px;
    fill: ${({ theme, success }) => getColor(success, theme)};
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const Point: FC<PointProps> = ({ success, children }) => {
  return (
    <Container success={success}>
      {success ? <CheckIcon /> : <CloseIcon />}

      {children}
    </Container>
  );
};

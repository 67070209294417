import styled from 'styled-components';

export const HEADER_HEIGHT = 80;

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.gray['100']};
  position: fixed;
  z-index: 1002;
  top: 0;
  left: 0;
  right: 0;
  box-shadow: ${({ theme }) => theme.shadows[2]};
`;

const Inner = styled.div`
  display: flex;
  align-items: center;
  height: ${() => HEADER_HEIGHT}px;
`;

const HeadLink = styled.a`
  padding: 4px;
  position: relative;
  transition: color 0.2s;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.text.colorful};

  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    height: 2px;
    width: 100%;
    background-color: transparent;
    left: 0;
    transition: background-color 0.2s;
  }

  &.active,
  &:hover {
    color: ${({ theme }) => theme.colors.text.colorful};
    overflow: unset;
  }

  &.active {
    &:after {
      display: block;
      background-color: ${({ theme }) => theme.colors.text.colorful};
    }
  }
`;

const Brand = styled.div`
  display: block;
  cursor: pointer;
  margin-right: 0.75em;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)}px;

  img {
    height: 32px;
    width: 32px;
  }

  span {
    color: #5d01a2;
  }
`;

const MenuToggle = styled.button`
  font-size: 24px;
  margin-left: ${({ theme }) => `-${theme.spacing(6)}px`};
  padding: 0 ${({ theme }) => `${theme.spacing(6)}px`};
`;

export const HeaderUI = { Wrapper, Inner, HeadLink, Brand, MenuToggle };

import styled from 'styled-components';

export const Label = styled.div`
  position: relative;
  border-radius: ${({ theme }) => theme.spacing(1.5)}px;
  background: ${({ theme }) => theme.colors.gray[200]};

  &::before {
    position: absolute;
    content: '';
    border: none;
    border-top-right-radius: ${({ theme }) => theme.spacing(3)}px;
    border-bottom-right-radius: ${({ theme }) => theme.spacing(3)}px;
    background: ${({ theme }) => theme.colors.gradients[1]};
    width: 4px;
    height: 80%;
    top: 10%;
    left: 0;
  }
`;

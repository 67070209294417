import { FC } from 'react';
import { Outlet } from 'react-router-dom';

import { useIsDesktop } from '@root/infra/device-type';

import { useSidebar } from '../hooks';
import { Header } from './header';
import { Sidebar } from './sidebar';
import { LiveChat } from '../components/live-chat/live-chat';
import { LayoutUI } from '../components/layout/layout.ui';

const { Wrapper, Content } = LayoutUI;

export const Layout: FC = () => {
  const [sidebar] = useSidebar();
  const isDesktop = useIsDesktop();

  return (
    <Wrapper sidebarIsOpen={sidebar.isOpen} isDesktop={isDesktop}>
      {isDesktop && <Sidebar />}
      <Header />
      <Content>
        <Outlet />
        <LiveChat />
      </Content>
    </Wrapper>
  );
};

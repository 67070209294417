import { TFunction } from "react-i18next";
import * as yup from 'yup';


export function checkInvalidCharacter(this: yup.TestContext, value: string | undefined, t: TFunction) {
  if (!value) return true;

  const emailRegex = /^[a-zA-Z0-9.!#$%&'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/;

  if (!emailRegex.test(value)) {
    const invalidCharacterCheck = getInvalidCharacter(t, 'emailSpecialCharsNotSupported', /[^a-zA-Z0-9.!#:$%&'*+\\/=?^_`{|}~@-]/).call(this, value);

    if (invalidCharacterCheck !== true) {
      return invalidCharacterCheck;
    }

    return this.createError({
      message: t('validation:matches.supportedMessage'),
    });
  }

  return true;
}

export function getInvalidCharacter(t: TFunction, typeOfMessage: string, forbiddenCharacters: RegExp) {
  return function (this: yup.TestContext, value: string | undefined): yup.ValidationError | boolean {
    if (!value) return true;
    const invalidCharMatch = value.match(forbiddenCharacters);

    if (invalidCharMatch) {
      const invalidChar = invalidCharMatch[0];
      return this.createError({
        message: t(`validation:matches.errorMessage.${typeOfMessage}`, { invalidChar }),
      });
    } else {
      return true;
    }
  };
}

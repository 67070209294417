import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import { paymentMethodsSelector } from '@root/modules/payment-methods/store/payment-methods.selector';

export const usePaymentMethodsInfo = () => {
  const [isOpen, setIsOpen] = useState(false);

  const defaultPaymentMethod = useSelector(paymentMethodsSelector.defaultPaymentMethod);
  const isLoading = useSelector(paymentMethodsSelector.isLoading);

  const toggleDropdown = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  const handleOutsideClick = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const state = { isOpen, isLoading, defaultPaymentMethod };
  const handlers = { toggleDropdown, handleOutsideClick };

  return [state, handlers] as [typeof state, typeof handlers];
};

import { MetaResponseData } from "../services";

export class MetaMapper {
  public static toDomain(meta): MetaResponseData {
    return {
      legalDocuments: meta.legalDocuments,
      regions: meta.regions,
      currentInstance: meta.currentInstance,
      settings: meta.settings,
      isTwilioEnabled: meta.isTwilioEnabled,
      companyInfo: {
        name: meta.companyName,
        email: meta.email,
        phone: meta.phone,
        registrationNumber: meta.registrationNumber,
        address: meta.address,
        socialMedia: meta.socialMedia,
        isShowOnContactPage: meta.isShown
      },
    };
  }
}

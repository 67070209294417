import { FC } from 'react';

export const ActionsPaymentsIcon: FC<any> = (props) => {
  return (
    <svg width='100%' height='100%' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='20' cy='20' r='20' fill='url(#paint0_linear_1004_41702)' />
      <circle cx='20' cy='20' r='20' fill='url(#paint1_linear_1004_41702)' />
      <path
        d='M7.61727 9.96605C6.3261 10.0046 5.1961 11.0904 5.12063 12.3828C5.09852 12.7734 5.07813 13.164 5.05946 13.5546C5.82407 14.1235 6.59688 14.6983 7.37618 15.2782C6.56258 15.8788 5.74899 16.4766 4.93711 17.0703C4.86071 20.5859 4.9218 24.1015 5.12055 27.6171C5.19602 28.9094 6.32602 29.9953 7.61719 30.0339C9.57032 30.0894 11.5234 30.1318 13.4766 30.1611C13.2591 23.387 13.2591 16.6129 13.4766 9.83887C11.5235 9.86816 9.57039 9.91059 7.61727 9.96605Z'
        fill='url(#paint2_linear_1004_41702)'
      />
      <path
        d='M32.3828 9.96607C31.6016 9.94389 30.8203 9.92373 30.0391 9.90576C30.3737 16.6353 30.3737 23.3647 30.0391 30.0943C30.8203 30.0762 31.6016 30.0562 32.3828 30.034C33.674 29.9954 34.804 28.9094 34.8795 27.6172C35.0782 24.1016 35.1394 20.586 35.0629 17.0704C34.2511 16.4766 33.4374 15.8789 32.6238 15.2783C33.4031 14.6985 34.176 14.1236 34.9405 13.5547C34.9219 13.1641 34.9015 12.7735 34.8794 12.3829C34.804 11.0905 33.674 10.0047 32.3828 9.96607Z'
        fill='url(#paint3_linear_1004_41702)'
      />
      <path
        d='M30.039 9.90575C24.5182 9.77848 18.9974 9.75621 13.4765 9.83887C12.1852 9.85911 11.0872 10.9477 11.0407 12.2614C10.8679 17.4204 10.8679 22.5795 11.0407 27.7386C11.0872 29.0524 12.1852 30.1407 13.4765 30.1611C18.9974 30.2438 24.5182 30.2214 30.039 30.0942C31.3303 30.0629 32.4475 28.9752 32.5113 27.6729C32.7527 22.5576 32.7527 17.4423 32.5113 12.327C32.4475 11.0248 31.3303 9.93715 30.039 9.90575Z'
        fill='url(#paint4_linear_1004_41702)'
      />
      <path
        d='M34.9405 13.5546C34.1479 13.5375 33.3552 13.5218 32.5627 13.5074C31.7936 14.0833 31.0169 14.6644 30.2344 15.2497C31.0448 15.852 31.8557 16.4521 32.6655 17.0488C33.4646 17.0553 34.2638 17.0624 35.0628 17.0703C35.0373 15.8984 34.9966 14.7265 34.9405 13.5546Z'
        fill='url(#paint5_linear_1004_41702)'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.0042 13.452C18.1905 13.3586 25.3768 13.3771 32.563 13.5075C32.6102 14.6879 32.6445 15.8684 32.6659 17.0489C25.4208 16.9896 18.1758 16.9812 10.9308 17.0236C10.9307 17.0236 10.9307 17.0237 10.9307 17.0237C8.93297 17.0353 6.93523 17.0509 4.9375 17.0703C4.96297 15.8984 5.00375 14.7266 5.05977 13.5547C7.0412 13.5119 9.02263 13.4777 11.0042 13.452C11.0042 13.452 11.0042 13.452 11.0042 13.452Z'
        fill='url(#paint6_linear_1004_41702)'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.9119 19.4047L10.9119 19.4047C15.3693 19.3995 19.8265 19.3982 24.2838 19.4006C24.2848 20.1997 24.2838 20.9989 24.281 21.7982C19.8267 21.8056 15.3724 21.8014 10.9181 21.7858L10.9181 21.7857L10.918 21.7858C10.1178 21.7829 9.31765 21.7797 8.51749 21.7762C8.50976 20.9868 8.50718 20.1973 8.50976 19.4079C9.31046 19.4066 10.1112 19.4056 10.9119 19.4047ZM31.4902 19.4078C31.4928 20.1973 31.4902 20.9868 31.4825 21.7762C29.8821 21.7834 28.2818 21.789 26.6814 21.7931C26.6859 20.9961 26.6874 20.1992 26.6859 19.4022C28.2873 19.4036 29.8888 19.4054 31.4902 19.4078Z'
        fill='url(#paint7_linear_1004_41702)'
      />
      <path
        d='M8.54406 23.5525C8.55961 24.342 8.58023 25.1315 8.60609 25.9209C8.61687 26.2479 8.89273 26.5173 9.22094 26.5225C9.81539 26.5317 10.4098 26.5402 11.0043 26.5479C11.3837 25.9579 11.7677 25.3659 12.1552 24.7726C11.7463 24.1728 11.3378 23.5739 10.9306 22.9763C10.3313 22.9728 9.73195 22.969 9.13266 22.9647C8.80172 22.9625 8.53758 23.2257 8.54406 23.5525H8.54406Z'
        fill='url(#paint8_linear_1004_41702)'
      />
      <path
        d='M10.9307 22.9764C10.946 24.167 10.9706 25.3575 11.0043 26.5481C11.9951 26.561 12.9858 26.5717 13.9766 26.5803C14.3048 26.5832 14.5664 26.3172 14.5614 25.9865C14.549 25.1883 14.5392 24.3901 14.5318 23.5919C14.5287 23.2613 14.2584 22.9922 13.9274 22.991C12.9284 22.9871 11.9295 22.9823 10.9307 22.9764L10.9307 22.9764Z'
        fill='url(#paint9_linear_1004_41702)'
      />
      <defs>
        <linearGradient id='paint0_linear_1004_41702' x1='20' y1='48.2843' x2='48.2843' y2='20' gradientUnits='userSpaceOnUse'>
          <stop stopColor='#560DFF' />
          <stop offset='1' stopColor='#73ECFF' />
        </linearGradient>
        <linearGradient id='paint1_linear_1004_41702' x1='40.0919' y1='-0.0918582' x2='-0.0918367' y2='-0.0918582' gradientUnits='userSpaceOnUse'>
          <stop stopColor='#41C7FF' />
          <stop offset='1' stopColor='#8C84FF' />
        </linearGradient>
        <linearGradient id='paint2_linear_1004_41702' x1='29.6679' y1='121.04' x2='107.367' y2='21.6372' gradientUnits='userSpaceOnUse'>
          <stop stopColor='#C9EFFF' />
          <stop offset='1' stopColor='#C9EFFF' />
        </linearGradient>
        <linearGradient id='paint3_linear_1004_41702' x1='44.6451' y1='120.376' x2='121.272' y2='62.1699' gradientUnits='userSpaceOnUse'>
          <stop stopColor='#C9EFFF' />
          <stop offset='1' stopColor='#C9EFFF' />
        </linearGradient>
        <linearGradient id='paint4_linear_1004_41702' x1='-26.1285' y1='142.705' x2='72.8311' y2='53.4475' gradientUnits='userSpaceOnUse'>
          <stop stopColor='#C9EFFF' />
          <stop offset='1' stopColor='white' />
        </linearGradient>
        <linearGradient id='paint5_linear_1004_41702' x1='38.2749' y1='21.8772' x2='47.6967' y2='7.57358' gradientUnits='userSpaceOnUse'>
          <stop stopColor='#389FFF' />
          <stop offset='0.1139' stopColor='#45AFFA' />
          <stop offset='0.5397' stopColor='#73E9E7' />
          <stop offset='0.7447' stopColor='#85FFE0' />
          <stop offset='0.8044' stopColor='#9DFFE6' />
          <stop offset='0.9298' stopColor='#DAFFF6' />
          <stop offset='1' stopColor='white' />
        </linearGradient>
        <linearGradient id='paint6_linear_1004_41702' x1='64.4088' y1='30.2379' x2='70.0322' y2='-11.5608' gradientUnits='userSpaceOnUse'>
          <stop stopColor='#389FFF' />
          <stop offset='0.1139' stopColor='#45AFFA' />
          <stop offset='0.5397' stopColor='#73E9E7' />
          <stop offset='0.7447' stopColor='#85FFE0' />
          <stop offset='0.8044' stopColor='#9DFFE6' />
          <stop offset='0.9298' stopColor='#DAFFF6' />
          <stop offset='1' stopColor='white' />
        </linearGradient>
        <linearGradient id='paint7_linear_1004_41702' x1='7.61607' y1='27.8132' x2='10.8382' y2='8.22812' gradientUnits='userSpaceOnUse'>
          <stop stopColor='#8CDDFF' />
          <stop offset='1' stopColor='#C9EFFF' />
        </linearGradient>
        <linearGradient id='paint8_linear_1004_41702' x1='19.0467' y1='34.6043' x2='37.8484' y2='15.5069' gradientUnits='userSpaceOnUse'>
          <stop stopColor='#389FFF' />
          <stop offset='0.1139' stopColor='#45AFFA' />
          <stop offset='0.5397' stopColor='#73E9E7' />
          <stop offset='0.7447' stopColor='#85FFE0' />
          <stop offset='0.8554' stopColor='#9DFFE6' />
          <stop offset='1' stopColor='#C2FFF0' />
        </linearGradient>
        <linearGradient id='paint9_linear_1004_41702' x1='19.349' y1='39.0858' x2='40.2706' y2='17.8506' gradientUnits='userSpaceOnUse'>
          <stop stopColor='#389FFF' />
          <stop offset='0.1139' stopColor='#45AFFA' />
          <stop offset='0.5397' stopColor='#73E9E7' />
          <stop offset='0.7447' stopColor='#85FFE0' />
          <stop offset='0.8554' stopColor='#9DFFE6' />
          <stop offset='1' stopColor='#C2FFF0' />
        </linearGradient>
      </defs>
    </svg>
  );
};

import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { createFetchingReducers } from '@root/shared/redux';
import { IRemoteData } from '@root/shared/remote-data';

import { IProductCategoryWithSub } from '../../types/product-category';

export type IProductCategoriesState = IRemoteData<IProductCategoryWithSub[]>;

export namespace ProductCategoriesActions {
  export type FetchPending = PayloadAction;
  export type FetchFulfilled = PayloadAction<IProductCategoryWithSub[]>;
  export type FetchRejected = PayloadAction<string>;
}

export type ProductCategoriesSliceReducer = {
  fetchPending: CaseReducer<IProductCategoriesState, ProductCategoriesActions.FetchPending>;
  fetchFulfilled: CaseReducer<IProductCategoriesState, ProductCategoriesActions.FetchFulfilled>;
  fetchRejected: CaseReducer<IProductCategoriesState, ProductCategoriesActions.FetchRejected>;
};

export const productCategoriesState: IProductCategoriesState = {
  isLoading: false,
  data: [],
  error: null,
};

export const productCategoriesSlice = createSlice<IProductCategoriesState, ProductCategoriesSliceReducer, 'productCategories'>({
  name: 'productCategories',
  initialState: productCategoriesState,
  reducers: createFetchingReducers<
    IProductCategoriesState,
    ProductCategoriesActions.FetchPending,
    ProductCategoriesActions.FetchFulfilled,
    ProductCategoriesActions.FetchRejected
  >(),
});

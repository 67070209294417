import { FC } from 'react';

export const StackIcon: FC = (props) => {
  return (
    <svg width='1em' height='1em' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg' {...props} style={{scale: '1.25'}}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.49731 8.33236C2.49731 7.31983 3.31813 6.49902 4.33065 6.49902H13.669C14.6815 6.49902 15.5023 7.31984 15.5023 8.33236V13.669C15.5023 14.6815 14.6815 15.5024 13.669 15.5024H4.33065C3.31812 15.5024 2.49731 14.6815 2.49731 13.669V8.33236ZM4.33065 7.49902C3.87041 7.49902 3.49731 7.87212 3.49731 8.33236V13.669C3.49731 14.1293 3.87041 14.5024 4.33065 14.5024H13.669C14.1292 14.5024 14.5023 14.1293 14.5023 13.669V8.33236C14.5023 7.87212 14.1292 7.49902 13.669 7.49902H4.33065Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.16479 4.99829C4.16479 4.72215 4.38865 4.49829 4.66479 4.49829H13.3351C13.6112 4.49829 13.8351 4.72215 13.8351 4.99829C13.8351 5.27443 13.6112 5.49829 13.3351 5.49829H4.66479C4.38865 5.49829 4.16479 5.27443 4.16479 4.99829Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.49878 2.99731C5.49878 2.72117 5.72264 2.49731 5.99878 2.49731H12.6682C12.9444 2.49731 13.1682 2.72117 13.1682 2.99731C13.1682 3.27346 12.9444 3.49731 12.6682 3.49731H5.99878C5.72264 3.49731 5.49878 3.27346 5.49878 2.99731Z'
        fill='currentColor'
      />
    </svg>
  );
};

import styled from 'styled-components';
import { Theme } from '@root/infra/theme';

export type LabelBadgeColor = 'default' | 'primary' | 'secondary' | 'info' | 'warning' | 'danger' | 'light';

export type LabelBadgeSize = 1 | 2 | 3 | 4 | 5;

export interface LabelBadgeExtraProps {
  color?: LabelBadgeColor;
  size?: LabelBadgeSize;
}

const getBgColor = (theme: Theme, color?: LabelBadgeColor) => {
  switch (color) {
    case 'primary':
      return theme.colors.primary['200'];
    case 'secondary':
      return theme.colors.success['200'];
    case 'info':
      return theme.colors.info['200'];
    case 'warning':
      return theme.colors.warning['500'];
    case 'danger':
      return theme.colors.danger['400'];
    case 'light':
      return theme.colors.gray['100'];

    default:
      return theme.colors.gray['300'];
  }
};

const getBorderRadius = (theme: Theme, size?: LabelBadgeSize): string => {
  switch (size) {
    case 1:
    case 2:
    case 3:
      return theme.spacing(1.5) + 'px';
    default:
      return theme.spacing(2) + 'px';
  }
};

const getPadding = (theme: Theme, size?: LabelBadgeSize): string => {
  switch (size) {
    case 1:
      return `${theme.spacing(0.25)}px ${theme.spacing(1.25)}px`;
    case 2:
      return `${theme.spacing(0.75)}px ${theme.spacing(2)}px`;
    case 3:
      return `${theme.spacing(1.25)}px ${theme.spacing(2.5)}px`;
    case 5:
      return `${theme.spacing(1.5)}px ${theme.spacing(2.5)}px`;
    default:
      return `${theme.spacing(1.25)}px ${theme.spacing(2.5)}px`;
  }
};

const getFontSize = (theme: Theme, size?: LabelBadgeSize): string => {
  switch (size) {
    case 5:
      return '16px';
    case 2:
    case 1:
      return '12px';
    default:
      return '14px';
  }
};

export const LabelBadge = styled.div<LabelBadgeExtraProps>`
  background: ${({ theme, color }) => getBgColor(theme, color)};
  border-radius: ${({ theme, size }) => getBorderRadius(theme, size)};
  padding: ${({ theme, size }) => getPadding(theme, size)};
  font-size: ${({ theme, size }) => getFontSize(theme, size)};
  color: ${({ theme }) => theme.colors.gray[1000]};
  text-transform: uppercase;
  font-weight: bold;
  box-sizing: border-box;
  align-items: center;
  display: inline-flex;
  transition: 0.2s, color 0.2s;
  justify-content: center;
  z-index: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

LabelBadge.defaultProps = {
  size: 1,
  color: 'default',
};

export const smoothScroll = (element: Element, direction: 'left' | 'right' = 'left', amount: number) => {
  const scrollStep = amount / 10;
  const scrollStepCeil = Math.ceil(scrollStep);
  const lastStepWidth = (scrollStepCeil - scrollStep) * 10;
  let currentStep = 0;
  let interval;

  interval = setInterval(() => {
    const isLastStep = scrollStepCeil === currentStep;

    if (direction === 'left') {
      if (!isLastStep) {
        element.scrollLeft -= 10;
      } else {
        element.scrollLeft -= lastStepWidth;
      }
    }

    if (direction === 'right') {
      if (!isLastStep) {
        element.scrollLeft += 10;
      } else {
        element.scrollLeft += lastStepWidth;
      }
    }

    currentStep++;

    if (currentStep >= scrollStep) {
      clearInterval(interval);
    }
  }, 10);
};

import { FC, ReactNode } from 'react';
import { EmailAutocompleteDropdown } from '@root/shared/ui';
import { Input, InputProps } from './input';
import { InputError } from './input-error';
import { InputLabel } from './input-label';
import { ReactI18NextChild } from 'react-i18next';

export interface TextFieldProps extends InputProps {
  label?: string;
  error?: string;
  hideError?: boolean;
  emailAutocomplete?: boolean;
  itemClassName?: string;
  extra?: ReactNode;
  topLinkElement?: ReactI18NextChild;
}
export const TextField: FC<TextFieldProps> = ({ label, error, itemClassName, hideError, extra, topLinkElement = null, emailAutocomplete, ...inputProps }) => {
  const isEmailAutocomplete = inputProps.name === 'email' && emailAutocomplete;

  return (
    <div className={itemClassName}>
      <div className='w-full flex justify-between'>
        <div>{!!label && <InputLabel>{label}</InputLabel>}</div>
        <div>{topLinkElement}</div>
      </div>
      <div className='relative'>
        {isEmailAutocomplete ? (
          <EmailAutocompleteDropdown {...inputProps} value={inputProps.value} setFieldValue={inputProps.setFieldValue} onBlur={inputProps.onBlur} onChange={inputProps.onChange} />
        ) : (
          <Input {...inputProps} error={!!error} />
        )}
        <div className='absolute top-0 right-0 bottom-0'>{extra}</div>
      </div>
      {!hideError && <InputError>{error}</InputError>}
    </div>
  );
};

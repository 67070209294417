import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Footnote } from '@root/shared/ui';

import { membershipSelector } from '../redux/membership.selector';
import { useCalculateProgress } from '../hooks/use-calculate-progress';
import { IMembershipLevel } from '../types/membership-level';

import { MembershipItemContent, MembershipProgressItem, MembershipProgressWrapper } from './membership-progress.ui';
import { PriceItem } from '@root/modules/currencies/components/price-item';

type Props = {
  spentAmount: number;
  currentMembership: IMembershipLevel | null;
  memberships: IMembershipLevel[];
};

export const MembershipProgress: FC<Props> = ({ spentAmount, currentMembership }) => {
  const { t } = useTranslation();
  const memberships = useSelector(membershipSelector);

  const membershipsWithProgress = useCalculateProgress(memberships, spentAmount, true);

  const nextLevel = currentMembership ? memberships.find((item) => item.priority === currentMembership?.priority + 1) : memberships[0];
  const shouldShowCrown = nextLevel?.name !== memberships[0].name;

  const valueToNextLevel = nextLevel && nextLevel?.min - spentAmount > 0 ? nextLevel?.min - spentAmount : 0 || 0;

  return (
    <div className='w-full flex gap-4 items-end justify-between'>
      <div className='w-full mb-1'>
        <MembershipProgressWrapper count={memberships.length}>
          {membershipsWithProgress.map((item) => (
            <MembershipProgressItem key={item.name}>
              <MembershipItemContent progress={item.progress} gradient={item.gradient} />
            </MembershipProgressItem>
          ))}
        </MembershipProgressWrapper>

        {nextLevel ? (
          <Footnote lineHeight={16} className='mt-2'>
            {shouldShowCrown ? '👑' : ''}{' '}
            <span className='ml-1 font-bold'>
              <PriceItem value={valueToNextLevel} type='long' rounding={0} />
            </span>{' '}
            {t('common:to reach next level', { title: nextLevel.label })}
          </Footnote>
        ) : (
          <Footnote lineHeight={16} className='font-bold mt-2'>
            👑 {t('You have the highest Level')}
          </Footnote>
        )}
      </div>
    </div>
  );
};

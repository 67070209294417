import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { IncreaseEWalletBalanceModal } from '@root/modules/payment-methods/containers/increase-e-wallet-balance-modal';
import { LayoutContainer } from '@root/infra/layout';
import { H1 } from '@root/shared/ui';

import { StatisticCard } from '../components/statistic-card';
import { useStatistics } from '../hooks/useStatistics';

export const Statistics = () => {
  const { t } = useTranslation('dashboard', { keyPrefix: 'statistics' });
  const [{ items, openedModalType }, { closeModal }] = useStatistics();

  return (
    <Fragment>
      <LayoutContainer className='pb-4 pt-10 lg:pt-9 lg:pb-0'>
        <H1 className='text-2xl font-bold mb-6 lg:mb-4'>{t('title')}</H1>
        <div className='grid grid-cols-1 lg:grid-cols-3 gap-4 lg:gap-[30px]'>
          {Object.keys(items).map((item, index) => (
            <StatisticCard {...items[item]} key={`${index}_${items[item]['title']}`} />
          ))}
        </div>
      </LayoutContainer>

      {openedModalType === 'topup' && <IncreaseEWalletBalanceModal isOpen={true} close={closeModal} />}
    </Fragment>
  );
};

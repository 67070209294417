import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import { authSelector } from '@root/modules/auth/redux';
import { pageLinks } from '@root/shared/routing';
import { KYC_STATUSES } from '../../enums/kyc.statuses';
import { selectKycLevel1Data, selectKycLevel2Data, selectKycMeta, selectKycSettings } from '../../redux/kyc.selector';
import { useKycCommands } from './use-kyc-commands';
import { useLocation } from 'react-router';

export const useIdentityVerificationStep = () => {
  const { user } = useSelector(authSelector);
  const level1Data = useSelector(selectKycLevel1Data);
  const level2Data = useSelector(selectKycLevel2Data);
  const settings = useSelector(selectKycSettings);
  const kycMeta = useSelector(selectKycMeta);
  const location = useLocation();
  const [kycVisited, setKycVisited] = useState<boolean>(false);
  const [, { showPage, enableNavigation }] = useKycCommands();

  const status = level1Data?.verificationStatus;
  const level2Status = level2Data?.verificationStatus;
  const kycLevel2isEnabled = settings?.level2.enabled || false;
  const userHasUnverifiedProduct = user?.unverifiedProducts.has;

  const identityController = useCallback(
    (nextStepCb: () => void) => {
      console.log('-- 2.run identity verification step');

      if (status === KYC_STATUSES.NOT_STARTED) {
        // start level2 kyc if user can do it
        if (level2Status && [KYC_STATUSES.PROCESSING, KYC_STATUSES.PENDING].includes(level2Status)) {
          return nextStepCb();
        }

        if (!userHasUnverifiedProduct) {
          enableNavigation();
          return showPage(pageLinks.home);
        }
      }

      if ((status && [KYC_STATUSES.PENDING, KYC_STATUSES.PROCESSING].includes(status)) || kycMeta.isAnotherKyc1Started) {
        return showPage(pageLinks.kycIdentityVerificationProcessing);
      }

      if (status && [KYC_STATUSES.ERROR].includes(status)) {
        return showPage(pageLinks.kycIdentityVerificationFailed);
      }

      if (status && [KYC_STATUSES.ACCEPTABLE, KYC_STATUSES.SUCCESS].includes(status) && kycLevel2isEnabled && level2Status && level2Status !== KYC_STATUSES.NOT_STARTED) {
        return nextStepCb();
      }

      if (status && [KYC_STATUSES.ACCEPTABLE].includes(status) && (!kycVisited || location.pathname === '/kyc')) {
        showPage(pageLinks.kycIdentityVerificationSuccess);
        enableNavigation();
        setKycVisited(true);
        return;
      }

      if (status && [KYC_STATUSES.SUCCESS].includes(status)) {
        if (
          (kycLevel2isEnabled &&
            level2Status &&
            [KYC_STATUSES.PROCESSING, KYC_STATUSES.ACCEPTABLE, KYC_STATUSES.SUCCESS, KYC_STATUSES.ERROR, KYC_STATUSES.PENDING].includes(level2Status)) ||
          kycMeta.isKyc2StartedFromKyc1Success
        ) {
          // run kycLevel2 checker
          return nextStepCb();
        } else {
          showPage(pageLinks.kycIdentityVerificationSuccess);
          return enableNavigation();
        }
      }

      enableNavigation();
      console.log('-- 2. identity verification step --- end');
      return;
    },
    [
      enableNavigation,
      showPage,
      kycLevel2isEnabled,
      status,
      kycMeta.isAnotherKyc1Started,
      kycMeta.isKyc2StartedFromKyc1Success,
      level2Status,
      kycVisited,
      location.pathname,
      userHasUnverifiedProduct,
    ],
  );

  return identityController;
};

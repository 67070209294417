import { fetcher } from '@root/infra/fetcher';
import { INSTANCE_TYPES } from '@root/modules/auth/constants/instance-types';
import { LoginDataMapper } from '@root/modules/auth/mappers/login-data.mapper';
import type { IHttpResponse } from '@root/shared/http-service';
import { isDevelopmentMode } from '@root/shared/utils';
import { AxiosError } from 'axios';
import { AuthTokensMapper } from '../mappers';
import { IAuth } from './../types';

export type SignInFinalServiceResponse = IHttpResponse<200, IAuth> | IHttpResponse<400, string>;

export type SignInFinalBody = { token: string };

export const signInFinalService = async ({ token }: SignInFinalBody): Promise<SignInFinalServiceResponse> => {
  try {
    const response = await fetcher.get(`/auth/signin-final/?initiator=customer_portal&instanceType=${INSTANCE_TYPES.CUSTOMER_PORTAL}&devMode=${isDevelopmentMode}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return {
      status: 200,
      payload: {
        loginData: LoginDataMapper.toDomain(response.data.data),
        tokens: AuthTokensMapper.toDomain(response.data.data),
      },
    };
  } catch (e) {
    const error = e as AxiosError;
    console.error(e);

    if (error.isAxiosError && error.response) {
      return {
        status: 400,
        payload: error.response.data.message,
      };
    }

    return {
      status: 400,
      payload: 'Bad request',
    };
  }
};

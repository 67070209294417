import { useMemo, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';

import { Dictionary } from '@root/shared/types';
import { StatisticsOrdersIcon } from '@root/shared/ui/icons/statistics-orders-icon';
import { StatisticsProductsIcon } from '@root/shared/ui/icons/statistics-products-icon';
import { StatisticsTopupIcon } from '@root/shared/ui/icons/statistics-topup-icon';
import { purchasedServicesSelector } from '@root/modules/products/redux/services/services.selector';
import { eWalletSelector } from '@root/modules/e-wallet/store/e-wallet.selector';
import { OrdersTabKey } from '@root/modules/orders/constants/orders-tab-key';

import { IStatisticsCard } from '../components/statistic-card';
import { useOrdersStatistics } from '../hooks/useOrdersStatistics';

export type StatisticsModalType = 'topup' | null;

export const useStatistics = () => {
  const { t } = useTranslation('dashboard', { keyPrefix: 'statistics.cards' });
  const navigate = useNavigate();

  const { data: productsData, isLoading: isProductsLoading } = useSelector(purchasedServicesSelector);
  const { data: eWalletData, isLoading: isEWalletLoading } = useSelector(eWalletSelector.self);
  const [{ totalOrders, totalNewOrders, isLoading: isOrdersLoading }] = useOrdersStatistics();

  const [openedModalType, setOpenedModalType] = useState<StatisticsModalType>(null);

  const openModal = useCallback((type: Exclude<StatisticsModalType, null>) => {
    setOpenedModalType(type);
  }, []);

  const closeModal = useCallback(() => {
    setOpenedModalType(null);
  }, []);

  const items: Dictionary<IStatisticsCard> = useMemo(
    () => ({
      products: {
        title: t('products.title', { amount: productsData.length || 0 }),
        description: t('products.description'),
        buttonText: t('products.button'),
        buttonAction: () => navigate('/products'),
        icon: <StatisticsProductsIcon />,
      },
      orders: {
        title: t('orders.title', { amount: totalNewOrders > 0 ? `+${totalNewOrders}` : 0 }),
        description: t('orders.description', { amount: totalOrders }),
        buttonText: t('orders.button'),
        buttonAction: () => {
          navigate('/orders', { state: { openedTab: OrdersTabKey.RECEIVED } });
        },
        icon: <StatisticsOrdersIcon />,
      },
      topup: {
        title: t('wallet.title', { amount: Number(eWalletData.point).toFixed(1) }),
        description: t('wallet.description', { amount: Number(eWalletData.gifted).toFixed(1) }),
        buttonText: t('wallet.button'),
        buttonAction: () => openModal('topup'),
        icon: <StatisticsTopupIcon />,
      },
    }),
    [t, navigate, openModal, productsData, eWalletData, totalOrders, totalNewOrders],
  );

  const state = { items, isLoading: isProductsLoading || isEWalletLoading || isOrdersLoading, openedModalType };
  const handlers = { openModal, closeModal };

  return [state, handlers] as [typeof state, typeof handlers];
};

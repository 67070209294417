import { takeLatest } from '@redux-saga/core/effects';

import { authSlice } from '@root/modules/auth/redux';

import { removeAllItemsFromSessionStorage } from '../helpers/storage-utils';
import { CLOSED_NOTIFICATIONS_KEY } from '../constants/closed-notification-key';

export function* handleClear() {
  yield removeAllItemsFromSessionStorage(CLOSED_NOTIFICATIONS_KEY);
}

export function* notificationsFlowSaga() {
  yield takeLatest([authSlice.actions.loggedOut.type], handleClear);
}

import { AbsolutePortal } from '@root/infra/portals';
import { useLegalDocument } from '@root/modules/meta/hooks/use-legal-document';
import { getCookie, setCookie } from '@root/shared/cookies';
import { BodyText, Button, H5 } from '@root/shared/ui';
import { add } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';

const CookiesWrapper = styled.div`
  z-index: 1030;
  position: fixed;
  left: 0;
  bottom: 0;
  box-shadow: ${({ theme }) => theme.shadows[8]};
`;

const CookieButton = styled(Button)`
  padding: ${({ theme }) => theme.spacing(4)}px ${({ theme }) => theme.spacing(19)}px !important;
`;

const cookiesKey = 'accepts-cookies-shop';

export const CookiesModal: FC = () => {
  const [cookieAccepted, setCookieAccepted] = useState<boolean>(true);
  const { t } = useTranslation();
  const [, { open: openLegalDocument }] = useLegalDocument();

  useEffect(() => {
    if (!getCookie(cookiesKey)) {
      setCookieAccepted(false);
    }
  }, []);

  const onAcceptClick = () => {
    const monthAfter = add(new Date(), { months: 1 });
    setCookie(cookiesKey, 'true', monthAfter.getTime() / 1000);
    setCookieAccepted(true);
  };

  if (cookieAccepted) {
    return null;
  }

  return (
    <AbsolutePortal>
      <CookiesWrapper className='text-gray-100 bg-gray-900 w-full px-9 lg:px-16 py-5 gap-4 flex flex-col md:flex-row justify-between items-center rounded-t-xl'>
        <div className='flex flex-col w-full md:flex-row items-center flex-grow'>
          <div className='mr-0 md:mr-10 mb-9 md:mb-0 mt-11 mb:mt-0 flex-shrink-0 w-60'>{/*<Image src={cookiesImg} placeholder='blur' alt='' width={700} height={330} />*/}</div>
          <div className='w-full md:w-3/5'>
            <H5 className='font-bold mb-3'>{t('common:This website uses cookies')}</H5>
            <BodyText className='mb-2'>{t('common:cookiesText')}</BodyText>
            <div className='font-bold hover:underline'>
              <button onClick={() => openLegalDocument('privacy_policy')}>{t('common:Learn More')}</button>
            </div>
          </div>
        </div>
        <CookieButton className='w-full md:w-auto flex-shrink-0' color='outlined-alt' onClick={onAcceptClick}>
          {t('common:OK, Close')}
        </CookieButton>
      </CookiesWrapper>
    </AbsolutePortal>
  );
};

import { authUserSelector } from '@root/modules/auth/redux';
import { KYC_STATUSES } from '@root/modules/kyc/enums/kyc.statuses';
import { selectKycLevel1Data, selectKycLevel2Data } from '@root/modules/kyc/redux/kyc.selector';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export type UserDropdownKycStatus = 'success' | 'pending' | 'failed' | undefined;

export const useUserDropdownKycStatus = () => {
  const level1Data = useSelector(selectKycLevel1Data);
  const level2Data = useSelector(selectKycLevel2Data);
  const userData = useSelector(authUserSelector);

  const status = useMemo<UserDropdownKycStatus>(() => {
    const level2Unavailable = !userData?.isKycLevel2user || !level2Data;

    const level1Success = level1Data && [KYC_STATUSES.SUCCESS, KYC_STATUSES.ACCEPTABLE].includes(level1Data.verificationStatus);
    const level2Success = level2Data && [KYC_STATUSES.SUCCESS, KYC_STATUSES.ACCEPTABLE].includes(level2Data?.verificationStatus);

    const level1Failed = level1Data && [KYC_STATUSES.ERROR].includes(level1Data?.verificationStatus);
    const level2Failed = level2Data && [KYC_STATUSES.ERROR].includes(level2Data?.verificationStatus);

    const level1Required = level1Data && [KYC_STATUSES.PENDING, KYC_STATUSES.PROCESSING].includes(level1Data?.verificationStatus);
    const level2Required = level2Data && [KYC_STATUSES.PENDING, KYC_STATUSES.PROCESSING].includes(level2Data?.verificationStatus);

    if ((level1Success && level2Unavailable) || (level1Success && level2Success)) {
      return 'success';
    }

    if ((level1Failed && level2Unavailable) || (level1Failed && level2Failed)) {
      return 'failed';
    }

    if ((level1Success && level2Failed) || level1Required || level2Required) {
      return 'pending';
    }

    return undefined;
  }, [level1Data, level2Data, userData]);

  const state = { status };
  const handlers = {};

  return [state, handlers] as [typeof state, typeof handlers];
};

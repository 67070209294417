import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RECURRING_TYPES } from '@root/infra/enums/reccuring-type';
import { IAuth, IAuthTokens, IAuthUserDetails, SignUpFormFields } from '../../types';

export type IAuthState = {
  initialized: boolean;
  captchaPassed: boolean;
  authed: boolean;
  loading: boolean;
  error: string | null;
  user: IAuthUserDetails | null;
  ssoError: string | null;
  country: string | null;
  tokenToRemove: string | null;
  signUpFormContent: SignUpFormFields | null;
};

export namespace AuthActions {
  export type Registered = PayloadAction<IAuth>;
  export type LoggedIn = PayloadAction<IAuth>;
  export type LoggedOut = PayloadAction;
  export type LoggedOutPrevented = PayloadAction;
  export type FetchProfilePending = PayloadAction;
  export type FetchProfileFulfilled = PayloadAction<IAuthUserDetails>;
  export type FetchProfileRejected = PayloadAction<string>;
  export type TokensMissed = PayloadAction;
  export type TokensRefreshed = PayloadAction<IAuthTokens>;
  export type SSORejected = PayloadAction<string>;
  export type CountryUpdated = PayloadAction<string>;
  export type RemoveTempToken = PayloadAction<string | null>;
  export type MembershipUpdated = PayloadAction;
  export type SignUpFormContentSaved = PayloadAction<SignUpFormFields>;
  export type SignUpFormContentCleared = PayloadAction;
  export type SetUserRecurringType = PayloadAction<RECURRING_TYPES>;
  export type SetCaptchaPassed = PayloadAction<boolean>;
  export type UpdateUserInfo = PayloadAction<Partial<IAuthUserDetails>>;
}

export type AuthSliceReducer = {
  loggedIn: CaseReducer<IAuthState, AuthActions.LoggedIn>;
  loggedOut: CaseReducer<IAuthState, AuthActions.LoggedOut>;
  loggedOutPrevented: CaseReducer<IAuthState, AuthActions.LoggedOutPrevented>;

  fetchProfilePending: CaseReducer<IAuthState, AuthActions.FetchProfilePending>;
  fetchProfileFulfilled: CaseReducer<IAuthState, AuthActions.FetchProfileFulfilled>;
  fetchProfileRejected: CaseReducer<IAuthState, AuthActions.FetchProfileRejected>;
  tokensMissed: CaseReducer<IAuthState, AuthActions.TokensMissed>;
  tokensRefreshed: CaseReducer<IAuthState, AuthActions.TokensRefreshed>;
  ssoRejected: CaseReducer<IAuthState, AuthActions.SSORejected>;
  countryUpdated: CaseReducer<IAuthState, AuthActions.CountryUpdated>;
  removeTempToken: CaseReducer<IAuthState, AuthActions.RemoveTempToken>;

  membershipUpdated: CaseReducer<IAuthState, AuthActions.MembershipUpdated>;

  signUpFormContentSaved: CaseReducer<IAuthState, AuthActions.SignUpFormContentSaved>;
  signUpFormContentCleared: CaseReducer<IAuthState, AuthActions.SignUpFormContentCleared>;
  setUserRecurringType: CaseReducer<IAuthState, AuthActions.SetUserRecurringType>;
  setCaptchaPassed: CaseReducer<IAuthState, AuthActions.SetCaptchaPassed>;
  updateUserInfo: CaseReducer<IAuthState, AuthActions.UpdateUserInfo>;
};

export const authState: IAuthState = {
  initialized: false,
  authed: false,
  loading: false,
  error: null,
  user: null,
  ssoError: null,
  country: null,
  tokenToRemove: null,
  signUpFormContent: null,
  captchaPassed: !!sessionStorage.getItem('captchaPassed'),
};

export const authSlice = createSlice<IAuthState, AuthSliceReducer, 'auth'>({
  name: 'auth',
  initialState: authState,
  reducers: {
    loggedIn: (state) => {
      state.authed = true;
      state.initialized = true;
      state.loading = true;
    },
    loggedOut: (state) => {
      state.authed = false;
      state.user = null;
      state.country = null;
    },
    loggedOutPrevented: () => {},
    fetchProfilePending: (state) => {
      state.loading = true;
    },
    fetchProfileFulfilled: (state, action) => {
      state.loading = false;
      state.initialized = true;
      state.authed = true;
      state.user = action.payload;
      state.country = action.payload.country;
    },
    fetchProfileRejected: (state, action) => {
      state.loading = false;
      state.initialized = true;
      state.error = action.payload;
    },
    tokensMissed: (state) => {
      state.initialized = true;
      state.loading = false;
    },
    setCaptchaPassed: (state, action) => {
      state.captchaPassed = action.payload;
    },
    ssoRejected: (state, action) => {
      state.initialized = false;
      state.loading = false;
      state.authed = false;
      state.ssoError = action.payload;
    },

    tokensRefreshed: () => {},

    countryUpdated: (state, action) => {
      state.country = action.payload;
    },
    removeTempToken: (state, action) => {
      state.tokenToRemove = action.payload;
    },
    membershipUpdated: () => {},

    signUpFormContentSaved: (state, action) => {
      state.signUpFormContent = action.payload;
    },
    signUpFormContentCleared: (state) => {
      state.signUpFormContent = null;
    },
    setUserRecurringType: (state, action) => {
      if (state.user) {
        state.user.recurringType = action.payload;
      }
    },
    updateUserInfo: (state, action) => {
      state.user = { ...state.user, ...action.payload } as IAuthUserDetails;
    },
  },
});

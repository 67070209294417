import { IRemoteData } from '@root/shared/remote-data';
import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetOrdersServiceData, GetOrdersServiceQuery } from '../services/get-orders.service';
import { OrdersTabKey } from '../constants/orders-tab-key';

export type OrdersStateTempFilters = Partial<Pick<GetOrdersServiceQuery, 'statusFilter' | 'productFilter' | 'typeFilter'>>;
export type OrdersStateFilters = Partial<GetOrdersServiceQuery>;
export type RequestedOrder = { id: number; transactionId?: number };

export interface OrdersState extends IRemoteData<GetOrdersServiceData> {
  query: GetOrdersServiceQuery;
  tempFilters: OrdersStateTempFilters;
  cancelingRequestedOrderIds: number[];
  payingRequestedOrderIds: RequestedOrder[];
}

export namespace OrdersActions {
  export type FetchPending = PayloadAction;
  export type FetchFulfilled = PayloadAction<GetOrdersServiceData>;
  export type FetchRejected = PayloadAction<string>;
  export type TempFiltersChanged = PayloadAction<OrdersStateTempFilters>;
  export type FiltersChanged = PayloadAction<OrdersStateFilters>;
  export type SortChanged = PayloadAction<{ column: string; direction: string }>;
  export type PageChanged = PayloadAction<number>;
  export type TabChanged = PayloadAction<OrdersTabKey>;
  export type FiltersApplied = PayloadAction;
  export type CancelRequestedOrderPending = PayloadAction<number>;
  export type CancelRequestedOrderFulfilled = PayloadAction<number>;
  export type CancelRequestedOrderRejected = PayloadAction<{ orderId: number; message: string }>;
  export type PayRequestedOrderPending = PayloadAction<RequestedOrder>;
  export type PayRequestedOrderFulfilled = PayloadAction<number>;
  export type PayRequestedOrderRejected = PayloadAction<{ orderId: number; message: string }>;
}

export type OrdersSliceReducer = {
  fetchPending: CaseReducer<OrdersState, OrdersActions.FetchPending>;
  fetchFulfilled: CaseReducer<OrdersState, OrdersActions.FetchFulfilled>;
  fetchRejected: CaseReducer<OrdersState, OrdersActions.FetchRejected>;
  filtersChanged: CaseReducer<OrdersState, OrdersActions.FiltersChanged>;
  tempFiltersChanged: CaseReducer<OrdersState, OrdersActions.TempFiltersChanged>;
  sortChanged: CaseReducer<OrdersState, OrdersActions.SortChanged>;
  pageChanged: CaseReducer<OrdersState, OrdersActions.PageChanged>;
  tabChanged: CaseReducer<OrdersState, OrdersActions.TabChanged>;
  tempFiltersApplied: CaseReducer<OrdersState, OrdersActions.FiltersApplied>;
  cancelRequestedOrderPending: CaseReducer<OrdersState, OrdersActions.CancelRequestedOrderPending>;
  cancelRequestedOrderFulfilled: CaseReducer<OrdersState, OrdersActions.CancelRequestedOrderFulfilled>;
  cancelRequestedOrderRejected: CaseReducer<OrdersState, OrdersActions.CancelRequestedOrderRejected>;
  payRequestedOrderPending: CaseReducer<OrdersState, OrdersActions.PayRequestedOrderPending>;
  payRequestedOrderFulfilled: CaseReducer<OrdersState, OrdersActions.PayRequestedOrderFulfilled>;
  payRequestedOrderRejected: CaseReducer<OrdersState, OrdersActions.PayRequestedOrderRejected>;
};

export const ordersSlice = createSlice<OrdersState, OrdersSliceReducer, 'orders'>({
  name: 'orders',
  initialState: {
    query: {
      page: 1,
      limit: 10,
      sortKey: 'createdAt',
      sortDirection: 'DESC',
    },
    tempFilters: {},
    data: { totalItems: 0, totalPages: 0, data: [] },
    isLoading: false,
    error: null,
    cancelingRequestedOrderIds: [],
    payingRequestedOrderIds: [],
  },
  reducers: {
    fetchPending: (state) => {
      state.isLoading = true;
    },
    fetchFulfilled: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    fetchRejected: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    filtersChanged: (state, action) => {
      state.query = { ...state.tempFilters, ...action.payload, page: 1 };
    },
    tempFiltersChanged: (state, action) => {
      state.tempFilters = { ...state.tempFilters, ...action.payload };
    },
    sortChanged: (state, action) => {
      state.query.sortKey = action.payload.column;
      state.query.sortDirection = action.payload.direction;
      state.query.page = 1;
    },
    tabChanged: (state, action) => {
      switch (action.payload) {
        case OrdersTabKey.RECEIVED:
          state.query.hasCoordinator = false;
          state.query.isRequested = true;
          break;
        case OrdersTabKey.FORWARDED:
          state.query.hasCoordinator = true;
          state.query.isRequested = false;
          break;
        case OrdersTabKey.ORDERS:
          state.query.hasCoordinator = undefined;
          state.query.isRequested = false;
          break;
      }
      state.data.data = [];
      state.data.totalItems = 0;
      state.isLoading = true;
      state.data.totalPages = 1;
      state.query.page = 1;
    },
    pageChanged: (state, action) => {
      state.query.page = action.payload;
    },
    tempFiltersApplied: (state) => {
      state.query = { ...state.query, ...state.tempFilters, page: 1 };
    },
    cancelRequestedOrderPending: (state, action) => {
      state.cancelingRequestedOrderIds.push(action.payload);
    },
    cancelRequestedOrderFulfilled: (state, action) => {
      state.cancelingRequestedOrderIds = state.cancelingRequestedOrderIds.filter((id) => id !== action.payload);
    },
    cancelRequestedOrderRejected: (state, action) => {
      state.cancelingRequestedOrderIds = state.cancelingRequestedOrderIds.filter((id) => id !== action.payload.orderId);
    },
    payRequestedOrderPending: (state, action) => {
      state.payingRequestedOrderIds.push(action.payload);
    },
    payRequestedOrderFulfilled: (state, action) => {
      state.payingRequestedOrderIds = state.payingRequestedOrderIds.filter(({ id }) => id !== action.payload);
    },
    payRequestedOrderRejected: (state, action) => {
      state.payingRequestedOrderIds = state.payingRequestedOrderIds.filter(({ id }) => id !== action.payload.orderId);
    },
  },
});

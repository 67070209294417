import { FC } from 'react';
import { baseIconProps } from './base-icon-props';

export const StatisticsTopupIcon: FC<any> = (props) => {
  return (
    <svg {...baseIconProps} style={{transform: `scale(1.2)`}} viewBox='0 0 34 34' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.66113 16.8003C4.21342 16.8003 4.66113 17.2481 4.66113 17.8003V26.8472C4.67084 26.8662 4.69834 26.9088 4.76806 26.9747C4.91578 27.1144 5.18301 27.2861 5.59594 27.4514C6.41628 27.7796 7.61802 28.005 8.99669 28.005C10.3754 28.005 11.5771 27.7796 12.3974 27.4514C12.8104 27.2861 13.0776 27.1144 13.2253 26.9747C13.295 26.9088 13.3225 26.8662 13.3322 26.8472V17.8003C13.3322 17.2481 13.78 16.8003 14.3322 16.8003C14.8845 16.8003 15.3322 17.2481 15.3322 17.8003V26.8708C15.3314 27.5284 14.9938 28.0551 14.5993 28.4281C14.2069 28.799 13.6928 29.0872 13.1404 29.3082C12.03 29.7525 10.5648 30.005 8.99669 30.005C7.42861 30.005 5.96333 29.7525 4.85297 29.3082C4.30054 29.0872 3.78649 28.799 3.39408 28.4281C2.99961 28.0551 2.66198 27.5297 2.66113 26.8721L2.66113 17.8003C2.66113 17.2481 3.10885 16.8003 3.66113 16.8003Z'
        fill='url(#paint0_linear_1004_41601)'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.65645 17.8342C4.65627 17.8342 4.65672 17.8324 4.65834 17.8286C4.65744 17.8324 4.65663 17.8342 4.65645 17.8342ZM4.6747 17.8002C4.69035 17.7771 4.71846 17.7422 4.76767 17.6957C4.91526 17.5562 5.1825 17.3845 5.59565 17.2193C6.41641 16.8912 7.61862 16.6661 8.99669 16.6661C10.3745 16.6661 11.5763 16.8915 12.3969 17.2198C12.81 17.385 13.0774 17.5568 13.2253 17.6965C13.2734 17.7421 13.3015 17.7765 13.3174 17.7997C13.3018 17.8228 13.2737 17.8577 13.2243 17.9044C13.0768 18.0439 12.8096 18.2156 12.3966 18.3809C11.576 18.7091 10.3738 18.9346 8.99517 18.9346C7.61649 18.9346 6.41478 18.7091 5.59467 18.3809C5.18185 18.2157 4.91488 18.044 4.76747 17.9045C4.71839 17.8581 4.69034 17.8233 4.6747 17.8002ZM15.329 17.8814C15.3312 17.8542 15.3323 17.8268 15.3322 17.799C15.3314 17.1412 14.9935 16.6157 14.5989 16.2429C14.2064 15.872 13.6923 15.5839 13.1398 15.3629C12.0293 14.9186 10.5641 14.6661 8.99669 14.6661C7.42953 14.6661 5.96396 14.9182 4.85325 15.3622C4.30067 15.5831 3.78625 15.8712 3.39361 16.2424C2.9988 16.6157 2.66113 17.1418 2.66113 17.8003C2.66113 18.4583 2.9985 18.9842 3.39291 19.3573C3.7852 19.7285 4.29923 20.0167 4.85157 20.2378C5.96179 20.6821 7.4271 20.9346 8.99517 20.9346C10.5632 20.9346 12.0289 20.6821 13.1394 20.2378C13.6919 20.0168 14.2061 19.7286 14.5985 19.3575C14.9767 18.9999 15.3027 18.5018 15.329 17.8814ZM4.6583 17.7718C4.65669 17.7681 4.65624 17.7662 4.65642 17.7662C4.6566 17.7662 4.65741 17.7681 4.6583 17.7718Z'
        fill='url(#paint1_linear_1004_41601)'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.66185 22.3124C4.64956 21.7708 4.20667 21.3356 3.66211 21.3356C3.10982 21.3356 2.66211 21.7833 2.66211 22.3356C2.66211 22.9936 2.99948 23.5194 3.39389 23.8926C3.78618 24.2637 4.30021 24.5519 4.85255 24.773C5.96277 25.2173 7.42808 25.4698 8.99614 25.4698C10.5642 25.4698 12.0298 25.2173 13.1404 24.773C13.6929 24.552 14.2071 24.2638 14.5995 23.8927C14.994 23.5197 15.3317 22.9938 15.3317 22.3356C15.3317 21.7833 14.884 21.3356 14.3317 21.3356C13.7872 21.3356 13.3443 21.7708 13.332 22.3123C13.3225 22.331 13.2952 22.3736 13.2253 22.4396C13.0778 22.5791 12.8106 22.7509 12.3975 22.9161C11.577 23.2444 10.3748 23.4698 8.99614 23.4698C7.61746 23.4698 6.41575 23.2444 5.59564 22.9162C5.18282 22.7509 4.91586 22.5792 4.76845 22.4398C4.6986 22.3737 4.67133 22.3312 4.66185 22.3124ZM13.3364 22.3013C13.3366 22.3013 13.3362 22.3031 13.3346 22.3068C13.3355 22.3031 13.3363 22.3013 13.3364 22.3013ZM4.65928 22.307C4.65767 22.3033 4.65722 22.3014 4.6574 22.3014C4.65758 22.3014 4.65838 22.3033 4.65928 22.307Z'
        fill='url(#paint2_linear_1004_41601)'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.99512 9.66334C3.99512 7.26933 5.93584 5.32861 8.32984 5.32861H25.6704C28.0644 5.32861 30.0051 7.26933 30.0051 9.66334V12.8897C30.7937 13.2642 31.339 14.068 31.339 14.9991V19.0008C31.339 19.9319 30.7937 20.7356 30.0051 21.1102V24.3361C30.0051 26.7301 28.0644 28.6709 25.6704 28.6709H18.334C17.7817 28.6709 17.334 28.2231 17.334 27.6709C17.334 27.1186 17.7817 26.6709 18.334 26.6709H25.6704C26.9598 26.6709 28.0051 25.6256 28.0051 24.3361V21.3346H25.6704C23.2764 21.3346 21.3357 19.3939 21.3357 16.9999C21.3357 14.6059 23.2764 12.6652 25.6704 12.6652H28.0051V9.66334C28.0051 8.3739 26.9598 7.32861 25.6704 7.32861H8.32984C7.04041 7.32861 5.99512 8.3739 5.99512 9.66334V11.6642C5.99512 12.2165 5.5474 12.6642 4.99512 12.6642C4.44283 12.6642 3.99512 12.2165 3.99512 11.6642V9.66334ZM29.0145 19.3345C29.0114 19.3345 29.0082 19.3345 29.0051 19.3345C28.9988 19.3345 28.9926 19.3345 28.9863 19.3346H25.6704C24.381 19.3346 23.3357 18.2894 23.3357 16.9999C23.3357 15.7105 24.381 14.6652 25.6704 14.6652H29.0051C29.1895 14.6652 29.339 14.8147 29.339 14.9991V19.0008C29.339 19.182 29.1946 19.3295 29.0145 19.3345Z'
        fill='url(#paint3_linear_1004_41601)'
      />
      <defs>
        <linearGradient id='paint0_linear_1004_41601' x1='15.3613' y1='16.77' x2='2.63204' y2='16.77' gradientUnits='userSpaceOnUse'>
          <stop stopColor='#41C7FF' />
          <stop offset='1' stopColor='#8C84FF' />
        </linearGradient>
        <linearGradient id='paint1_linear_1004_41601' x1='15.3613' y1='14.6517' x2='2.63204' y2='14.6517' gradientUnits='userSpaceOnUse'>
          <stop stopColor='#41C7FF' />
          <stop offset='1' stopColor='#8C84FF' />
        </linearGradient>
        <linearGradient id='paint2_linear_1004_41601' x1='15.3608' y1='21.3261' x2='2.63302' y2='21.3261' gradientUnits='userSpaceOnUse'>
          <stop stopColor='#41C7FF' />
          <stop offset='1' stopColor='#8C84FF' />
        </linearGradient>
        <linearGradient id='paint3_linear_1004_41601' x1='31.4018' y1='5.27501' x2='3.93234' y2='5.27501' gradientUnits='userSpaceOnUse'>
          <stop stopColor='#41C7FF' />
          <stop offset='1' stopColor='#8C84FF' />
        </linearGradient>
      </defs>
    </svg>
  );
};

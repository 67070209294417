import { FC } from 'react';

export const OrdersIcon: FC = (props) => {
  return (
    <svg width='1em' height='1em' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.78313 0.00105971C1.79437 0.000353421 1.80563 0 1.81689 0H10.7513C10.7626 0 10.7739 0.000353421 10.7851 0.00105971C11.8224 0.0662448 12.6143 0.951452 12.5661 1.9877V6.28203C12.5661 6.5793 12.3252 6.82028 12.0279 6.82028C11.7306 6.82028 11.4896 6.5793 11.4896 6.28203V1.9742C11.4896 1.9644 11.4899 1.9546 11.4904 1.94481C11.5149 1.49692 11.1777 1.11237 10.7324 1.07651H1.83587C1.39055 1.11237 1.0533 1.49692 1.07779 1.94481C1.07833 1.9546 1.07859 1.9644 1.07859 1.9742V12.0258C1.07859 12.0356 1.07833 12.0454 1.07779 12.0552C1.0533 12.5031 1.39055 12.8876 1.83587 12.9235H5.56614C5.86341 12.9235 6.1044 13.1645 6.1044 13.4617C6.1044 13.759 5.86341 14 5.56614 14H1.81689C1.80563 14 1.79437 13.9996 1.78313 13.9989C0.745854 13.9338 -0.0460739 13.0485 0.00208478 12.0123V1.98771C-0.046074 0.95146 0.745854 0.066245 1.78313 0.00105971Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.87402 8.07695C2.87402 7.77968 3.11501 7.5387 3.41228 7.5387H5.56619C5.86346 7.5387 6.10445 7.77968 6.10445 8.07695C6.10445 8.37422 5.86346 8.61521 5.56619 8.61521H3.41228C3.11501 8.61521 2.87402 8.37422 2.87402 8.07695Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.87402 10.5899C2.87402 10.2926 3.11501 10.0516 3.41228 10.0516H5.56619C5.86346 10.0516 6.10445 10.2926 6.10445 10.5899C6.10445 10.8872 5.86346 11.1281 5.56619 11.1281H3.41228C3.11501 11.1281 2.87402 10.8872 2.87402 10.5899Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.4694 10.0345C11.6793 10.245 11.6788 10.5858 11.4684 10.7957L10.3979 11.8633C10.1877 12.0729 9.84766 12.0729 9.63759 11.8633L8.99572 11.2228C8.78529 11.0129 8.78491 10.6721 8.99487 10.4616C9.20484 10.2512 9.54564 10.2508 9.75608 10.4608L10.0179 10.722L10.7082 10.0335C10.9187 9.82356 11.2595 9.82402 11.4694 10.0345Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.18188 9.87131C7.18188 8.78132 8.0655 7.89771 9.15548 7.89771H11.3106C12.4006 7.89771 13.2842 8.78132 13.2842 9.8713V12.0264C13.2842 13.1164 12.4006 14 11.3106 14H9.15549C8.0655 14 7.18188 13.1164 7.18188 12.0264V9.87131ZM9.15548 8.97421C8.66004 8.97421 8.25839 9.37586 8.25839 9.87131V12.0264C8.25839 12.5219 8.66004 12.9235 9.15549 12.9235H11.3106C11.806 12.9235 12.2077 12.5219 12.2077 12.0264V9.8713C12.2077 9.37586 11.806 8.97421 11.3106 8.97421H9.15548Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.53666 3.02948C9.74686 3.23968 9.74686 3.58048 9.53666 3.79068L7.38275 5.9446C7.19456 6.13279 6.89708 6.15519 6.68283 5.9973L5.08246 4.81796L3.7667 5.9691C3.54297 6.16484 3.20293 6.14214 3.00719 5.91841C2.81145 5.69468 2.83414 5.35464 3.05787 5.1589L4.69915 3.72295C4.88928 3.55661 5.16951 3.54488 5.37288 3.69474L6.94884 4.8561L8.77546 3.02948C8.98566 2.81928 9.32646 2.81928 9.53666 3.02948Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.18188 3.41008C7.18188 3.11281 7.42287 2.87183 7.72014 2.87183H9.15608C9.45335 2.87183 9.69434 3.11281 9.69434 3.41008V4.84602C9.69434 5.14329 9.45335 5.38428 9.15608 5.38428C8.85881 5.38428 8.61783 5.14329 8.61783 4.84602V3.94834H7.72014C7.42287 3.94834 7.18188 3.70735 7.18188 3.41008Z'
        fill='currentColor'
      />
    </svg>
  );
};

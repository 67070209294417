import { memo, useEffect } from 'react';

const GAScripts = memo(
  () => {
    const GOOGLE_ANALYTICS_ID = import.meta.env.REACT_APP_GOOGLE_ANALYTICS_GLOBAL;

    useEffect(() => {
      const script1 = document.createElement('script');
      const script2 = document.createElement('script');
      const script3 = document.createElement('script');

      script1.src = `https://www.googletagmanager.com/gtag/js?id=${GOOGLE_ANALYTICS_ID}`;

      script2.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${GOOGLE_ANALYTICS_ID}', {
        page_path: window.location.pathname,
      });`;

      script3.innerHTML = `
        (function(w,d,s,l,i){w[l] = w[l] || [];w[l].push(
        {'gtm.start': new Date().getTime(),event:'gtm.js'}
        );var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${GOOGLE_ANALYTICS_ID}');
      `;

      document.head.appendChild(script1);
      document.head.appendChild(script2);
      document.head.appendChild(script3);

      return () => {
        document.head.removeChild(script1);
        document.head.removeChild(script2);
        document.head.removeChild(script3);
      };
    }, [GOOGLE_ANALYTICS_ID]);

    return (
      <noscript>
        <iframe src={`https://www.googletagmanager.com/ns.html?id=${GOOGLE_ANALYTICS_ID}`} height='0' width='0'></iframe>
      </noscript>
    );
  },
  () => true,
);

export default GAScripts;

import { AxiosError } from 'axios';
import { IHttpResponse } from '@root/shared/http-service';
import { handleAxiosError } from '@root/shared/utils/handle-axios-error';
import { fetcher } from '@root/infra/fetcher';

export type CancelOrderServiceValues = { orderId: number };
export type CancelOrderServiceResponse = IHttpResponse<200> | IHttpResponse<400, string>;

export const cancelOrderService = async (values: CancelOrderServiceValues): Promise<CancelOrderServiceResponse> => {
  try {
    await fetcher.post(`/v1/order/${values.orderId}/cancel`);

    return {
      status: 200,
      payload: null,
    };
  } catch (e) {
    return handleAxiosError(e as AxiosError);
  }
};

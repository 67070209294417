import queryString from 'query-string';
import { call, put, SagaReturnType, select, takeLatest } from '@redux-saga/core/effects';

import { AppState } from '@root/infra/redux';
import { authSlice } from '@root/modules/auth/redux';
import { membershipSelector } from '@root/modules/membership/redux/membership.selector';
import { selectBaseInstanceCountry } from '@root/modules/meta/redux';

import { getProducts, GetProductsQuery } from '../../services/get-products';
import { productsSlice } from './products.slice';

function* handleFetch() {
  const auth = yield select((state: AppState) => state.auth);
  const uiCountry = yield select(selectBaseInstanceCountry);
  const membership = yield select(membershipSelector);
  const queryData = queryString.parse(window?.location?.search, { parseNumbers: true });
  const userMembership = auth?.user?.membershipId;
  const country = queryData?.country || auth?.country || auth?.user?.country || uiCountry;
  const query: GetProductsQuery = { uiCountry: country };

  if (auth.user) {
    query.userId = auth.user.customerId;
  }

  const result: SagaReturnType<typeof getProducts> = yield call(getProducts, query);

  const membershipData = membership?.find((m) => m?.id === userMembership);

  if (result.status === 200) {
    const planList =
      (userMembership &&
        result.payload?.map((p) => {
          if (p?.purchased && p?.isDigitalBundle) {
            return {
              ...p,
              pricing: {
                ...p.pricing,
                recurringPrice: { amount: membershipData?.recurringPrice },
              },
            };
          }
          return p;
        })) ||
      result.payload;
    yield put(productsSlice.actions.fetchFulfilled(planList));
  } else {
    yield put(productsSlice.actions.fetchRejected(result.payload));
  }
}

export function* productsFlowSaga() {
  yield takeLatest([productsSlice.actions.fetchPending, authSlice.actions.countryUpdated, authSlice.actions.fetchProfileFulfilled], handleFetch);
}

import { subscriptionsSelector } from '../redux/subscriptions.selector';
import { subscriptionsSlice } from '../redux/subscriptions.slice';
import { UpgradeSubscriptionItemsUI } from './upgrade-subscription-items.ui';
import { PriceItem } from '@root/modules/currencies/components/price-item';
import { ICanBeSwitchedToItem } from '@root/modules/products/types/purchased-product';
import { BodyText, BodyTextLarge, Image } from '@root/shared/ui';
import { getNumberOfMonth, updateProductNameWithRecurringType } from '@root/shared/utils/recurring-type-hepler';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

const { ItemCheck } = UpgradeSubscriptionItemsUI;

interface IProps {
  item: ICanBeSwitchedToItem;
}

export const UpgradeSubscriptionItem: FC<IProps> = ({ item }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('subscriptions', { keyPrefix: 'upgrade_subscription_modal' });

  const { activeUpgrade } = useSelector(subscriptionsSelector);

  const isActive = item.id === activeUpgrade?.id;
  const recurringPeriodMonth = getNumberOfMonth(item.recurringType);
  const name = updateProductNameWithRecurringType(item.planName, item.recurringType);

  const handleActivateUpgrade = () => {
    dispatch(subscriptionsSlice.actions.setActiveUpgrade(item));
  };

  return (
    <div className='flex p-4 lg:py-2.5 hover:bg-gray-200 transition cursor-pointer border-b border-gray-300 last:border-none' onClick={handleActivateUpgrade}>
      <div className='hidden sm:flex flex-shrink-0'>
        <div className='border-warning-400 border mr-4 lg:mr-2 overflow-hidden rounded-full self-center'>
          <Image src={item.planPreview} alt='product' className='object-cover h-10 w-10' />
        </div>
      </div>
      <div>
        <div className='flex items-center gap-1'>
          <BodyTextLarge className='font-bold lg:text-[14px]'>{name}</BodyTextLarge>
          <span className='text-gray-600'>|</span>
          <BodyText className='font-bold text-xs'>
            {t('switch-price')} <PriceItem value={item.switchPrice} type='long' />
          </BodyText>
        </div>
        <BodyText className='text-xs text-gray-600 font-bold'>
          <PriceItem value={item.recurringPrice} type='long' />
          {` ${recurringPeriodMonth > 1 ? t('recurringType.every_months', { amount: recurringPeriodMonth }) : t('recurringType.every_month', { amount: recurringPeriodMonth })}`}
        </BodyText>
      </div>
      <div className='flex flex-col justify-center ml-auto'>
        <ItemCheck checked={isActive} />
      </div>
    </div>
  );
};

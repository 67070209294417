import { createGlobalStyle } from 'styled-components';
import { Theme } from './theme';

export const GlobalStyles = createGlobalStyle<{ theme: Theme }>`
  body {
    background-color: ${({ theme }) => theme.colors.gray[100]} ;
    color: ${({ theme }) => theme.colors.gray[900]};
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    min-width: 375px;
    -webkit-font-smoothing: antialiased;
  }

  .react-datepicker {
    box-shadow: ${({ theme }) => theme.shadows[4]};
  }
  
  .react-tiny-popover-container {
    z-index: 10030;
  }

  .react-datepicker {
    border: 1px solid ${({ theme }) => theme.colors.gray[300]};
  }

  .react-datepicker__header  {
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray[300]};
  }

  .react-datepicker__navigation-icon {
    &::before {
      top: 16px;
      border-color: ${({ theme }) => theme.colors.gray[500]};
    }
  }

  .react-datepicker__year-read-view--down-arrow {
    top: 2px;
    border-color: ${({ theme }) => theme.colors.gray[500]};
  }

  .react-datepicker {
    font-family: 'Montserrat', sans-serif;
  }

  .react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header, .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__day--outside-month {
    color: ${({ theme }) => theme.colors.gray[900]} !important;
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected, 
  .react-datepicker__day--selected, 
  .react-datepicker__day--in-selecting-range, 
  .react-datepicker__day--in-range, 
  .react-datepicker__month-text--selected, 
  .react-datepicker__month-text--in-selecting-range, 
  .react-datepicker__month-text--in-range, 
  .react-datepicker__quarter-text--selected, 
  .react-datepicker__quarter-text--in-selecting-range, 
  .react-datepicker__quarter-text--in-range, 
  .react-datepicker__year-text--selected, 
  .react-datepicker__year-text--in-selecting-range, 
  .react-datepicker__year-text--in-range
  {
    background-color: ${({ theme }) => theme.colors.success[500]} !important;
    color: ${({ theme }) => theme.colors.gray[100]} !important;
  }

  .react-datepicker__header {
    background-color: ${({ theme }) => theme.colors.gray[200]} !important;
  }
  
  .react-datepicker__triangle {
    &::before {
      border-bottom-color: ${({ theme }) => theme.colors.gray[300]} !important;
    }

    &::after {
      border-bottom-color: ${({ theme }) => theme.colors.gray[200]} !important;
    }
  }

  .react-datepicker__navigation {
    width: 25px;
    height: 25px;
  }

  .react-datepicker__year-select {
    outline: none;
    padding: 2px 5px;
    border-radius: 4px;
    max-height: 100px;
  }

  #chat-widget-container {
    bottom: 0 !important;
  }
`;

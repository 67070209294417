import { IProductPricing } from '@root/modules/products/types/product-pricing';

export class ProductPricingMapper {
  public static toDomain(obj): IProductPricing {
    return {
      price: (typeof obj.price === 'number' ? { amount: obj.price } : null) || (typeof obj?.setupPrice === 'number' ? { amount: obj?.setupPrice } : null),
      discountPrice: typeof obj.discountPrice === 'number' ? { amount: obj.discountPrice } : null,
      recurringPrice: typeof obj.recurringPrice === 'number' ? { amount: obj.recurringPrice } : null,
      recurringDiscountPrice: typeof obj.recurringDiscountPrice === 'number' ? { amount: obj.recurringDiscountPrice } : null,
      fakeDiscountPercentage: typeof obj.fakeDiscountPercentage === 'number' ? { amount: obj.fakeDiscountPercentage } : null,
    };
  }
}

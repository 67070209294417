import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IKycSetting } from '@root/modules/kyc/types/settings';
import { IIdentityVerificationData, IAddressVerificationData } from '@root/modules/kyc/types/identity-verification';
import { KYC_STATUSES } from '../enums/kyc.statuses';

export type IKycState = {
  activateOrderId: number | null;
  loading: boolean;
  error: string | null;
  level1: IIdentityVerificationData | null;
  level2: IAddressVerificationData | null;
  settings: null | IKycSetting[];
  meta: {
    isNavigationEnabled: boolean;
    isProductActivated: boolean;
    isVerificationSkipped: boolean;
    isAnotherKyc1Started: boolean;
    isAnotherKyc2Started: boolean;
    isKyc2StartedFromKyc1Success: boolean;
  };
};

export namespace KycActions {
  export type SetActivateOrderID = PayloadAction<number | null>;
  export type LoggedOut = PayloadAction;
  export type LoggedOutPrevented = PayloadAction;
  export type SetKycSettings = PayloadAction<IKycSetting[]>;
  export type SetKycLevel1Data = PayloadAction<IIdentityVerificationData>;
  export type SetKycLevel2Data = PayloadAction<IAddressVerificationData>;
  export type EnabledNavigation = PayloadAction;
  export type DisabledNavigation = PayloadAction;
  export type ProductActivated = PayloadAction;
  export type SkippedVerification = PayloadAction<boolean>;
  export type StartedAnotherKyc1Attempt = PayloadAction;
  export type StartedAnotherKyc2Attempt = PayloadAction;
  export type StartedKyc2FromKyc1Success = PayloadAction;
  export type Reset = PayloadAction;
}

export type KycSliceReducer = {
  setActivateOrderId: CaseReducer<IKycState, KycActions.SetActivateOrderID>;
  loggedOut: CaseReducer<IKycState, KycActions.LoggedOut>;
  loggedOutPrevented: CaseReducer<IKycState, KycActions.LoggedOutPrevented>;
  setKycSettings: CaseReducer<IKycState, KycActions.SetKycSettings>;
  setKycLevel1Data: CaseReducer<IKycState, KycActions.SetKycLevel1Data>;
  setKycLevel2Data: CaseReducer<IKycState, KycActions.SetKycLevel2Data>;
  enabledNavigation: CaseReducer<IKycState, KycActions.EnabledNavigation>;
  disabledNavigation: CaseReducer<IKycState, KycActions.DisabledNavigation>;
  productActivated: CaseReducer<IKycState, KycActions.ProductActivated>;
  skippedVerification: CaseReducer<IKycState, KycActions.SkippedVerification>;
  startedAnotherKyc1Attempt: CaseReducer<IKycState, KycActions.StartedAnotherKyc1Attempt>;
  startedAnotherKyc2Attempt: CaseReducer<IKycState, KycActions.StartedAnotherKyc2Attempt>;
  startedKyc2FromKyc1Success: CaseReducer<IKycState, KycActions.StartedKyc2FromKyc1Success>;
  reset: CaseReducer<IKycState, KycActions.Reset>;
};

export const kycState: IKycState = {
  activateOrderId: null,
  loading: false,
  settings: null,
  error: null,
  level1: null,
  level2: null,
  meta: {
    isNavigationEnabled: true,
    isProductActivated: false,
    isVerificationSkipped: false,
    isAnotherKyc1Started: false,
    isAnotherKyc2Started: false,
    isKyc2StartedFromKyc1Success: false,
  },
};

export const kycSlice = createSlice<IKycState, KycSliceReducer, 'kyc'>({
  name: 'kyc',
  initialState: kycState,
  reducers: {
    setActivateOrderId: (state, action) => {
      state.activateOrderId = action.payload;
    },
    setKycSettings: (state, action) => {
      state.settings = action.payload;
    },
    setKycLevel1Data: (state, action) => {
      state.level1 = action.payload;

      if ([KYC_STATUSES.ACCEPTABLE, KYC_STATUSES.SUCCESS, KYC_STATUSES.ERROR].includes(action.payload.verificationStatus)) {
        state.meta.isAnotherKyc1Started = false;
      }
    },
    setKycLevel2Data: (state, action) => {
      state.level2 = action.payload;

      if ([KYC_STATUSES.ACCEPTABLE, KYC_STATUSES.SUCCESS, KYC_STATUSES.ERROR].includes(action.payload.verificationStatus)) {
        state.meta.isAnotherKyc2Started = false;
      }
    },
    enabledNavigation: (state) => {
      state.meta.isNavigationEnabled = true;
    },
    disabledNavigation: (state) => {
      state.meta.isNavigationEnabled = false;
    },

    productActivated: (state) => {
      state.meta.isProductActivated = true;
    },

    skippedVerification: (state, action) => {
      state.meta.isVerificationSkipped = action.payload;
    },

    startedAnotherKyc1Attempt: (state) => {
      state.meta.isAnotherKyc1Started = true;
    },

    startedAnotherKyc2Attempt: (state) => {
      state.meta.isAnotherKyc2Started = true;
    },

    startedKyc2FromKyc1Success: (state) => {
      state.meta.isKyc2StartedFromKyc1Success = true;
    },

    loggedOut: (state) => {},
    loggedOutPrevented: () => {},

    reset: (state) => {
      state.activateOrderId = null;
      state.loading = false;
      state.error = null;
      state.level1 = null;
      state.level2 = null;
      state.meta.isNavigationEnabled = true;
      state.meta.isProductActivated = false;
      state.meta.isVerificationSkipped = false;
      state.meta.isAnotherKyc1Started = false;
      state.meta.isAnotherKyc2Started = false;
      state.meta.isKyc2StartedFromKyc1Success = false;
    },
  },
});

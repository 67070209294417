import { FC } from 'react';

export const ActionsAccountIcon: FC<any> = (props) => {
  return (
    <svg width='100%' height='100%'  viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_1004_18472)'>
        <path d='M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z' fill='url(#paint0_linear_1004_18472)' />
        <path
          opacity='0.15'
          d='M32.9696 21.6667C32.1721 21.6792 31.214 21.6564 30.4166 21.6667C30.374 24.036 30.6503 26.7973 30.5468 29.1667C31.3281 29.1418 32.1094 29.1971 32.8906 29.1667C33.5372 29.1411 34.2881 28.3419 34.323 27.6952C34.323 25.8334 34.323 24.909 34.323 23.3334C34.323 22.409 33.7499 21.6667 32.9696 21.6667Z'
          fill='#0281FF'
        />
        <path
          d='M31.9356 27.6152C31.9356 25.8334 31.9356 25 31.9356 23.0542C31.9356 22.5 31.2498 21.6667 30.4165 21.6667C29.2202 21.682 28.2794 21.6565 27.0831 21.6666C25.8727 22.8707 24.7583 24.3407 23.5596 25.5488C24.734 26.7394 25.9458 27.9976 27.0831 29.1667C28.3331 29.1667 29.1665 29.1667 30.5467 29.1667C31.6665 29.1667 31.9356 28.3334 31.9356 27.6152Z'
          fill='#8CDDFF'
        />
        <path
          d='M6.84137 21.6666C6.18051 21.6569 5.65715 22.3611 5.67692 23.0078C5.72379 24.5703 5.59383 26.1328 5.67688 27.6953C5.7118 28.3419 6.43661 29.141 7.08317 29.1666C13.7498 29.1666 20.8332 29.1666 27.4998 29.1666C27.4998 26.6666 27.4998 24.3221 27.4998 21.6666C20.4165 21.6666 13.7498 21.6666 6.84137 21.6666Z'
          fill='white'
        />
        <path
          d='M19.9997 4.70163C17.7457 4.70241 15.8714 6.6114 15.8398 8.92241C15.8221 10.5206 15.8098 12.1189 15.8027 13.7171C16.6022 13.7146 17.4016 13.7127 18.201 13.7116C18.2041 12.1107 18.2094 10.5098 18.2169 8.90874C18.2226 7.91429 19.0213 7.1039 19.9997 7.1039C20.978 7.1039 21.7769 7.91429 21.7825 8.90874C21.7716 6.59257 21.7548 4.7039 19.9997 4.70163Z'
          fill='#C9EFFF'
        />
        <path
          d='M20 4.70163C20.966 4.70179 21.7693 6.59194 21.7828 8.90882C21.7904 10.5098 21.7956 12.1108 21.7987 13.7117C22.5981 13.7128 23.3975 13.7146 24.197 13.7172C24.1898 12.119 24.1775 10.5207 24.1599 8.92249C24.1283 6.61132 22.254 4.70233 20 4.70163Z'
          fill='url(#paint1_linear_1004_18472)'
        />
        <path
          d='M15.8176 11.3198C15.1579 11.3236 14.6174 11.8636 14.612 12.5245C14.5967 14.4663 14.5917 16.4082 14.5965 18.3501C14.5982 19.0111 15.1381 19.5489 15.8006 19.5507C16.6005 19.5527 17.4004 19.5542 18.2003 19.5552C18.1961 16.8069 18.1985 14.0585 18.2076 11.3102C17.4109 11.3121 16.6142 11.3153 15.8176 11.3198Z'
          fill='#41C7FF'
        />
        <path
          d='M22.9531 25.5561C22.9491 26.2192 23.4801 26.7538 24.1399 26.7482C24.7997 26.7424 25.3435 26.2 25.353 25.5386C25.3625 24.8771 24.8297 24.3456 24.1646 24.3495C23.4994 24.3535 22.9573 24.893 22.9531 25.5561Z'
          fill='url(#paint2_linear_1004_18472)'
        />
        <path
          d='M18.0801 25.5561C18.0761 26.2192 18.607 26.7538 19.2669 26.7482C19.9266 26.7424 20.4705 26.2 20.48 25.5386C20.4894 24.8771 19.9566 24.3456 19.2915 24.3495C18.6263 24.3535 18.0842 24.893 18.0801 25.5561Z'
          fill='url(#paint3_linear_1004_18472)'
        />
        <path
          d='M13.2061 25.5561C13.202 26.2192 13.733 26.7538 14.3928 26.7482C15.0526 26.7424 15.5964 26.2 15.6059 25.5386C15.6154 24.8771 15.0826 24.3456 14.4175 24.3495C13.7523 24.3535 13.2102 24.893 13.2061 25.5561Z'
          fill='url(#paint4_linear_1004_18472)'
        />
        <path
          d='M8.33303 25.5561C8.329 26.2192 8.85997 26.7538 9.5198 26.7482C10.1796 26.7424 10.7234 26.2 10.7329 25.5386C10.7424 24.8771 10.2096 24.3456 9.54445 24.3495C8.87925 24.3535 8.33714 24.893 8.33303 25.5561Z'
          fill='url(#paint5_linear_1004_18472)'
        />
        <path
          d='M20.0296 11.3081C19.4222 11.3081 18.8149 11.3088 18.2075 11.3102C17.5478 11.312 17.0098 11.8513 17.0067 12.5139C16.9982 14.4605 16.9953 16.407 16.9981 18.3534C16.9991 19.016 17.5378 19.5542 18.2003 19.5551C20.1999 19.5573 22.1997 19.5558 24.1994 19.5506C24.8619 19.5487 25.4017 19.0109 25.4035 18.35C25.4044 17.9932 25.405 17.6365 25.4052 17.2797C23.6146 15.2965 21.8123 13.2992 20.0296 11.3081Z'
          fill='#41C7FF'
        />
        <path
          d='M25.4049 17.2798C25.4059 15.6947 25.4002 14.1095 25.3878 12.5245C25.3824 11.8636 24.8419 11.3238 24.1822 11.3198C22.7979 11.312 21.4136 11.3082 20.0293 11.3081C20.0273 14.4143 22.3828 16.9686 25.4049 17.2798Z'
          fill='#8CDDFF'
        />
      </g>
      <defs>
        <linearGradient id='paint0_linear_1004_18472' x1='40.0919' y1='-0.0918582' x2='-0.0918367' y2='-0.0918582' gradientUnits='userSpaceOnUse'>
          <stop stopColor='#41C7FF' />
          <stop offset='1' stopColor='#8C84FF' />
        </linearGradient>
        <linearGradient id='paint1_linear_1004_18472' x1='10.7321' y1='20.5758' x2='24.0241' y2='7.28382' gradientUnits='userSpaceOnUse'>
          <stop stopColor='#FDA0FF' />
          <stop offset='1' stopColor='white' />
        </linearGradient>
        <linearGradient id='paint2_linear_1004_18472' x1='25.3586' y1='24.3439' x2='22.9476' y2='24.3439' gradientUnits='userSpaceOnUse'>
          <stop stopColor='#41C7FF' />
          <stop offset='1' stopColor='#8C84FF' />
        </linearGradient>
        <linearGradient id='paint3_linear_1004_18472' x1='20.4856' y1='24.3439' x2='18.0746' y2='24.3439' gradientUnits='userSpaceOnUse'>
          <stop stopColor='#41C7FF' />
          <stop offset='1' stopColor='#8C84FF' />
        </linearGradient>
        <linearGradient id='paint4_linear_1004_18472' x1='15.6116' y1='24.3439' x2='13.2005' y2='24.3439' gradientUnits='userSpaceOnUse'>
          <stop stopColor='#41C7FF' />
          <stop offset='1' stopColor='#8C84FF' />
        </linearGradient>
        <linearGradient id='paint5_linear_1004_18472' x1='10.7385' y1='24.3439' x2='8.3275' y2='24.3439' gradientUnits='userSpaceOnUse'>
          <stop stopColor='#41C7FF' />
          <stop offset='1' stopColor='#8C84FF' />
        </linearGradient>
        <clipPath id='clip0_1004_18472'>
          <rect width='40' height='40' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

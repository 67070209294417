import { IAuthUserDetails } from './../types';

export class AuthUserDetailsMapper {
  public static toDomain(item): IAuthUserDetails {
    const isKycLevel2user = item.rankId > 40 && item.roles?.some((r) => r?.name === 'IBI');

    return {
      country: item.country,
      customerId: item.customerId,
      username: item.username,
      email: item.email,
      firstName: item.firstName,
      fullName: item.fullName,
      isRetailCustomer: item.isRetailCustomer,
      languageId: item.languageId,
      lastName: item.lastName,
      phone: item.phone,
      phoneVerified: item.phoneVerified,
      defaultBaseUrl: item.region?.defaultBaseUrl,
      moneySpentAmount: item.spendings,
      enroller: item.enroller,
      membershipId: item.membershipId,
      unverifiedProducts: item.unverifiedProducts,
      roles: item.roles,
      rankId: item.rankId,
      isKycLevel2user,
      city: item.city,
      address: item.address,
      companyName: item.companyName,
      coapplicantLastName: item.coapplicantLastName,
      coapplicantFirstName: item.coapplicantFirstName,
      birthdate: item.birthdate,
      state: item.state,
      companyRegistrationNumber: item.companyRegistrationNumber,
      dateOfIncorporation: item.dateOfIncorporation,
      emailVerified: item.emailVerified,
      taxCode: item.taxCode,
      taxFilingNumber: item.taxFilingNumber,
      zipCode: item.zipCode,
      currencyCode: item.preferredCurrency?.code || null,
      isPaymentCardExpired: item.isPaymentCardExpired,
    };
  }
}

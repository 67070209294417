import { all, put, take } from '@redux-saga/core/effects';
import { authSlice } from '@root/modules/auth/redux';
import { call, SagaReturnType, takeLatest } from 'redux-saga/effects';
import { getBeBackOfficeToken } from '@root/modules/auth/services';
import { appSettingsSlice } from '@root/modules/app-settings/store/app-settings.slice';
import { DOMAINS } from '@root/modules/auth/constants/redirection-domains';

function* handleClear() {
  while (true) {
    yield take([authSlice.actions.loggedOut]);
    yield put(appSettingsSlice.actions.setSupportUrl(''));
  }
}

function* handleFetch() {
  const response: SagaReturnType<typeof getBeBackOfficeToken> = yield call(getBeBackOfficeToken);

  if (response.status === 200) {
    yield put(appSettingsSlice.actions.setSupportUrl(`${DOMAINS.helpCenter}?Token=${response.payload}`));
  } else {
    yield put(appSettingsSlice.actions.setSupportUrl(`${DOMAINS.helpCenter}`));
  }
}

export function* appSettingsFlowSaga() {
  yield takeLatest([authSlice.actions.fetchProfileFulfilled], handleFetch);
  yield all([handleClear()]);
}

export const RadioOnIcon = () => {
  return (
    <svg width='1em' height='1em' viewBox='0 0 15 15' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink'>
      <defs>
        <filter x='-228.6%' y='-228.6%' width='557.1%' height='557.1%' filterUnits='objectBoundingBox' id='filter-1'>
          <feOffset dx='-2' dy='2' in='SourceAlpha' result='shadowOffsetOuter1' />
          <feGaussianBlur stdDeviation='3' in='shadowOffsetOuter1' result='shadowBlurOuter1' />
          <feColorMatrix values='0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.128566576 0' type='matrix' in='shadowBlurOuter1' result='shadowMatrixOuter1' />
          <feMerge>
            <feMergeNode in='shadowMatrixOuter1' />
            <feMergeNode in='SourceGraphic' />
          </feMerge>
        </filter>
      </defs>
      <g id='Student+Educator' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='GUI---10.-Student-&amp;-Public-Pages---Elements' transform='translate(-250.000000, -1898.000000)'>
          <g id='Group' transform='translate(250.000000, 1898.000000)'>
            <g id='Border' stroke='#373D47'>
              <rect id='Rectangle-3' x='0.5' y='0.5' width='14' height='14' rx='7' />
            </g>
            <g filter='url(#filter-1)' transform='translate(4.000000, 4.000000)' fill='#00EDB8' id='Oval-8'>
              <circle cx='3.5' cy='3.5' r='3.5' />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

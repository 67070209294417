import styled from 'styled-components';

export const Title = styled.span`
  font-size: 13px;
  line-height: 18px;
  display: block;
  margin-bottom: 10px;
`;

export const Header = styled(Title)`
  font-weight: bold;
`;

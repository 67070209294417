import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { pageLinks } from '@root/shared/routing';
import { useLocation } from 'react-router';

import { selectKycLevel2Data, selectKycMeta } from '../../redux/kyc.selector';
import { KYC_STATUSES } from '../../enums/kyc.statuses';
import { useKycCommands } from './use-kyc-commands';

export const useAddressVerificationStep = () => {
  const level2Data = useSelector(selectKycLevel2Data);
  const kycMeta = useSelector(selectKycMeta);
  const location = useLocation();
  const [kycVisited, setKycVisited] = useState<boolean>(false);

  const status = level2Data?.verificationStatus;

  const [, { showPage, enableNavigation }] = useKycCommands();

  const addressController = useCallback(() => {
    console.log('-- 3. run address verification step');

    if ((status && [KYC_STATUSES.PENDING, KYC_STATUSES.PROCESSING].includes(status)) || kycMeta.isAnotherKyc2Started) {
      return showPage(pageLinks.kycAddressVerificationProcessing);
    }

    if (status && [KYC_STATUSES.ERROR].includes(status)) {
      return showPage(pageLinks.kycAddressVerificationFailed);
    }

    if (status && [KYC_STATUSES.SUCCESS, KYC_STATUSES.ACCEPTABLE].includes(status) && (!kycVisited || location.pathname === '/kyc')) {
      showPage(pageLinks.kycAddressVerificationSuccess);
      setKycVisited(true);
    }

    console.log('___ passed all steps');
    enableNavigation();
    return;
  }, [enableNavigation, showPage, status, kycMeta.isAnotherKyc2Started, kycVisited, location.pathname]);

  return addressController;
};

import { getProductCategories } from '../../services/get-product-categories';
import { productCategoriesSlice } from './product-categories.slice';
import { call, put, SagaReturnType, select, takeLatest } from '@redux-saga/core/effects';
import { AppState } from '@root/infra/redux';
import { authSlice } from '@root/modules/auth/redux';
import { selectBaseInstanceCountry } from '@root/modules/meta/redux';
import { GetProductsQuery } from '@root/modules/products/services/get-products';
import queryString from 'query-string';

function* handleFetch() {
  const auth = yield select((state: AppState) => state.auth);
  const uiCountry = yield select(selectBaseInstanceCountry);
  const queryData = queryString.parse(window?.location?.search, { parseNumbers: true });
  const country = queryData?.country || auth?.country || auth?.user?.country || uiCountry;
  const query: GetProductsQuery = { uiCountry: country };

  if (auth.user) {
    query.userId = auth.user.customerId;
  }

  const result: SagaReturnType<typeof getProductCategories> = yield call(getProductCategories, query);

  if (result.status === 200) {
    yield put(productCategoriesSlice.actions.fetchFulfilled(result.payload));
  } else {
    yield put(productCategoriesSlice.actions.fetchRejected(result.payload));
  }
}

export function* productCategoriesFlowSaga() {
  // yield call(handleFetch);
  yield takeLatest([productCategoriesSlice.actions.fetchPending.type, authSlice.actions.countryUpdated.type, authSlice.actions.fetchProfileFulfilled.type], handleFetch);
}

import { fetcher } from '@root/infra/fetcher';
import { IGiftCards } from "@root/modules/gift-cards/types/giftCards";
import { IHttpResponse } from "@root/shared/http-service";
import { IPaginatedData } from "@root/shared/paginated-data";
import { handleAxiosError } from "@root/shared/utils/handle-axios-error";
import { AxiosError } from "axios";


export type GetGiftCardServiceQuery = Partial<{
	page: number;
	limit: number;
	sortDirection: string;
	searchParam: string;
	used: boolean;
}>

export type GetGiftCardsServiceResponse = IHttpResponse<200, GetGiftCardsServiceData> | IHttpResponse<400, string>;

export type GetGiftCardsServiceData = IPaginatedData<IGiftCards>;

export const getGiftCardsService = async (query): Promise<GetGiftCardsServiceResponse> => {
  try {
    const response = await fetcher.get(`/coupon/gifted/list`, {params: query});
    return {
      status: 200,
      payload: {
        totalItems: response.data.data.meta.total,
        totalPages: response.data.data.meta.totalPages,
        data: response.data.data.items,
      },
    };
  } catch (error) {
    return handleAxiosError(error as AxiosError);
  }
};

import { IQuickActionsCard } from '../components/quick-action-card';
import { Dictionary } from '@root/shared/types';
import { ActionsAccountIcon } from '@root/shared/ui/icons/actions-account-icon';
import { ActionsPaymentsIcon } from '@root/shared/ui/icons/actions-payments-icon';
import { ActionsSubscriptionsIcon } from '@root/shared/ui/icons/actions-subscriptions-icon';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

export const useQuickActions = () => {
  const { t } = useTranslation('dashboard', { keyPrefix: 'quickActions.cards' });
  const navigate = useNavigate();

  const items: Dictionary<IQuickActionsCard> = useMemo(
    () => ({
      subscriptions: {
        title: t('subscriptions.title'),
        description: t('subscriptions.description'),
        icon: <ActionsSubscriptionsIcon />,
        cardClickAction: () => navigate('/subscriptions'),
      },
      paymentMethods: {
        title: t('paymentMethods.title'),
        description: t('paymentMethods.description'),
        icon: <ActionsPaymentsIcon />,
        cardClickAction: () => navigate('/payment-methods'),
      },
      account: {
        title: t('account.title'),
        description: t('account.description'),
        icon: <ActionsAccountIcon />,
        cardClickAction: () => navigate('/settings'),
      },
    }),
    [t, navigate],
  );

  const state = { items };
  const handlers = {};

  return [state, handlers] as [typeof state, typeof handlers];
};

import { useState, useCallback } from 'react';

import { Id } from '@root/shared/constants';
import { INotification } from '../types/notification';

export const useNotifications = () => {
  const [items, setItems] = useState<INotification[]>([]);

  const addNotification = useCallback((item: INotification) => {
    setItems((prev) => [...prev, item]);
  }, []);

  const removeNotification = useCallback((id: Id) => {
    setItems((prev) => prev.filter((item) => item.id !== id));
  }, []);

  const clearNotifications = useCallback(() => {
    setItems([]);
  }, []);

  const state = { notifications: items };
  const handlers = { addNotification, removeNotification, clearNotifications };

  return [state, handlers] as [typeof state, typeof handlers];
};

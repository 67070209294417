import { createContext, useContext } from 'react';

type ContextProps = {
  activeStep: number;
  setActiveStep(step: number): void;
};

export const StepperContext = createContext<ContextProps>({ activeStep: 0, setActiveStep: () => {} });

export const useStepperContext = () => useContext(StepperContext);

import { FC, ReactNode } from 'react';

import { BodyText, H3, LinkButton } from '@root/shared/ui';
import { ArrowLeftWithoutColorIcon as ArrowIcon } from '@root/shared/ui/icons/arrow-left';

import { DashboardUI } from './dashboard.ui';

const { Card } = DashboardUI;

export interface IStatisticsCard {
  title: string;
  description: string;
  icon: ReactNode;
  buttonText: string;
  buttonAction: () => any;
}

export const StatisticCard: FC<IStatisticsCard> = ({ title, description, buttonAction, buttonText, icon }) => {
  return (
    <Card>
      <div className='min-h-[90px] h-full flex gap-4'>
        <div className='flex flex-col h-full'>
          <H3 className='font-bold mb-1 lg:text-2xl'>{title}</H3>
          <BodyText className='font-bold text-gray-600 mb-2'>{description}</BodyText>
          <LinkButton as='button' className='mt-auto justify-self-end text-left' onClick={buttonAction}>
            {buttonText}
            <ArrowIcon className='rotate-180 ml-2' />
          </LinkButton>
        </div>
        <div className='ml-auto text-3xl'>{icon}</div>
      </div>
    </Card>
  );
};

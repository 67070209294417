import styled from 'styled-components';

export const SearchResult = styled.div`
  & > *:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing(4)}px;
  }

  padding-bottom: ${({ theme }) => theme.spacing(4)}px;
`;

export const SearchItem = styled.p`
  font-size: 14px;
  margin-bottom: 0;
`;

export const SearchItemWithAvatar = styled.div`
  display: flex;
`;

export const SearchItemAvatar = styled.img`
  width: 68px;
  height: 42px;
  object-fit: cover;
  border-radius: ${({ theme }) => theme.spacing(2)}px;
  margin-right: ${({ theme }) => theme.spacing(4)}px;
`;

export const SearchItemText = styled.p`
  font-size: 14px;
  max-height: 42px;
`;

export const SearchContent = styled.div<{ rounded?: boolean }>`
  padding: ${({ theme }) => theme.spacing(6)}px;
  border-radius: ${({ rounded, theme }) => (rounded ? `${theme.spacing(4)}px` : '0')};
  background-color: ${({ theme }) => theme.colors.gray['800']};
`;

export const SearchTitle = styled.h4`
  color: ${({ theme }) => theme.colors.gray['500']};
  margin-bottom: ${({ theme }) => theme.spacing(4)}px;
`;

export const SearchAction = styled.h4`
  color: ${({ theme }) => theme.colors.primary['300']};
  font-weight: 600;
`;

import { RefObject, useCallback, useEffect } from 'react';

export const useOutsideClick = (ref: RefObject<any>, onOutsideClick: () => void, skipClicksInsideModal?: boolean) => {
  const handleOutsideClick = useCallback(
    (e: MouseEvent) => {
      const modal = document.getElementById('absolute-portal-area');

      if (skipClicksInsideModal && modal && modal.contains(e.target as Node)) {
        return;
      }

      if (ref.current && !ref.current.contains(e.target)) {
        onOutsideClick();
      }
    },
    [ref, onOutsideClick, skipClicksInsideModal],
  );

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);

    return () => document.removeEventListener('click', handleOutsideClick);
  }, [handleOutsideClick]);
};

import { CalendarIcon } from '@root/shared/ui/icons/calendar-icon';
import { FC, forwardRef } from 'react';
import DatePicker from 'react-datepicker';
import InputMask from 'react-input-mask';
import styled from 'styled-components';
import { Input, InputProps } from './input';
import { InputError } from './input-error';
import { InputLabel } from './input-label';
import { ReactI18NextChild } from 'react-i18next';

export interface DateFieldProps extends InputProps {
  label?: string;
  error?: string;
  hideError?: boolean;
  itemClassName?: string;
  topLinkElement?: ReactI18NextChild;
  format?: string;
  mask: string;
}

export const CalendarIconWrapper = styled.div`
  filter: grayscale(100%);

  &:hover {
    filter: grayscale(0);
  }
`;

const InputWithRef = forwardRef((props: any, ref: any) => {
  return (
    <div className='relative w-full'>
      <InputMask {...props} error={props.className === '--error'} ref={(e) => (ref = e)} autoComplete='off'>
        {(maskProps) => <Input {...maskProps} />}
      </InputMask>
      <div className='absolute top-0 right-0 bottom-0 cursor-pointer'>
        <CalendarIconWrapper className='flex items-center h-full w-6 mr-4'>
          <CalendarIcon />
        </CalendarIconWrapper>
      </div>
    </div>
  );
});

InputWithRef.displayName = 'InputWithRef';

export const DateField: FC<DateFieldProps> = ({ label, error, format, itemClassName, hideError, extra, topLinkElement = null, mask, value, placeholder, ...inputProps }) => {
  return (
    <label className={itemClassName}>
      <div className='w-full flex justify-between'>
        <div>{!!label && <InputLabel>{label}</InputLabel>}</div>
        <div>{topLinkElement}</div>
      </div>
      <DatePicker
        dateFormat={format}
        {...inputProps}
        className={error ? '--error' : ''}
        selected={(value && new Date(value)) || null}
        placeholderText={placeholder}
        mask={mask}
        showYearDropdown
        dropdownMode='select'
        customInput={<InputWithRef mask={mask} />}
      />
      {!hideError && <InputError>{error}</InputError>}
    </label>
  );
};

export const DOMAINS = {
  helpCenter: 'https://help.livesupportnow.com/hc/en-gb/requests/new',
  sustainableClothing: 'https://kephiclothing.com/ ',
};

const stgUrls = {
  myPortalBaseUrl: 'stg.fastshoppingstore.com',
};

const prodUrls = {
  myPortalBaseUrl: 'fastshoppingstore.com',
};

export const externalLinks = typeof window !== 'undefined' ? (window.location.href.includes('stg') || window.location.href.includes('localhost') ? stgUrls : prodUrls) : prodUrls;

import { call, put, takeLatest, SagaReturnType } from '@redux-saga/core/effects';

import { getServices } from '../../services/get-services';
import { servicesSlice } from './services.slice';

export function* handleFetch() {
  const response: SagaReturnType<typeof getServices> = yield call(getServices);
  if (response.status === 200) {
    yield put(servicesSlice.actions.fulfilled(response.payload));
  } else {
    yield put(servicesSlice.actions.rejected(response.payload));
  }
}

export function* servicesFlowSaga() {
  yield call(handleFetch);
  yield takeLatest(servicesSlice.actions.pending, handleFetch);
}

import styled from 'styled-components';

export const AspectRatio = styled.div<{ height: number; width: number }>`
  width: 100%;
  height: 0;
  padding-bottom: ${({ height, width }) => (height / width) * 100}%;
  position: relative;
`;

export const RatioInner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

import { FC } from 'react';

export const ActionsSubscriptionsIcon: FC<any> = (props) => {
  return (
    <svg width='100%' height='100%' {...props} viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='40' height='40' rx='20' fill='url(#paint0_linear_1004_41619)' />
      <g clipPath='url(#clip0_1004_41619)'>
        <path
          d='M27.8556 8.86847C27.642 8.86847 27.4688 8.69526 27.4688 8.48162C27.4688 8.26799 27.642 8.09478 27.8556 8.09478C28.4955 8.09478 29.0161 7.57413 29.0161 6.93424C29.0161 6.29434 28.4955 5.77369 27.8556 5.77369C27.642 5.77369 27.4688 5.60048 27.4688 5.38685C27.4688 5.17321 27.642 5 27.8556 5C28.9221 5 29.7898 5.8677 29.7898 6.93424C29.7898 8.00077 28.9221 8.86847 27.8556 8.86847Z'
          fill='#8CDDFF'
        />
        <path
          d='M20.505 8.86847C20.2914 8.86847 20.1182 8.69526 20.1182 8.48162C20.1182 8.26799 20.2914 8.09478 20.505 8.09478C21.1449 8.09478 21.6656 7.57413 21.6656 6.93424C21.6656 6.29434 21.1449 5.77369 20.505 5.77369C20.2914 5.77369 20.1182 5.60048 20.1182 5.38685C20.1182 5.17321 20.2914 5 20.505 5C21.5715 5 22.4392 5.8677 22.4392 6.93424C22.4392 8.00077 21.5715 8.86847 20.505 8.86847Z'
          fill='#8CDDFF'
        />
        <path
          opacity='0.15'
          d='M32.2283 23.1818H17.7717C16.9313 23.1818 16.25 22.489 16.25 21.6344V10.029H33.75V21.6344C33.75 22.489 33.0687 23.1818 32.2283 23.1818Z'
          fill='#0281FF'
        />
        <path d='M30.5386 23.3334H17.7947C16.9416 23.3334 16.25 22.5872 16.25 21.6667V9.16669H32.0833V21.6667C32.0833 22.5872 31.3917 23.3334 30.5386 23.3334Z' fill='#C9EFFF' />
        <path
          opacity='0.15'
          d='M32.2283 6.66662H17.7717C16.9313 6.66662 16.25 7.22627 16.25 7.91662V9.99996H33.75V7.91662C33.75 7.22627 33.0687 6.66662 32.2283 6.66662Z'
          fill='#0281FF'
        />
        <path d='M30.5386 6.66662H17.7947C16.9416 6.66662 16.25 7.41282 16.25 8.33329V9.99996H32.0833V8.33329C32.0833 7.41282 31.3917 6.66662 30.5386 6.66662Z' fill='#8CDDFF' />
        <path opacity='0.15' d='M22.9167 6.66667V23.3333H16.25V9.65812V7.94872C16.5833 6.92308 17.5 6.66667 17.9167 6.66667H22.9167Z' fill='#0281FF' />
        <path
          d='M20.5055 8.86847C19.439 8.86847 18.5713 8.00077 18.5713 6.93424C18.5713 5.8677 19.439 5 20.5055 5C20.7192 5 20.8924 5.17321 20.8924 5.38685C20.8924 5.60048 20.7192 5.77369 20.5055 5.77369C19.8656 5.77369 19.345 6.29434 19.345 6.93424C19.345 7.57413 19.8656 8.09478 20.5055 8.09478C20.7192 8.09478 20.8924 8.26799 20.8924 8.48162C20.8924 8.69526 20.7192 8.86847 20.5055 8.86847Z'
          fill='white'
        />
        <path
          d='M27.8551 8.86847C26.7886 8.86847 25.9209 8.00077 25.9209 6.93424C25.9209 5.8677 26.7886 5 27.8551 5C28.0688 5 28.242 5.17321 28.242 5.38685C28.242 5.60048 28.0688 5.77369 27.8551 5.77369C27.2152 5.77369 26.6946 6.29434 26.6946 6.93424C26.6946 7.57413 27.2152 8.09478 27.8551 8.09478C28.0688 8.09478 28.242 8.26799 28.242 8.48162C28.242 8.69526 28.0688 8.86847 27.8551 8.86847Z'
          fill='white'
        />
        <path
          d='M22.4398 14.2843C23.0808 14.2843 23.6004 13.7647 23.6004 13.1238C23.6004 12.4828 23.0808 11.9632 22.4398 11.9632C21.7989 11.9632 21.2793 12.4828 21.2793 13.1238C21.2793 13.7647 21.7989 14.2843 22.4398 14.2843Z'
          fill='#41C7FF'
        />
        <path
          d='M25.9213 14.2843C26.5622 14.2843 27.0818 13.7647 27.0818 13.1238C27.0818 12.4828 26.5622 11.9632 25.9213 11.9632C25.2803 11.9632 24.7607 12.4828 24.7607 13.1238C24.7607 13.7647 25.2803 14.2843 25.9213 14.2843Z'
          fill='#41C7FF'
        />
        <path
          d='M29.4037 14.2843C30.0447 14.2843 30.5642 13.7647 30.5642 13.1238C30.5642 12.4828 30.0447 11.9632 29.4037 11.9632C28.7628 11.9632 28.2432 12.4828 28.2432 13.1238C28.2432 13.7647 28.7628 14.2843 29.4037 14.2843Z'
          fill='#41C7FF'
        />
        <path
          d='M18.9584 17.7659C19.5993 17.7659 20.1189 17.2463 20.1189 16.6054C20.1189 15.9644 19.5993 15.4448 18.9584 15.4448C18.3174 15.4448 17.7979 15.9644 17.7979 16.6054C17.7979 17.2463 18.3174 17.7659 18.9584 17.7659Z'
          fill='#AAC3D7'
        />
        <path
          d='M22.4398 17.7659C23.0808 17.7659 23.6004 17.2463 23.6004 16.6054C23.6004 15.9644 23.0808 15.4448 22.4398 15.4448C21.7989 15.4448 21.2793 15.9644 21.2793 16.6054C21.2793 17.2463 21.7989 17.7659 22.4398 17.7659Z'
          fill='#AAC3D7'
        />
        <path
          d='M25.9213 17.7659C26.5622 17.7659 27.0818 17.2463 27.0818 16.6054C27.0818 15.9644 26.5622 15.4448 25.9213 15.4448C25.2803 15.4448 24.7607 15.9644 24.7607 16.6054C24.7607 17.2463 25.2803 17.7659 25.9213 17.7659Z'
          fill='#41C7FF'
        />
        <path
          d='M29.4037 17.7659C30.0447 17.7659 30.5642 17.2463 30.5642 16.6054C30.5642 15.9644 30.0447 15.4448 29.4037 15.4448C28.7628 15.4448 28.2432 15.9644 28.2432 16.6054C28.2432 17.2463 28.7628 17.7659 29.4037 17.7659Z'
          fill='#41C7FF'
        />
        <path
          d='M18.9584 21.2475C19.5993 21.2475 20.1189 20.7279 20.1189 20.0869C20.1189 19.446 19.5993 18.9264 18.9584 18.9264C18.3174 18.9264 17.7979 19.446 17.7979 20.0869C17.7979 20.7279 18.3174 21.2475 18.9584 21.2475Z'
          fill='#41C7FF'
        />
        <path
          d='M22.4398 21.2475C23.0808 21.2475 23.6004 20.7279 23.6004 20.0869C23.6004 19.446 23.0808 18.9264 22.4398 18.9264C21.7989 18.9264 21.2793 19.446 21.2793 20.0869C21.2793 20.7279 21.7989 21.2475 22.4398 21.2475Z'
          fill='#41C7FF'
        />
        <path
          d='M25.9213 21.2475C26.5622 21.2475 27.0818 20.7279 27.0818 20.0869C27.0818 19.446 26.5622 18.9264 25.9213 18.9264C25.2803 18.9264 24.7607 19.446 24.7607 20.0869C24.7607 20.7279 25.2803 21.2475 25.9213 21.2475Z'
          fill='#41C7FF'
        />
        <path
          d='M18.9584 17.7659C19.5993 17.7659 20.1189 17.2463 20.1189 16.6054C20.1189 15.9644 19.5993 15.4448 18.9584 15.4448C18.3174 15.4448 17.7979 15.9644 17.7979 16.6054C17.7979 17.2463 18.3174 17.7659 18.9584 17.7659Z'
          fill='#41C7FF'
        />
        <path
          d='M22.4398 17.7659C23.0808 17.7659 23.6004 17.2463 23.6004 16.6054C23.6004 15.9644 23.0808 15.4448 22.4398 15.4448C21.7989 15.4448 21.2793 15.9644 21.2793 16.6054C21.2793 17.2463 21.7989 17.7659 22.4398 17.7659Z'
          fill='#41C7FF'
        />
        <g clipPath='url(#clip1_1004_41619)'>
          <path d='M6.25 14.1666H24.25V35.1666C24.25 36.8235 22.9069 38.1666 21.25 38.1666H9.25C7.59315 38.1666 6.25 36.8235 6.25 35.1666V14.1666Z' fill='white' />
          <path d='M6.25 12.1666C6.25 10.5098 7.59315 9.16663 9.25 9.16663H21.25C22.9069 9.16663 24.25 10.5098 24.25 12.1666V14.1666H6.25V12.1666Z' fill='#C9EFFF' />
          <path
            opacity='0.15'
            d='M9.16667 32.5V13.3333H6.25V13.4016V31.0066C6.25 32.9166 7.91667 33.3333 8.75 33.3333H9.58333C9.20622 33.3332 9.16667 32.8771 9.16667 32.5Z'
            fill='#0281FF'
          />
          <path
            d='M16.6792 34.9745H10.6699C10.1042 34.9745 9.64551 34.5159 9.64551 33.9502V32.721C9.64551 32.1553 10.1041 31.6966 10.6699 31.6966H16.6792C17.2449 31.6966 17.7036 32.1552 17.7036 32.721V33.9502C17.7036 34.5159 17.2449 34.9745 16.6792 34.9745Z'
            fill='url(#paint1_linear_1004_41619)'
          />
          <path
            opacity='0.15'
            d='M12.377 33.9502V32.721C12.377 32.1553 12.8356 31.6966 13.4014 31.6966H10.6699C10.1042 31.6966 9.64551 32.1552 9.64551 32.721V33.9502C9.64551 34.5159 10.1041 34.9745 10.6699 34.9745H13.4014C12.8356 34.9745 12.377 34.5159 12.377 33.9502Z'
            fill='#0281FF'
          />
          <path
            d='M18.0786 24.2935H10.9425C10.6679 24.2935 10.4453 24.0709 10.4453 23.7964C10.4453 23.5218 10.6679 23.2992 10.9425 23.2992H18.0786C18.3532 23.2992 18.5758 23.5218 18.5758 23.7964C18.5758 24.0709 18.3532 24.2935 18.0786 24.2935Z'
            fill='#8CDDFF'
          />
          <path
            d='M9.40624 24.2935H9.26962C8.99506 24.2935 8.77246 24.0709 8.77246 23.7964C8.77246 23.5218 8.99506 23.2992 9.26962 23.2992H9.40624C9.6808 23.2992 9.9034 23.5218 9.9034 23.7964C9.9034 24.0709 9.6808 24.2935 9.40624 24.2935Z'
            fill='#8CDDFF'
          />
          <path
            d='M18.0786 27.106H10.9425C10.6679 27.106 10.4453 26.8834 10.4453 26.6089C10.4453 26.3343 10.6679 26.1117 10.9425 26.1117H18.0786C18.3532 26.1117 18.5758 26.3343 18.5758 26.6089C18.5758 26.8834 18.3532 27.106 18.0786 27.106Z'
            fill='#8CDDFF'
          />
          <path
            d='M9.40624 27.106H9.26962C8.99506 27.106 8.77246 26.8834 8.77246 26.6089C8.77246 26.3343 8.99506 26.1117 9.26962 26.1117H9.40624C9.6808 26.1117 9.9034 26.3343 9.9034 26.6089C9.9034 26.8834 9.6808 27.106 9.40624 27.106Z'
            fill='#8CDDFF'
          />
          <path
            d='M18.0786 29.9185H10.9425C10.6679 29.9185 10.4453 29.6959 10.4453 29.4214C10.4453 29.1468 10.6679 28.9242 10.9425 28.9242H18.0786C18.3532 28.9242 18.5758 29.1468 18.5758 29.4214C18.5758 29.6959 18.3532 29.9185 18.0786 29.9185Z'
            fill='#8CDDFF'
          />
          <path
            d='M9.40624 29.9185H9.26962C8.99506 29.9185 8.77246 29.6959 8.77246 29.4214C8.77246 29.1468 8.99506 28.9242 9.26962 28.9242H9.40624C9.6808 28.9242 9.9034 29.1468 9.9034 29.4214C9.9034 29.6959 9.6808 29.9185 9.40624 29.9185Z'
            fill='#8CDDFF'
          />
          <path
            d='M17.6354 12.0487H9.71396C9.43939 12.0487 9.2168 11.8261 9.2168 11.5515C9.2168 11.2769 9.43939 11.0543 9.71396 11.0543H17.6354C17.9099 11.0543 18.1325 11.2769 18.1325 11.5515C18.1325 11.8261 17.91 12.0487 17.6354 12.0487Z'
            fill='#C9EFFF'
          />
          <path
            opacity='0.15'
            d='M13.9771 20.068C13.9771 19.1545 13.2082 18.4114 12.263 18.4114H11.1457C10.976 18.4114 10.838 18.278 10.838 18.114C10.838 17.95 10.976 17.8166 11.1457 17.8166H13.1143C13.5027 17.8166 13.8175 17.5123 13.8175 17.1369C13.8175 16.7616 13.5027 16.4573 13.1143 16.4573H12.4076V16.1342C12.4076 15.7589 12.0928 15.4546 11.7044 15.4546C11.3161 15.4546 11.0012 15.7589 11.0012 16.1342V16.4644C10.1236 16.5357 9.43164 17.2476 9.43164 18.114C9.43164 19.0275 10.2005 19.7706 11.1457 19.7706H12.263C12.4327 19.7706 12.5707 19.904 12.5707 20.068C12.5707 20.232 12.4327 20.3654 12.263 20.3654H10.2944C9.90605 20.3654 9.59122 20.6697 9.59122 21.045C9.59122 21.4204 9.90605 21.7246 10.2944 21.7246H11.0011V22.0477C11.0011 22.423 11.316 22.7273 11.7043 22.7273C12.0927 22.7273 12.4075 22.423 12.4075 22.0477V21.7175C13.2852 21.6463 13.9771 20.9344 13.9771 20.068Z'
            fill='#0281FF'
          />
          <path
            d='M13.523 19.6134C13.523 18.6999 12.7541 17.9568 11.8089 17.9568H10.6916C10.5219 17.9568 10.3839 17.8234 10.3839 17.6594C10.3839 17.4953 10.5219 17.362 10.6916 17.362H12.6602C13.0486 17.362 13.3634 17.0577 13.3634 16.6823C13.3634 16.307 13.0486 16.0027 12.6602 16.0027H11.9535V15.6796C11.9535 15.3042 11.6387 15 11.2503 15C10.862 15 10.5471 15.3042 10.5471 15.6796V16.0098C9.66947 16.0811 8.97754 16.793 8.97754 17.6594C8.97754 18.5728 9.74645 19.316 10.6916 19.316H11.8089C11.9786 19.316 12.1166 19.4494 12.1166 19.6134C12.1166 19.7774 11.9786 19.9108 11.8089 19.9108H9.8403C9.45195 19.9108 9.13712 20.2151 9.13712 20.5904C9.13712 20.9657 9.45195 21.27 9.8403 21.27H10.547V21.5931C10.547 21.9684 10.8619 22.2727 11.2502 22.2727C11.6386 22.2727 11.9534 21.9684 11.9534 21.5931V21.2629C12.8311 21.1917 13.523 20.4798 13.523 19.6134Z'
            fill='url(#paint2_linear_1004_41619)'
          />
          <path
            opacity='0.15'
            d='M10.25 10.6666C10.65 9.86663 11.75 9.16663 12.25 9.16663H9.25C6.85 9.16663 6.25 11.1666 6.25 12.1666V14.1666H9.75C9.75 11.6666 9.75 11.6666 10.25 10.6666Z'
            fill='#0281FF'
          />
        </g>
      </g>
      <defs>
        <linearGradient id='paint0_linear_1004_41619' x1='40.0919' y1='-0.0918582' x2='-0.0918367' y2='-0.0918582' gradientUnits='userSpaceOnUse'>
          <stop stopColor='#41C7FF' />
          <stop offset='1' stopColor='#8C84FF' />
        </linearGradient>
        <linearGradient id='paint1_linear_1004_41619' x1='17.7221' y1='31.6891' x2='9.62701' y2='31.6891' gradientUnits='userSpaceOnUse'>
          <stop stopColor='#41C7FF' />
          <stop offset='1' stopColor='#8C84FF' />
        </linearGradient>
        <linearGradient id='paint2_linear_1004_41619' x1='13.5334' y1='14.9833' x2='8.9671' y2='14.9833' gradientUnits='userSpaceOnUse'>
          <stop stopColor='#41C7FF' />
          <stop offset='1' stopColor='#8C84FF' />
        </linearGradient>
        <clipPath id='clip0_1004_41619'>
          <rect width='27.5' height='30' fill='white' transform='translate(6.25 5)' />
        </clipPath>
        <clipPath id='clip1_1004_41619'>
          <rect width='15' height='25.8333' fill='white' transform='translate(6.25 9.16663)' />
        </clipPath>
      </defs>
    </svg>
  );
};

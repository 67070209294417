import { FC } from 'react';
import { baseIconProps } from './base-icon-props';

export const AccountIcon: FC<any> = (props) => {
  return (
    <svg {...baseIconProps} width='24px' height='24px' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g id='15-11' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='GUI---6.-Interactive-Elements' transform='translate(-1308.000000, -301.000000)'>
          <g id='Menu' transform='translate(0.000000, 270.000000)'>
            <g id='user' transform='translate(1308.000000, 31.000000)'>
              <polygon id='Path' points='0 0 24.0000001 0 24.0000001 24.0000001 0 24.0000001' />
              <path
                d='M12,12.7580001 C16.6950635,12.7580001 20.75,15.11863 20.75,18.5000001 L20.75,18.5000001 L20.75,19.5000001 C20.75,20.4662137 19.9662136,21.2500001 19,21.2500001 L19,21.2500001 L5.00000002,21.2500001 C4.03378646,21.2500001 3.25000002,20.4662136 3.25000002,19.5000001 L3.25000002,19.5000001 L3.25000002,18.5000001 C3.25000002,15.11863 7.3049366,12.7580001 12,12.7580001 Z M12,14.2580001 C8.02413042,14.2580001 4.75000002,16.1640743 4.75000002,18.5000001 L4.75000002,18.5000001 L4.75000002,19.5000001 C4.75000002,19.6377865 4.86221358,19.7500001 5.00000002,19.7500001 L5.00000002,19.7500001 L19,19.7500001 C19.1377865,19.7500001 19.25,19.6377866 19.25,19.5000001 L19.25,19.5000001 L19.25,18.5000001 C19.25,16.1640743 15.9758697,14.2580001 12,14.2580001 Z M15.0052301,3.99479993 C16.6649634,5.65453316 16.6649634,8.3454769 15.0052301,10.0052101 C13.3454969,11.6649433 10.6545532,11.6649433 8.99481995,10.0052101 C7.33508673,8.3454769 7.33508673,5.65453316 8.99481995,3.99479993 C10.6545532,2.33506671 13.3454969,2.33506671 15.0052301,3.99479993 Z M10.0554801,5.0554601 C8.98153334,6.12940689 8.98153334,7.87060317 10.0554801,8.94454995 C11.1294269,10.0184967 12.8706232,10.0184967 13.94457,8.94454995 C14.973769,7.91535095 15.0166523,6.27334816 14.0732198,5.19308218 L13.94457,5.0554601 L13.8069479,4.92681023 C12.7266819,3.98337781 11.0846791,4.0262611 10.0554801,5.0554601 Z'
                id='Combined-Shape'
                fill='currentColor'
                fillRule='nonzero'
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

import { SagaReturnType, takeEvery, call, put } from 'redux-saga/effects';
import { authSlice } from '@root/modules/auth/redux';
import { getBillingDetails } from '../../services/get-billing-details.service';
import { paymentMethodsSlice } from '../payment-methods.slice';
import { billingDetailsSlice } from './billing-details.slice';

function* handleFetch() {
  const result: SagaReturnType<typeof getBillingDetails> = yield call(getBillingDetails);

  if (result.status === 200) {
    yield put(billingDetailsSlice.actions.fetchFulfilled(result.payload));
  } else {
    yield put(billingDetailsSlice.actions.fetchRejected(result.payload));
  }
}

export function* billingDetailsSaga() {
  yield takeEvery([billingDetailsSlice.actions.fetchPending, authSlice.actions.fetchProfileFulfilled, paymentMethodsSlice.actions.makeDefaultFulfilled], handleFetch);
}

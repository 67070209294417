import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { To } from '@remix-run/router';
import { NavigateOptions } from 'react-router/dist/lib/context';

type NavigationParams = { to: To; options?: NavigateOptions };

export type INavigationState = {
  initialized: boolean;
  previouslyNavigatedParams: NavigationParams | null;
};

export namespace NavigationActions {
  export type Push = PayloadAction<NavigationParams | null>;
  export type SetInitialized = PayloadAction<boolean>;
}

export type NavigationSliceReducer = {
  push: CaseReducer<INavigationState, NavigationActions.Push>;
  setInitialized: CaseReducer<INavigationState, NavigationActions.SetInitialized>;
};

export const navigationState: INavigationState = {
  initialized: false,
  previouslyNavigatedParams: null,
};

export const navigationSlice = createSlice<INavigationState, NavigationSliceReducer, 'navigation'>({
  name: 'navigation',
  initialState: navigationState,
  reducers: {
    push: (state, action) => {
      state.previouslyNavigatedParams = state.initialized ? null : action.payload;
    },
    setInitialized: (state, action) => {
      state.initialized = action.payload;
    },
  },
});

import { useHelpCenter } from '../hooks';
import React, { FC, PropsWithChildren, useCallback } from 'react';

export const HelpCenter: FC<PropsWithChildren> = ({ children }) => {
  const { redirect, supportLinkWithToken } = useHelpCenter();

  const handleSupportClick = useCallback(
    (e: React.SyntheticEvent) => {
      e.preventDefault();
      redirect();
    },
    [redirect],
  );

  return (
    <a href={supportLinkWithToken} rel='noopener noreferrer' className='help_button_header' onClick={handleSupportClick}>
      {children}
    </a>
  );
};

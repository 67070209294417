import { ILoginData } from '../types';

export class LoginDataMapper {
  public static toDomain(item): ILoginData {
    return {
      country: item.country,
      redirectionUrl: item.redirectionUrl,
      wrongDomain: item.wrongDomain,
    };
  }
}

import { fetcher } from '@root/infra/fetcher';
import type { IHttpResponse } from '@root/shared/http-service';
import { AxiosError } from 'axios';
import { AuthUserDetailsMapper } from '../mappers';
import { IAuthUserDetails } from '../types';

export type FetchProfileServiceResponse = IHttpResponse<200, IAuthUserDetails> | IHttpResponse<400, string>;

export const fetchProfileService = async (): Promise<FetchProfileServiceResponse> => {
  try {
    const response = await fetcher('v1/user/details');
    return {
      status: 200,
      payload: AuthUserDetailsMapper.toDomain(response.data.data),
    };
  } catch (e) {
    const error = e as AxiosError;
    console.error(e);

    if (error.isAxiosError && error.response) {
      return {
        status: 400,
        payload: error.response.data.message,
      };
    }

    return {
      status: 400,
      payload: 'Bad request',
    };
  }
};

import { IRemoteData } from '@root/shared/remote-data';
import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ICanBeSwitchedToItem } from '@root/modules/products/types/purchased-product';
import { ToggleRenewalDto } from '../dtos/toggle-renewal.dto';

export type SubscriptionsState = {
  activeUpgrade: ICanBeSwitchedToItem | null;
  toggleRenewal: IRemoteData<any>;
};

export namespace SubscriptionsActions {
  export type SetActiveUpgrade = PayloadAction<ICanBeSwitchedToItem>;
  export type ResetActiveUpgrade = PayloadAction;

  export type ToggleRenewalPending = PayloadAction<ToggleRenewalDto>;
  export type ToggleRenewalFulfilled = PayloadAction;
  export type ToggleRenewalRejected = PayloadAction<string>;
}

export type SettingsSliceReducer = {
  setActiveUpgrade: CaseReducer<SubscriptionsState, SubscriptionsActions.SetActiveUpgrade>;
  resetActiveUpgrade: CaseReducer<SubscriptionsState, SubscriptionsActions.ResetActiveUpgrade>;

  toggleRenewalPending: CaseReducer<SubscriptionsState, SubscriptionsActions.ToggleRenewalPending>;
  toggleRenewalFulfilled: CaseReducer<SubscriptionsState, SubscriptionsActions.ToggleRenewalFulfilled>;
  toggleRenewalRejected: CaseReducer<SubscriptionsState, SubscriptionsActions.ToggleRenewalRejected>;
};

const initialState: SubscriptionsState = {
  activeUpgrade: null,
  toggleRenewal: {
    isLoading: false,
    data: null,
    error: null,
  },
};

export const subscriptionsSlice = createSlice<SubscriptionsState, SettingsSliceReducer, 'subscriptions'>({
  name: 'subscriptions',
  initialState,
  reducers: {
    setActiveUpgrade: (state, action) => {
      state.activeUpgrade = action.payload;
    },
    resetActiveUpgrade: (state) => {
      state.activeUpgrade = null;
    },

    toggleRenewalPending: (state) => {
      state.toggleRenewal.error = null;
      state.toggleRenewal.isLoading = true;
    },
    toggleRenewalFulfilled: (state) => {
      state.toggleRenewal.isLoading = false;
    },
    toggleRenewalRejected: (state, action) => {
      state.toggleRenewal.error = action.payload;
      state.toggleRenewal.isLoading = false;
    },
  },
});

import { UpdatePaymentCardDto } from '../dtos/update-payment-card.dto';
import { IPaymentCard } from '../types/payment-item';
import { IAddress } from '../types/address';
import { CreatePaymentCardDto } from '@root/modules/payment-methods/dtos/create-payment-card.dto';

export class UpdatePaymentCardDtoMapper {
  public static toDomain(paymentCard: IPaymentCard, address?: IAddress): UpdatePaymentCardDto {
    return {
      id: paymentCard.id,
      expirationDate: paymentCard.expiration,
      billingAddress: address
        ? {
            firstname: address.firstname,
            lastname: address.lastname,
            firstAddressLine: address.firstAddressLine,
            secondAddressLine: address.secondAddressLine,
            phoneNumbers: [],
            city: address.city,
            country: address.country,
            postalCode: address.postalCode,
          }
        : undefined,
    };
  }

  public static toPersistence(dto: UpdatePaymentCardDto) {
    return {
      paymentInstrumentId: dto.id,
      expMonth: parseInt(dto.expirationDate.split('/')[0]),
      expYear: parseInt(dto.expirationDate.split('/')[1]),
      cvv: dto.cvv,
      billingAddress: !!dto.billingAddress
        ? {
            firstName: dto.billingAddress.firstname,
            lastName: dto.billingAddress.lastname,
            address: dto.billingAddress.firstAddressLine,
            address2: dto.billingAddress.secondAddressLine,
            phoneNumbers: dto.billingAddress.phoneNumbers,
            city: dto.billingAddress.city,
            country: dto.billingAddress.country,
            postalCode: dto.billingAddress.postalCode,
          }
        : undefined,
    };
  }

  public static toCreate(values: CreatePaymentCardDto, data: IPaymentCard): UpdatePaymentCardDto {
    const [firstname, lastname] = values.cardholder.split(' ');

    return {
      id: values.id || '',
      expirationDate: values.expirationDate,
      cvv: values.securityCode,
      isDefault: values.isDefault,
      billingAddress: !!data.billingAddress
        ? {
            ...data.billingAddress,
            firstname,
            lastname,
          }
        : undefined,
    };
  }
}

import { FC, SVGProps } from 'react';
import { baseIconProps } from './base-icon-props';

export const ChevronIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg {...baseIconProps} viewBox='0 -1 12 8' version='1.1' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M2.11750442,0.707504417 L6,4.59 L6,4.59 L9.88249558,0.707504417 C10.2718612,0.318138798 10.9031476,0.318138798 11.2925132,0.707504417 C11.2933436,0.708334813 11.2941725,0.709166675 11.295,0.71 L11.2978816,0.712902032 C11.6860516,1.10382497 11.6849363,1.7350637 11.2953874,2.12461259 L6.70710678,6.71289322 C6.31658249,7.10341751 5.68341751,7.10341751 5.29289322,6.71289322 L0.704612585,2.12461259 C0.315063701,1.7350637 0.313948441,1.10382497 0.702118405,0.712902032 L0.705,0.71 C1.09298735,0.31926097 1.7242698,0.31703029 2.11500883,0.705017637 C2.11584216,0.705845093 2.11667402,0.706674021 2.11750442,0.707504417 Z' />
    </svg>
  );
};

export const ChevronLeftIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg {...baseIconProps} {...props} viewBox='0 0 8 12' version='1.1' xmlns='http://www.w3.org/2000/svg'>
      <g id='GUI' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='GUI---10.-Student-&amp;-Public-Pages---Elements' transform='translate(-178.000000, -1172.000000)' fill='currentColor'>
          <g id='Group-17' transform='translate(182.000000, 1178.000000) scale(-1, 1) translate(-182.000000, -1178.000000) translate(158.000000, 1154.000000)'>
            <g id='chevron-down' transform='translate(24.000000, 24.000000) rotate(-90.000000) translate(-24.000000, -24.000000) translate(18.000000, 20.000000)'>
              <path
                d='M2.11750442,0.707504417 L6,4.59 L6,4.59 L9.88249558,0.707504417 C10.2718612,0.318138798 10.9031476,0.318138798 11.2925132,0.707504417 C11.2933436,0.708334813 11.2941725,0.709166675 11.295,0.71 L11.2978816,0.712902032 C11.6860516,1.10382497 11.6849363,1.7350637 11.2953874,2.12461259 L6.70710678,6.71289322 C6.31658249,7.10341751 5.68341751,7.10341751 5.29289322,6.71289322 L0.704612585,2.12461259 C0.315063701,1.7350637 0.313948441,1.10382497 0.702118405,0.712902032 L0.705,0.71 C1.09298735,0.31926097 1.7242698,0.31703029 2.11500883,0.705017637 C2.11584216,0.705845093 2.11667402,0.706674021 2.11750442,0.707504417 Z'
                id='Path'
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export const ChevronRightIcon: FC<any> = (props) => {
  return (
    <svg width='1em' height='1em' {...props} viewBox='0 0 8 12' version='1.1' xmlns='http://www.w3.org/2000/svg'>
      <g id='GUI' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='GUI---10.-Student-&amp;-Public-Pages---Elements' transform='translate(-245.000000, -1172.000000)' fill='currentColor'>
          <g id='Group-17' transform='translate(225.000000, 1154.000000)'>
            <g id='chevron-down' transform='translate(24.000000, 24.000000) rotate(-90.000000) translate(-24.000000, -24.000000) translate(18.000000, 20.000000)'>
              <path
                d='M2.11750442,0.707504417 L6,4.59 L6,4.59 L9.88249558,0.707504417 C10.2718612,0.318138798 10.9031476,0.318138798 11.2925132,0.707504417 C11.2933436,0.708334813 11.2941725,0.709166675 11.295,0.71 L11.2978816,0.712902032 C11.6860516,1.10382497 11.6849363,1.7350637 11.2953874,2.12461259 L6.70710678,6.71289322 C6.31658249,7.10341751 5.68341751,7.10341751 5.29289322,6.71289322 L0.704612585,2.12461259 C0.315063701,1.7350637 0.313948441,1.10382497 0.702118405,0.712902032 L0.705,0.71 C1.09298735,0.31926097 1.7242698,0.31703029 2.11500883,0.705017637 C2.11584216,0.705845093 2.11667402,0.706674021 2.11750442,0.707504417 Z'
                id='Path'
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

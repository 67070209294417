import { getDiscordLink } from '@root/modules/discord/services/get-discord-link';
import { externalRedirect, notify } from '@root/shared/utils';
import { useCallback, useState } from 'react';

export const useDiscordButton = () => {
  const [loading, setIsLoading] = useState(false);

  const handleRedirect = useCallback(async () => {
    setIsLoading(true);
    const response = await getDiscordLink();

    if (response.status === 200) {
      externalRedirect(response.payload);
    } else {
      notify({
        title: 'Error',
        text: response.payload,
        type: 'danger',
      });
    }

    setIsLoading(false);
  }, []);

  return { loading, handleRedirect };
};

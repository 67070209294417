const colors = {
  primary: {
    '100': '#EDE1FF',
    '200': '#CBA6FF',
    '300': '#B473FF',
    '400': '#8E53F8',
    '500': '#6932D4',
    '600': '#4B1DA5',
    '700': '#311071',
    '800': '#19004A',
    '900': '#100030',
  },
  success: {
    '100': '#CBFFF6',
    '200': '#9FFFEE',
    '300': '#00FCCF',
    '400': '#00EDB8',
    '500': '#00C2AE',
    '600': '#01AE9C',
    '700': '#008B7A',
    '800': '#006575',
    '900': '#004A60',
  },
  info: {
    '100': '#C9EFFF',
    '200': '#8CDDFF',
    '300': '#41C7FF',
    '400': '#029CFF',
    '500': '#0281FF',
    '600': '#005CFA',
    '700': '#004DC0',
    '800': '#0235A1',
    '900': '#002084',
  },
  warning: {
    '100': '#FFF7CC',
    '200': '#FFED99',
    '300': '#FFE166',
    '400': '#FFD200',
    '500': '#FEBF42',
    '600': '#F6AF22',
    '700': '#B78000',
    '800': '#936400',
    '900': '#7A4F00',
  },
  danger: {
    '100': '#FFE2E6',
    '200': '#FFD3D8',
    '300': '#FF7C8D',
    '400': '#FF5466',
    '500': '#F0263B',
    '600': '#F0263B',
    '700': '#E6152B',
    '800': '#B60417',
    '900': '#7F010E',
  },
  gray: {
    '100': '#FFFFFF',
    '200': '#F4F4F4',
    '300': '#E1E3E6',
    '400': '#CFCFD2',
    '500': '#AFAFB8',
    '600': '#9595A2',
    '700': '#81818D',
    '800': '#484851',
    '900': '#1C1925', // in use
    '1000': '#131014',
    '1100': '#090809',
  },
  cards: {
    rookie: '#6932D4',
    bronze: '#B57133',
    silver: '#909090',
    gold: '#BAAC90',
    prime: '#DBBE5C',
    elite: '#EAAC3B',
  },
};

export const theme = {
  screens: {
    sm: '576px',
    md: '768px',
    lg: '1140px',
    xl: '1200px',
    '2xl': '1440px',
    '3xl': '2560px',
  },
  colors: {
    ...colors,
    text: {
      default: colors.gray['900'],
      secondary: colors.gray['600'],
      tertiary: colors.gray['100'],
      colorful: colors.primary['500'],
    },
    gradients: {
      1: 'linear-gradient(270deg, #6983cd 0%, #9c4efe 100%)',
      2: 'linear-gradient(135deg, #B67341 0%, #FFB964 100%)', // bronze
      3: 'linear-gradient(135deg, #B6A88E 0%, #EECB4E 100%)', // gold
      4: 'linear-gradient(270deg, #F2C266 0%, #BE9E62 100%)', // gold prime
      6: 'linear-gradient(270deg, #FF8C32 0%, #FF5466 100%)', // special offer
      7: 'linear-gradient(270deg, #FF8C32 0%, #FF5466 100%)', // danger button
      8: 'linear-gradient(270deg, #41C7FF 0%, #8C84FF 100%)', // primary blue
      9: 'linear-gradient(135deg, #F1EEE8 0%, #FFFBE1 100%)', // yellow
      10: 'linear-gradient(270deg, #FF6D41 0%, #FEBF42 100%)', // warning
      11: 'linear-gradient(270deg, #AAACAE 0%, #6B6B6E 100%)', // silver,
      12: 'linear-gradient(270deg, #FFDE47 0%, #E0A72E 100%)', // gold elite
      13: 'linear-gradient(90.09deg, #8058DD 0%, #D6855D 100%)', // rookie
      14: 'linear-gradient(90deg, #00EDB8 0%, #41C7FF 100%)', // success
      15: 'linear-gradient(270deg, #FFE166 0%, #FEBF42 100%)', // pending
      16: 'linear-gradient(90deg, #00EDB8 0%, #41C7FF 100%)', // green
      17: 'linear-gradient(270deg, #ECECED 0%, #E0E2EB 100%)', // light gray
      18: 'linear-gradient(270deg, #AFAFB8 0%, #AFAFB8 100%)',  // gray - 500
    },
    card: {
      light: '#FFFFFF',
      dark: '#1F232A',
    },
  },
  shadows: [
    'none',
    '0 1px 4px 0 rgba(28,25,37,0.2)',
    '0 2px 5px 0 rgba(28,25,37,0.2)',
    '0 4px 8px 0 rgba(28,25,37,0.2)',
    '0 8px 16px 0 rgba(28,25,37,0.3)',
    '0 10px 24px 0 rgba(28,25,37,0.4)',
    '0 2px 8px 0 rgba(0, 0, 0, 0.28)',
    '0 8px 16px 0 rgba(28, 25, 37,0.3)',
    '0 -4px 8px 0 rgba(28,25,37,0.2)',
    '0 -1px 4px 0 rgba(28,25,37,0.2)',
    '0 6px 16px 0 rgba(28,25,37,0.5)',
    '0 -1px 4px 0 rgba(28, 25, 37, 0.2)',
    '0 2px 4px 0 rgba(28, 25, 37, 0.4)',
    '0 4px 4px -4px rgb(28, 25, 37, 0.2)',
  ],
  spacing: (value: number) => value * 4,
};

export type Theme = typeof theme;

declare module 'styled-components' {
  export interface DefaultTheme extends Theme {}
}

import { FC, SVGProps } from 'react';

import { baseIconProps } from './base-icon-props';

export const AddIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg {...baseIconProps} viewBox='0 0 18 18' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' {...props}>
      <g transform='translate(-743.000000, -2106.000000)'>
        <path d='M751.03125,2106 C746.637187,2106 743,2109.57469 743,2113.96875 C743,2118.36281 746.637187,2122 751.03125,2122 C755.425313,2122 759,2118.36281 759,2113.96875 C759,2109.57469 755.425313,2106 751.03125,2106 Z M755.25,2114.90625 L751.96875,2114.90625 L751.96875,2118.25 C751.96875,2118.76687 751.547812,2119.1875 751.03125,2119.1875 C750.514375,2119.1875 750.09375,2118.76687 750.09375,2118.25 L750.09375,2114.90625 L746.75,2114.90625 C746.233125,2114.90625 745.8125,2114.48562 745.8125,2113.96875 C745.8125,2113.45188 746.233125,2113.03125 746.75,2113.03125 L750.09375,2113.03125 L750.09375,2109.75 C750.09375,2109.23313 750.514375,2108.8125 751.03125,2108.8125 C751.547812,2108.8125 751.96875,2109.23313 751.96875,2109.75 L751.96875,2113.03125 L755.25,2113.03125 C755.766562,2113.03125 756.1875,2113.45188 756.1875,2113.96875 C756.1875,2114.48562 755.766562,2114.90625 755.25,2114.90625 Z' />
      </g>
    </svg>
  );
};

import styled from 'styled-components';

import { Theme } from '@root/infra/theme';

export type IconElementSize = 1 | 2 | 3 | 4 | 5;

export interface IconElementProps {
  size?: IconElementSize;
  transparent?: boolean;
  gradientIcon?: boolean;
}

const getPadding = (theme: Theme, size?: IconElementSize): string => {
  switch (size) {
    case 1:
      return `${theme.spacing(0.5)}px`;
    case 2:
      return `${theme.spacing(1.5)}px`;
    case 3:
      return `${theme.spacing(2.5)}px`;
    case 5:
      return `${theme.spacing(4)}px`;
    default:
      return `${theme.spacing(3)}px`;
  }
};

const getFontSize = (theme: Theme, size?: IconElementSize) => {
  switch (size) {
    case 1:
      return `${theme.spacing(3)}px`;
    case 2:
      return `${theme.spacing(4)}px`;
    case 3:
      return `${theme.spacing(5)}px`;
    case 5:
      return `${theme.spacing(7)}px`;
    default:
      return `${theme.spacing(6)}px`;
  }
};

export const IconElement = styled.div<IconElementProps>`
  padding: ${({ theme, size }) => getPadding(theme, size)};
  font-size: ${({ theme, size }) => getFontSize(theme, size)};
  background: ${({ theme, transparent }) => (transparent ? 'transparent' : theme.colors.gray[1000])};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
`;

IconElement.defaultProps = {
  size: 4,
  transparent: false,
  gradientIcon: false,
};

import { call, put, all, SagaReturnType, select } from '@redux-saga/core/effects';
import { selectBaseInstanceCountry } from '@root/modules/meta/redux/meta.selector';
import { getMeta } from '../services';
import { metaSlice } from './meta.slice';

const { fulfilled, rejected } = metaSlice.actions;

export function* handleFetch() {
  const uiCountry = yield select(selectBaseInstanceCountry);

  if (uiCountry) {
    return;
  }

  const response: SagaReturnType<typeof getMeta> = yield call(getMeta, undefined);
  if (response.status === 200) {
    yield put(fulfilled(response.payload));
  } else {
    yield put(rejected(response.payload));
  }
}

export function* metaFlowSaga() {
  yield all([handleFetch()]);
}

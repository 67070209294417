import { createSelector } from '@reduxjs/toolkit';
import { AppState } from '@root/infra/redux';
import { IGiftCardsState } from '@root/modules/gift-cards/store/gift-cards.slice';
import { Selector } from 'react-redux';

const getGiftCards = (state: AppState) => state.giftCards;

const getQuery = (giftCards: IGiftCardsState) => giftCards.query;
const getData = (giftCards: IGiftCardsState) => giftCards.data;
const getTempFilter = (giftCards: IGiftCardsState) => giftCards.tempFilter;

export const giftCardsSelector = {
  query: createSelector<[Selector<AppState, IGiftCardsState>], IGiftCardsState['query']>([getGiftCards], getQuery),
  data: createSelector<[Selector<AppState, IGiftCardsState>], IGiftCardsState['data']>([getGiftCards], getData),
  tempFilter: createSelector<[Selector<AppState, IGiftCardsState>], IGiftCardsState['tempFilter']>([getGiftCards], getTempFilter),
};

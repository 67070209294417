import { useIncreaseEWalletBalance } from '../hooks/use-increase-e-wallet-balance';
import { Button, ModalComponent, TextField } from '@root/shared/ui';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
  isOpen: boolean;
  close: () => void;
}

export const IncreaseEWalletBalanceModal: FC<IProps> = ({ isOpen, close }) => {
  const { t } = useTranslation('payment-methods', { keyPrefix: 'paymentMethods.e-wallet.increase' });

  const [{ isValid, isSubmitting, amount }, { onChange, onSubmit }] = useIncreaseEWalletBalance();

  return (
    <ModalComponent
      closeModal={close}
      modalIsOpen={isOpen}
      headerTitle={t('title')}
      footer={
        <form onSubmit={onSubmit} className='px-6 flex space-x-6'>
          <Button onClick={close} type='button' className='flex-1' color='secondary'>
            {t('actions.cancel')}
          </Button>
          <Button loading={isSubmitting} disabled={!isValid} type='submit' className='flex-1'>
            {t('actions.continue')}
          </Button>
        </form>
      }
    >
      <div className='px-6 pt-6'>
        <TextField name='amount' label={t('fields.amount.label')} placeholder={t('fields.amount.placeholder')} value={amount} onChange={onChange} />
      </div>
    </ModalComponent>
  );
};

import { ToastProps } from '@root/shared/types';
import { CheckSquareIcon } from '@root/shared/ui/icons/check-square';
import { ErrorSquareIcon } from '@root/shared/ui/icons/error-square';
import { QuestionSquareInfo } from '@root/shared/ui/icons/question-square-info';
import clsx from 'clsx';
import { FC } from 'react';
import { ToastWrapper } from './toast.ui';

type Props = {
  toast: ToastProps;
};

export const Toast: FC<Props> = ({ toast }) => {
  if (!toast) {
    return null;
  }

  return (
    <ToastWrapper type={toast.type}>
      <div className='flex items-center'>
        <div className='w-10 h-10 mr-4 flex-shrink-0'>
          {toast.type === 'info' ? <QuestionSquareInfo /> : null}
          {toast.type === 'danger' ? <ErrorSquareIcon /> : null}
          {toast.type === 'success' ? <CheckSquareIcon /> : null}
        </div>
        <div className='text-gray-100 flex flex-col justify-center'>
          <div className={clsx('font-bold', { 'mb-2': !!toast.text })}>{toast.title}</div>
          {!!toast.text ? typeof toast.text === 'object' ? <div dangerouslySetInnerHTML={{ __html: toast.text.content }} /> : <div>{toast.text}</div> : null}
          {!!toast.details && Array.isArray(toast.details) ? (
            <div>
              {toast.details.map((item) => (
                <div>- {item}</div>
              ))}
            </div>
          ) : null}
        </div>
      </div>
    </ToastWrapper>
  );
};

import React, { FC, PropsWithChildren, ReactNode, useEffect } from 'react';
import Modal from 'react-modal';

import { H4 } from '@root/shared/ui';
import { CloseIcon } from '@root/shared/ui/icons/close-icon';

import { CloseButton, customStyles, StyledModal } from './modal.ui';

export interface ModalComponentProps {
  fullModalWidthOnMobile?: boolean;
  fullModalHeightOnMobile?: boolean;
  modalIsOpen: boolean;
  closeModal: () => void;
  className?: string;
  padding?: number;
  headerTitle?: ReactNode;
  visible?: boolean;
  footer?: ReactNode;
  contentRef?: (node) => void;
  footerClassName?: string;
  overlayStyles?: React.CSSProperties;
  hideFooter?: boolean;
}

Modal.setAppElement('#absolute-portal-area');

export const ModalComponent: FC<PropsWithChildren<ModalComponentProps>> = ({
  children,
  visible,
  className,
  fullModalWidthOnMobile,
  fullModalHeightOnMobile,
  headerTitle,
  modalIsOpen,
  closeModal,
  padding,
  footer,
  contentRef,
  footerClassName,
  overlayStyles = {},
  hideFooter,
}) => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (modalIsOpen) {
        window.document.body.style.overflowY = 'hidden';
      } else {
        window.document.body.style.overflowY = 'auto';
      }
      return () => {
        window.document.body.style.overflowY = 'auto';
      };
    }
  }, [modalIsOpen]);

  return (
    <StyledModal
      headerTitle={headerTitle}
      className={className}
      isOpen={modalIsOpen}
      padding={padding}
      onRequestClose={closeModal}
      style={{ ...customStyles, overlay: { ...customStyles.overlay, ...overlayStyles } }}
      fullWidthOnMobile={fullModalWidthOnMobile}
      fullHeightOnMobile={fullModalHeightOnMobile}
      withHeader={!!headerTitle}
      contentRef={contentRef}
      visible={visible}
      hideFooter={hideFooter}
      parentSelector={() => document.querySelector('#absolute-portal-area') as HTMLElement}
    >
      {headerTitle && (
        <div className='header'>
          <H4 className='font-bold'>{headerTitle}</H4>
        </div>
      )}
      <CloseButton onClick={closeModal}>
        <CloseIcon />
      </CloseButton>
      <div className='modal-content'>{children}</div>
      {footer && <div className={`footer ${footerClassName || ''}`}>{footer}</div>}
    </StyledModal>
  );
};

ModalComponent.defaultProps = {
  fullModalWidthOnMobile: false,
};

import { theme } from '@root/infra/theme';
import cn from 'classnames';
import { FC, PropsWithChildren, useCallback, useState } from 'react';
import styled from 'styled-components';
import { QuestionSquareFulfilled } from '@root/shared/ui/icons/question-square-fulfilled';
import { QuestionSquareIcon } from '@root/shared/ui/icons/question-square';

import { Popover } from 'react-tiny-popover';

const Container = styled.div`
  display: block;
  box-shadow: 0 10px 24px 0 rgba(12, 12, 12, 0.6);
  background-color: ${({ theme }) => theme.colors.gray[100]};
  border-radius: 4px;
  padding: 15px;
  position: relative;
  border: ${({ theme }) => `1px solid ${theme.colors.gray[100]}`};
  min-width: 100px;
  max-width: 280px;

  &::before {
    display: block;
    content: '';
    position: absolute;
    top: -7px;
    left: calc(50% + 0px);
    transform: translateX(-50%) rotate(45deg);
    width: 14px;
    height: 14px;
    border-top: ${({ theme }) => `1px solid ${theme.colors.gray[100]}`};
    border-left: ${({ theme }) => `1px solid ${theme.colors.gray[100]}`};
    background-color: ${({ theme }) => theme.colors.gray[100]};
  }
`;

const IconWrapper = styled.div<{ active: boolean }>`
  cursor: pointer;
  filter: ${({ active }) => (active ? 'none' : 'grayscale(100%)')};
`;

type Props = {
  type?: 'fulfilled' | 'default';
  wrapperClassName?: string;
  active?: boolean;
};

export const InfoTooltip: FC<PropsWithChildren<Props>> = ({ type = 'default', children, wrapperClassName = '', active = false }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  const toggleDropdown = useCallback(() => {
    setIsDropdownOpen((prev) => !prev);
  }, []);

  return (
    <>
      <Popover
        positions={['bottom']}
        align='center'
        padding={15}
        onClickOutside={toggleDropdown}
        isOpen={isDropdownOpen}
        content={<Container>{children}</Container>}
        containerStyle={{ boxShadow: theme.shadows[1] }}
      >
        <IconWrapper active={isDropdownOpen || active} onClick={toggleDropdown} className={cn('bg-gray-100 flex-shrink-0 w-6 h-6', wrapperClassName)}>
          {type === 'fulfilled' ? <QuestionSquareFulfilled /> : null}
          {type === 'default' ? <QuestionSquareIcon /> : null}
        </IconWrapper>
      </Popover>
    </>
  );
};

import { type CaseReducer, type PayloadAction, createSlice } from '@reduxjs/toolkit';
import type { Id } from '@root/shared/constants';
import type { IRemoteData } from '@root/shared/remote-data';
import { authSlice } from '@root/modules/auth/redux';
import type { CreatePaymentCardResponse } from '../services/create-payment-card.service';
import type { CreatePaymentCardDto } from '../dtos/create-payment-card.dto';
import type { UpdatePaymentCardDto } from '../dtos/update-payment-card.dto';
import type { IPaymentAddress, IPaymentItem } from '../types/payment-item';

export interface IPaymentMethodsStateMeta {
  createdPaymentCardId: Id | null;
  remove: {
    ids: Id[];
  };
  candidateToRemove: null | Id;
  update: {
    chooseAddress: boolean;
    addressIsLoading: boolean;
  };
  makeDefaultIsLoading: boolean;
}

export interface IPaymentMethodsState extends IRemoteData<IPaymentItem[]> {
  meta: IPaymentMethodsStateMeta;
}

export namespace PaymentMethodsActions {
  export type FetchPending = PayloadAction;
  export type FetchFulfilled = PayloadAction<IPaymentItem[]>;
  export type FetchRejected = PayloadAction<string>;

  export type CreateCardPending = PayloadAction<CreatePaymentCardDto>;
  export type CreateCardFulfilled = PayloadAction<Id>;
  export type CreateCardRejected = PayloadAction<CreatePaymentCardResponse>;
  export type CreateCardCleared = PayloadAction;

  export type UpdateCardPending = PayloadAction<UpdatePaymentCardDto>;
  export type UpdateCardFulfilled = PayloadAction<Id>;
  export type UpdateCardRejected = PayloadAction<string>;

  export type ChooseAddressOpened = PayloadAction;
  export type ChooseAddressClosed = PayloadAction;

  export type UpdateCardAddressPending = PayloadAction<{ id: Id; address: IPaymentAddress }>;
  export type UpdateCardAddressFulfilled = PayloadAction<{ id: Id }>;
  export type UpdateCardAddressRejected = PayloadAction<{ id: Id; message: string }>;

  export type SetRemoveCandidate = PayloadAction<Id | null>;
  export type RemovePending = PayloadAction<Id>;
  export type RemoveFulfilled = PayloadAction<Id>;
  export type RemoveRejected = PayloadAction<{ id: Id; message: string }>;

  export type MakeDefaultPending = PayloadAction<Id>;
  export type MakeDefaultFulfilled = PayloadAction<Id>;
  export type MakeDefaultRejected = PayloadAction<{ id: Id; message: string }>;

  export type AddressAssigned = PayloadAction<{ cardId?: Id; addressId: Id; updateUserAddress?: boolean }>;
}

export type PaymentMethodsCaseReducer = {
  fetchPending: CaseReducer<IPaymentMethodsState, PaymentMethodsActions.FetchPending>;
  fetchFulfilled: CaseReducer<IPaymentMethodsState, PaymentMethodsActions.FetchFulfilled>;
  fetchRejected: CaseReducer<IPaymentMethodsState, PaymentMethodsActions.FetchRejected>;

  createCardPending: CaseReducer<IPaymentMethodsState, PaymentMethodsActions.CreateCardPending>;
  createCardFulfilled: CaseReducer<IPaymentMethodsState, PaymentMethodsActions.CreateCardFulfilled>;
  createCardRejected: CaseReducer<IPaymentMethodsState, PaymentMethodsActions.CreateCardRejected>;
  createCardCleared: CaseReducer<IPaymentMethodsState, PaymentMethodsActions.CreateCardCleared>;

  updateCardPending: CaseReducer<IPaymentMethodsState, PaymentMethodsActions.UpdateCardPending>;
  updateCardFulfilled: CaseReducer<IPaymentMethodsState, PaymentMethodsActions.UpdateCardFulfilled>;
  updateCardRejected: CaseReducer<IPaymentMethodsState, PaymentMethodsActions.UpdateCardRejected>;

  chooseAddressOpened: CaseReducer<IPaymentMethodsState, PaymentMethodsActions.ChooseAddressOpened>;
  chooseAddressClosed: CaseReducer<IPaymentMethodsState, PaymentMethodsActions.ChooseAddressClosed>;

  updateCardAddressPending: CaseReducer<IPaymentMethodsState, PaymentMethodsActions.UpdateCardAddressPending>;
  updateCardAddressFulfilled: CaseReducer<IPaymentMethodsState, PaymentMethodsActions.UpdateCardAddressFulfilled>;
  updateCardAddressRejected: CaseReducer<IPaymentMethodsState, PaymentMethodsActions.UpdateCardAddressRejected>;

  setRemoveCandidate: CaseReducer<IPaymentMethodsState, PaymentMethodsActions.SetRemoveCandidate>;
  removePending: CaseReducer<IPaymentMethodsState, PaymentMethodsActions.RemovePending>;
  removeFulfilled: CaseReducer<IPaymentMethodsState, PaymentMethodsActions.RemoveFulfilled>;
  removeRejected: CaseReducer<IPaymentMethodsState, PaymentMethodsActions.RemoveRejected>;

  makeDefaultPending: CaseReducer<IPaymentMethodsState, PaymentMethodsActions.MakeDefaultPending>;
  makeDefaultFulfilled: CaseReducer<IPaymentMethodsState, PaymentMethodsActions.MakeDefaultFulfilled>;
  makeDefaultRejected: CaseReducer<IPaymentMethodsState, PaymentMethodsActions.MakeDefaultRejected>;

  addressAssigned: CaseReducer<IPaymentMethodsState, PaymentMethodsActions.AddressAssigned>;
};

export const paymentMethodsSlice = createSlice<IPaymentMethodsState, PaymentMethodsCaseReducer, 'paymentMethods'>({
  name: 'paymentMethods',
  initialState: {
    isLoading: false,
    data: [],
    error: null,
    loadedOnce: false,
    meta: {
      createdPaymentCardId: null,
      remove: {
        ids: [],
      },
      candidateToRemove: null,
      update: {
        chooseAddress: false,
        addressIsLoading: false,
      },
      makeDefaultIsLoading: false,
    },
  },
  extraReducers: (builder) => {
    builder.addCase(authSlice.actions.fetchProfileFulfilled, (state) => {
      state.isLoading = true;
      state.error = null;
    });
  },
  reducers: {
    fetchPending: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    fetchFulfilled: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      if (!state.loadedOnce) {
        state.loadedOnce = true;
      }
    },
    fetchRejected: (state, action) => {
      state.isLoading = true;
      state.error = action.payload;
    },

    createCardPending: () => {},
    createCardFulfilled: (state, action) => {
      state.meta.createdPaymentCardId = action.payload;
    },
    createCardRejected: () => {},
    createCardCleared: (state) => {
      state.meta.createdPaymentCardId = null;
    },

    updateCardPending: () => {},
    updateCardFulfilled: () => {},
    updateCardRejected: () => {},

    makeDefaultPending: (state) => {
      state.meta.makeDefaultIsLoading = true;
    },
    makeDefaultFulfilled: (state) => {
      state.meta.makeDefaultIsLoading = false;
    },
    makeDefaultRejected: (state) => {
      state.meta.makeDefaultIsLoading = false;
    },

    chooseAddressOpened: (state) => {
      state.meta.update.chooseAddress = true;
    },
    chooseAddressClosed: (state) => {
      state.meta.update.chooseAddress = false;
    },

    updateCardAddressPending: (state) => {
      state.meta.update.addressIsLoading = true;
    },
    updateCardAddressFulfilled: (state) => {
      state.meta.createdPaymentCardId = null;
      state.meta.update.addressIsLoading = false;
    },
    updateCardAddressRejected: (state) => {
      state.meta.update.addressIsLoading = false;
    },

    addressAssigned: () => {},

    setRemoveCandidate: (state, action) => {
      state.meta.candidateToRemove = action.payload;
    },
    removePending: (state, action) => {
      state.meta.remove.ids.push(action.payload);
    },
    removeFulfilled: (state, action) => {
      state.meta.remove.ids = state.meta.remove.ids.filter((id) => id !== action.payload);
      state.meta.candidateToRemove = null;
      state.data = state.data.filter((item) => item.id !== action.payload);
    },
    removeRejected: (state, action) => {
      state.meta.remove.ids = state.meta.remove.ids.filter((id) => id !== action.payload.id);
    },
  },
});

import { createSelector, Selector } from '@reduxjs/toolkit';

import { AppState } from '@root/infra/redux';
import { IAuthState } from '@root/modules/auth/redux';

import { IProductCategoriesState } from '@root/modules/products/redux/product-categories/product-categories.slice';

const getState = (state: AppState) => state.productCategories;
const getAuthState = (state: AppState) => state.auth;
const getSelf = (categories: IProductCategoriesState) => categories;
const getFiltered = (categories: IProductCategoriesState, auth: IAuthState) => ({
  ...categories,
  data: categories.data.filter((item) => (!auth.authed ? item.urlParam !== 'tickets' : true)),
});
const getPlans = (categories: IProductCategoriesState) => {
  try {
    return categories.data.reduce((acc: any, val) => {
      val?.subCategories?.flatMap((item) => item.products).forEach((item) => (acc[item?.id as string] = item?.shopUrl));
      return acc;
    }, {});
  } catch {
    return null;
  }
};

const allSelector = createSelector<[Selector<AppState, IProductCategoriesState>], IProductCategoriesState>([getState], getSelf);

const filteredSelector = createSelector<[Selector<AppState, IProductCategoriesState>, Selector<AppState, IAuthState>], IProductCategoriesState>(
  [getState, getAuthState],
  getFiltered,
);

const plansSelector = createSelector<[Selector<AppState, IProductCategoriesState>], { [key: string]: string } | null>([filteredSelector], getPlans);

export const productCategoriesSelector = {
  all: allSelector,
  filtered: filteredSelector,
  plans: plansSelector,
};

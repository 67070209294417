import { NotificationItem } from '../components/notification-item';
import { useNotificationsChecker } from '../hooks/useNotificationsChecker';

export const Notifications = () => {
  const [{ notifications }, { removeNotification }] = useNotificationsChecker();

  if (!notifications.length) {
    return null;
  }

  return (
    <div className='flex flex-col gap-4'>
      {notifications.map((item) => (
        <NotificationItem item={item} key={item.id} closeNotification={removeNotification} />
      ))}
    </div>
  );
};

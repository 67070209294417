import { ArrowRightPurpleIcon } from '@root/shared/ui/icons/arrow-right-purple';
import { externalRedirect } from '@root/shared/utils';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FC, forwardRef, useState } from 'react';
import { BodyTextLarge, Footnote } from './text';

type Props = {
  link: string;
  small?: boolean;
  disabled?: boolean;
};

const LearnMoreText = forwardRef<HTMLDivElement, Omit<Props, 'link'> & { onClick?: () => void }>(function LearnMoreText({ small = false, disabled, ...props }, ref) {
  const [onHover, setOnHover] = useState<boolean>(false);
  const { t } = useTranslation();

  return (
    <div {...props} ref={ref} className='flex'>
      <div className='flex items-center' onMouseEnter={() => setOnHover(true)} onMouseLeave={() => setOnHover(false)}>
        <div className={clsx({ 'mr-1': small, 'mr-4': !small })}>
          <ArrowRightPurpleIcon width={small ? '14px' : '18px'} onHover={onHover} disabled={disabled} />
        </div>

        {small ? (
          <Footnote className='cursor-pointer font-bold text-primary-500 hover:text-success-500'>{t('common:Learn More')}</Footnote>
        ) : (
          <BodyTextLarge lineHeight={24} className='cursor-pointer font-bold text-primary-500 hover:text-success-500'>
            {t('common:Learn More')}
          </BodyTextLarge>
        )}
      </div>
    </div>
  );
});

export const LearnMoreLink: FC<Props> = ({ link, small, disabled }) => {
  const isExternal = link.startsWith('https://' || 'http://');

  if (disabled) {
    return (
      <div className='cursor-not-allowed'>
        <LearnMoreText small={small} disabled={disabled} />
      </div>
    );
  }

  if (isExternal) {
    return <LearnMoreText onClick={() => externalRedirect(link, { target: '_blank' })} small={small} disabled={disabled} />;
  }

  return (
    <Link to={link}>
      <LearnMoreText small={small} disabled={disabled} />
    </Link>
  );
};

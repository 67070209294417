import { FC } from 'react';
import styled from 'styled-components';
import { ViewGridIcon } from '@root/shared/ui/icons/view-grid';
import { ViewListIcon } from '@root/shared/ui/icons/view-list';

export enum View {
  Vertical = 'vertical',
  Horizontal = 'horizontal',
}

type Props = {
  viewType: View;
  onViewClick: (viewType: View) => void;
};

const ViewButton = styled.button`
  border-radius: 50%;
  width: ${({ theme }) => theme.spacing(8)}px;
  height: ${({ theme }) => theme.spacing(8)}px;
  box-shadow: ${({ theme }) => theme.shadows[3]};
`;

export const ViewToggler: FC<Props> = ({ viewType, onViewClick }) => {
  return (
    <div className='flex gap-4'>
      <ViewButton className='flex-shrink-0 flex justify-center items-center' onClick={() => onViewClick(View.Horizontal)}>
        <div className='w-4 h-3 flex-shrink-0'>
          <ViewListIcon active={viewType === View.Horizontal} />
        </div>
      </ViewButton>
      <ViewButton className='flex-shrink-0 flex justify-center items-center' onClick={() => onViewClick(View.Vertical)}>
        <div className='w-4 h-3 flex-shrink-0'>
          <ViewGridIcon active={viewType === View.Vertical} />
        </div>
      </ViewButton>
    </div>
  );
};

import { FC } from 'react';
import styled from 'styled-components';

import { CloseIcon } from '@root/shared/ui/icons/close-icon';

const Container = styled.div`
  display: block;
  box-shadow: 0 10px 24px 0 rgba(12, 12, 12, 0.6);
  background-color: ${({ theme }) => theme.colors.gray[100]};
  border-radius: 4px;
  padding: 15px;
  position: relative;
  border: ${({ theme }) => `1px solid ${theme.colors.gray[100]}`};
  min-width: 240px;
`;

const CloseButton = styled.button`
  font-size: 0px;
  display: inline-block;
  width: 11px;
  height: 11px;
  position: absolute;
  top: 12px;
  right: 16px;

  svg {
    width: 11px;
    height: 11px;
    fill: ${({ theme }) => theme.colors.gray[500]};
  }
`;

export interface PasswordInfoProps {
  children: any;
  handleTogglePopover: () => void;
}

export const PasswordInfo: FC<PasswordInfoProps> = ({ handleTogglePopover, children }) => {
  return (
    <Container>
      <CloseButton onClick={handleTogglePopover}>
        <CloseIcon />
      </CloseButton>
      {children}
    </Container>
  );
};

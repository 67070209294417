import { Fragment, FC } from 'react';
import styled from 'styled-components';
import BaseCarousel, { CarouselProps as BaseCarouselProps, ResponsiveType } from 'react-multi-carousel';
import clsx from 'clsx';
import { useIsDesktop } from '@root/infra/device-type';
import { ChevronLeftIcon } from '../icons/chevron-icon';

export const carouselTinyResponsiveness = {
  desktop: {
    breakpoint: { max: 5000, min: 994 },
    items: 2,
  },
  tablet: {
    breakpoint: { max: 994, min: 768 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 768, min: 0 },
    items: 1,
    partialVisibilityGutter: 124,
  },
};

const carouselTinyResponsivenessOneSlide = {
  ...carouselTinyResponsiveness,
  mobile: {
    breakpoint: { max: 576, min: 0 },
    items: 1,
  },
};

const CarouselSlideButton = styled.button<{ position?: 'left' | 'right' }>`
  position: absolute;
  transform: translate(0, -50%);
  border-radius: 50%;
  min-height: 48px;
  min-width: 48px;
  background-color: ${({ theme }) => theme.colors.gray['800']};
  box-shadow: ${({ theme }) => theme.shadows[5]};
  left: ${({ position }) => position === 'left' && '-24px'};
  right: ${({ position }) => position === 'right' && '-24px'};
  color: ${({ theme }) => theme.colors.primary['400']};
  top: 50%;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CarouselWrapper = styled.div`
  position: relative;
`;

const CarouselInner = styled.div`
  overflow: hidden;
`;

const CarouselButtonGroup: FC<{ next?: any; previous?: any; carouselState?: any }> = ({ next, previous, carouselState }) => {
  const { currentSlide, totalItems, slidesToShow } = carouselState;

  return (
    <Fragment>
      {currentSlide !== 0 && (
        <CarouselSlideButton onClick={previous} position='left' className='text-sm'>
          <ChevronLeftIcon />
        </CarouselSlideButton>
      )}
      {currentSlide + slidesToShow !== totalItems && totalItems > slidesToShow && (
        <CarouselSlideButton onClick={next} position='right'>
          <ChevronLeftIcon className='text-sm transform rotate-180 align-self-center' />
        </CarouselSlideButton>
      )}
    </Fragment>
  );
};

const CarouselComponent = styled(BaseCarousel)`
  margin: 0 -20px;

  .react-multi-carousel-item {
    padding: ${({ theme }) => `${theme.spacing(0.5)}px ${theme.spacing(2)}px`};

    &.react-multi-carousel-item--active{
      padding-left: ${({ theme }) => theme.spacing(5)}px;
    }

    &:first-child {
      padding-left: ${({ theme }) => theme.spacing(5)}px;
    }

    &:last-child {
      padding-right: ${({ theme }) => theme.spacing(5)}px;

      &.react-multi-carousel-item--active{
        padding-left: ${({ theme }) => theme.spacing(2)}px;
      }
    }

    @media screen and (min-width: 768px) {
      padding: ${({ theme }) => `${theme.spacing(0.5)}px ${theme.spacing(5)}px`};
    }

    & > div {
      height: 100%;
    }
  }

  /* .react-multi-carousel-item--active {
    padding: ${({ theme }) => `${theme.spacing(0.5)}px ${theme.spacing(2)}px`};
    padding-left: 20px;

    &:first-child {
      padding-left: ${({ theme }) => theme.spacing(5)}px;
    }

    &:last-child {
      padding-right: ${({ theme }) => theme.spacing(5)}px;
    }

    @media screen and (min-width: 768px) {
      padding: ${({ theme }) => `${theme.spacing(0.5)}px ${theme.spacing(5)}px`};
    } */
  }

  .react-multiple-carousel__arrow {
    min-height: 48px;
    min-width: 48px;
    background-color: ${({ theme }) => theme.colors.gray['800']};
    box-shadow: ${({ theme }) => theme.shadows[4]};

    &:before {
      font-size: 14px;
      color: ${({ theme }) => theme.colors.primary['400']};
    }

    &--right {
      right: 24px;
    }

    &--left {
      left: 24px;
    }
  }

  .react-multi-carousel-dot {
    &:last-child {
      button {
        margin-right: 0;
      }
    }

    button {
      background: ${({ theme }) => theme.colors.primary['400']};
      width: 6px;
      height: 6px;
      opacity: 0.5;
      border: none;
      margin-right: ${({ theme }) => `${theme.spacing(5)}px`};
    }
  }

  .react-multi-carousel-dot--active {
    button {
      width: 8px;
      height: 8px;
      opacity: 1;
      margin-top: 8px;
    }
  }
`;

export type CarouselProps = Omit<BaseCarouselProps, 'responsive'> & { normalizeHeight?: boolean; responsive?: ResponsiveType; isDesktop?: boolean };

export const Carousel: FC<CarouselProps> = ({ children, normalizeHeight, ...props }) => {
  const isWeb = useIsDesktop();

  return (
    <CarouselWrapper
      className={clsx({
        '-mt-8': normalizeHeight,
        '-mb-8': normalizeHeight && !props.showDots,
        'min-h-full': true,
        '-ml-5 -mr-5': true,
      })}
    >
      <CarouselInner className='px-5'>
        <CarouselComponent
          responsive={children.length !== 1 ? carouselTinyResponsiveness : carouselTinyResponsivenessOneSlide}
          arrows={false}
          swipeable={!isWeb}
          customButtonGroup={isWeb ? <CarouselButtonGroup /> : undefined}
          {...props}
        >
          {children}
        </CarouselComponent>
      </CarouselInner>
    </CarouselWrapper>
  );
};

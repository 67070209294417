import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from '@react-hook/media-query';

import { userAgentSelector } from '@root/shared/user-agent';

export const useIsDesktop = () => {
  const deviceType = useSelector(userAgentSelector);
  const isSSR = typeof window === 'undefined';

  const [isDesktop, setIsDesktop] = useState<boolean>(deviceType.deviceType === 'desktop');

  const isDesktopSize = useMediaQuery('screen and (min-width: 1140px)');

  useEffect(() => {
    if (isSSR) {
      setIsDesktop(deviceType.userAgent === 'desktop');
    } else {
      setIsDesktop(isDesktopSize);
    }
  }, [deviceType.userAgent, isDesktopSize, isSSR]);

  return isDesktop;
};

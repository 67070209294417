import { createFakeBundleStatistics } from '../helpers/create-fake-bundle-statistics';
import { HARDCODE_BUNDLE_DATA } from '../helpers/fake-products-data';
import { normalizePlanSubtitle } from '../helpers/product-subtitle-normalizer';
import { ProductPricingMapper } from '@root/modules/products/mappers/product-pricing.mapper';
import { IMiniProduct } from '@root/modules/products/types/mini-product';
import { updateProductNameWithRecurringType } from '@root/shared/utils/recurring-type-hepler';
import { isBefore, parseISO } from 'date-fns';

export class MiniProductMapper {
  public static toDomain(item): IMiniProduct {
    const { imageUrl, fakeDiscountPercentage, bullets } = HARDCODE_BUNDLE_DATA.BRENTRY01;

    const isExpired = item.expiredOn && isBefore(parseISO(item.expiredOn), new Date());
    const pricing = ProductPricingMapper.toDomain({
      ...item.pricing,
      fakeDiscountPercentage: !!item.isAddon || item.urlParam === 'topup' ? 0 : fakeDiscountPercentage,
      setupPrice: item?.setupPrice,
    });

    const title = updateProductNameWithRecurringType(item?.title || item?.name, item.recurringType);

    return {
      id: item.id,
      title: {
        text: title,
        color: item.color.toLowerCase().includes('fff') ? '#000' : item.color || '#000',
      },
      subtitle: item.isDigitalBundle ? normalizePlanSubtitle(item.subtitle) : item.subtitle,
      originalSubtitle: item.subtitle,
      pricing,
      priority: item.priority,
      // TODO - remove after reviews implemented
      statistics: createFakeBundleStatistics(item.title),
      bullets: item?.bullets?.length
        ? item.bullets.map((bullet) => ({ text: bullet.replace("style='font-family: Wingdings;'", '').replace('<strong>ü', '<strong>✓'), points: [] }))
        : bullets,
      language: {
        id: item.language || 1,
        code: item.language || 'en',
        name: item.language || 'English',
      },
      imageUrl: item.imageUrl || imageUrl,
      urlParam: item.urlParam,
      shopId: item.id,
      isBestSeller: item.isBestSeller,
      isAddon: item.isAddon,
      isPhysicalAddon: item.isPhysicalAddon,
      isDigitalBundle: item.isDigitalBundle,
      isPhysicalBundle: item.isPhysicalBundle,
      isAnnual: item.isAnnual,
      includedServices: item.includedServices,
      isEventTicket: item.isEventTicket,
      membershipDiscountAllowed: item.membershipDiscountAllowed,
      purchased: item.purchased,
      expiredOn: isExpired ? item.expiredOn : null,
      shortDescription: item.description,
      primaryServiceId: item.primaryService?.id,
      intervalUnit: item.intervalUnit,
      intervalSize: item.intervalSize,
      status: item?.status?.toLowerCase(),
      renewalOptions: item.renewalOptions,
      recurringType: item.recurringType,
    };
  }
}

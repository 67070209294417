import { useRedirection } from '@root/modules/auth/hooks/use-redirection';
import { REDIRECTION_TYPES } from '@root/modules/auth/types';
import { selectRedirectInstanceTypeByUserSubscription } from '@root/modules/products/redux/purchased-products/purchased-products.selector';
import { IPurchasedProduct } from '@root/modules/products/types/purchased-product';
import { UpgradeSubscriptionModal } from '@root/modules/subscriptions/components/modals/upgrade-subscription';
import { subscriptionsSelector } from '@root/modules/subscriptions/redux/subscriptions.selector';
import { BodyText, Button } from '@root/shared/ui';
import { ChevronRightIcon } from '@root/shared/ui/icons/chevron-icon';
import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

export const UpgradeOptions: FC<{ item: IPurchasedProduct }> = ({ item }) => {
  const { t } = useTranslation('dashboard', { keyPrefix: 'bundleInfo' });
  const { redirect } = useRedirection();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { activeUpgrade } = useSelector(subscriptionsSelector);
  const { isAnnual } = useSelector(selectRedirectInstanceTypeByUserSubscription);

  const options = item.canBeSwitchedTo;

  const openModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const handleConfirmUpgrade = useCallback(() => {
    closeModal();
    redirect(REDIRECTION_TYPES.SWITCH_TO_PLAN, { planId: activeUpgrade?.planId, isAnnual });
  }, [closeModal, activeUpgrade, isAnnual, redirect]);

  if (!options.length) {
    return null;
  }

  return (
    <div className='border-b border-gray-300 p-4'>
      {/*{options.length === 1 ? (*/}
      {/*  <div className='flex flex-col sm:flex-row sm:items-center sm:justify-between gap-2'>*/}
      {/*    <div>*/}
      {/*      <BodyTextLarge className='font-bold'>*/}
      {/*        <PriceItem value={options[0].recurringPrice} type='long' />*/}
      {/*        <span className='text-xs'>/{options[0].recurringType === RECURRING_TYPES.MONTHLY ? t('recurring-types.monthly.month') : t('recurring-types.annual.year')}</span>*/}
      {/*      </BodyTextLarge>*/}
      {/*      {options[0].youSave > 0 && (*/}
      {/*        <BodyText className='text-success-500 font-bold'>*/}
      {/*          {t('you-save')}*/}
      {/*          <PriceItem value={options[0].youSave} type='long' />*/}
      {/*        </BodyText>*/}
      {/*      )}*/}
      {/*    </div>*/}
      {/*    <Button className='w-full sm:w-auto py-2 px-4 text-base lg:text-sm lg:py-1.5' onClick={() => handleSingleUpgradeRedirect(options[0].planId)}>*/}
      {/*      {options[0].recurringType === RECURRING_TYPES.MONTHLY ? t('recurring-types.monthly.upgrade') : t('recurring-types.annual.upgrade')}*/}
      {/*    </Button>*/}
      {/*  </div>*/}
      {/*) : (*/}
      <div className='flex flex-col sm:flex-row sm:items-center sm:justify-between gap-2 flex-wrap'>
        <BodyText className='font-bold mb-1 sm:mb-0'>{t('available-options')}</BodyText>
        <Button className='upgrade_subscription_button w-full sm:w-auto py-2 px-4 text-base lg:text-sm lg:py-1.5 ml-auto' color='secondary' onClick={openModal}>
          {t('upgrade_subscription')} <ChevronRightIcon className='ml-2 text-xs' />
        </Button>
      </div>
      {/*)}*/}

      {isModalOpen && <UpgradeSubscriptionModal isOpen={true} close={closeModal} confirm={handleConfirmUpgrade} item={item} />}
    </div>
  );
};

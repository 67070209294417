import { FC } from 'react';
import { useCurrencyConverter } from '@root/modules/currencies/hooks/use-currency-converter';
import { isNull } from 'lodash';

export interface PriceItemProps {
  value: number | null;
  type?: 'short' | 'long';
  rounding?: number;
  currencyCode?: string;
}

export const PriceItem: FC<PriceItemProps> = ({ value, type, rounding, currencyCode }) => {
  const { convert } = useCurrencyConverter(currencyCode);

  if (isNull(value)) {
    return null;
  }

  return convert(value, type || 'short', rounding);
};

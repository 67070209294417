import { toggleRenewalService } from '../services/toggle-renewal.service';
import { SubscriptionsActions, subscriptionsSlice } from './subscriptions.slice';
import { PayloadAction } from '@reduxjs/toolkit';
import { purchasedProductsSelector } from '@root/modules/products/redux/purchased-products/purchased-products.selector';
import { purchasedProductsSlice } from '@root/modules/products/redux/purchased-products/purchased-products.slice';
import { Id } from '@root/shared/constants';
import { notify } from '@root/shared/utils';
import { call, put, SagaReturnType, select, takeEvery, takeLatest } from 'redux-saga/effects';

const { toggleRenewalPending, toggleRenewalFulfilled, toggleRenewalRejected } = subscriptionsSlice.actions;

function* handleToggleRenewal(action: SubscriptionsActions.ToggleRenewalPending) {
  // TODO - extend saga flow for checking default payment method after paymentMethods module implemented

  const { data: purchasedProducts } = yield select(purchasedProductsSelector);

  const data = action.payload;

  const result: SagaReturnType<typeof toggleRenewalService> = yield call(toggleRenewalService, data);
  if (result.status === 200) {
    yield put(toggleRenewalFulfilled());

    const updatedSubscription = purchasedProducts.find((item) => item.id === action.payload.item.id);
    const newPurchasedProducts = [...purchasedProducts.filter((item) => item.id !== updatedSubscription.id), { ...updatedSubscription, autopay: !updatedSubscription.autopay }];

    yield put(purchasedProductsSlice.actions.fetchFulfilled(newPurchasedProducts));

    notify({ type: 'success', title: 'Success' });
  } else {
    yield put(toggleRenewalRejected(result.payload));
  }
}

function* handleError(action: PayloadAction<string | { id: Id; error: string }>) {
  if (typeof action.payload === 'string') {
    notify({ type: 'danger', text: action.payload, title: 'Error' });
  } else if (typeof action.payload === 'object') {
    notify({ type: 'danger', text: action.payload.error, title: 'Error' });
  }
  yield;
}

export function* subscriptionsFlowSaga() {
  yield takeLatest(toggleRenewalPending.type, handleToggleRenewal);
  yield takeEvery([toggleRenewalRejected.type], handleError);
}

import { KYCMenuItem } from './user-dropdown-kyc-status.ui';
import { authUserSelector } from '@root/modules/auth/redux';
import { KYC_STATUSES } from '@root/modules/kyc/enums/kyc.statuses';
import { useUserDropdownKycStatus } from '@root/modules/kyc/hooks/use-userdropdown-kyc-status';
import { selectKycLevel1Data, selectKycLevel2Data } from '@root/modules/kyc/redux/kyc.selector';
import { BodyText } from '@root/shared/ui';
import { ChevronRightIcon } from '@root/shared/ui/icons/chevron-icon';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

export const UserDropdownKYCStatus = () => {
  const { t } = useTranslation('kyc', { keyPrefix: 'kyc_user_dropdown' });
  const level1Data = useSelector(selectKycLevel1Data);
  const level2Data = useSelector(selectKycLevel2Data);
  const userData = useSelector(authUserSelector);
  const userHasUnverifiedProduct = userData?.unverifiedProducts.has;

  const [{ status: kycLabelStatus }] = useUserDropdownKycStatus();

  const kycLevel1Message = useMemo(() => {
    switch (level1Data?.verificationStatus) {
      case KYC_STATUSES.PENDING:
      case KYC_STATUSES.PROCESSING:
        return t('identity.required');

      case KYC_STATUSES.ACCEPTABLE:
      case KYC_STATUSES.SUCCESS:
        return t('identity.success');

      case KYC_STATUSES.ERROR:
        return t('identity.failed');

      case KYC_STATUSES.NOT_STARTED:
        if (userHasUnverifiedProduct) {
          return t('identity.required');
        }
        return;
      default:
        return;
    }
  }, [level1Data?.verificationStatus, t, userHasUnverifiedProduct]);

  const kycLevel2Message = useMemo(() => {
    switch (level2Data?.verificationStatus) {
      case KYC_STATUSES.PENDING:
      case KYC_STATUSES.PROCESSING:
        return t('address.required');

      case KYC_STATUSES.ACCEPTABLE:
      case KYC_STATUSES.SUCCESS:
        return t('address.success');

      case KYC_STATUSES.ERROR:
        return t('address.failed');

      default:
        return undefined;
    }
  }, [level2Data?.verificationStatus, t]);

  if (!kycLabelStatus || !userData || (!kycLevel2Message && !kycLevel1Message)) {
    return null;
  }

  return (
    <Link to='/kyc/identity-verification'>
      <KYCMenuItem className='px-4 py-3' status={kycLabelStatus}>
        <div className='flex items-center gap-2'>
          <div>
            {kycLevel1Message && <BodyText className='font-bold text-gray-100'>{kycLevel1Message}</BodyText>}
            {kycLevel2Message && <BodyText className='font-bold text-gray-100 mt-1'>{kycLevel2Message}</BodyText>}
          </div>
          <div className='flex-shrink-0 text-gray-100 ml-auto'>
            <ChevronRightIcon />
          </div>
        </div>
      </KYCMenuItem>
    </Link>
  );
};

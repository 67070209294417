import styled from 'styled-components';

import { Theme } from '@root/infra/theme';

import { Button, ButtonSize } from './button';

const getPadding = (theme: Theme, size?: ButtonSize): string => {
  switch (size) {
    case 1:
      return `${theme.spacing(0.5)}px`;
    case 2:
      return `${theme.spacing(1.5)}px`;
    case 3:
      return `${theme.spacing(2.5)}px`;
    case 5:
      return `${theme.spacing(4)}px`;
    default:
      return `${theme.spacing(3)}px`;
  }
};

const getFontSize = (theme: Theme, size?: ButtonSize) => {
  switch (size) {
    case 1:
    case 2:
      return `${theme.spacing(3)}px`;
    case 3:
      return `${theme.spacing(5)}px`;
    case 5:
      return `${theme.spacing(7)}px`;
    default:
      return `${theme.spacing(6)}px`;
  }
};

const getBorderRadius = (theme: Theme, size?: ButtonSize) => {
  switch (size) {
    case 1:
    case 2:
    case 3:
      return `${theme.spacing(2)}px`;
    default:
      return `${theme.spacing(3)}px`;
  }
};

export const IconButton = styled(Button)<{ circle?: boolean }>`
  padding: ${({ theme, size }) => getPadding(theme, size)};
  font-size: ${({ theme, size }) => getFontSize(theme, size)};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme, circle, size }) => (circle ? '50%' : getBorderRadius(theme, size))};

  &:before {
    border-radius: ${({ theme, circle, size }) => (circle ? '50%' : getBorderRadius(theme, size))};
  }
`;

IconButton.defaultProps = { circle: true };

import { FC, HTMLAttributes } from 'react';
import styled from 'styled-components';

import { HEADER_HEIGHT } from '../header/header.ui';
import { SIDEBAR_CLOSED_WIDTH, SIDEBAR_OPENED_WIDTH } from '../sidebar/sidebar.ui';

const Wrapper = styled.div<{ sidebarIsOpen?: boolean; isDesktop?: boolean }>`
  padding-left: ${({ sidebarIsOpen, isDesktop }) => (isDesktop ? (sidebarIsOpen ? `${SIDEBAR_OPENED_WIDTH}px` : `${SIDEBAR_CLOSED_WIDTH}px`) : 0)};
  padding-top: ${() => HEADER_HEIGHT}px;
  transition: padding-left 0.2s 0.2s;
`;

const Content: FC<HTMLAttributes<HTMLDivElement>> = ({ children }) => {
  return <main>{children}</main>;
};

export const LayoutUI = { Wrapper, Content };

import { takeLatest, call, put, SagaReturnType } from 'redux-saga/effects';

import { CurrenciesActions, currenciesSlice } from './currencies.slice';
import { getCurrencies } from '../services/get-currencies';
import { saveUserCurrency } from '@root/modules/currencies/services/save-user-currency';
import { AuthActions, authSelector, authSlice } from '@root/modules/auth/redux';
import { select } from '@redux-saga/core/effects';
import { currenciesSelector } from '@root/modules/currencies/redux/currencies.selector';

function* handleFetch() {
  const result: SagaReturnType<typeof getCurrencies> = yield call(getCurrencies);

  if (result.status === 200) {
    yield put(currenciesSlice.actions.fetchFulfilled(result.payload));
  } else {
    yield put(currenciesSlice.actions.fetchRejected(result.payload));
  }
}

function* handleSaveCurrency(action: CurrenciesActions.SetUserCurrency) {
  const auth = yield select(authSelector);

  if (auth.user) {
    const result: SagaReturnType<typeof saveUserCurrency> = yield call(saveUserCurrency, action.payload);
    if (result.status === 400) {
      console.log("currency wasn't save");
    }
  }
}

function* handleCurrencyUpdate(action: AuthActions.FetchProfileFulfilled) {
  const userCurrency = yield select(currenciesSelector.userCurrency);
  const list = yield select(currenciesSelector.list);

  const userCurrencyInList = list.some((item) => item.code === action.payload?.currencyCode);
  if (action.payload.currencyCode && action.payload.currencyCode !== userCurrency.code && userCurrencyInList) {
    yield put(currenciesSlice.actions.setUserCurrency(action.payload.currencyCode));
  }

  if (!action.payload.currencyCode && userCurrency.code) {
    const result: SagaReturnType<typeof saveUserCurrency> = yield call(saveUserCurrency, userCurrency.code);
    if (result.status === 400) {
      console.log("currency wasn't save");
    }
  }
}

export function* currenciesFlowSaga() {
  yield takeLatest(currenciesSlice.actions.fetchPending.type, handleFetch);
  yield takeLatest(authSlice.actions.fetchProfileFulfilled.type, handleCurrencyUpdate);
  yield takeLatest(currenciesSlice.actions.setUserCurrency.type, handleSaveCurrency);
  yield call(handleFetch);
}

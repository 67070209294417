import { PayloadAction } from '@reduxjs/toolkit';
import { Draft } from 'immer/dist/types/types-external';

import { IRemoteData } from '@root/shared/remote-data';

export const createFetchingReducers = <
  State extends IRemoteData<any> = IRemoteData<any>,
  // eslint-disable-next-line
  PendingAction extends PayloadAction<any> = PayloadAction<any>,
  FulFilledAction extends PayloadAction<any> = PayloadAction<any>,
  RejectedAction extends PayloadAction<string> = PayloadAction<string>,
>() => {
  return {
    fetchPending: (state: Draft<State>) => {
      state.error = null;
      state.isLoading = true;
    },
    fetchFulfilled: (state: Draft<State>, action: FulFilledAction) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    fetchRejected: (state: Draft<State>, action: RejectedAction) => {
      state.error = action.payload;
      state.isLoading = false;
    },
  };
};

import styled from 'styled-components';

export const MenuWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.gray['800']};
  border-radius: ${({ theme }) => `${theme.spacing(3)}px`};
  box-shadow: ${({ theme }) => theme.shadows[4]};
`;

export const MenuItem = styled.button`
  display: block;
  padding: ${({ theme }) => `${theme.spacing(3)}px ${theme.spacing(6)}px`};
  background-color: transparent;
  border: none;
`;

import { IMembershipLevel } from '../types/membership-level';

export class MembershipLevelsMapper {
  public static toDomain(obj): IMembershipLevel {
    return {
      memberName: obj.memberName,
      label: obj.name,
      iconImage: obj.iconUrl || '/images/membership/rookie.svg',
      cardImage: obj.imageUrl || '/images/membership/rookie-3x.png',
      color: obj.color || '#ccc',
      addonsDiscount: obj.addonsDiscount,
      isCurrent: false,
      min: Math.floor(obj.minSpendings),
      max: Math.floor(obj.maxSpendings),
      id: obj.id,
      priority: obj.priority,
      recurringPrice: obj.recurringPrice,
      name: obj.name,
    };
  }
}

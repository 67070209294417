import { ButtonContainer } from '@root/shared/ui';
import { FC } from 'react';
import { Button } from './button';
import { ModalComponent } from './modal';
import { BodyTextLarge } from './text';

export interface ConfirmationModalProps {
  isModalOpen: boolean;
  closeModal: () => void;
  handleConfirm: () => void;
  handleCancel: () => void;
  title?: JSX.Element;
  content: string;
  confirmButtonText: string;
  cancelButtonText: string;
  danger?: boolean;
}

export const ConfirmationModal: FC<ConfirmationModalProps> = ({
  isModalOpen,
  closeModal,
  title,
  content,
  cancelButtonText,
  confirmButtonText,
  danger,
  handleConfirm,
  handleCancel,
}) => {
  return (
    <ModalComponent modalIsOpen={isModalOpen} closeModal={closeModal} headerTitle={title}>
      <div>
        <div className='pt-4 pb-8 px-10'>
          <BodyTextLarge>{content}</BodyTextLarge>
        </div>
        <ButtonContainer withPadding>
          <div className='flex items-center flex-col lg:flex-row '>
            <Button color='secondary' className='w-full' onClick={handleCancel}>
              {cancelButtonText}
            </Button>
            <Button color={!danger ? 'primary' : 'danger'} className='lg:ml-3 w-full mt-3 lg:mt-0' onClick={handleConfirm}>
              {confirmButtonText}
            </Button>
          </div>
        </ButtonContainer>
      </div>
    </ModalComponent>
  );
};

ConfirmationModal.defaultProps = {
  danger: false,
};

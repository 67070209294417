import { ToastContainer as BaseToastContainer } from 'react-toastify';
import styled from 'styled-components';

export const ToastOverlay = styled.div`
  position: fixed;
  z-index: 1010;
  top: 2em;
  right: -350px;
  width: 300px;
  transition: 1s;

  &.--visible {
    right: 1em;
  }
`;

export const ToastWrapper = styled.div<{ type: 'info' | 'success' | 'danger' }>`
  border-radius: ${({ theme }) => theme.spacing(1)}px;
  padding: 1em 1.5em;
  width: 100%;
  height: 100%;
  z-index: 100000;

  ${({ type, theme }) => {
    if (type === 'info') {
      return `background-color: ${theme.colors.info[500]}`;
    }
    if (type === 'success') {
      return `background-color: ${theme.colors.success[500]}`;
    }
    if (type === 'danger') {
      return `background-color: ${theme.colors.danger[500]}`;
    }
  }}
`;

export const ToastContainer = styled(BaseToastContainer)`
  --toastify-color-progress-light: ${({ theme }) => theme.colors.gray[100]};

  .Toastify__toast {
    padding: 0;
    background: transparent;
  }

  .Toastify__toast-body {
    padding: 0;
  }

  .Toastify__close-button {
    display: none;
  }
`;

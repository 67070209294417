export const checkItemInSessionStorage = (key, item) => {
  const items = JSON.parse(sessionStorage.getItem(key) as string) || [];

  return items.some((arrItem) => arrItem === item);
};

export const addItemToSessionStorage = (key, item) => {
  const closedBeforeItems = JSON.parse(sessionStorage.getItem(key) as string) || [];

  sessionStorage.setItem(key, JSON.stringify([...closedBeforeItems, item]));
};

export const removeAllItemsFromSessionStorage = (key) => {
  sessionStorage.removeItem(key);
};

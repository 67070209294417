import { Theme } from '@root/infra/theme';
import { FC, forwardRef, ReactNode } from 'react';
import styled, { StyledComponentProps } from 'styled-components';

export const Tabs = styled.div`
  display: flex;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
`;

const getTabButtonMargin = (theme: Theme, large: boolean = false, colorful: boolean = false) => {
  if (colorful) return 0;

  return large ? `${theme.spacing(0)}px ${theme.spacing(5)}px` : `${theme.spacing(0)}px ${theme.spacing(6)}px`;
};

const getTabButtonPadding = (theme: Theme, large: boolean = false, colorful: boolean = false) => {
  if (colorful) {
    if (large) return `0 ${theme.spacing(1)}px ${theme.spacing(3)}px ${theme.spacing(1)}px`;

    return `0 ${theme.spacing(1)}px ${theme.spacing(2.5)}px ${theme.spacing(1)}px`;
  }

  return `0 ${theme.spacing(1)}px`;
};

const getTabButtonFontWeight = (large: boolean = false, colorful: boolean = false, active: boolean = false) => {
  if (colorful) return active ? 'bold' : 'normal';

  return large ? 'bold' : 'normal';
};

const getTabButtonLineHeight = (large: boolean = false, colorful: boolean = false) => {
  if (colorful) return 'normal';

  return large ? '32px' : '13px';
};

const getTabButtonUnderlineColor = (theme: Theme, colorful: boolean = false, active: boolean = false) => {
  if (!active) return 'transparent';
  if (colorful) return theme.colors.gradients[1];

  return theme.colors.gray['900'];
};

const getTabButtonUnderlineHeight = (large: boolean = false, colorful: boolean = false) => {
  if (colorful) return '4px';

  return large ? '2px' : '1px';
};

type TabButtonExtraProps = { active?: boolean; large?: boolean; colorful?: boolean };
type TabButtonProps = StyledComponentProps<'button', Theme, TabButtonExtraProps, any>;

const TabButton = styled.button<TabButtonExtraProps>`
  position: relative;
  margin: ${({ theme, large, colorful }) => getTabButtonMargin(theme, large, colorful)};
  padding: ${({ theme, large, colorful }) => getTabButtonPadding(theme, large, colorful)};
  color: ${({ active, theme }) => active && theme.colors.gray['900']};
  transition: color 0.2s;
  font-size: ${({ large }) => (large ? '16px' : '13px')};
  font-weight: ${({ large, colorful, active }) => getTabButtonFontWeight(large, colorful, active)};
  line-height: ${({ large, colorful }) => getTabButtonLineHeight(large, colorful)};
  display: inline-flex;
  align-items: center;

  &.active {
    color: ${({ theme }) => theme.colors.gray['900']};

    &:after {
      background-color: ${({ theme }) => theme.colors.gray['900']};
    }
  }

  &:hover {
    color: ${({ theme }) => theme.colors.gray['900']};
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: ${({ theme, colorful }) => (colorful ? 0 : `-${theme.spacing(1)}px`)};
    height: ${({ large, colorful }) => getTabButtonUnderlineHeight(large, colorful)};
    width: 100%;
    margin: 0 auto;
    background: ${({ theme, colorful, active }) => getTabButtonUnderlineColor(theme, colorful, active)};
    left: 0;
    transition: background-color 0.2s;
    border-top-left-radius: ${({ colorful }) => (colorful ? '50px' : 0)};
    border-top-right-radius: ${({ colorful }) => (colorful ? '50px' : 0)};
  }

  &:disabled {
    color: ${({ theme }) => theme.colors.gray['200']};
  }
`;

export type TabProps = TabButtonProps & { icon?: ReactNode };

export const Tab: FC<TabProps> = forwardRef<HTMLButtonElement, TabProps>(function Tab({ icon, children, ...props }, ref) {
  return (
    <TabButton ref={ref} {...props}>
      {icon && <span className='mr-2 text-lg'>{icon}</span>}
      {children}
    </TabButton>
  );
});

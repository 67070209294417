import { FC, SVGProps } from 'react';

export const PendingIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M12.9276 6.28296C13.337 6.28457 13.6676 6.61751 13.666 7.02661C13.6593 8.72104 13.0151 10.418 11.7181 11.7141C9.11299 14.3175 4.88857 14.3175 2.28348 11.7141C1.81848 11.2495 1.4417 10.7333 1.14443 10.1889C0.948329 9.82981 1.08066 9.37983 1.44 9.18385C1.79935 8.98788 2.24963 9.12012 2.44574 9.47923C2.67919 9.90673 2.97249 10.3076 3.33174 10.6666C5.35789 12.6914 8.64366 12.6914 10.6698 10.6666C11.6765 9.66052 12.1783 8.34414 12.1835 7.02079C12.1851 6.61169 12.5183 6.28136 12.9276 6.28296Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M10.6702 3.33345C8.64404 1.30866 5.35827 1.30866 3.33212 3.33345C2.3407 4.32421 1.83616 5.61508 1.81538 6.91557C1.80885 7.32462 1.47173 7.65092 1.06241 7.64439C0.653089 7.63786 0.326568 7.30097 0.333104 6.89192C0.359763 5.22352 1.00858 3.56031 2.28386 2.28589C4.88895 -0.317464 9.11337 -0.317464 11.7185 2.28589C12.1835 2.75058 12.5602 3.26677 12.8575 3.81112C13.0536 4.17023 12.9213 4.62021 12.5619 4.81618C12.2026 5.01216 11.7523 4.87992 11.5562 4.52081C11.3227 4.09331 11.0294 3.69247 10.6702 3.33345Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M12.5519 0.806763C12.9613 0.806763 13.2932 1.1384 13.2932 1.5475V4.16676C13.2932 4.57586 12.9613 4.9075 12.5519 4.9075H9.93166C9.52229 4.9075 9.19043 4.57586 9.19043 4.16676C9.19043 3.75766 9.52229 3.42602 9.93166 3.42602H11.8107V1.5475C11.8107 1.1384 12.1426 0.806763 12.5519 0.806763Z" fill="currentColor"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M0.708984 9.83339C0.708984 9.42429 1.04085 9.09265 1.45022 9.09265H4.07048C4.47986 9.09265 4.81172 9.42429 4.81172 9.83339C4.81172 10.2425 4.47986 10.5741 4.07048 10.5741H2.19145V12.4527C2.19145 12.8618 1.85959 13.1934 1.45022 13.1934C1.04085 13.1934 0.708984 12.8618 0.708984 12.4527V9.83339Z" fill="currentColor"/>
    </svg>
  );
};


import { Theme } from '@root/infra/theme';
import { CloseIcon } from '@root/shared/ui/icons/close-icon';
import { FC, MouseEventHandler, ReactNode } from 'react';
import styled, { css, StyledComponentProps } from 'styled-components';

type TagButtonProps = StyledComponentProps<'button', Theme, {}, any>;

const TagIcon = styled.div`
  height: 48px;
  width: 48px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.gray['800']};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  transition: color 0.2s;

  & svg path {
    fill: white;
    stroke: white;
    filter: none;
  }

  & svg * {
    strokewidth: 0;
  }

  & svg rect,
  & svg circle {
    fill: transparent;
    stroke: transparent;
  }
`;

const TagText = styled.div`
  font-weight: normal;
  padding: 13px;
`;

const TagButton = styled.button<{ active?: boolean; size?: 'smallest' | 'small' | 'mild' | 'normal' }>`
  background-color: transparent;
  box-sizing: border-box;
  border: 2px solid ${({ theme }) => theme.colors.gray['600']};
  color: ${({ theme }) => theme.colors.gray['100']};
  padding: ${({ theme }) => `${theme.spacing(0.5)}px`};
  display: inline-flex;
  align-items: center;
  border-radius: 28px;
  transition: border-color 0.2s;

  ${({ size }) =>
    size === 'small' &&
    css`
      ${TagText} {
        padding: 6px 12px;
      }
    `}

  ${({ size }) =>
    size === 'smallest' &&
    css`
      ${TagText} {
        padding: 0 8px;
        font-size: 13px;
        line-height: 15px;
      }

      ${TagIcon} {
        height: 16px;
        width: 16px;
        font-size: 6px;
      }
    `}

  ${({ size }) =>
    size === 'mild' &&
    css`
      ${TagText} {
        padding: 0 12px;
      }

      ${TagIcon} {
        height: 32px;
        width: 32px;
        font-size: 12px;
      }
    `}

  &:hover {
    border-color: ${({ theme }) => theme.colors.info['400']};

    ${TagIcon} {
      color: ${({ theme }) => theme.colors.info['400']};
    }
  }

  ${({ active, theme }) =>
    active &&
    css`
      border-color: ${theme.colors.info['400']};
      background-color: ${theme.colors.info['400']};

      ${TagIcon} {
        color: ${theme.colors.info['400']};
        background-color: ${theme.colors.gray['100']};

        & svg path {
          fill: ${theme.colors.info['400']};
          stroke: ${theme.colors.info['400']};
        }

        & svg g {
          filter: none;
        }
      }
    `}
`;

TagButton.defaultProps = { size: 'normal' };

export type TagProps = TagButtonProps & { icon?: ReactNode; removable?: boolean; onRemove?: MouseEventHandler<any> };

export const Tag: FC<TagProps> = ({ icon, children, removable, onRemove, ...props }) => {
  return (
    <TagButton {...props}>
      {icon && <TagIcon>{icon}</TagIcon>}
      <TagText>{children}</TagText>
      {removable && (
        <TagIcon as='button' className='bg-gray-500 text-gray-1000' onClick={onRemove}>
          <CloseIcon />
        </TagIcon>
      )}
    </TagButton>
  );
};

import { H6 } from '@root/shared/ui';
import { rgba } from 'polished';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(12, 12, 12, 0.5);
  z-index: 1003;
`;

const Wrapper = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.primary[700]};
  color: ${({ theme }) => theme.colors.gray[100]};
  z-index: 1004;
  padding-top: 0;
  overflow: auto;
  transform: ${({ isOpen }) => (!isOpen ? 'translate(-100%)' : 'translate(0)')};
  transition: transform 0.2s;
  box-shadow: ${({ theme }) => theme.shadows[5]};
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray['600']};
`;

const ActionBtn = styled.button`
  font-size: 18px;
`;

const Brand = styled.div`
  height: 29px;
  width: 137px;
`;

const Section = styled.div<{ noBorder?: boolean; isDisabled?: boolean }>`
  border-bottom: 1px solid ${({ theme, noBorder }) => (!noBorder ? theme.colors.gray['600'] : 'transparent')};
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'auto')};
  opacity: ${({ isDisabled }) => (isDisabled ? '50%' : '100%')};
`;

const SectionTitle = styled(H6)`
  font-weight: bold;
  margin-top: ${({ theme }) => `${theme.spacing(4)}px`};
  margin-bottom: ${({ theme }) => `${theme.spacing(4)}px`};
`;

const Link = styled(NavLink)<{ disabled?: boolean }>`
  display: flex;
  width: 100%;
  align-items: center;
  padding: ${({ theme }) => `${theme.spacing(5)}px`} ${({ theme }) => theme.spacing(6)}px;
  text-align: left;
  cursor: pointer;
  opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'unset')};

  &:hover {
    background-color: ${({ theme, disabled }) => (disabled ? 'transparent' : rgba(theme.colors.primary['400'], 0.5))} !important;
  }

  &.active {
    background-color: ${({ theme }) => rgba(theme.colors.primary['400'], 0.6)};
  }
`;

const Item = styled.button<{ isActive?: boolean; disabled?: boolean }>`
  display: flex;
  width: 100%;
  align-items: center;
  padding: ${({ theme }) => `${theme.spacing(5)}px`} ${({ theme }) => theme.spacing(6)}px;
  text-align: left;
  cursor: pointer;
  opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'unset')};

  background-color: ${({ theme, isActive }) => !!isActive && rgba(theme.colors.primary['400'], 0.6)};

  &:hover {
    background-color: ${({ theme, disabled }) => (disabled ? 'transparent' : rgba(theme.colors.primary['400'], 0.5))} !important;
  }
`;

const ItemText = styled.div`
  flex: 1;
`;

const ItemIcon = styled.div`
  font-size: 16px;
  margin-right: ${({ theme }) => theme.spacing(4)}px;
`;

const ColorfulItem = styled(Item)`
  color: ${({ theme }) => theme.colors.text.colorful};
  font-weight: bold;
`;

const LinkItem = styled.div<{ disabled?: boolean }>`
  margin-left: ${({ theme }) => `${theme.spacing(2)}px`};
  margin-top: ${({ theme }) => `${theme.spacing(3)}px`};
  margin-bottom: ${({ theme }) => `${theme.spacing(3)}px`};
  opacity: ${({ disabled }) => disabled && 0.5};
  pointer-events: ${({ disabled }) => disabled && 'none'};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
`;

export { Overlay, Wrapper, Header, ActionBtn, Brand, Section, SectionTitle, Item, ItemText, ItemIcon, ColorfulItem, Link, LinkItem };

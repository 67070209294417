import styled from 'styled-components';

import { BodyText, HeadlineSmall } from '@root/shared/ui';

export const Card = styled.div`
  background-color: ${({ theme }) => theme.colors.gray['100']};
  min-width: 260px;
  max-width: 320px;
  border-radius: ${({ theme }) => `${theme.spacing(2)}px`};
  box-shadow: ${({ theme }) => theme.shadows[3]};
  overflow: hidden;
`;

export const CardHeader = styled.div``;

export const CardText = styled.div`
  padding-top: ${({ theme }) => `${theme.spacing(4)}px`};
`;

export const CardFooter = styled.div``;

export const EnrollerInfoWrapper = styled.div`
  border-top: 2px solid ${({ theme }) => theme.colors.gray['300']};
`;

export const Title = styled(HeadlineSmall)`
  margin-bottom: 0;
  font-weight: bold;
`;

export const Subtitle = styled(BodyText)`
  margin-bottom: 0;
  color: ${({ theme }) => theme.colors.gray['400']};
`;

export const MenuItem = styled.a`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => `${theme.spacing(3)}px ${theme.spacing(4)}px `};
  transition: background-color 0.2s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray['200']};
  }
`;

export const MenuItemContent = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: ${({ theme }) => `${theme.spacing(3)}px ${theme.spacing(6)}px `};
  transition: background-color 0.2s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.gray['200']};
  }
`;

export const Username = styled.div`
  min-width: 70px;
  max-width: 120px;

  ${BodyText} {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

import styled from 'styled-components';

export const ProgressBar = styled.div`
  height: 6px;
  background: ${({ theme }) => theme.colors.success[400]};
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
  margin-top: ${({ theme }) => `${theme.spacing(2)}px`};
  transition: width 0.5s;

  @media (min-width: 994px) {
    margin-top: -6px;
  }
`;

import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IPurchasedProduct } from '@root/modules/products/types/purchased-product';

export type PurchasedProductsState = {
  data: IPurchasedProduct[];
  isLoading: boolean;
  error: string | null;
  loaded: boolean;
};

export namespace PurchasedProductsActions {
  export type FetchPending = PayloadAction;
  export type FetchFulfilled = PayloadAction<IPurchasedProduct[]>;
  export type FetchRejected = PayloadAction<string>;
  export type SetLoading = PayloadAction<boolean>;
  export type RESET = PayloadAction;
}

export type PurchasedProductsSliceReducer = {
  fetchPending: CaseReducer<PurchasedProductsState, PurchasedProductsActions.FetchPending>;
  fetchFulfilled: CaseReducer<PurchasedProductsState, PurchasedProductsActions.FetchFulfilled>;
  fetchRejected: CaseReducer<PurchasedProductsState, PurchasedProductsActions.FetchRejected>;
  setLoading: CaseReducer<PurchasedProductsState, PurchasedProductsActions.SetLoading>;
  reset: CaseReducer<PurchasedProductsState, PurchasedProductsActions.RESET>;
};

const purchasedProductsState: PurchasedProductsState = {
  isLoading: true,
  data: [],
  error: null,
  loaded: false,
};

export const purchasedProductsSlice = createSlice<PurchasedProductsState, PurchasedProductsSliceReducer>({
  name: 'purchasedProducts',
  initialState: purchasedProductsState,
  reducers: {
    fetchPending: (state) => {
      state.error = null;
      state.isLoading = true;
    },
    fetchFulfilled: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.loaded = true;
    },
    fetchRejected: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    reset: (state) => {
      state.isLoading = false;
      state.data = [];
      state.error = null;
    },
  },
});

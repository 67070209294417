import { ICurrency } from '@root/modules/currencies/types/currency';

type CustomRound = (value: number, roundRule: ICurrency['round']) => number;

export const customRound: CustomRound = (value, roundRule) => {
  switch (roundRule) {
    case 0:
      return Math.floor(value);
    case 0.25:
      return Math.round(value * 4) / 4;
    case 0.5:
      return Math.round(value * 2) / 2;
    case 0.99: {
      const stringValue = value.toString();
      if (stringValue.includes('.')) {
        const [stringBeforePoint, stringAfterPoint] = stringValue.split('.');
        if (Number(stringAfterPoint[0]) >= 5) {
          return Number(`${stringBeforePoint}.99`);
        } else {
          return Number(stringBeforePoint);
        }
      }
      return value;
    }
    case 1:
      return Math.round(value);
  }
};

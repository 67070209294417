import { createSelector, Selector } from '@reduxjs/toolkit';

import { AppState } from '@root/infra/redux';

import { SubscriptionsState } from './subscriptions.slice';

export const subscriptionsSelector = createSelector<[Selector<AppState, SubscriptionsState>], SubscriptionsState>(
  (state) => state.subscriptions,
  (subscriptions) => subscriptions,
);

export const toggleRenewalSubscriptionSelector = createSelector<[Selector<AppState, SubscriptionsState>], SubscriptionsState['toggleRenewal']>(
  (state) => state.subscriptions,
  (subscriptions) => subscriptions.toggleRenewal,
);

import { Theme } from '@root/infra/theme';
import { UserDropdownKycStatus } from '@root/modules/kyc/hooks/use-userdropdown-kyc-status';
import styled from 'styled-components';

const getButtonColor = (theme: Theme, status?: UserDropdownKycStatus) => {
  switch (status) {
    case 'success':
      return theme.colors.success[500];
    case 'pending':
      return theme.colors.warning[500];
    case 'failed':
      return theme.colors.danger[500];
    default:
      return theme.colors.gray[200];
  }
};

const Icon = styled.div<{ status: UserDropdownKycStatus }>`
  border-radius: 50px;
  position: absolute;
  color: ${({ theme }) => theme.colors.gray[100]};
  padding: ${({ theme }) => theme.spacing(1)}px;
  right: ${({ theme }) => `-${theme.spacing(1)}px`};
  top: 0;
  background: ${({ theme, status }) => getButtonColor(theme, status)};
`;

const Button = styled.button<{ status: UserDropdownKycStatus; hovered: boolean }>`
  color: ${({ theme, status }) => getButtonColor(theme, status)};
  border-radius: 100px;
  font-size: 14px;
  position: relative;
  background: ${({ theme, hovered }) => (hovered ? theme.colors.gray[100] : theme.colors.gray[200])};
  padding: ${({ theme }) => theme.spacing(1.5)}px;
`;

export const UserDropdownButtonUI = { Button, Icon };

import { fetcher } from '@root/infra/fetcher';
import type { IHttpResponse } from '@root/shared/http-service';
import { AxiosError } from 'axios';
import { INSTANCE_TYPES } from '../constants/instance-types';

export type RedirectionUrlResponseResponse = IHttpResponse<200, string> | IHttpResponse<400, string>;
export type RedirectionUrlRequestData = { instanceType: INSTANCE_TYPES, countryCode: string };

export const getRedirectionUrlService = async (data: RedirectionUrlRequestData): Promise<RedirectionUrlResponseResponse> => {
  try {
    const response = await fetcher.get(`/region/redirection-url?instanceType=${data.instanceType}&countryCode=${data.countryCode}`);
    return {
      status: 200,
      payload: response.data.data,
    };
  } catch (e) {
    const error = e as AxiosError;
    console.error(e);

    if (error.isAxiosError && error.response) {
      return {
        status: 400,
        payload: error.response.data.message,
      };
    }

    return {
      status: 400,
      payload: 'Bad request',
    };
  }
};

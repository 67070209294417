import { UpgradeSubscriptionItem } from './upgrade-subscription-item';
import { UpgradeSubscriptionItemsUI } from './upgrade-subscription-items.ui';
import { ICanBeSwitchedToItem } from '@root/modules/products/types/purchased-product';
import { FC, Fragment } from 'react';

const { Label } = UpgradeSubscriptionItemsUI;

interface IProps {
  items: ICanBeSwitchedToItem[];
}

export const UpgradeSubscriptionItems: FC<IProps> = ({ items }) => {
  return (
    <Fragment>
      <Label recurringType='monthly' className='px-4 py-px lg:py-px flex flex-col sm:flex-row sm:justify-between sm:items-center sm:gap-2'></Label>
      {items.map((item) => (
        <UpgradeSubscriptionItem item={item} key={item.id} />
      ))}
    </Fragment>
  );
};

import capitalize from 'lodash/capitalize';

import { IPaymentItem } from '../types/payment-item';

import paypal from '../assets/paypal.png';
import visa from '../assets/visa.png';
import master from '../assets/master.png';
import maestro from '../assets/maestro.png';
import money from '../assets/money.png';

export const getPaymentMethodInfo = (method: IPaymentItem) => {
  if (method.type === 'paypal') {
    return {
      title: 'PayPal',
      imageUrl: paypal,
    };
  }

  if (method.type === 'payment-card') {
    switch (method.cardType) {
      case 'visa':
        return {
          title: 'Visa',
          imageUrl: visa,
        };
      case 'mastercard':
        return {
          title: 'Master',
          imageUrl: master,
        };
      case 'maestro':
        return {
          title: 'Maestro',
          imageUrl: maestro,
        };
    }
  }

  return {
    title: capitalize(method.type),
    imageUrl: money,
  };
};

export function detectCardType(cardNumber) {
  // Define the patterns for different card types
  const cardPatterns = {
    amex: /^3[47]/,
    visa: /^4/,
    mastercard: /^5[1-5]/,
    discover: /^6(?:011|5[0-9]{2})/,
    maestro: /^(50|5[6-9]|6)/,
    // Add more card types and their respective patterns if needed
  };

  // Check the card number against known patterns
  for (const [cardType, pattern] of Object.entries(cardPatterns)) {
    if (cardNumber?.match(pattern)) {
      return cardType;
    }
  }

  return 'Unknown'; // If the card type is not recognized
}

export function validateLuhnAlgorithm(cardNumber) {
  let sum = 0;
  let isEven = false;

  for (let i = cardNumber.length - 1; i >= 0; i--) {
    let digit = parseInt(cardNumber.charAt(i), 10);

    if (isEven) {
      digit *= 2;
      if (digit > 9) {
        digit -= 9;
      }
    }
    sum += digit;
    isEven = !isEven;
  }
  return sum % 10 === 0;
}

export function validateExpiryDate(expiryDate) {
  if (!expiryDate) {
    return false;
  }

  const [exMonth, exYear] = expiryDate.split('/');
  const today = new Date();
  const someday = new Date();

  if (parseInt(exMonth) > 12 || parseInt(exMonth) < 1) return false;

  someday.setFullYear(Number('20' + exYear), Number(exMonth), 1);

  return someday >= today;
}

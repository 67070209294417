import { Theme } from '@root/infra/theme';
import { ChangeEvent, FC, RefObject, useEffect, useRef } from 'react';
import InputMask, { Props as InputMaskProps } from 'react-input-mask';
import styled from 'styled-components';

export interface OTPCellProps {
  error?: boolean;
  transparent?: boolean;
}

const getBorderColor = (theme: Theme, error?: boolean) => {
  return error ? `1px solid ${theme.colors.danger['500']}` : `1px solid ${theme.colors.gray['400']}`;
};

export const OTPCellInput = styled(InputMask)<OTPCellProps>`
  background: ${({ theme }) => theme.colors.gray['100']};
  color: ${({ theme }) => theme.colors.gray['900']};
  border-radius: ${({ theme }) => `${theme.spacing(1.5)}px`};
  border: ${({ error, theme }) => getBorderColor(theme, error)};
  font-size: 36px;
  width: 60px;
  height: 80px;
  cursor: pointer;
  outline: none;
  text-align: center;

  &::placeholder {
    color: ${({ theme }) => theme.colors.gray['600']};
  }

  &:focus {
    border-color: ${({ theme, transparent }) => !transparent && theme.colors.success[500]};
    caret-color: ${({ theme }) => theme.colors.success[500]};
  }

  &:disabled {
    opacity: 0.3;
    pointer-events: none;
    background-color: ${({ theme }) => theme.colors.gray['200']};
    border: 1px solid ${({ theme }) => theme.colors.gray['400']};
    color: ${({ theme }) => theme.colors.gray['600']};
  }
`;

const maskPlaceholder = '_';

type Props = {
  values: string[];
  order: number;
  inputRefs: RefObject<any>[];
  mask: string | string[];
  setInputRef(ref: RefObject<any>, order: number): void;
  onChange(value: string, order: number): void;
} & InputMaskProps;

export const OTPCell: FC<Props> = ({ values, order, inputRefs, setInputRef, onChange, mask, ...props }) => {
  const ref = useRef<any>(null);

  useEffect(() => {
    if (ref?.current) {
      setInputRef(ref, order);
    }
  }, [ref, setInputRef, order]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (value?.length > 0 && value !== maskPlaceholder && inputRefs[order + 1]?.current) {
      inputRefs[order + 1]?.current?.getInputDOMNode().focus();
      inputRefs[order + 1]?.current?.getInputDOMNode().setSelectionRange(0, 0);
    }

    if(!!onChange) {
      if(value === maskPlaceholder){
        onChange('', order);
        return;
      } 

      onChange(value, order);
    }
  };

  const beforeMaskedValueChange = (newState, oldState, userInput) => {
    const { value, selection } = newState;

    if (selection?.start >= mask.length && userInput) {
      return {
        value: userInput,
        selection,
      };
    }

    return {
      value,
      selection,
    };
  };

  // @ts-ignore
  return <OTPCellInput ref={ref} value={values[order]} onChange={handleChange} mask={mask} beforeMaskedValueChange={beforeMaskedValueChange} {...props} type="tel"/>;
};

import { fetcher } from '@root/infra/fetcher';
import { INSTANCE_TYPES } from '@root/modules/auth/constants/instance-types';
import type { IHttpResponse } from '@root/shared/http-service';
import { AxiosError } from 'axios';
import { IDecryptSSO } from '../types';

export type DecryptSSOResponse = IHttpResponse<200, IDecryptSSO> | IHttpResponse<400, string>;

export const decryptSSOService = async (token: string): Promise<DecryptSSOResponse> => {
  try {
    const response = await fetcher.get(`/auth/sso/${token}?instanceType=${INSTANCE_TYPES.CUSTOMER_PORTAL}`);
    return {
      status: 200,
      payload: response.data.data,
    };
  } catch (e) {
    const error = e as AxiosError;
    console.error(e);

    if (error.isAxiosError && error.response) {
      return {
        status: 400,
        payload: error.response.data.message,
      };
    }

    return {
      status: 400,
      payload: 'Bad request',
    };
  }
};

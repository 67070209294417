import { fetcher } from '@root/infra/fetcher';
import { IHttpResponse } from '@root/shared/http-service';
import { IService } from '@root/modules/products/types/product-service';

export type GetServicesResponse = IHttpResponse<200, IService[]> | IHttpResponse<400, string>;

export const getServices = async (): Promise<GetServicesResponse> => {
  try {
    const response = await fetcher.get(`shop/services`);
    return {
      status: 200,
      payload: response.data.data.map((item) => ({ ...item, bullets: item.bullets.length ? item.bullets.map((bullet) => ({ text: bullet, points: [] })) : item.bullets })),
    };
  } catch (e) {
    console.error(e);
    return {
      status: 400,
      payload: 'Bad request',
    };
  }
};

import { Theme } from '@root/infra/theme';
import { CloseIcon } from '@root/shared/ui/icons/close-icon';
import { FC, useCallback, useState } from 'react';
import styled from 'styled-components';
import { BodyText } from './text';

export type InfoMessageColor = 'danger' | 'warning' | 'success' | 'info' | 'default';

export interface InfoMessageProps {
  message: string;
  color?: InfoMessageColor;
}

const getBgColor = (theme: Theme, color?: InfoMessageColor) => {
  switch (color) {
    case 'danger':
      return theme.colors.danger['500'];
    case 'warning':
      return theme.colors.warning['600'];
    case 'success':
      return theme.colors.success['500'];
    case 'info':
      return theme.colors.info['500'];
    default:
      return theme.colors.gray['500'];
  }
};

const Container = styled.div<{ color: InfoMessageColor }>`
  background: ${({ theme, color }) => getBgColor(theme, color)};
  color: ${({ theme }) => theme.colors.gray[100]};
  display: flex;
  padding: ${({ theme }) => `${theme.spacing(4)}px`};
  border-radius: ${({ theme }) => `${theme.spacing(3)}px`};
`;

const CloseButton = styled.button<{ color: InfoMessageColor }>`
  background-color: ${({ theme }) => theme.colors.gray[100]};
  color: ${({ theme, color }) => getBgColor(theme, color)};
  width: 16px;
  height: 16px;
  padding: 5px;
  border-radius: 100px;
  flex-shrink: 0;
  margin-right: ${({ theme }) => `${theme.spacing(3)}px`};
  margin-top: 2px;

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const InfoMessage: FC<InfoMessageProps> = ({ message, color = 'default' }) => {
  const [isMessageShow, setIsMessageShow] = useState(true);

  const handleClose = useCallback(() => {
    setIsMessageShow(false);
  }, []);

  if (!isMessageShow) {
    return null;
  }

  return (
    <Container color={color}>
      <CloseButton type='button' color={color} onClick={handleClose}>
        <CloseIcon />
      </CloseButton>
      <BodyText>{message}</BodyText>
    </Container>
  );
};

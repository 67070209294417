import { KeyboardEvent, ChangeEvent, forwardRef, useCallback, useState } from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';

import { CloseIcon } from '@root/shared/ui/icons/close-icon';
import { SearchIcon } from '@root/shared/ui/icons/search-icon';

import { Input, InputProps } from '../fields';
import { Button } from '../button';

const SearchInput = styled(Input)`
  height: 44px;
  padding-left: 10px;
  padding-right: 10px;
  flex: 1;
  border-color: transparent;
`;

const SearchButton = styled(Button)`
  border-radius: 0 10px 10px 0;
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;
  min-height: 44px;
  border: none;

  &::before {
    border-radius: 0 10px 10px 0;
  }

  &:hover {
    background: ${({ theme }) => `linear-gradient(90deg, ${theme.colors.success['400']} 0%, #41C7FF 100%)`};
    color: ${({ theme }) => theme.colors.gray[100]};
  }
`;

const DeleteButton = styled.button`
  padding: 4px;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.gray['300']};
  margin-right: 14px;
  height: 16px;
  width: 16px;
  transform: translateY(-50%);
  top: 50%;
  right: 0;
  position: absolute;
  font-size: 8px;

  &::after {
    border-radius: 50%;
  }

  svg {
    transform: rotate(-90deg);
    margin-left: 0;
    fill: ${({ theme }) => theme.colors.gray['800']};
  }

  &:hover {
    background: ${({ theme }) => rgba(theme.colors.gray['500'], 0.8)};
    border: none;
  }
`;

const Container = styled.div<{ size?: 'large' | 'normal' }>`
  display: flex;
  border: ${({ theme }) => `1px solid ${theme.colors.gray['300']}`};
  background-color: ${({ theme }) => theme.colors.gray['100']};
  border-radius: 12px;
  padding: ${({ size }) => (size === 'large' ? '3px' : '1px')};
  width: 100%;
  position: relative;
  box-shadow: ${({ size, theme }) => size === 'large' && `0 4px 14px 0 ${rgba(theme.colors.gray[1100], 0.2)}`};

  ${Input} {
    padding: ${({ size }) => size === 'large' && '5px 16px'};
    font-size: ${({ size }) => size === 'large' && '16px'};
    box-sizing: ${({ size }) => size === 'large' && 'content-box'};
  }

  ${SearchButton} {
    padding-left: ${({ size }) => size === 'large' && '21px'};
    padding-right: ${({ size }) => size === 'large' && '21px'};
    border-radius: ${({ size }) => (size === 'large' ? '0 8px 8px 0' : '0 10px 10px 0')};

    &:before {
      border-radius: ${({ size }) => (size === 'large' ? '0 8px 8px 0' : '0 10px 10px 0')};
    }
  }

  & .search-icon {
    font-size: ${({ size }) => (size === 'large' ? '30px' : '22px')};
    color: ${({ theme }) => theme.colors.gray['500']};
    margin-left: ${({ size }) => (size === 'large' ? '20px' : '14px')};
    transform: translateY(-2px);
  }

  &:focus-within {
    border: ${({ theme }) => `1px solid ${theme.colors.success['500']}`};
  }
  &:hover {
    border: ${({ theme }) => `1px solid ${theme.colors.success['500']}`};
  }
`;

export interface SearchFieldProps extends InputProps {
  onSearch?: (value: string) => void;
  onClear?: () => void;
  size?: 'large' | 'normal';
}

export const SearchField = forwardRef<any, SearchFieldProps>(function SearchField({ onSearch, onClear, size, ...inputProps }, ref) {
  const [value, setValue] = useState<string>('');

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  }, []);

  const handleSearch = useCallback(() => {
    onSearch?.(typeof inputProps.value === 'string' ? inputProps.value : value);
  }, [inputProps.value, onSearch, value]);

  const handleClear = useCallback(() => {
    setValue('');
    onSearch?.('');
  }, [onSearch]);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        handleSearch();
      }
    },
    [handleSearch],
  );

  return (
    <Container size={size} ref={ref}>
      <div className='flex items-center search-icon'>
        <SearchIcon />
      </div>
      <SearchInput transparent value={value} onKeyDown={handleKeyDown} onChange={handleChange} {...inputProps} />

      {(!!inputProps.value || !!value) && (
        <DeleteButton onClick={onClear || handleClear}>
          <CloseIcon />
        </DeleteButton>
      )}
    </Container>
  );
});

import { AxiosError } from 'axios';
import { fetcher } from '@root/infra/fetcher';
import { Id } from '@root/shared/constants';
import { IHttpResponse } from '@root/shared/http-service';
import { handleAxiosError } from '@root/shared/utils/handle-axios-error';

export type RemovePaymentMethodsResponse = IHttpResponse<200> | IHttpResponse<400, string>;

export const removePaymentItemService = async (id: Id): Promise<RemovePaymentMethodsResponse> => {
  try {
    await fetcher.post('/payments/method/deactivate', { paymentInstrumentId: id });

    return {
      status: 200,
      payload: null,
    };
  } catch (e) {
    return handleAxiosError(e as AxiosError);
  }
};

export const pageLinks = {
  login: '/login',
  signUp: '/signup',
  resetPassword: '/reset-password',
  forgotUsername: '/forgot-username',
  forgotUsernameSuccess: '/forgot-username/success',
  forgotPassword: '/forgot-password',
  home: '/',
  paymentMethods: '/payment-methods',

  // KYC
  kycActivation: '/kyc/activation',
  kycActivationReminder: '/kyc/activation-reminder',
  kycActivationStatus: '/kyc/activation-status',

  kycIdentityVerification: '/kyc/identity-verification',
  kycIdentityVerificationProcessing: '/kyc/identity-verification/processing',
  kycIdentityVerificationSuccess: '/kyc/identity-verification/success',
  kycIdentityVerificationFailed: '/kyc/identity-verification/failed',

  kycAddressVerification: '/kyc/address-verification',
  kycAddressVerificationProcessing: '/kyc/address-verification/processing',
  kycAddressVerificationSuccess: '/kyc/address-verification/success',
  kycAddressVerificationFailed: '/kyc/address-verification/failed',
};

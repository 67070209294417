import { Theme } from '@root/infra/theme';
import { CheckedIcon } from '@root/shared/ui/icons/checked-icon';
import { RadioOffIcon } from '@root/shared/ui/icons/radio-off-icon';
import { RadioOnIcon } from '@root/shared/ui/icons/radio-on-icon';
import { UncheckedIcon } from '@root/shared/ui/icons/unchecked-icon';
import { FC, Key, RefCallback, useMemo } from 'react';
import BaseSelect, {
  components as DefaultComponents,
  ControlProps,
  CSSObjectWithLabel,
  OptionProps,
  Props as BaseSelectProps,
  SingleValueProps,
  StylesConfig,
  ValueContainerProps,
} from 'react-select';
import styled, { CSSObject, useTheme, css } from 'styled-components';

export type OptionType = {
  value: Key;
  label: any;
  prefix?: any;
  suffix?: any;
  disabled?: boolean;
};
type IsMulti = boolean;

export type SelectExtraProps = {
  isMulti?: boolean;
  bordered?: boolean;
  colorful?: boolean;
  tiny?: boolean;
  forcePlaceholder?: boolean;
  checkedOption?: boolean;
  radioOption?: boolean;
  coloredBorder?: boolean;
  shadow?: number;
};
export type SelectProps = Omit<BaseSelectProps<OptionType>, 'isMulti'> & SelectExtraProps;

const useSelectStyles = (options: SelectExtraProps = {}) => {
  const theme: Theme = useTheme() as any;
  const { bordered, shadow, coloredBorder } = options;

  return useMemo<StylesConfig<OptionType, IsMulti>>(
    () => ({
      control: (base: CSSObjectWithLabel, props) => {
        let borderColor: string | undefined;
        let boxShadow: string | undefined;

        if (options.colorful) {
          borderColor = theme.colors.primary['300'];
        } else if (props.isFocused) {
          borderColor = theme.colors.success['500'];
        }

        if (options.colorful) {
          boxShadow = `0 0 0 1px ${theme.colors.primary['300']}`;
        } else if (props.isFocused) {
          boxShadow = shadow ? theme.shadows[shadow] : `0 0 0 1px ${theme.colors.success['400']}`;
        }

        return {
          ...(base as CSSObject),
          height: options.tiny ? 39 : 48,
          borderRadius: theme.spacing(3),
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: !bordered ? 'transparent' : options.colorful || (coloredBorder && props.isFocused) ? borderColor : theme.colors.gray['400'],
          backgroundColor: !bordered ? theme.colors.gray['100'] : 'transparent',
          fontWeight: options.colorful ? 700 : 400,
          boxShadow: !bordered && !options.colorful ? boxShadow : '',
          background: props.isDisabled ? theme.colors.gray['200'] : theme.colors.gray['100'],

          '&:hover': {
            borderColor: 'none',
          },
        };
      },
      valueContainer: (base: CSSObjectWithLabel, props: ValueContainerProps<OptionType, IsMulti>) =>
        ({
          ...base,
          maxHeight: 27,
          display: 'flex',
          opacity: props.hasValue ? (options.forcePlaceholder && !props.isMulti ? 0 : 1) : 1,
          padding: `0 ${theme.spacing(bordered ? 4 : 6)}px`,
          color: props.isDisabled ? theme.colors.gray['600'] : options.colorful ? theme.colors.primary[300] : theme.colors.gray['900'],
          fontSize: 14,
        } as CSSObjectWithLabel),
      multiValue: (base: CSSObjectWithLabel) =>
        ({
          ...base,
          opacity: 0,
          userSelect: 'none',
        } as CSSObjectWithLabel),
      singleValue: (base: CSSObjectWithLabel) =>
        ({
          ...base,
          color: options.colorful ? theme.colors.primary['300'] : theme.colors.gray[''],
        } as CSSObjectWithLabel),
      placeholder: (base: CSSObjectWithLabel) =>
        ({
          ...base,
          color: options.colorful ? theme.colors.primary['300'] : theme.colors.gray['400'],
        } as CSSObjectWithLabel),
      input: (base: CSSObjectWithLabel) =>
        ({
          ...base,
          color: options.colorful ? theme.colors.primary['300'] : theme.colors.gray['900'],
        } as CSSObjectWithLabel),
      indicatorsContainer: (base: CSSObjectWithLabel, props) =>
        ({
          ...base,
          paddingRight: theme.spacing(2),
          '[class*="indicatorContainer"]': {
            transition: 'transform 0.2s',
            transform: `rotate(${props.selectProps.menuIsOpen ? 180 : 0}deg)`,
            color: options.colorful ? theme.colors.primary['300'] : theme.colors.gray['900'],
            padding: '0',
          },
        } as CSSObjectWithLabel),
      indicatorSeparator: (): CSSObject => ({
        display: 'none',
      }),
      menu: (base: CSSObjectWithLabel) =>
        ({
          ...base,
          backgroundColor: theme.colors.gray['800'],
          borderRadius: theme.spacing(3),
          overflow: 'hidden',
          width: 'auto',
          minWidth: '100%',
          zIndex: 3,
        } as CSSObjectWithLabel),
      menuList: (base: CSSObjectWithLabel) =>
        ({
          ...base,
          scrollbarColor: theme.colors.gray['800'] + ' ' + theme.colors.gray['1000'],
          backgroundColor: theme.colors.gray['100'],
          '&::-webkit-scrollbar-track': {
            backgroundColor: theme.colors.gray['800'],
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: theme.colors.gray['1000'],
          },
        } as CSSObjectWithLabel),
      option: (base: CSSObjectWithLabel) =>
        ({
          ...base,
          backgroundColor: theme.colors.gray['100'],
          width: '100%',
          padding: `${theme.spacing(3.25)}px ${theme.spacing(6)}px`,
          '&:hover': {
            backgroundColor: theme.colors.gray['100'],
          },
        } as CSSObjectWithLabel),
    }),
    [bordered, coloredBorder, options.colorful, options.forcePlaceholder, options.tiny, shadow, theme],
  );
};

const SelectValueWrapper = styled.div`
  display: flex;
`;

const SelectOptionWrapper = styled.div<{ isDisabled?: boolean; isFocused?: boolean }>`
  display: flex;
  padding: ${({ theme }) => `${theme.spacing(3)}px ${theme.spacing(6)}px`};
  width: 100%;
  background-color: ${({ theme, isDisabled }) => (isDisabled ? theme.colors.gray['300'] : theme.colors.gray['100'])};
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'default')};

  &:hover {
    background-color: ${({ theme, isDisabled }) => (isDisabled ? theme.colors.gray['300'] : theme.colors.gray['200'])};
  }
    
  ${({ isFocused, isDisabled }) =>
    isFocused &&
    css`
      background-color: ${({ theme }) => (isDisabled ? theme.colors.gray['300'] : theme.colors.gray['200'])};
    `}
`;

const SelectOptionPrefix = styled.div`
  margin-right: ${({ theme }) => `${theme.spacing(4)}px`};
  font-size: 20px;

  & > * {
    height: 21px;
  }
`;

const RadioCheckOptionPrefix = styled(SelectOptionPrefix)`
  font-size: 16px;
  margin-right: ${({ theme }) => `${theme.spacing(4)}px`};
`;

const SelectOptionLabel = styled.div`
  white-space: nowrap;
  width: 100%;
`;

const SelectOptionSuffix = styled.div`
  margin-left: ${({ theme }) => `${theme.spacing(4)}px`};
  height: 21px;
`;

const SingleValue: FC<SingleValueProps<OptionType> & { innerRef?: RefCallback<HTMLDivElement> }> = ({ data, innerProps, innerRef }) => {
  return (
    <SelectValueWrapper {...innerProps} ref={innerRef}>
      <>
        <>{data.prefix && <SelectOptionPrefix>{data.prefix}</SelectOptionPrefix>}</>
        <>{data.label && <SelectOptionLabel>{data.label}</SelectOptionLabel>}</>
        <>{data.suffix && <SelectOptionSuffix>{data.suffix}</SelectOptionSuffix>}</>
      </>
    </SelectValueWrapper>
  );
};

const Option: FC<OptionProps<OptionType>> = ({ data, innerProps, innerRef, isDisabled, ...rest }) => {
  return (
    <SelectOptionWrapper isDisabled={isDisabled} {...innerProps} ref={innerRef} isFocused={rest.isFocused}>
      <>
        <>{data.prefix && <SelectOptionPrefix>{data.prefix}</SelectOptionPrefix>}</>
        <>{data.label && <SelectOptionLabel>{data.label}</SelectOptionLabel>}</>
        <>{data.suffix && <SelectOptionSuffix>{data.suffix}</SelectOptionSuffix>}</>
      </>
    </SelectOptionWrapper>
  );
};

const CheckOption: FC<OptionProps<OptionType>> = ({ data, isSelected, innerProps, innerRef, isDisabled }) => {
  return (
    <SelectOptionWrapper {...innerProps} isDisabled={isDisabled} ref={innerRef} className='px-4'>
      <>
        <RadioCheckOptionPrefix>{isSelected ? <CheckedIcon /> : <UncheckedIcon />}</RadioCheckOptionPrefix>
        <>{data.prefix && <SelectOptionPrefix className='mr-2'>{data.prefix}</SelectOptionPrefix>}</>
        <>{data.label && <SelectOptionLabel>{data.label}</SelectOptionLabel>}</>
        <>{data.suffix && <SelectOptionSuffix className='ml-2'>{data.suffix}</SelectOptionSuffix>}</>
      </>
    </SelectOptionWrapper>
  );
};

const RadioOption: FC<OptionProps<OptionType>> = ({ data, isSelected, innerProps, innerRef, isDisabled }) => {
  return (
    <SelectOptionWrapper {...innerProps} isDisabled={isDisabled} ref={innerRef} className='px-4'>
      <>
        <RadioCheckOptionPrefix>{isSelected ? <RadioOnIcon /> : <RadioOffIcon />}</RadioCheckOptionPrefix>
        <>{data.prefix && <SelectOptionPrefix className='mr-2'>{data.prefix}</SelectOptionPrefix>}</>
        <>{data.label && <SelectOptionLabel>{data.label}</SelectOptionLabel>}</>
        <>{data.suffix && <SelectOptionSuffix className='ml-2'>{data.suffix}</SelectOptionSuffix>}</>
      </>
    </SelectOptionWrapper>
  );
};

const HiddenControl: FC<ControlProps<OptionType>> = ({ children, ...props }) => {
  const placeholderStyle = useMemo(() => ({ paddingLeft: 18 }), []);

  return (
    <DefaultComponents.Control {...props}>
      <>
        {props.hasValue && <div style={placeholderStyle}>{props.selectProps.placeholder}</div>}
        <>{children}</>
      </>
    </DefaultComponents.Control>
  );
};

export const Select: FC<SelectProps> = ({ bordered, shadow, colorful, coloredBorder, tiny, forcePlaceholder, components, checkedOption, radioOption, ...props }) => {
  const styles = useSelectStyles({ bordered, colorful, tiny, forcePlaceholder, shadow, coloredBorder });

  const SelectedOption = useMemo(() => {
    if (checkedOption) {
      return CheckOption;
    }

    if (radioOption) {
      return RadioOption;
    }

    return Option;
  }, [checkedOption, radioOption]);

  return (
    <BaseSelect
      menuPlacement={props.menuPlacement || 'auto'}
      hideSelectedOptions={false}
      menuPosition='absolute'
      components={{
        SingleValue,
        Option: SelectedOption,
        Control: forcePlaceholder ? HiddenControl : DefaultComponents.Control,
        ...components,
      }}
      styles={styles}
      {...props}
    />
  );
};

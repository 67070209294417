import { ActionBtn, Item, Section, Link, ItemIcon } from '../components/menu/hamburger.ui';
import { authSlice } from '@root/modules/auth/redux';
import { UserDropdownKYCStatus } from '@root/modules/kyc/components/user-dropdown-kyc-status';
import { selectIsNavigationEnabled } from '@root/modules/kyc/redux/kyc.selector';
import { menuSubscriptionsWarningSelector } from '@root/modules/products/redux/purchased-products/purchased-products.selector';
import { BodyText, SubHeadline } from '@root/shared/ui';
import { WarningBadge } from '@root/shared/ui/components/warning-badge';
import { ChevronLeftIcon } from '@root/shared/ui/icons/chevron-icon';
import { GearIcon } from '@root/shared/ui/icons/gear-icon';
import { LogoutIcon } from '@root/shared/ui/icons/logout-icon';
import { FC, Fragment, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

export type IHamburgerSubMenu = 'user' | 'subscriptions';

type Props = {
  activeSubMenu: IHamburgerSubMenu;
  setActiveSubMenu: (category: IHamburgerSubMenu | null) => void;
  closeMenu: () => void;
};

const UserMenu = () => {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();

  const isNavigationEnabled = useSelector(selectIsNavigationEnabled);

  const handleUserLogout = useCallback(async () => {
    dispatch(authSlice.actions.loggedOut());
  }, [dispatch]);

  return (
    <Fragment>
      <UserDropdownKYCStatus />

      <Section noBorder isDisabled={!isNavigationEnabled}>
        <Link to='/settings'>
          <ItemIcon>
            <GearIcon />
          </ItemIcon>
          <BodyText>{t('AccountSettings')}</BodyText>
        </Link>
      </Section>

      <Item as='button' onClick={handleUserLogout}>
        <ItemIcon>
          <LogoutIcon />
        </ItemIcon>
        <BodyText>{t('Log_out')}</BodyText>
      </Item>
    </Fragment>
  );
};

const SubscriptionsMenu = () => {
  const { t } = useTranslation('common');

  const showSubscriptionWarning = useSelector(menuSubscriptionsWarningSelector);

  return (
    <Fragment>
      <Link to='/subscriptions'>
        <BodyText>
          {t('My Subscriptions')}
          {showSubscriptionWarning && <WarningBadge content='!' className='ml-2' />}
        </BodyText>
      </Link>
      <Link to='/membership'>
        <BodyText>{t('Membership')}</BodyText>
      </Link>
    </Fragment>
  );
};

export const HamburgerSubMenu: FC<Props> = ({ activeSubMenu, setActiveSubMenu, closeMenu }) => {
  const { t } = useTranslation('common');

  return (
    <Fragment>
      <Item as='span' className='px-6' onClick={() => setActiveSubMenu(null)}>
        <ActionBtn className='mr-3'>
          <ChevronLeftIcon width={16} height={16} />
        </ActionBtn>
        <SubHeadline>{t('Main Menu')}</SubHeadline>
      </Item>
      <Section className='border-none mb-auto'>
        {activeSubMenu === 'user' && <UserMenu />}
        {activeSubMenu === 'subscriptions' && <SubscriptionsMenu />}
      </Section>
    </Fragment>
  );
};

import styled from 'styled-components';

export const MembershipProgressWrapper = styled.div<{ count: number }>`
  display: grid;
  grid-template-columns: repeat(${({ count }) => count}, 1fr);
  grid-gap: 2px;
`;

export const MembershipProgressItem = styled.div`
  width: 100%;
  height: 8px;
  background: ${({ theme }) => theme.colors.gray[300]};
  overflow: hidden;
  position: relative;

  &:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;

export const MembershipItemContent = styled.div<{ progress: number; gradient: string }>`
  width: ${({ progress }) => progress}%;
  height: 100%;
  background: ${({ gradient }) => gradient};
  position: absolute;
  top: 0;
  left: 0;
`;

import { Id } from '@root/shared/constants';

import { INotification } from '../types/notification';

export const createNotification = (userId: Id, item: Omit<INotification, 'id'>): INotification => {
  return {
    ...item,
    id: userId + '_' + item.type,
  };
};

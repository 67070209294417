export const calculateMembershipProgress = (spentAmount: number, min: number, max: number) => {
  if (spentAmount < min) {
    return 0;
  }
  if (spentAmount > max) {
    return 100;
  }

  return Math.round(((spentAmount - min) / (max - min)) * 100);
};
